import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import useSession from 'core/hooks/use-session';
import { Card } from 'core/components/card';
import CollapsibleCard from 'core/components/collapsible-card';
import { Label } from 'core/components/label';
import { Loading } from 'core/components/loading';
import Section from 'core/components/section';
import { useToast } from 'core/components/toast';
import { useStore } from 'core/store';
import { ApplicationLinks } from './components/application-url-form';
import { Task } from './components/task';
import completedIcon from '../../assets/completed.svg';
import coffeeIcon from './assets/coffee.svg';
import useStyles from './onboarding-task-center.styles';

const OnboardingTaskCenter = () => {
  const classes = useStyles();
  const { canEditPolicies, canUploadDocuments } = useSession();
  const {
    account: { accountLoading, fname, lname, tasks, incompleteTasks, completedTasks, tasksError }
  } = useStore();
  const toast = useToast();

  useEffect(() => {
    if (tasksError) {
      toast.notify({
        type: 'error',
        message: tasksError
      });
    }
  }, [tasksError]);

  return (
    <Section
      title="Onboarding Task Center"
      rightLabel="Total"
      rightValue={tasks.length ? `${accountLoading ? '...' : incompleteTasks.length} incomplete tasks` : null}
    >
      {accountLoading ? (
        <Loading />
      ) : (
        <>
          {canEditPolicies && <ApplicationLinks />}
          {!tasks.length && (
            <Card>
              <div className={classes.empty}>
                <img className={classes.icon} src={coffeeIcon} alt="Coffee mug icon" />
                <Label className={classes.greenLabel}>Relax, there are no tasks to complete</Label>
              </div>
            </Card>
          )}
          {tasks.length > 0 && (
            <Card>
              {incompleteTasks.length ? (
                incompleteTasks.map((task, ix) => <Task key={ix} {...task} readOnly={!canUploadDocuments} />)
              ) : (
                <div className={classes.container}>
                  <img src={completedIcon} alt="Rounded checkmark" />
                  <Label type="darkGreenSmall">
                    All of {fname} {lname}’s tasks have been completed
                  </Label>
                </div>
              )}
              <CollapsibleCard
                className={classes.collapsible}
                content={
                  <>
                    <Label type="infoSubtitle" className={classes.greenLabel}>
                      Complete
                    </Label>
                    <Label type="noteSmall" className={classes.greenLabel}>
                      {completedTasks.length} {completedTasks.length === 1 ? 'task' : 'tasks'} completed
                    </Label>
                  </>
                }
                type="noBorders"
              >
                {completedTasks.map((task, ix) => (
                  <Task key={ix} {...task} />
                ))}
              </CollapsibleCard>
            </Card>
          )}
        </>
      )}
    </Section>
  );
};

export default observer(OnboardingTaskCenter);
