import React from 'react';

import { Label } from 'core/components/label';
import { withToast } from 'core/components/toast';

import useStyles from '../footer.styles';

const NoOptionsAvailable = () => {
  const classes = useStyles();

  return (
    <div className={classes.titleContainer}>
      <Label className={classes.invalidOptionTitle} type="subtitle">
        There are no valid policy options for this offer.
      </Label>
    </div>
  );
};
export default withToast(NoOptionsAvailable);
