import fetchRetry from 'fetch-retry';

import config from 'aws-exports';

const affinityLookupsEndpoint = `${config.coreResourcesEndpoint}/affinity_lookups`;
const agencyInformationEndpoint = `${config.coreResourcesEndpoint}/agency_information`;

const fetcher = (url, { retryOnNotFound = true, ...restRequestOptions }) =>
  fetchRetry(fetch)(url, {
    retryDelay: 1500,
    retryOn: function retryOn(attempt, error, response) {
      if (attempt > 10) {
        return false;
      }
      // retry on any network error, or 4xx or 5xx status codes
      if (error !== null || (response.status >= 400 && (response.status !== 404 || retryOnNotFound))) {
        return true;
      }
    },

    method: 'GET', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors',
    headers: {
      'X-Api-Key': config.coreResourcesApiKey
    },
    ...restRequestOptions
  }).then((response) => {
    if (!response.ok) {
      return Promise.reject(response);
    }
    return response.json();
  });

export const affinityLookupsRequest = ({ query = null, limit, isFuzzySearch, requestOptions = {} }) => {
  let queryStr = '';
  if (query) {
    queryStr += `?query=${encodeURIComponent(JSON.stringify(query))}`;
  }

  if (limit) {
    // eslint-disable-next-line no-underscore-dangle
    const _limit = `limit=${limit}`;
    queryStr += query ? `&${_limit}` : `?${_limit}`;
  }

  if (isFuzzySearch) {
    queryStr += query ? `&fuzzy=true` : `?fuzzy=true`;
  }
  return fetcher(`${affinityLookupsEndpoint}${queryStr}`, requestOptions);
};
export const agencyInformationRequest = (code) => {
  return fetcher(`${agencyInformationEndpoint}/${code}`, { retryOnNotFound: false });
};
