import React, { useCallback, useMemo, useRef, useEffect, useState, useContext } from 'react';
import { Form, Formik, useFormikContext } from 'formik';
import { observer } from 'mobx-react';

import { useStore } from 'core/store';
import { Label } from 'core/components/label';
import { Button } from 'core/components/button';
import { useQueryParams } from 'core/hooks/useQueryParams';
import { phoneNumberFormatter } from 'core/helpers/formatters';
import { AuthContext } from 'core/components/auth';
import { getNestedErrors } from 'quote/helpers/get-nested-errors';
import { getInitialValues } from './initial-values';
import { updateUserInputWithConnectedHomeValues } from 'quote/helpers/get-connected-home-new-sign-up-values';
import { validateQuoteForm } from './quote-forms.validations';
import { visibleSections, handleCleanUp } from './quote-forms.clean';
import { AddCar } from '../add-car';
import ApplicantDetails from '../applicant-details/applicant-details';
import { PersonalDetails } from '../personal-details';
import { AutoDetails } from '../auto-details';
import { Footer } from '../footer';
import { PriorAddress } from '../prior-address';
import { PropertyDetail } from '../property-detail';
import { Disclaimer } from '../disclaimer';
import useStyles from './quote-forms.styles';

const ScrollToError = ({ quoteErrors, sectionsToShow, setSectionsToShow, showAllSectionsClicked }) => {
  const { errors, isSubmitting, setFieldTouched, setFieldValue, values } = useFormikContext();
  const { isNewConstruction, isNewPurchase } = values;

  useEffect(() => {
    if (isSubmitting) {
      handleCleanUp(
        quoteErrors,
        sectionsToShow,
        isNewConstruction,
        isNewPurchase,
        setFieldValue,
        setSectionsToShow,
        showAllSectionsClicked
      );
    }
  }, [isSubmitting]);

  useEffect(() => {
    if (Object.keys(errors).length && isSubmitting) {
      const smoothScrollToElementById = (id) =>
        document.getElementById(id).scrollIntoView({ block: 'center', behavior: 'smooth' });
      const errorPaths = getNestedErrors(errors);
      // touch all nested paths
      for (const path of errorPaths) {
        path.includes('.') && setFieldTouched(path);
      }
      try {
        if (errorPaths?.length > 1 && errorPaths[0] === 'fcraDisclaimer') {
          smoothScrollToElementById(errorPaths[1]);
        } else {
          smoothScrollToElementById(errorPaths[0]);
        }
      } catch (e) {
        // something went wrong just scroll to top
        smoothScrollToElementById('firstName');
      }
    }
  }, [errors, isSubmitting, setFieldTouched]);
  return null;
};

const formatAdditionalPhoneNumbers = (additionalPhoneNumbers) => {
  return additionalPhoneNumbers
    .filter((phoneDetail) => phoneDetail.phoneNumber)
    .map((phoneDetail) => {
      return {
        phoneNumber: phoneNumberFormatter({ phoneNumber: phoneDetail.phoneNumber }),
        note: phoneDetail?.note ? phoneDetail.note.trim() : '',
        canText: phoneDetail.canText
      };
    });
};

const QuoteForms = () => {
  const store = useStore();
  const {
    quote: {
      requestQuoteAction,
      errors,
      offer,
      loading,
      hasRejections,
      prefillData,
      clearPrefillData,
      fetchAffinityData
    },
    affinityLookups
  } = store;

  const classes = useStyles();
  const form = useRef(null);
  const params = useQueryParams();
  const { allowedStates, user, isAgency } = useContext(AuthContext);

  // these states added to remotely collapse / expand two of the collapsible cards based on the
  // value of isNewConstruction or isNewPurchase( only uses Prior Address)
  const [showPriorAddressCallout, setShowPriorAddressCallout] = useState(false);
  const [showPropertyDetailsCallout, setShowPropertyDetailsCallout] = useState(false);
  const [sectionsToShow, setSectionsToShow] = useState([]);
  const [showAllSectionsClicked, setShowAllSectionsClicked] = useState(false);

  const handleSubmit = useCallback(
    async (values) => {
      const affinityData = affinityLookups.data?.find(({ affinity }) => affinity === values.affinity);
      // send in values with connected home toggled on for affinity codes that are home security partners
      if (affinityData?.data.homeSecurity) {
        values = updateUserInputWithConnectedHomeValues(values, affinityData);
      }

      if (values.additionalPhoneNumbers?.length) {
        values.additionalPhoneNumbers = formatAdditionalPhoneNumbers(values.additionalPhoneNumbers);
      }

      if (values.phone?.length) {
        values.phone = phoneNumberFormatter({ phoneNumber: values.phone.toString() });
      }

      values.isApartment = Boolean(values.policyType?.includes('R'));

      await requestQuoteAction(values, allowedStates);
      setSectionsToShow(
        visibleSections({
          errors,
          isNewConstruction: values.isNewConstruction,
          isNewPurchase: values.isNewPurchase,
          dirtySections: sectionsToShow,
          showAllFieldsClicked: showAllSectionsClicked
        })
      );
    },
    [requestQuoteAction, errors, sectionsToShow, showAllSectionsClicked, allowedStates, affinityLookups]
  );
  const marginBottom = useMemo(() => {
    let num = 32;
    if (hasRejections) {
      num += errors.length * 150;
    }
    if (offer) {
      num += 360;
    }
    return num;
  }, [offer, hasRejections, errors]);

  const validate = useCallback(
    (values) => {
      return validateQuoteForm(
        errors.map(({ code }) => Number(code)),
        values,
        allowedStates,
        isAgency,
        affinityLookups
      )
        .then(() => null)
        .catch((e) => {
          // log so we can quickly see validation issues in production
          // eslint-disable-next-line no-console
          console.log({ FormikErrors: e });
          return e;
        });
    },
    [errors, allowedStates, isAgency, affinityLookups]
  );

  const prefill = prefillData || params;
  const initialValues = getInitialValues(prefill, user);
  // reset the store or else we'll be stuck with the prefill data forever
  useEffect(() => {
    clearPrefillData();
    fetchAffinityData(initialValues.affinity);
  }, []);

  return (
    <>
      <div className={classes.container} style={{ marginBottom }}>
        <Label type="title" className={classes.title}>
          Create a New Offer
        </Label>
        <Formik
          innerRef={form}
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validate={validate}
          validateOnBlur={false}
          validateOnChange={false}
        >
          {({ errors: formikErrors }) => (
            <>
              <ApplicantDetails
                setShowPriorAddressCallout={setShowPriorAddressCallout}
                setShowPropertyDetailsCallout={setShowPropertyDetailsCallout}
                sectionsToShow={sectionsToShow}
                setSectionsToShow={setSectionsToShow}
                showAllSectionsClicked={showAllSectionsClicked}
                setShowAllSectionsClicked={setShowAllSectionsClicked}
              />
              <PersonalDetails sectionsToShow={sectionsToShow} />
              <PriorAddress showPriorAddressCallout={showPriorAddressCallout} sectionsToShow={sectionsToShow} />
              <AutoDetails sectionsToShow={sectionsToShow} />
              <AddCar sectionsToShow={sectionsToShow} />
              <PropertyDetail showPropertyDetailsCallout={showPropertyDetailsCallout} sectionsToShow={sectionsToShow} />
              <Disclaimer />
              <Form>
                <Button
                  loading={loading}
                  disabled={Boolean(loading || (formikErrors.affinity && isAgency))}
                  className={classes.submit}
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  See Price
                </Button>
                {formikErrors.affinity && isAgency && (
                  <Label type="largeWarning" className={classes.warning}>
                    {formikErrors.affinity}
                  </Label>
                )}
              </Form>
              <ScrollToError
                quoteErrors={errors}
                sectionsToShow={sectionsToShow}
                setSectionsToShow={setSectionsToShow}
                showAllSectionsClicked={showAllSectionsClicked}
              />
              <Footer hasRejections={!!hasRejections} />
            </>
          )}
        </Formik>
      </div>
    </>
  );
};

export default observer(QuoteForms);
