/* eslint-disable func-names */
import React, { useContext, useCallback, useState } from 'react';
import { observer } from 'mobx-react';
import { addDays, isBefore, isAfter, parseISO, format } from 'date-fns';
import { Formik, Form, yupToFormErrors } from 'formik';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';

import { useToast } from 'core/components/toast';
import { Loading } from 'core/components/loading';
import { AuthContext } from 'core/components/auth';
import { Button } from 'core/components/button';
import BaseDialog from 'core/components/base-dialog';
import { Label } from 'core/components/label';
import { useStore } from 'core/store';
import { NotificationCard } from 'core/components/notification-card';
import withDatePicker from 'core/components/with-date-picker';
import { FormField } from 'core/components/form';

import useStyles from './rewrite-modal.styles';

const policyTypeMap = { H: 'home', A: 'auto' };

const initialValues = {
  rewriteEffectiveDate: format(new Date(), 'yyyy-MM-dd'),
  internalNotes: ''
};

const RewriteModal = observer(({ setShowRewriteModal }) => {
  const {
    account: {
      policies: {
        policy: { rewritePolicy, policy }
      }
    },
    account: accountStore
  } = useStore();
  const history = useHistory();
  const { canBackDate } = useContext(AuthContext);
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const classes = useStyles();

  // set up dates
  const today = new Date().setHours(0, 0, 0, 0);
  const maxDate = addDays(today, 60);
  const minDate = canBackDate ? null : today;

  const generateNewPolicyId = useCallback(() => {
    const policyIdArray = policy.id.split('-');

    let policyCount = 0;
    if (accountStore.policies.list.length) {
      const policiesOfSameType = accountStore.policies.list.filter(
        (policyRecord) => policyRecord.policyType === policy.policyType
      );
      policyCount = policiesOfSameType.length
        ? new Set(policiesOfSameType.map((policyRecord) => policyRecord.id.split('-')[1])).size // [home, home, home, home2, home2, home3] => [home, home2, home3]
        : 0;
    }

    return `${policyIdArray[0]}-${policyTypeMap[policy.policyType]}${policyCount + 1}-001-${policyIdArray[3]}`;
  }, []);

  const submitRewrite = useCallback(
    async (details) => {
      setLoading(true);
      const { rewriteEffectiveDate, internalNotes } = details;
      const newPolicyResults = await rewritePolicy(policy.id, rewriteEffectiveDate, internalNotes);

      if (newPolicyResults.success) {
        const { protocol, host } = window.location;

        toast.notify({
          type: 'success',
          message: `${policy.id} has been rewritten to ${newPolicyResults.message}`,
          label: 'Go to new policy',
          action: window.open(
            `${protocol}//${host}/customer/${accountStore.id}/policy/${newPolicyResults.message}`,
            '_blank'
          )
        });
        setShowRewriteModal(false);
      } else {
        toast.notify({
          type: 'error',
          message: `Failed to rewrite the policy: ${newPolicyResults.message}`
        });
      }
    },
    [toast, setShowRewriteModal, history]
  );

  const validationSchema = () =>
    Yup.object().shape({
      internalNotes: Yup.string()
        .ensure()
        .trim()
        .test('minLength10', 'Internal notes must be 10 characters in length or more.', (val) => {
          return val.length >= 10;
        })
        .required('Internal notes are required!'),
      rewriteEffectiveDate: Yup.string()
        .test('isBeforeToday', 'You do not have permission to back date', function (val) {
          if (isBefore(parseISO(val), today)) {
            return canBackDate;
          }
          return true;
        })
        .test('minDate', `New Effective Date must be on or after ${minDate}`, function (val) {
          if (minDate) {
            return !isBefore(parseISO(val), minDate);
          }
          return true;
        })
        .test('maxDate', 'New policy cannot be more than 60 days in the future', function (val) {
          return !isAfter(parseISO(val), maxDate);
        })
    });

  const validate = useCallback((values) => {
    try {
      return validationSchema(values);
    } catch (err) {
      return yupToFormErrors(err);
    }
  }, []);

  return (
    <BaseDialog size="lg" onClose={() => setShowRewriteModal(false)} open>
      {loading ? (
        <Loading type="secondary" noBackground />
      ) : (
        <>
          <Label type={'greenBig'}>Rewrite this policy?</Label>
          <Label type="infoInnerTitle" inlineDisplay className={classes.mainLabel}>
            <span className={classes.boldedLetters}>{policy.id}</span> will be rewritten and remain canceled, and a new
            policy by the name of
            <span className={classes.boldedLetters}> {generateNewPolicyId()}</span> will be generated.
          </Label>

          <NotificationCard type="light" className={classes.notificationCard}>
            Any future effective changes to the policy will need to be redone in {generateNewPolicyId()}. This includes
            segments lost during a flat cancel.
          </NotificationCard>
          <Formik onSubmit={submitRewrite} initialValues={initialValues} validationSchema={validate}>
            <Form>
              <Grid container className={classes.formContainer}>
                <FormField
                  type="string"
                  id="internalNotes"
                  name="internalNotes"
                  label="Add An Internal Note"
                  mode="light"
                  xs={12}
                  ignoreGlobalDisabledState // cancel policy sets all form fields to disabled, so need to manually exclude this field
                />
                <Grid container direction="row" className={classes.buttonContainer}>
                  <FormField
                    name="rewriteEffectiveDate"
                    id="rewriteEffectiveDate"
                    type="date"
                    label="Select Effective Date"
                    mode="light"
                    xs={9}
                    disableFuture={false}
                    maxDate={maxDate}
                    minDate={minDate}
                    ignoreGlobalDisabledState // cancel policy sets all form fields to disabled, so need to manually exclude this field
                    className={classes.internalNotes}
                  />
                  <Button className={classes.buttons} type="submit" variant="contained" color="primary">
                    Rewrite policy
                  </Button>
                </Grid>
              </Grid>
            </Form>
          </Formik>
        </>
      )}
    </BaseDialog>
  );
});

export default withDatePicker(RewriteModal);
