import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import { FieldArray, getIn } from 'formik';
import { InputAdornment, Grid } from '@material-ui/core';

import { TextField } from '../../index';
import { Select } from '../select';
import DatePicker from '../date-picker';
import Switch from '../switch';
import Checkbox from '../checkbox';
import ValueField from '../value-field';
import { Slider } from '../slider';
import { FieldElementPropTypes } from './propTypes';
import { awsDateFormatter, awsDateToJs } from '../../helpers/formatters';
import RemoveButton from '../remove-button';
import AddButton from '../add-button';
import { Radio } from '../radio';

const noop = () => {};

const withDefaultArr = (cb) => (props) => {
  const { field } = props;
  if (!field.value || field.value.length === 0) {
    return cb({
      ...props,
      field: {
        ...field,
        value: [field.defaultValue]
      }
    });
  }
  return cb(props);
};

const buildItemKey = (item, defaultKey) => {
  const key = Object.keys(item).reduce((val, nextVal) => `${val}${nextVal}-`, '');
  if (!key) {
    return defaultKey;
  }
  return key;
};

const NumberFormatCustom = ({ inputRef, onChange, id, name, format, value, ...props }) => {
  return (
    <NumberFormat
      {...format}
      {...props}
      value={value || ''}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.floatValue,
            id,
            name
          }
        });
      }}
    />
  );
};
NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.number,
  format: PropTypes.shape({
    thousandSeparator: PropTypes.bool,
    decimalSeparator: PropTypes.bool,
    allowNegative: PropTypes.bool,
    prefix: PropTypes.string,
    suffix: PropTypes.string,
    mask: PropTypes.string
  })
};

NumberFormatCustom.defaultProps = {
  format: {},
  value: undefined
};

const fieldTypes = {
  string: ({
    field: { id, name, label, mode, props, value, onChange = noop },
    ctx: { handleChange, touched, errors, classes, setFieldTouched, setFieldValue }
  }) => (
    <TextField
      key={id}
      id={id}
      name={name || id}
      label={label}
      value={value || ''}
      onChange={(e) => {
        handleChange(e);
        setFieldTouched(id);
        onChange({ value: e.target.value, setFieldValue, setFieldTouched });
      }}
      error={touched[id] && !!getIn(errors, name || id)}
      helperText={touched[id] && getIn(errors, name || id)}
      mode={mode}
      fullWidth
      classes={classes[id]}
      style={{ marginRight: 8 }}
      {...props}
    />
  ),
  numeric: ({
    field: { id, name, label, mode, props, format, value, onChange = noop },
    ctx: { handleChange, touched, errors, classes, setFieldTouched, setFieldValue }
  }) => {
    const { inputProps, InputProps, ...elemProps } = props || {};
    return (
      <TextField
        {...elemProps}
        key={id}
        id={id}
        name={name || id}
        label={label}
        value={typeof value === 'number' ? value : undefined}
        onChange={(e) => {
          handleChange(e);
          setFieldTouched(id, !!value); // Avoid field touched by react-number-format onChange
          onChange({ value: e.target.value, setFieldValue, setFieldTouched });
        }}
        error={touched[id] && !!getIn(errors, name || id)}
        helperText={touched[id] && getIn(errors, name || id)}
        mode={mode}
        fullWidth
        classes={classes[id]}
        style={{ marginRight: 8 }}
        InputProps={{
          inputComponent: NumberFormatCustom,
          inputProps: {
            format
          },
          name: name || id,
          ...(inputProps || {}),
          ...(InputProps || {})
        }}
      />
    );
  },
  select: ({
    field: { id, name, label, mode, props, options, value, onChange = noop },
    ctx: { setFieldValue, touched, errors, classes, setFieldTouched }
  }) => (
    <Select
      key={id}
      id={id}
      name={name || id}
      label={label}
      onChange={(fieldName, fieldVal) => {
        setFieldValue(fieldName, fieldVal);
        setFieldTouched(id);
        onChange({ value: fieldVal, setFieldValue, setFieldTouched });
      }}
      value={value || value === 0 ? value : ''}
      error={touched[id] && !!getIn(errors, name || id)}
      helperText={touched[id] && getIn(errors, name || id)}
      options={options}
      mode={mode}
      fullWidth
      classes={classes[id]}
      {...props}
    />
  ),
  date: ({
    field: { id, name, label, mode, props, value, onChange = noop },
    ctx: { setFieldValue, touched, errors, classes, setFieldTouched }
  }) => (
    <DatePicker
      key={id}
      id={id}
      name={name || id}
      placeholder={label}
      onChange={(date) => {
        setFieldValue(name || id, awsDateFormatter(date.value));
        setFieldTouched(id);
        onChange({ value: awsDateFormatter(date.value), setFieldValue, setFieldTouched });
      }}
      value={typeof value === 'string' ? awsDateToJs(value) : value} // Avoid day change for timezone (aws returns date without time)
      error={touched[id] && !!getIn(errors, name || id)}
      helperText={touched[id] && getIn(errors, name || id)}
      mode={mode}
      fullWidth
      label={label}
      classes={classes[id]}
      {...props}
    />
  ),
  switch: ({
    field: { id, name, label, mode, props, value, onChange = noop },
    ctx: { setFieldValue, classes, setFieldTouched, touched, errors }
  }) => (
    <Switch
      key={id}
      id={id}
      name={name || id}
      value={!!value}
      mode={mode}
      label={label}
      classes={classes[id]}
      onChange={(e) => {
        setFieldValue(name || id, e.target.checked);
        setFieldTouched(id);
        onChange({ value: e.target.value, setFieldValue, setFieldTouched });
      }}
      error={touched[id] && !!getIn(errors, name || id)}
      helperText={touched[id] ? getIn(errors, name || id) : undefined}
      {...props}
    />
  ),
  checkbox: ({
    field: { id, name, label, mode, props, value, onChange = noop },
    ctx: { setFieldValue, setFieldTouched, touched, errors, classes }
  }) => (
    <Checkbox
      key={id}
      id={id}
      name={name || id}
      value={!!value}
      mode={mode}
      label={label}
      classes={classes[id]}
      onChange={(e) => {
        setFieldValue(name || id, e.target.checked);
        setFieldTouched(id);
        onChange({ value: e.target.value, setFieldValue, setFieldTouched });
      }}
      error={touched[id] && !!getIn(errors, name || id)}
      helperText={touched[id] ? getIn(errors, name || id) : undefined}
      {...props}
    />
  ),
  radio: ({
    field: { id, name, mode, options, props, value, onChange = noop },
    ctx: { setFieldValue, touched, errors, setFieldTouched, classes }
  }) => (
    <Radio
      key={id}
      id={id}
      name={name || id}
      value={value}
      mode={mode}
      options={options}
      classes={classes[id]}
      onChange={(e) => {
        setFieldValue(name || id, e.target.value);
        setFieldTouched(id);
        onChange({ value: e.target.value, setFieldValue, setFieldTouched });
      }}
      error={touched[id] && !!getIn(errors, name || id)}
      helperText={touched[id] ? getIn(errors, name || id) : undefined}
      {...props}
    />
  ),
  value: ({ field: { id, label, mode, props, value }, ctx: { classes } }) => (
    <ValueField label={label} value={value} mode={mode} classes={classes[id]} {...props} />
  ),
  slider: ({
    field: { id, label, mode, props, options, onChange = noop },
    ctx: { values, setFieldValue, classes, setFieldTouched }
  }) => (
    <Slider
      key={id}
      id={id}
      name={id}
      label={label}
      value={values[id]}
      options={options}
      mode={mode}
      classes={classes[id]}
      onUpdate={(op) => {
        setFieldValue(id, op);
        setFieldTouched(id);
        onChange({ value: op, setFieldValue, setFieldTouched });
      }}
      {...props}
    />
  ),
  array: withDefaultArr(({ field, ctx }) => {
    const { id, name, max, label, addLabel, itemsType, defaultValue, props, value, itemWidth } = field;
    const { disabled } = ctx;
    return (
      <FieldArray
        name={name || id}
        render={(arrayHelpers) => {
          const canAdd = max ? value.length < max : true;
          return (
            <>
              {Array.isArray(value) &&
                value.length > 0 &&
                value.map((item, index) => {
                  const last = index === value.length - 1;
                  const fieldName = `${name || id}.${index}`;
                  const key = typeof item === 'object' ? `${buildItemKey(item, fieldName)}-${index}` : fieldName;
                  // Avoid assignemnt to function param
                  const element = {
                    ...field,
                    name: fieldName,
                    label: canAdd && last && addLabel ? addLabel : `${index + 1}# ${label}`,
                    value: value[index] || defaultValue,
                    props: {
                      InputProps: {
                        ...(canAdd && last
                          ? {
                              endAdornment: (
                                <InputAdornment position="end">
                                  <AddButton
                                    type="full"
                                    disabled={disabled}
                                    onClick={() => {
                                      ctx.setFieldTouched(id);
                                      arrayHelpers.push(defaultValue);
                                    }}
                                  />
                                </InputAdornment>
                              )
                            }
                          : {
                              endAdornment: (
                                <InputAdornment position="end">
                                  <RemoveButton
                                    disabled={disabled}
                                    onClick={() => {
                                      const vals = [...value]; // Only need shallow copy
                                      vals.splice(index, 1);
                                      ctx.setFieldTouched(id);
                                      ctx.setFieldValue(name || id, vals);
                                    }}
                                  />
                                </InputAdornment>
                              )
                            })
                      },
                      ...props
                    }
                  };
                  return (
                    <Grid item key={key} xs={itemWidth || 12}>
                      {typeof fieldTypes[itemsType] === 'function' && fieldTypes[itemsType]({ field: element, ctx })}
                    </Grid>
                  );
                })}
            </>
          );
        }}
      />
    );
  }),
  submit: ({ field: { id, props, component: Component } }) => <Component key={id} id={id} type="submit" {...props} />
};

fieldTypes.string.propTypes = FieldElementPropTypes;
fieldTypes.numeric.propTypes = FieldElementPropTypes;
fieldTypes.select.propTypes = FieldElementPropTypes;
fieldTypes.radio.propTypes = FieldElementPropTypes;
fieldTypes.date.propTypes = FieldElementPropTypes;
fieldTypes.switch.propTypes = FieldElementPropTypes;
fieldTypes.checkbox.propTypes = FieldElementPropTypes;
fieldTypes.value.propTypes = FieldElementPropTypes;
fieldTypes.slider.propTypes = FieldElementPropTypes;
fieldTypes.array.propTypes = FieldElementPropTypes;
fieldTypes.submit.propTypes = FieldElementPropTypes;

export default fieldTypes;
