import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Paper, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { Label } from 'core';
import { Button } from 'core/components/button';
import LaunchIconWhite from 'core/assets/svg/launch-white.svg';
import infoIcon from 'core/assets/svg/info-green-blank-bg.svg';
import styles from './no-results.styles';

const NoResults = ({ classes, message, className, error }) => {
  const timeSyncError =
    error &&
    (error.message?.toLowerCase().includes('signature not yet current') ||
      error.message?.toLowerCase().includes('signature expired'));

  if (timeSyncError) {
    return (
      <Paper className={classNames(classes.container, className)}>
        <img src={infoIcon} alt="info" />
        <Label type="greenBig" className={classes.title}>
          We can't show you search results due to inconsistent time settings
        </Label>
        <Label type="infoValue" data-cy="no-results" className={classes.content}>
          To fix this, go to your settings and make sure the date and time you have is synced properly with your
          location. Then, refresh this page.
        </Label>
        <Grid container alignItems="center" justify="center" className={classes.buttons}>
          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                window.open('https://pureinfotech.com/sync-clock-windows-10/', '_blank');
              }}
            >
              <img src={LaunchIconWhite} className={classes.buttonIcon} alt="Launch Icon" />
              How to fix on Windows
            </Button>
          </Grid>

          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                window.open(
                  'https://support.apple.com/guide/mac-help/set-the-date-and-time-automatically-mchlp2996/mac#:~:text=On%20your%20Mac%2C%20choose%20Apple,may%20need%20to%20scroll%20down.)&text=Turn%20on%20%E2%80%9CSet%20time%20and,automatically%20using%20your%20current%20location.%E2%80%9D',
                  '_blank'
                );
              }}
            >
              <img src={LaunchIconWhite} className={classes.buttonIcon} alt="Launch Icon" />
              How to fix on Mac
            </Button>
          </Grid>
        </Grid>
      </Paper>
    );
  }

  return (
    <Paper className={classNames(classes.container, className)}>
      <Label type="infoValue" data-cy="no-results">
        {message}
      </Label>
    </Paper>
  );
};

NoResults.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  message: PropTypes.string
};

NoResults.defaultProps = {
  message: 'No data available for your search.',
  className: null
};

export default withStyles(styles)(NoResults);
