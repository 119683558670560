import React, { useState, useCallback } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';

import { AgencyPortalHeader } from './components/header';
import { AgencyTabs } from './components/agency-tabs';
import { Updates } from './components/updates';
import { ReportsAndCommissions } from './components/reports-commissions';
import { Underwriting } from './components/underwriting';
import { Tools } from './components/tools';
import { Training } from './components/training';
import { Marketing } from './components/marketing';
import { Claims } from './components/claims';
import { ContactUs } from './components/contact-us';
import { Footer } from './components/footer';

const routes = [
  'updates',
  'reports-and-commissions',
  'underwriting',
  'tools',
  'training',
  'marketing',
  'claims',
  'contact-us'
];

const AgencyPortal = () => {
  const history = useHistory();
  const [activeTab, setActiveTab] = useState(() => {
    const pathName = history.location.pathname.slice(8);
    return routes.indexOf(pathName);
  });

  const handleTabChange = useCallback(
    (_, tab) => {
      setActiveTab(tab);
      history.push(`/portal/${routes[tab]}`);
    },
    [setActiveTab, routes, history]
  );

  return (
    <>
      <AgencyPortalHeader />
      <AgencyTabs activeTab={activeTab} handleTabChange={handleTabChange} setActiveTab={setActiveTab} />
      <Switch>
        {activeTab === 0 && (
          <Route path="/portal/updates">
            <Updates />
          </Route>
        )}
        {activeTab === 1 && (
          <Route path="/portal/reports-and-commissions">
            <ReportsAndCommissions />
          </Route>
        )}
        {activeTab === 2 && (
          <Route path="/portal/underwriting">
            <Underwriting />
          </Route>
        )}
        {activeTab === 3 && (
          <Route path="/portal/tools">
            <Tools />
          </Route>
        )}
        {activeTab === 4 && (
          <Route path="/portal/training">
            <Training />
          </Route>
        )}
        {activeTab === 5 && (
          <Route path="/portal/marketing">
            <Marketing />
          </Route>
        )}
        {activeTab === 6 && (
          <Route path="/portal/claims">
            <Claims />
          </Route>
        )}
        {activeTab === 7 && (
          <Route path="/portal/contact-us">
            <ContactUs />
          </Route>
        )}
      </Switch>
      <Footer activeTab={activeTab} />
    </>
  );
};

export default AgencyPortal;
