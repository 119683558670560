import AWS from 'aws-sdk';
import algoliasearch from 'algoliasearch';
import { Auth } from 'aws-amplify';
import * as Sentry from '@sentry/react';

/** @type {algoliasearch.Client} */
let client;
let staffUsersClient;

async function initClient(searchApiKey) {
  const credentials = await Auth.currentCredentials();

  const ssmClient = new AWS.SSM({ region: 'us-east-1', credentials: Auth.essentialCredentials(credentials) });

  const getParametersResponse = await new Promise((resolve, reject) => {
    ssmClient.getParameters(
      {
        Names: ['ALGOLIA_APPLICATION_ID', 'ALGOLIA_SEARCH_STAFF_INDEX_KEY'],
        WithDecryption: true
      },
      (err, data) => {
        if (err) {
          // eslint-disable-next-line
          console.error(err);
          Sentry.captureException(
            JSON.stringify({
              err
            })
          );
          reject(err);
        }
        resolve(data);
      }
    );
  });

  const applicationId =
    getParametersResponse && getParametersResponse.Parameters.find((p) => p.Name === 'ALGOLIA_APPLICATION_ID').Value;
  const searchStaffUsersKey =
    getParametersResponse &&
    getParametersResponse.Parameters.find((p) => p.Name === 'ALGOLIA_SEARCH_STAFF_INDEX_KEY').Value;

  staffUsersClient = algoliasearch(applicationId, searchStaffUsersKey);

  if (!searchApiKey) {
    // user with no search credentials
    return;
  }

  client = algoliasearch(applicationId, searchApiKey);
}

export async function getClient(apiKey) {
  if (!client) {
    await initClient(apiKey);
  }

  return client;
}

export async function getStaffUsersClient() {
  if (!staffUsersClient) {
    await initClient();
  }
  return staffUsersClient;
}
