import React, { useMemo, useCallback } from 'react';
import { useFormikContext } from 'formik';
import { observer } from 'mobx-react';
import {
  editableResidenceInsuranceType,
  policyType,
  residenceInsuranceTypes,
  standaloneRentersStates,
  rentersPolicyAvailable,
  condoStates
} from '@ourbranch/lookups';

import { ExpandableCard } from 'core/components/expandable-card';
import { tooltipHoverTexts } from 'core/helpers/constants';
import { useStore } from 'core/store';
import { PolicyTypeOption } from './policy-type-option';
import { SwitchPolicy } from './switch-policy';

const policyTypeOptionsByState = (state) => {
  let policies = [policyType.Home, policyType.HomeBundle, policyType.HABundle, policyType.Auto, policyType.AutoBundle];
  if (rentersPolicyAvailable[state]) {
    policies.push(policyType.ARBundle);
  }

  if (standaloneRentersStates[state]) {
    policies.push(policyType.Renters);
  }

  if (condoStates[state]) {
    policies = [policyType.Condo, policyType.CABundle, ...policies];
  }

  return policies;
};

const policyTypeLabelMap = {
  [policyType.Home]: 'Home',
  [policyType.HomeBundle]: 'Home with Bundled Discount',
  [policyType.HABundle]: 'Home & Auto',
  [policyType.Auto]: 'Auto',
  [policyType.AutoBundle]: 'Auto with Bundled Discount',
  [policyType.ARBundle]: 'Auto & Renters',
  [policyType.Renters]: 'Renters',
  [policyType.Condo]: 'Condo',
  [policyType.CABundle]: 'Condo & Auto',
  CB: 'Condo with Bundled Discount'
};

const inputOptionsMap = {
  [policyType.Condo]: [{ value: policyType.Condo, label: 'Condo' }],
  [policyType.CABundle]: [{ value: policyType.CABundle, label: 'Condo & Auto' }],
  [policyType.Home]: [{ value: policyType.Home, label: 'Home' }],
  [policyType.HABundle]: [{ value: policyType.HABundle, label: 'Home & Auto' }],
  [policyType.HomeBundle]: [{ value: policyType.HomeBundle, label: 'Home with Bundled Discount' }],
  [policyType.Auto]: [{ value: policyType.Auto, label: 'Auto' }],
  [policyType.AutoBundle]: [{ value: policyType.AutoBundle, label: 'Auto with Bundled Discount' }],
  [policyType.ARBundle]: [{ value: policyType.ARBundle, label: 'Auto & Renters' }],
  [policyType.Renters]: [{ value: policyType.Renters, label: 'Renters' }]
};

const getOption = (type, offer) => {
  const isAutoBundle = type === policyType.AutoBundle;
  const isHomeBundle = type === policyType.HomeBundle;
  const option = offer.options.find((opt) => {
    if (isAutoBundle) {
      return [policyType.HABundle, policyType.CABundle].includes(opt.type);
    }
    if (isHomeBundle) {
      return opt.type === policyType.HABundle;
    }
    return opt.type === type;
  });

  if (!option) {
    return null;
  }

  const autoData = {
    autoPrice: option.autoBill,
    autoFrequency: offer.quote.global.autoPaymentType === 'I' ? 'Avg. Monthly' : '6 months'
  };

  const homeData = {
    homePrice: option.homeBill,
    homeFrequency: offer.quote.global.homeownersPaymentType === 'I' ? 'Avg. Monthly' : '12 months'
  };

  const rentersData = {
    rentersPrice: option.rentersBill,
    rentersFrequency: offer.quote.global.rentersPaymentType === 'I' ? 'Avg. Monthly' : '12 months'
  };

  const condoData = {
    condoPrice: option.condoBill,
    condoFrequency: offer.quote.global.condoPaymentType === 'I' ? 'Avg. Monthly' : '12 months'
  };

  if (isAutoBundle) {
    return autoData;
  }
  if (isHomeBundle) {
    return homeData;
  }
  return { ...homeData, ...autoData, ...rentersData, ...condoData };
};

const getResidenceInsuranceTypeByPolicyType = (type) => {
  switch (type) {
    case policyType.HABundle:
      return residenceInsuranceTypes.Home;
    case policyType.ARBundle:
    case policyType.Renters:
      return residenceInsuranceTypes.Renters;
    case policyType.Auto:
      return residenceInsuranceTypes.None_NotApplicable;
    default:
      return null;
  }
};

const PolicyPickerCard = observer(() => {
  const {
    offer: store,
    offer: {
      offer: {
        quote: {
          existingCustomer,
          offerings: { monolineAutoRejectCode }
        },
        options
      },
      priorQuoteWithPreBindUWRejections: { ineligibleForHome, ineligibleForAuto }
    }
  } = useStore();

  const { values, setFieldValue } = useFormikContext();

  const linkedToExistingCustomer = !!existingCustomer?.id;

  const policyTypeOptions = useMemo(() => {
    const baseOptions = policyTypeOptionsByState(store?.state);
    const filteredOptions = [];
    baseOptions.forEach((o) => {
      const isAutoBundle = o === 'AB';
      const isHomeBundle = o === 'HB';
      let shouldAddOption = false;
      if (isAutoBundle) {
        shouldAddOption = !!options.find((opt) =>
          [policyType.Auto, policyType.CABundle, policyType.HABundle].includes(opt.type)
        );
      } else if (isHomeBundle) {
        shouldAddOption = !!options.find((opt) => [policyType.Home, policyType.HABundle].includes(opt.type));
      } else {
        shouldAddOption = !!options.find((opt) => opt.type === o);
      }
      if (shouldAddOption) {
        filteredOptions.push(o);
      }
    });

    return filteredOptions;
  }, [store.state, options]);

  const hasMonolineAutoOption = options.some((opt) => {
    return opt.type === 'A';
  });

  const onClick = useCallback(
    (type, disabled) => {
      if (!disabled) {
        store.setSelectedOption(type);

        setFieldValue('noBindHome', type === policyType.AutoBundle);
        setFieldValue('noBindAuto', type === policyType.HomeBundle);

        // Set residenceInsuranceType when policy type changes
        if (editableResidenceInsuranceType.includes(store.state)) {
          const residenceInsuranceType = getResidenceInsuranceTypeByPolicyType(type);
          setFieldValue('auto.residenceInsuranceType', residenceInsuranceType);
        }
      }
    },
    [store.setSelectedOption, store.state, setFieldValue]
  );

  return (
    <ExpandableCard title="Type" badge={policyTypeLabelMap[values.selectedOption]}>
      {policyTypeOptions.map((type, ix) => {
        const option = getOption(type, store.offer);

        let disabled = false;
        let tooltip = null;
        let noHome = false;
        let noAuto = false;

        if (!hasMonolineAutoOption || monolineAutoRejectCode || ineligibleForAuto) {
          noAuto = type === policyType.Auto || (!linkedToExistingCustomer && type === policyType.AutoBundle);
          if (type === policyType.Auto) {
            tooltip = tooltipHoverTexts.noMonolineAuto;
          } else if (!linkedToExistingCustomer && type === policyType.AutoBundle) {
            tooltip = tooltipHoverTexts.noMonolineAutoWBundle;
          }
        }

        noHome = ineligibleForHome && [policyType.HomeBundle, policyType.Home, policyType.HABundle].includes(type);

        disabled =
          ([policyType.HomeBundle, policyType.AutoBundle].includes(type) && !linkedToExistingCustomer) ||
          noAuto ||
          noHome;

        if (!option) {
          return null;
        }

        return (
          <PolicyTypeOption
            onClick={onClick}
            key={ix}
            option={option}
            type={type}
            state={store.state}
            label={policyTypeLabelMap[type].toUpperCase()}
            disabled={disabled}
            fieldOptions={inputOptionsMap[type]}
            tooltip={tooltip}
            noHome={noHome}
          />
        );
      })}
      <SwitchPolicy />
    </ExpandableCard>
  );
});

export default PolicyPickerCard;
