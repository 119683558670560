/* eslint-disable no-console */
import React, { useEffect, useContext } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import {
  ConfirmSignIn,
  ConfirmSignUp,
  ForgotPassword,
  RequireNewPassword,
  SignIn,
  VerifyContact,
  withAuthenticator,
  AuthPiece
} from 'aws-amplify-react';
import debounce from 'lodash-es/debounce';
import {
  startRegistration,
  startAuthentication,
  browserSupportsWebAuthn,
  browserSupportsWebAuthnAutofill,
  platformAuthenticatorIsAvailable
} from '@simplewebauthn/browser';

import awsConfig from '../../aws-exports';
import {
  AuthContext,
  VerifyingMFA,
  RegisterMFA,
  VerifyMFA,
  RegisteringMFA,
  ErrorWithMFA,
  ErrorWithBrowser,
  CatchAuthErrors
} from 'core/components/auth';
import { PolicyRoute, CustomerRoute } from 'customer/components/provider';
import PreviewClarionDoorRequestDifference from 'customer/components/policy/preview-clariondoor-request-diff';
import { OfferProvider, OfferDetails, OfferHeader, CheckoutSidebar, Checkout, Purchased, PreBindUW } from '../../offer';
import LayoutWithSidebar from './layout-with-sidebar';
import Quote from '../../quote';
import Search from '../../search/search';
import AgencyPortal from '../../agency-portal';
import { ReferralRedirect } from '../../referral-redirect';
import ChangePassword from './change-password';
import { ProtectedRoute } from './protected-route';

const Routes = () => {
  const session = useContext(AuthContext);

  // Amplify doesn't have a straightforward way of checking that the refresh token has expired, so we resort to this.
  useEffect(() => {
    async function validateTokenOrLogout() {
      try {
        await Auth.currentSession();
      } catch (err) {
        await Auth.signOut();
      }
    }

    // Check that there's a valid session every 1 minute
    const interval = setInterval(validateTokenOrLogout, 1000 * 60);

    // On clicks, taps, or keypresses, check that there's a valid session -- debounced to 5 5 seconds for performance reasons
    const debouncedValidateTokenOrLogoutFunction = debounce(validateTokenOrLogout, 5000, {
      leading: true,
      trailing: false,
      maxWait: 5000
    });
    const events = ['mousedown', 'touchstart', 'keydown'];
    const eventListeners = events.map((event) =>
      document.addEventListener(event, debouncedValidateTokenOrLogoutFunction, true)
    );

    return () => {
      clearInterval(interval);
      events.forEach((event, idx) => {
        document.removeEventListener(event, eventListeners[idx]);
      });
    };
  }, []);

  if (session.loading) {
    return <></>;
  }

  return (
    <Switch>
      {session.showAgencyPortal ? (
        <Redirect exact from="/" to="/portal/updates" />
      ) : (
        <Redirect exact from="/" to="/search/offers" />
      )}
      <ProtectedRoute exact path="/quote" permission={session.canQuote} component={Quote} />
      <ProtectedRoute path="/portal" permission={session.showAgencyPortal} component={AgencyPortal} />
      <Route path="/search" component={Search} />
      <Route exact path="/account" component={ChangePassword} />
      <ProtectedRoute exact path="/offer/:offerId/purchased" permission={session.canBind}>
        {({
          history,
          match: {
            params: { offerId }
          }
        }) => <Purchased offerId={offerId} history={history} />}
      </ProtectedRoute>
      <ProtectedRoute exact path="/offer/:offerId/:option/checkout" permission={session.canViewCheckoutPage}>
        {({
          history,
          match: {
            params: { offerId, option }
          }
        }) => (
          <LayoutWithSidebar
            offerId={offerId}
            header={OfferHeader}
            content={Checkout}
            side={CheckoutSidebar}
            history={history}
            onBack={() => {
              history.push(`/offer/${offerId}`);
            }}
            option={option}
          />
        )}
      </ProtectedRoute>
      <ProtectedRoute
        exact
        path="/offer/:offerId/details"
        permission={session.canSeeFullOfferDetails}
        component={OfferDetails}
      />
      <ProtectedRoute
        exact
        path="/offer/:offerId/verifyUW"
        permission={session.canViewCheckoutPage}
        component={PreBindUW}
      />
      <ProtectedRoute path="/offer/:offerId" permission={session.canQuote || session.viewOnly}>
        {({
          match: {
            params: { offerId }
          }
        }) => <OfferProvider offerId={offerId} />}
      </ProtectedRoute>
      <ProtectedRoute
        path="/customer/:id/policy/:policyId/:policyPreviewId"
        permission={session.canViewClarionDoorData}
      >
        {({
          history,
          match: {
            params: { id, policyId, policyPreviewId }
          }
        }) => (
          <PreviewClarionDoorRequestDifference
            accountId={id}
            policyId={policyId}
            history={history}
            policyPreviewId={policyPreviewId}
          />
        )}
      </ProtectedRoute>
      <Route path="/customer/:id/policy/:policyId">
        {({
          history,
          match: {
            params: { id, policyId }
          }
        }) => {
          if (id !== policyId.split('-')[0]) {
            const correctedId = policyId.split('-')[0];
            return <Redirect to={`/customer/${correctedId}/policy/${policyId}`} />;
          }
          return <PolicyRoute accountId={id} policyId={policyId} history={history} />;
        }}
      </Route>
      <Route path="/customer/:id">
        {({
          history,
          match: {
            params: { id }
          }
        }) => <CustomerRoute accountId={id} history={history} />}
      </Route>
      <ProtectedRoute path="/staff/referral" component={ReferralRedirect} permission={session.canQuote} />
    </Switch>
  );
};

class ProvideMFA extends AuthPiece {
  constructor(props) {
    super(props);
    this.state = { mfaState: false, fullWebAuthnSupport: true };
    this.registerMfa = this.registerMfa.bind(this);
    this.useMfa = this.useMfa.bind(this);
    this.getWebauthnSupport = async () => ({
      browser: browserSupportsWebAuthn(),
      autofill: await browserSupportsWebAuthnAutofill(),
      platform: await platformAuthenticatorIsAvailable()
    });
  }

  async componentDidUpdate() {
    const webauthnSupport = await this.getWebauthnSupport();
    if (this.state.fullWebAuthnSupport && (!webauthnSupport.browser || !webauthnSupport.platform)) {
      this.setState({ ...this.state, fullWebAuthnSupport: false });
    }
    console.log({ webauthnSupport });

    if (!this.state.mfaState && this.props.authState === 'customConfirmSignIn') {
      if (this.props.authData.challengeParam?.registrationOptions) {
        this.setState({ mfaState: 'prompting' });
      }
      if (this.props.authData.challengeParam?.verificationOptions) {
        this.setState({ mfaState: 'prompting' });
      }
    }
  }

  async useMfa() {
    const verificationOptions = JSON.parse(this.props.authData.challengeParam.verificationOptions);
    try {
      const authResp = await startAuthentication(verificationOptions);
      this.setState({ mfaState: 'verifying' });
      const user = await Auth.sendCustomChallengeAnswer(this.props.authData, JSON.stringify(authResp));
      this.changeState('signedIn', user);
    } catch (error) {
      this.setState({ mfaState: 'error' });
      console.error({ error });
    }
  }

  async registerMfa() {
    const registrationOptions = JSON.parse(this.props.authData.challengeParam.registrationOptions);
    try {
      registrationOptions.rp.id = window.location.hostname;
      const attResp = await startRegistration(registrationOptions);
      this.setState({ mfaState: 'registering' });
      const user = await Auth.sendCustomChallengeAnswer(this.props.authData, JSON.stringify(attResp));
      this.changeState('signedIn', user);
    } catch (error) {
      this.setState({ mfaState: 'error' });
      console.error({ error });
    }
  }

  render() {
    if (this.props.authState === 'customConfirmSignIn') {
      if (!this.state.fullWebAuthnSupport) {
        return <ErrorWithBrowser />;
      }

      if (this.state.mfaState === 'verifying') {
        return <VerifyingMFA />;
      }

      if (this.state.mfaState === 'registering') {
        return <RegisteringMFA />;
      }

      if (this.state.mfaState === 'error') {
        return <ErrorWithMFA />;
      }

      if (this.props.authData.challengeParam?.registrationOptions) {
        return <RegisterMFA registerMfa={this.registerMfa} />;
      }

      if (this.props.authData.challengeParam?.verificationOptions) {
        return <VerifyMFA useMfa={this.useMfa} />;
      }
      return null;
    }

    return null;
  }
}

// listen, I know this is bad, but whatever amplify/cognito is doing for the auth isn't
// very overridable. if you remove this code, after someone signs in for the first time
// and enters their updated non-temporary password, the auth flow prompts them to enter SMS
// but it will always error out and say we can't use the same session. so instead of showing that screen,
// I force log them out so they can login with their new password.
class CatchFirstLogin extends AuthPiece {
  constructor(props) {
    super(props);
    this.state = { lastAuthState: null };
  }
  componentDidUpdate() {
    // look for the requireNewPassword state, and following auth force a logout, and force a message why we logged out
    if (this.state.lastAuthState === null && sessionStorage.showPostRequireNewPasswordMessage) {
      setTimeout(() => {
        this.props.onAuthEvent(null, {
          type: 'error',
          data: 'Please sign in with your new password. If using SMS, you will receive 2 text messages with six digit codes. Use the most recent one sent to your phone on the next screen if applicable.'
        });
        delete sessionStorage.showPostRequireNewPasswordMessage;
      }, 2000);
    }
    if (this.props.authState !== this.state.lastAuthState) {
      this.setState({ lastAuthState: this.props.authState });
    }
    if (this.props.authState === 'confirmSignIn' && this.state.lastAuthState === 'requireNewPassword') {
      sessionStorage.showPostRequireNewPasswordMessage = 'true';
      Auth.signOut();
    }
  }

  render() {
    return null;
  }
}

export default withAuthenticator(Routes, false, [
  <SignIn federated={awsConfig.federated} />,
  <ConfirmSignIn />,
  <VerifyContact />,
  <RequireNewPassword />,
  <ConfirmSignUp />,
  <ForgotPassword />,
  <ProvideMFA />,
  <CatchFirstLogin />,
  <CatchAuthErrors />
]);
