import React, { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { addDays, getDate, parseISO } from 'date-fns';
import { observer } from 'mobx-react';

import { paymentMethod, originalGSNICStates } from '@ourbranch/lookups';
import { useStore } from 'core/store';
import Section from 'core/components/section';
import { Card } from 'core/components/card';
import { Label } from 'core/components/label';
import { FormField } from 'core/components/form';
import { NotificationCard } from 'core/components/notification-card';
import withDatePicker from 'core/components/with-date-picker';
import { canOfferMegaDownPay } from '../../helpers/mega-down-pay-helpers';
import useStyles from './settings.styles';

const AutoSettings = observer(function AutoSettings({ paymentMethodOptions, billingDaysOptions, paymentTypeOptions }) {
  const { offer } = useStore();
  const classes = useStyles();
  const { values, setFieldValue } = useFormikContext();
  const { isBix } = offer;

  const offerMegaDownPay = useMemo(
    () =>
      canOfferMegaDownPay(
        values.global.autoEffectiveDate,
        values.global.autoBillingDayOfMonth,
        values.global.autoPaymentType
      ),
    [values.global.autoEffectiveDate, values.global.autoBillingDayOfMonth, values.global.autoPaymentType]
  );

  // this is used to default the billing day of the month to the day that the policy is effective
  const autoEffectiveDay = useMemo(() => {
    if (values.global.autoEffectiveDate) {
      return getDate(parseISO(values.global.autoEffectiveDate));
    }
  }, [values]);

  const state = offer?.state;

  const showCreditCardNote =
    values.global.autoPaymentMethod === paymentMethod.ACH && !isBix && originalGSNICStates.includes(state);

  // if auto billing day of month is null, default select the effective day of the policy
  // or the 28th if the effectiveDay is past the allowed options of days 1 - 28
  useEffect(() => {
    if (!values.global.autoBillingDayOfMonth && values.global.autoBillingDayOfMonth !== autoEffectiveDay) {
      if (autoEffectiveDay > 28) {
        setFieldValue('global.autoBillingDayOfMonth', 28);
      } else {
        setFieldValue('global.autoBillingDayOfMonth', autoEffectiveDay);
      }
    }
  });

  return (
    <Section title="Auto Policy Settings" type="SubSection">
      <Card className={classes.container} type="secondary">
        <Label className={classes.subtitle} type="formSubTitle">
          Policy Term
        </Label>
        <Grid container>
          <FormField
            type="date"
            name="global.autoEffectiveDate"
            label="Auto policy effective date"
            xs={4}
            mode="dark"
            disableFuture={false}
            maxDate={addDays(new Date(), 60)}
            fast={false}
          />
        </Grid>
        <Grid container spacing={2} alignItems="center" xs={12}>
          <Grid item xs={2}>
            <Label type="formSubTitle" className={classes.subtitle}>
              Billing Details
            </Label>
          </Grid>
          {showCreditCardNote && (
            <Grid item xs={6}>
              <Label type="noteSmall">Note: There is no additional fee for paying with credit card.</Label>
            </Grid>
          )}
        </Grid>
        <Grid container>
          <Grid container item xs={12} spacing={4}>
            <FormField
              type="select"
              name="global.autoBillingDayOfMonth"
              label="Auto Billing Day"
              mode="dark"
              options={billingDaysOptions}
              xs={4}
              fast={false}
            />
            <FormField
              label="Auto payment method"
              xs={4}
              type="select"
              name="global.autoPaymentMethod"
              options={paymentMethodOptions}
              mode="dark"
            />
            <FormField
              label="Auto payment frequency"
              xs={4}
              type="select"
              name="global.autoPaymentType"
              options={paymentTypeOptions}
              mode="dark"
              fast={false}
            />
          </Grid>
        </Grid>
        {offerMegaDownPay && (
          <>
            <Grid container>
              <FormField
                type="checkbox"
                name="global.autoMegaDownPay"
                id="global.autoMegaDownPay"
                label="Add second payment to down payment billing"
                fast={false}
              />
            </Grid>
            {!values.global.autoMegaDownPay && (
              <NotificationCard type="quaternary">
                <Label className={classes.warningText}>
                  The member will be charged a second payment within 20 days of making their down payment.
                </Label>
              </NotificationCard>
            )}
          </>
        )}
      </Card>
    </Section>
  );
});

AutoSettings.propTypes = {
  paymentMethodOptions: PropTypes.array.isRequired,
  billingDaysOptions: PropTypes.array.isRequired,
  paymentTypeOptions: PropTypes.array.isRequired
};

export default withDatePicker(AutoSettings);
