import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { observer } from 'mobx-react';
import { lookupsJson, hideHighestEducationStates, hideGenderStates, noCreditStates } from '@ourbranch/lookups';
import { useFormikContext } from 'formik';
import { flowRight } from 'lodash-es';

import withDatePicker from 'core/components/with-date-picker';
import useSession from 'core/hooks/use-session';
import { useStore } from 'core/store/store.mobx';
import { FormField } from 'core/components/form';
import CollapsibleCard from 'core/components/collapsible-card';
import { Label } from 'core/components/label';
import ClearUDRsModal from 'common/components/people/clear-udrs-modal/clear-udrs-modal';
import { useSwapAppCoApp } from 'common/components/people/hooks';
import OfferApplicantActions from './applicant-actions';
import useStyles from '../people-details.styles';

const OfferApplicant = (props) => {
  const { item, fieldName, index, initialFold, onFold, allowLicensedActions } = props;
  const classes = useStyles();
  const { offer: offerStore } = useStore();
  const { values } = useFormikContext();
  const { canClearUDRs } = useSession();
  const [openClearUDRViolationModal, setOpenClearUDRViolationModal] = useState(false);
  const { isSwapping } = useSwapAppCoApp({ formikPath: 'drivers', allowLicensedActions });

  const { state } = offerStore;
  const hasUDR = item?.autoViolations?.UDR >= 1;

  const maritalStatusOptions = lookupsJson.maritalStatus.filter((option) => {
    return option.id !== 'W';
  });

  const onRecheckDrivingRecord = () => {
    // eslint-disable-next-line no-unused-expressions
    offerStore.drivers.get(item.id)?.toggleWillRecheckDrivingRecord();
  };
  const clearUDRViolationsIsChecked = values.drivers[index].clearUDRViolation;

  return (
    <>
      {openClearUDRViolationModal && (
        <ClearUDRsModal
          setOpenClearUDRViolationModal={setOpenClearUDRViolationModal}
          fieldName={fieldName}
          item={item}
        />
      )}

      <CollapsibleCard
        content={
          <>
            <Label type="infoSubtitle" className={classes.personLabel}>{`${item.firstName} ${item.lastName}`}</Label>
            {item.isPrimary && <Label type="infoCardTitle">Applicant</Label>}{' '}
          </>
        }
        contentRight={<OfferApplicantActions person={item} formikFieldPath={props.fieldName} {...props} />}
        collapsibleCardNotification={hasUDR ? 'This driver has an unverifiable driving record.' : null}
        initialOpen={initialFold || item.initialOpen}
        onChange={(val) => onFold(index, val)}
      >
        <div className={classes.collapsibleContainer}>
          <Grid container alignItems="flex-start" spacing={4}>
            <FormField
              id={`${fieldName}.firstName`}
              name={`${fieldName}.firstName`}
              type="string"
              label="First Name"
              mode="light"
              xs={3}
              disabled={isSwapping} // can't change name in same update as swapping app / coapp
              fast={false}
            />
            <FormField
              id={`${fieldName}.middleName`}
              name={`${fieldName}.middleName`}
              type="string"
              label="Middle Name"
              mode="light"
              xs={3}
              disabled={isSwapping} // can't change name in same update as swapping app / coapp
              fast={false}
            />
            <FormField
              id={`${fieldName}.lastName`}
              name={`${fieldName}.lastName`}
              type="string"
              label="Last Name"
              mode="light"
              xs={3}
              disabled={isSwapping} // can't change name in same update as swapping app / coapp
              fast={false}
            />
            <FormField
              id={`${fieldName}.dateOfBirth`}
              name={`${fieldName}.dateOfBirth`}
              type="date"
              label="Date of Birth"
              mode="light"
              xs={3}
            />
            <FormField
              id={`${fieldName}.suffix`}
              name={`${fieldName}.suffix`}
              type="string"
              label="Suffix"
              mode="light"
              xs={2}
            />
            {!hideGenderStates.includes(state) && (
              <FormField
                id={`${fieldName}.gender`}
                name={`${fieldName}.gender`}
                type="select"
                label="Gender"
                mode="light"
                options={lookupsJson.gender}
                xs={2}
                permissions={{ isLicensedAction: false }}
              />
            )}
            <FormField
              id={`${fieldName}.maritalStatus`}
              name={`${fieldName}.maritalStatus`}
              type="select"
              label="Marital Status"
              mode="light"
              options={maritalStatusOptions || lookupsJson.maritalStatus}
              xs={2}
              permissions={{ isLicensedAction: false }}
            />
            {!hideHighestEducationStates.includes(state) && (
              <FormField
                id={`${fieldName}.highestEducation`}
                name={`${fieldName}.highestEducation`}
                type="select"
                label="Highest Education Achieved"
                mode="light"
                options={lookupsJson.highestEducation}
                xs={6}
                permissions={{ isLicensedAction: false }}
              />
            )}
            <FormField
              id={`${fieldName}.driversLicenseState`}
              name={`${fieldName}.driversLicenseState`}
              type="state"
              label="License State"
              mode="light"
              xs={3}
            />
            <FormField
              id={`${fieldName}.driversLicenseNumber`}
              name={`${fieldName}.driversLicenseNumber`}
              type="string"
              label="License Number"
              mode="light"
              xs={3}
            />
            <FormField
              id={`${fieldName}.ageFirstLicensed`}
              name={`${fieldName}.ageFirstLicensed`}
              type="string"
              label="First Licensed at"
              mode="light"
              xs={3}
            />
            <FormField
              id={`${fieldName}.addDate`}
              name={`${fieldName}.addDate`}
              type="date"
              label="Covered Since"
              mode="light"
              xs={3}
            />
            {hasUDR && (
              <Grid item container spacing={5}>
                <Grid item xs={12}>
                  <FormField
                    name={`${fieldName}.recheckDrivingRecord`}
                    id={`${fieldName}.recheckDrivingRecord`}
                    type="checkbox"
                    label="Re-check for driving record"
                    mode="light"
                    onChange={onRecheckDrivingRecord}
                    disabled={clearUDRViolationsIsChecked}
                  />
                </Grid>

                {canClearUDRs && (
                  <Grid item xs={12}>
                    <FormField
                      id={`${fieldName}.clearUDRViolation`}
                      name={`${fieldName}.clearUDRViolation`}
                      type="checkbox"
                      label="Clear UDR Violation"
                      mode="light"
                      xs={6}
                      onClick={(e) => {
                        setOpenClearUDRViolationModal(e.target.checked);
                      }}
                    />
                  </Grid>
                )}
              </Grid>
            )}
          </Grid>
        </div>
        {(item.isPrimary || item.isCoApplicant) && item.insuranceScore && (
          <Grid container alignItems="center" justify="flex-end" className={classes.cardFooter}>
            <Grid container item xs={3} direction="column" alignItems="flex-end">
              <Label type="infoLabel">Insurance Score</Label>
              <Label type="infoValue">
                {noCreditStates[state]
                  ? 'N/A'
                  : Number(item.insuranceScore?.autoTotal?.toFixed(1) || item.insuranceScore?.total?.toFixed(1))}{' '}
              </Label>
            </Grid>
          </Grid>
        )}
      </CollapsibleCard>
    </>
  );
};

export default flowRight(observer, withDatePicker)(OfferApplicant);
