import React, { useCallback } from 'react';
import * as Sentry from '@sentry/react';
import { useStore } from 'core/store';
import { track } from 'core/helpers/analytics';
import { autorun } from 'mobx';
import { getMoratoriumZipCodes } from 'core/helpers/moratorium-zip-codes';

const isInMoratorium = async (zip) => {
  const moratoriumZipCodes = await getMoratoriumZipCodes();
  return moratoriumZipCodes.includes(String(zip).substring(0, 5));
};

const homePolicyIsInMoratorium = async (home) => {
  const homeLocation = home.homeLocation;
  const zipIsInMoratorium = await isInMoratorium(homeLocation.zip);
  return homeLocation && zipIsInMoratorium;
};

const carPolicyIsInMoratorium = async (cars) => {
  if (!cars) {
    return false;
  }

  for (const car of cars) {
    if (await isInMoratorium(car.garageLocation.zip)) {
      return true;
    }
  }

  return false;
};

const renterPolicyIsInMoratorium = async (rentersCoverage) => {
  const rentersLocation = rentersCoverage.rentersLocation;
  const zipIsInMoratorium = await isInMoratorium(rentersLocation.zip);
  return rentersLocation && zipIsInMoratorium;
};

const policyTypeHandler = {
  home: homePolicyIsInMoratorium,
  cars: carPolicyIsInMoratorium,
  rentersCoverage: renterPolicyIsInMoratorium
};

const policyIsInMoratorium = async (policy) => {
  if (!policy) return false;
  const [action, handler] = Object.entries(policyTypeHandler).find(([key]) => key in policy) || [];
  return handler && handler(policy[action]);
};

const moratoriumReason = 'moratorium';

const withMoratorium = (Component) => (props) => {
  const {
    account: {
      policies: {
        policy: { segment, addHoldCard, removeHoldCard, policy, getActiveHoldCardsByReason, canAddMoratorium }
      }
    }
  } = useStore();
  const [moratoriumHoldCard] = getActiveHoldCardsByReason(moratoriumReason);
  const onAddHoldCard = useCallback(
    async (reason, policyId) => {
      try {
        const res = await addHoldCard(reason, policyId);
        track('Moratorium Add Hold Card', { args: { reason, policyId }, res });
      } catch (res) {
        Sentry.captureException(
          JSON.stringify({
            res,
            body: {
              reason,
              policyId
            }
          })
        );
      }
    },
    [addHoldCard]
  );

  autorun(() => {
    const asyncAutorunFunction = async (
      segment,
      policy,
      canAddMoratorium,
      moratoriumHoldCard,
      removeHoldCard,
      onAddHoldCard
    ) => {
      if (!segment || !canAddMoratorium) return;

      const isPolicyInMoratorium = await policyIsInMoratorium(segment);

      if (moratoriumHoldCard && isPolicyInMoratorium) return;

      if (moratoriumHoldCard && !isPolicyInMoratorium) {
        removeHoldCard(policy.id, moratoriumHoldCard.holdCardId);

        return;
      }
      if (!isPolicyInMoratorium) return;

      onAddHoldCard(moratoriumReason, policy.id);
    };

    asyncAutorunFunction(segment, policy, canAddMoratorium, moratoriumHoldCard, removeHoldCard, onAddHoldCard);
  });

  return <Component {...props} />;
};

export default withMoratorium;
