import React from 'react';

const Marketing = () => {
  return (
    <iframe
      src="https://v2-embednotion.com/Marketing-4898e50ae5ab4336b772e3d5c11c5305"
      title="Marketing"
      style={{
        width: '100%',
        height: '600px',
        border: '1px solid #ccc',
        borderRadius: '10px',
        padding: 'none',
        boxShadow: '0 2px 2px #ccc '
      }}
    />
  );
};

export default Marketing;
