const getCurrentCarInitialValue = (initialCars, vin) => {
  return (
    initialCars?.find((car) => car.VIN === vin) || {
      symbolMake: undefined,
      symbolAux: undefined,
      symbolStyle: undefined,
      symbolModel: undefined,
      symbolPGS: undefined,
      symbolBI: undefined,
      symbolPD: undefined,
      symbolMed: undefined,
      symbolPIP: undefined,
      symbolColl: undefined,
      symbolComp: undefined,
      symbolUM: undefined
    }
  );
};

export const hasMakeModelStyleSymbols = (car) => {
  return car.symbolMake || car.symbolModel || car.symbolStyle;
};

export const haveAnyOfThisCarsSymbolsChanged = (currentCar, initialCars) => {
  const initialCarValue = getCurrentCarInitialValue(initialCars, currentCar.VIN);
  return (
    initialCarValue.symbolMake !== (currentCar.symbolMake?.toUpperCase() || undefined) ||
    initialCarValue.symbolModel !== (currentCar.symbolModel?.toUpperCase() || undefined) ||
    initialCarValue.symbolStyle !== (currentCar.symbolStyle?.toUpperCase() || undefined) ||
    initialCarValue.symbolAux !== (currentCar.symbolAux?.toUpperCase() || undefined) ||
    initialCarValue.symbolPGS !== (currentCar.symbolPGS?.toUpperCase() || undefined) ||
    initialCarValue.symbolBI !== currentCar.symbolBI ||
    initialCarValue.symbolPD !== currentCar.symbolPD ||
    initialCarValue.symbolMed !== currentCar.symbolMed ||
    initialCarValue.symbolPIP !== currentCar.symbolPIP ||
    initialCarValue.symbolColl !== currentCar.symbolColl ||
    initialCarValue.symbolComp !== currentCar.symbolComp ||
    initialCarValue.symbolUM !== currentCar.symbolUM
  );
};
