import React, { useContext, useEffect, useMemo, useCallback } from 'react';
import { policyTypes } from '@ourbranch/policy-types';
import { isPast, isToday } from 'date-fns';
import { toDate } from 'date-fns-tz';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import { getPolicyStatus, PolicyStatus } from 'core/helpers/policy-status';
import { AuthContext } from 'core/components/auth';
import { ValidationErrorsModal, useValidationErrorsModal } from 'core/components/validation-errors-modal';
import { useStore } from 'core/store';
import Footer from '../footer';
import { SegmentFormLeft, SegmentFormRight } from '../segment-form';
import { HomePolicy } from './home-policy';
import { CondoPolicy } from './condo-policy';
import { AutoPolicy } from './auto-policy';
import { RentersPolicy } from './renters-policy';
import useStyles from './details.styles';

const DetailsForm = observer(function DetailsForm({
  loadingPreview,
  handleRadioChange,
  state,
  handleStartDate,
  repEmail
}) {
  const { touched, handleSubmit, errors, validateForm, setErrors, setTouched } = useFormikContext();
  const { showValidationErrorsModal, setShowValidationErrorsModal } = useValidationErrorsModal();

  const { account } = useStore();
  const {
    policies: {
      policy: { segment, policy, changed: changedStatus, setChanged, loading }
    }
  } = account;

  const session = useContext(AuthContext);
  const classes = useStyles();
  const changed = useMemo(() => session?.canEditPolicies && Object.keys(touched).length > 0, [touched, session]);
  const editingFirstSegment =
    policy.segments[0].segmentId === segment.segmentId && state.get('changeType') === 'edit_change';

  const policyStatus = loading ? null : getPolicyStatus(policy);
  const cancelled = policyStatus === PolicyStatus.InCancellation || policyStatus === PolicyStatus.Cancelled;

  const footerOptions = () => {
    const options = [{ value: 'new_change', label: 'Create New Change' }];
    // agency can't back date, so don't allow this edit option
    if (isPast(toDate(segment.startDate)) && !session?.canBackDate && !isToday(toDate(segment.startDate))) {
      return options;
    }
    options.push({ value: 'edit_change', label: 'Edit Selected Change' });
    return options;
  };

  useEffect(() => {
    if (changed && !changedStatus) {
      setChanged(true);
    } else if (!changed) {
      setChanged(false);
    }
  }, [changed, changedStatus, setChanged]);

  useEffect(() => {
    if (Object.keys(errors).length) {
      // logging validation errors on details pane for better visbility of production issues
      // eslint-disable-next-line
      console.log({ errors });
    }
  }, [errors]);

  const onSubmit = useCallback(async () => {
    const errors = await validateForm();

    if (Object.keys(errors).length) {
      setErrors(errors);
      setTouched(errors);
      setShowValidationErrorsModal(true);
    } else {
      handleSubmit();
    }
  }, [handleSubmit, setShowValidationErrorsModal, validateForm, setErrors, setTouched]);
  return (
    <div className={changed ? classes.changed : null}>
      {/* need policy to know if we are on a bix policy in a nested component */}

      {policy.policyType === policyTypes.Auto && <AutoPolicy />}
      {policy.policyType === policyTypes.Home && <HomePolicy repEmail={repEmail} />}
      {policy.policyType === policyTypes.Condo && <CondoPolicy />}
      {policy.policyType === policyTypes.Renters && <RentersPolicy />}

      {changed && !session.viewOnly && !cancelled && (
        <Footer
          loading={loadingPreview}
          leftForm={
            <SegmentFormLeft
              onRadioChange={handleRadioChange}
              options={footerOptions()}
              value={state.get('changeType')}
            />
          }
          form={
            <SegmentFormRight
              editingFirstSegment={editingFirstSegment}
              onChange={handleStartDate}
              startDate={state.get('startDate')}
              minDate={state.get('minDate')}
              maxDate={state.get('maxDate')}
              policy={policy}
            />
          }
          onClick={onSubmit}
        />
      )}
      <ValidationErrorsModal open={showValidationErrorsModal} onClose={() => setShowValidationErrorsModal(false)} />
    </div>
  );
});

DetailsForm.propTypes = {
  loadingPreview: PropTypes.bool.isRequired,
  handleRadioChange: PropTypes.func.isRequired,
  state: PropTypes.object.isRequired,
  handleStartDate: PropTypes.func.isRequired,
  repEmail: PropTypes.string.isRequired
};

export default DetailsForm;
