import { useFormikContext, getIn } from 'formik';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useRef, useState } from 'react';
import throttle from 'lodash-es/throttle';

import { AuthContext } from 'core/components/auth';
import { TextField } from 'core/components/text-field';
import SmartyStreetsAPI from 'core/helpers/smartyStreetsAPI';
import useStyles from './address-autocomplete.styles';
import { Autocomplete } from '../autocomplete';

// Provide 'name' prop to component and add it to switch case statement in onSuggestionSelected...
// ...to correctly autofill the suggested address into corresponding fields
function AddressAutocomplete({ id, name, mode, xs, label, disabled, ...restProps }) {
  const [addressSuggestions, setAddressSuggestions] = useState([]);
  const session = useContext(AuthContext);
  const classes = useStyles();
  const {
    setTouched,
    setValues,
    setFieldTouched,
    setFieldValue,
    values: formValues,
    touched,
    errors
  } = useFormikContext();

  const throttledSearch = useRef(
    throttle(async (searchValue) => {
      const newSuggestions = await SmartyStreetsAPI.search(searchValue, session.allowedStates);

      setAddressSuggestions(
        newSuggestions.map((suggestion) => {
          const { streetLine, zipcode, city, state } = suggestion;

          return {
            ...suggestion,
            id: streetLine,
            text: `${streetLine}, ${city}, ${state} ${zipcode}`
          };
        })
      );
    }, 1000)
  );

  const onInputChange = (event) => {
    const {
      target: { value }
    } = event;
    setFieldTouched(id);
    setFieldValue(id, value);

    if (value) {
      throttledSearch.current(value);
    }
  };

  const onSuggestionSelected = (suggestion) => {
    const suggestedAddress = {
      address: suggestion.streetLine,
      address2: suggestion.secondary ? suggestion.secondary : undefined,
      city: suggestion.city,
      state: suggestion.state,
      zip: suggestion.zipcode
    };
    const fieldsToBeTouched = { address: true, unit: true, city: true, state: true, zip: true };

    if (name.includes('rentalPropertyAddresses') || name.includes('otherPropertyAddresses')) {
      setFieldValue(id, suggestedAddress);
      return;
    }

    switch (name) {
      case 'primaryMortgageDetail':
        setValues({
          ...formValues,
          primaryMortgageDetail: {
            ...formValues.primaryMortgageDetail,
            mortgageHolderAddress: suggestedAddress
          }
        });
        setTouched({
          ...touched,
          primaryMortgageDetail: {
            mortgageHolderAddress: fieldsToBeTouched
          }
        });
        break;
      case 'mortgageHolderAddress':
        setValues({
          ...formValues,
          mortgageHolderAddress: suggestedAddress
        });
        setTouched({
          ...touched,
          mortgageHolderAddress: fieldsToBeTouched
        });
        break;
      case 'rentersLocation':
        setValues({
          ...formValues,
          rentersCoverage: {
            rentersLocation: suggestedAddress
          }
        });
        setTouched({ ...touched, ...fieldsToBeTouched });
        break;
      case 'priorAddress':
        setValues({
          ...formValues,
          priorAddress: { ...suggestedAddress, unit: suggestedAddress.address2 }
        });
        setTouched({ ...touched, priorAddress: { ...fieldsToBeTouched } });
        break;
      default:
        setValues({
          ...formValues,
          ...suggestedAddress
        });
        setTouched({ ...touched, ...fieldsToBeTouched });
    }
  };

  const onAutocompleteChange = (_, value) => {
    if (value) {
      onSuggestionSelected(value);
    } else {
      setFieldValue(id, null);
    }
  };

  const formValue = getIn(formValues, id) || '';
  const getCurrentValue = useCallback(() => {
    // There once was a bug that caused some policies save with the address formatted like:
    // "address: {city=Columbus, state=OH, zip=43202, address=123 Sesame St.}"
    // The below code accounts for that

    if (typeof formValue === 'string') {
      const value = formValue.includes('{')
        ? formValue.slice(formValue.indexOf('address')).split('=')[1].replace('}', '')
        : formValue;
      return { id: value, text: value };
    }

    return {
      ...formValue,
      text: `${formValue.address}, ${formValue.city}, ${formValue.state}`,
      id: formValue.address
    };
  }, [formValue]);

  return (
    <Grid item xs={xs}>
      <Autocomplete
        options={addressSuggestions}
        className={classes.noMarginBottom}
        name={id || name}
        id={id}
        disabled={disabled}
        onSelection={onAutocompleteChange}
        InputComponent={TextField}
        getOptionLabel={(option) => option.text || option.address || ''}
        inputProps={{
          label,
          mode,
          onChange: onInputChange,
          error: getIn(touched, name || id) && !!getIn(errors, name || id),
          helperText: getIn(touched, id || name) ? getIn(errors, id || name) : undefined
        }}
        {...restProps}
        value={getCurrentValue()}
      />
    </Grid>
  );
}

AddressAutocomplete.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  label: PropTypes.string,
  mode: PropTypes.oneOf(['light', 'dark']).isRequired
};

AddressAutocomplete.defaultProps = {
  name: undefined,
  label: 'Address'
};

export default AddressAutocomplete;
