import { useMemo } from 'react';
import { FeeType, generateFee } from '@ourbranch/fee-utils';
import { policyTypes } from '@ourbranch/policy-types';
import { useStore } from 'core/store';
import { getCoveragesDiscount } from '../helpers/get-coverages-discount';
import { standaloneRentersStates } from '@ourbranch/lookups';

export const useDiscounts = () => {
  const {
    offer: store,
    offer: { offer }
  } = useStore();
  const { selectedOption, allowPaperlessDiscount, allowInventoryDiscount } = store;

  return useMemo(() => {
    const bundleOption = offer?.options?.find((o) => o.name === 'Bundle');
    const autoOption = offer?.options?.find((o) => o.type === policyTypes.Auto);
    const homeOption = offer?.options?.find((o) => o.type === policyTypes.Home);
    const rentersOption = offer?.options?.find((o) => o.type === policyTypes.Renters);
    const condoOption = offer?.options?.find((o) => o.type === policyTypes.Condo);

    const { affinity, discountInventoryScore, discountPaperless } = offer?.quote?.global;

    const windstormMitigationLevel = selectedOption?.includes('H')
      ? offer?.quote?.home?.windstormMitigationLevel
      : selectedOption?.includes('C')
      ? offer?.quote?.condo?.windstormMitigationLevel
      : '';

    const isBundle = [policyTypes.ARBundle, policyTypes.HABundle, policyTypes.CABundle, 'AB', 'HB'].includes(
      selectedOption
    );

    const hasRenters = offer?.options?.some(
      (opt) => opt.type === policyTypes.ARBundle || opt.type === policyTypes.Renters
    );

    const calculateBundleSavings = () => {
      const { existingCustomer, noBindAuto, noBindHome } = offer?.quote;
      if (offer && isBundle) {
        let homeSavings = 0;
        let autoSavings = 0;
        let condoSavings = 0;

        // if already have a home policy we don't apply the discount
        if (!(existingCustomer && noBindHome && hasRenters) && homeOption?.homeTotal && bundleOption?.homeTotal) {
          homeSavings = homeOption?.homeTotal - bundleOption?.homeTotal;
        }

        if (condoOption?.condoTotal && bundleOption?.condoTotal) {
          condoSavings = condoOption.condoTotal - bundleOption.condoTotal;
        }

        // if already have an auto policy we don't apply the discount
        if (!(existingCustomer && noBindAuto) && autoOption?.autoPremium && bundleOption?.autoPremium) {
          const rentersTotal =
            bundleOption.autoCoverages?.find((coverage) => coverage.type === 'renters_total')?.amount || 0;
          autoSavings = (autoOption.autoPremium - (bundleOption.autoPremium - rentersTotal)) * 2;
        }

        switch (selectedOption) {
          case 'AB':
            return autoSavings;
          case 'HB':
            return homeSavings;
          default:
            return autoSavings + homeSavings + condoSavings;
        }
      }
      return null;
    };

    const discounts = [];

    const bundleSavings = {
      label: 'Bundle',
      isActive: isBundle,
      description: 'Bundle discount for purchasing home and auto.'
    };

    // bundle savings - before defining,
    // check discounts that have booleans/info (discountInventoryScore, discountPaperless) on quote.global
    // and default to null if not offered and not able to add them through the module
    // If able, then use the isActive flag to check
    // If not, check the canBeAdded flag to display it in the module

    const inventoryFee = generateFee(FeeType.Imagery, {
      selectedOption,
      state: offer.quote.correctedAddress.state
    });

    const myCommunitySavings = { label: 'Community Discount', id: 'my_community_discount_savings' };
    const inventorySavings =
      homeOption && allowInventoryDiscount
        ? {
            label: 'Inventory Discount',
            id: 'imagery_collection_savings',
            isActive: discountInventoryScore,
            isActiveOverride: store.includeInventory,
            possibleSavings: !homeOption
              ? 0
              : Math.round(
                  (inventoryFee.amount * 100) /
                    (offer.quote.isBix
                      ? homeOption?.homeTotal || 0
                      : (homeOption?.homeTotal || 0) + (autoOption?.autoTotal || 0))
                ),
            canBeAdded: true,
            description:
              'Member saves by taking pictures of their stuff through the Branch app after joining the Branch community.'
          }
        : null;
    const paperlessSavings = allowPaperlessDiscount
      ? {
          label: 'Paperless',
          id: 'paperless_savings',
          isActive: discountPaperless,
          isActiveOverride: store.includePaperless,
          possibleSavings: 4, // possible saving if not toggled
          canBeAdded: true,
          description:
            'Member will receive their ID cards and policy documents electronically, rather than receiving paper copies in their mail.'
        }
      : null;

    const affinitySavings = affinity
      ? {
          label: `Affinity - ${affinity} (Annual)`,
          id: 'affinity_discount_savings',
          isActive: true,
          description: `Lead from ${offer.quote.leadSource}`
        }
      : null;

    // home only
    const connectedHomeSavings = {
      label: 'Connected Home',
      id: 'connected_home_discount_savings',
      isActive: store.includeConnectedHome,
      possibleSavings: 18, // possible saving if not added
      canBeAdded: true,
      description: 'Save on member’s home policy if their home is professionally monitored by a home security system.'
    };

    const alWindstormMitigationDiscount = {
      label: 'Windstorm Mitigation',
      id: 'windstorm_mitigation_discount_savings',
      description: 'Discount on wind coverage if a homeowner customer meets certain criteria.',
      isActive: windstormMitigationLevel > 0
    };

    const allPoliciesDiscounts = [myCommunitySavings, paperlessSavings, affinitySavings];
    const homeDiscounts = [connectedHomeSavings, inventorySavings, alWindstormMitigationDiscount];
    const rentersDiscounts = [connectedHomeSavings];
    const condoDiscounts = [{ ...connectedHomeSavings, possibleSavings: 6.6 }];

    if (isBundle) {
      bundleSavings.amount = calculateBundleSavings();
      bundleSavings.amount && discounts.push(bundleSavings);
    }

    allPoliciesDiscounts.forEach((discount) => {
      if (discount) {
        let total = 0;
        if (selectedOption?.includes('A')) {
          const coverages = isBundle && bundleOption ? bundleOption.autoCoverages : autoOption?.autoCoverages;
          total += getCoveragesDiscount(coverages, discount.id);
        }
        if (selectedOption?.includes('H')) {
          const coverages = isBundle && bundleOption ? bundleOption.homeCoverages : homeOption?.homeCoverages;
          total += getCoveragesDiscount(coverages, discount.id);
        }
        if (selectedOption?.includes('C')) {
          const coverages = isBundle && bundleOption ? bundleOption.condoCoverages : condoOption?.condoCoverages;
          total += getCoveragesDiscount(coverages, discount.id);
        }

        if (selectedOption?.includes('R') && standaloneRentersStates[store.state]) {
          const coverages = isBundle && bundleOption ? bundleOption.rentersCoverages : rentersOption?.rentersCoverages;
          total += getCoveragesDiscount(coverages, discount.id);
        }

        if (typeof discount.isActiveOverride === 'boolean') {
          discount.isActive = discount.isActiveOverride;
        } else {
          discount.isActive = total > 0;
        }
        discount.amount = total;
        (discount.canBeAdded || discount.isActive) && discounts.push(discount);
      }
    });

    selectedOption?.includes('H') &&
      homeDiscounts.forEach((discount) => {
        if (discount) {
          let total = 0;
          total += getCoveragesDiscount(
            isBundle && bundleOption ? bundleOption.homeCoverages : homeOption?.homeCoverages,
            discount.id
          );
          discount.isActive =
            typeof discount.isActiveOverride === 'boolean' ? discount.isActiveOverride : total > 0 || discount.isActive;
          discount.amount = total;

          if (discount.canBeAdded || discount.isActive) {
            discounts.push(discount);
          }
        }
      });

    if (selectedOption?.includes('C')) {
      condoDiscounts.forEach((discount) => {
        if (discount) {
          const total = getCoveragesDiscount(
            isBundle && bundleOption ? bundleOption.condoCoverages : condoOption?.condoCoverages,
            discount.id
          );
          discount.isActive =
            typeof discount.isActiveOverride === 'boolean' ? discount.isActiveOverride : total > 0 || discount.isActive;
          discount.amount = total;

          if (discount.canBeAdded || discount.isActive) {
            discounts.push(discount);
          }
        }
      });
    }

    if (selectedOption?.includes('R') && standaloneRentersStates[store.state]) {
      rentersDiscounts.forEach((discount) => {
        let total = 0;
        total += getCoveragesDiscount(
          isBundle && bundleOption ? bundleOption.rentersCoverages : rentersOption?.rentersCoverages,
          discount.id
        );
        discount.isActive =
          typeof discount.isActiveOverride === 'boolean' ? discount.isActiveOverride : !!total || discount.isActive;
        discount.amount = total;

        if (discount.canBeAdded || discount.isActive) {
          discounts.push(discount);
        }
      });
    }

    return discounts.sort((a, b) => b.amount - a.amount);
  }, [
    offer,
    selectedOption,
    store.state,
    store.includeConnectedHome,
    store.includeInventory,
    store.includePaperless,
    allowPaperlessDiscount,
    allowInventoryDiscount
  ]);
};
