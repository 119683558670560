/* eslint-disable func-names */
import * as Yup from 'yup';
import { differenceInYears } from 'date-fns';
import {
  driverCarAssignmentStates,
  UMPDOnlyWithUMBIStates,
  combinedUMUIM,
  doNotShowCarLevelUMPDStates,
  policyLevelUMPDStates,
  inexperiencedYearsLimit,
  canRejectPIPStates
} from '@ourbranch/lookups';

import { haveAnyOfThisCarsSymbolsChanged, hasMakeModelStyleSymbols } from 'common/helpers/car-symbols-helpers';
import { validUSState, requiredString } from 'common/helpers/yup-helpers';
import { HEALTH_INSURANCE_TYPE } from 'core/helpers/constants';
import { isLimitACPEValid, getUMPDValue, getPLimitValue } from 'core/helpers/car-validations';
import { driversLicenseNumberSchema } from 'core/helpers/drivers-license-number-validations';
import { checkZipCodeRange } from 'common/helpers/address-helpers';

Yup.addMethod(Yup.string, 'requiredString', requiredString);
Yup.addMethod(Yup.mixed, 'validUSState', validUSState);

export const autoValidationSchema = ({ policies }) =>
  Yup.lazy(({ autoCoverage }) => {
    return Yup.object().shape({
      auto: Yup.object()
        .shape({
          pipAllResidents: Yup.number()
            .test('requiredForMI', 'Required', function (value) {
              if (this.options.context?.state === 'MI') {
                return value >= 0;
              }
              return true;
            })
            .nullable(),
          pipResidentsWithQHC: Yup.number()
            .test('requiredForMI', 'Required', function (value) {
              if (this.options.context?.state === 'MI') {
                return value >= 0;
              }
              return true;
            })
            .test(
              'compareToAllPipEligibleResidents',
              'The amount of residents with QHC cannot exceed the amount of eligible pip residents',
              function (value) {
                if (this.options.context?.state === 'MI') {
                  const pipEligibleResidentsCount =
                    this.options.context.auto?.pipAllResidents - this.options.context.auto?.pipAdditionalResidents;
                  if (value > pipEligibleResidentsCount) {
                    // using this.createError to have a dynamic error message
                    return this.createError({
                      message: `This cannot exceed ${pipEligibleResidentsCount} - the amount of pip eligible residents`
                    });
                  }
                }
                return true;
              }
            )
            .test('compareToHealthInsurance', 'Must be at least 1 because Medicare is selected', function (value) {
              if (
                this.options.context?.state === 'MI' &&
                this.options.context.auto?.pipHealthInsuranceType === HEALTH_INSURANCE_TYPE.MEDICARE &&
                !this.options.context.auto?.pipEveryoneOnSamePlan
              ) {
                return value >= 1;
              }
              return true;
            })
            .test(
              'compareToHealthInsurance2',
              'Must be the same as the number of residents in household because Medicare is selected and everyone is on the same plan',
              function (value) {
                if (
                  this.options.context?.state === 'MI' &&
                  this.options.context.auto?.pipHealthInsuranceType === HEALTH_INSURANCE_TYPE.MEDICARE &&
                  this.options.context.auto?.pipEveryoneOnSamePlan
                ) {
                  return value === this.options.context.auto?.pipAllResidents;
                }
                return true;
              }
            )
            .nullable(),
          pipExcludedResidents: Yup.number()
            .test('requiredForMI', 'Required', function (value) {
              if (this.options.context?.state === 'MI') {
                return value >= 0;
              }
              return true;
            })
            .test(
              'checkIfMedicare',
              'The number of excluded residents must be 0 or the same as the number of residents in the household',
              function (value) {
                if (
                  this.options.context?.state === 'MI' &&
                  this.options.context.auto?.pipHealthInsuranceType === HEALTH_INSURANCE_TYPE.MEDICARE
                ) {
                  return value === 0 || value === this.options.context.auto?.pipAllResidents;
                }
                return true;
              }
            )
            .test(
              'checkIfMedicare2',
              'The number of excluded residents must be 0 because residents with QHC is less than residents in household',
              function (value) {
                if (
                  this.options.context?.state === 'MI' &&
                  this.options.context.auto?.pipHealthInsuranceType === HEALTH_INSURANCE_TYPE.MEDICARE &&
                  this.options.context.auto?.pipAllResidents > this.options.context.auto?.pipResidentsWithQHC
                ) {
                  return value === 0;
                }
                return true;
              }
            )
            .test(
              'compareToAllPipEligibleResidents',
              'The amount of excluded residents cannot exceed the amount of eligible pip residents',
              function (value) {
                if (this.options.context?.state === 'MI') {
                  const pipEligibleResidentsCount =
                    this.options.context.auto?.pipAllResidents - this.options.context.auto?.pipAdditionalResidents;
                  if (value > pipEligibleResidentsCount) {
                    // using this.createError to have a dynamic error message
                    return this.createError({
                      message: `This cannot exceed ${pipEligibleResidentsCount} - the amount of pip eligible residents`
                    });
                  }
                }
                return true;
              }
            )
            .nullable(),
          pipAdditionalResidents: Yup.number()
            .test(
              'compareToAllResidents',
              'The amount of excluded residents cannot exceed the amount of residents living in the household',
              function (value) {
                if (this.options.context?.state === 'MI') {
                  return value <= this.options.context.auto?.pipAllResidents;
                }
                return true;
              }
            )
            .nullable(),
          pipHealthInsuranceType: Yup.string()
            .test('requiredForMI', 'Required', function (value) {
              if (this.options.context?.state === 'MI') {
                return value?.length;
              }
              return true;
            })
            .nullable()
        })
        .nullable(),
      autoCoverage: Yup.object().shape({
        policyLimitPIPME: Yup.string()
          .test('requiredForMI', 'Required', function (value) {
            if (this.options.context?.state === 'MI') {
              return value?.length;
            }
            return true;
          })
          .nullable(),
        policyLimitPIPACR: Yup.string()
          .test('requiredForMI', 'Required', function (value) {
            if (this.options.context?.state === 'MI') {
              return value?.length;
            }
            return true;
          })
          .nullable(),
        policyLimitMedicalPayments: Yup.string()
          .test('medicalPaymentsOrComboFBP', 'Required', function (value) {
            if (this.options.context?.state === 'PA') {
              const { policyLimitComboFBP } = this.parent;
              if (policyLimitComboFBP && policyLimitComboFBP !== 'NONE' && value !== 'NONE') {
                return this.createError({
                  message: 'Can not choose both this and combined first party benefits'
                });
              }
              if (policyLimitComboFBP && policyLimitComboFBP === 'NONE' && value === 'NONE') {
                return this.createError({
                  message: 'Choose medical payments or combined first party benefits'
                });
              }
            }
            return true;
          })
          .test('medicalPaymentsOrPIPNotBothTX', 'Cannnot chose this and PIP in TX', function (value) {
            if (this.options.context?.state === 'TX') {
              const { policyLimitPIP } = this.parent;
              if (value !== 'NONE' && policyLimitPIP && policyLimitPIP !== 'NONE') {
                return false;
              }
              return true;
            }
            return true;
          })
          .nullable(),
        policyLimitComboFBP: Yup.string()
          .test('medicalPaymentsOrComboFBP', 'required', function (value) {
            if (this.options.context?.state === 'PA') {
              const { policyLimitMedicalPayments } = this.parent;
              if (policyLimitMedicalPayments && policyLimitMedicalPayments !== 'NONE' && value !== 'NONE') {
                return this.createError({
                  message: 'Can not choose both this and medical payments'
                });
              }
              if (policyLimitMedicalPayments && policyLimitMedicalPayments === 'NONE' && value === 'NONE') {
                return this.createError({
                  message: 'Choose medical payments or combined first party benefits'
                });
              }
            }
            return true;
          })
          .nullable(),
        policyLimitPIP: Yup.string()
          .test('medicalPaymentsOrPIPNotBothTX', 'Cannnot chose this and MedPay in TX', function (value) {
            if (this.options.context?.state === 'TX') {
              const { policyLimitMedicalPayments } = this.parent;
              if (value !== 'NONE' && policyLimitMedicalPayments && policyLimitMedicalPayments !== 'NONE') {
                return false;
              }
              return true;
            }
            return true;
          })
          .test(
            'policyLimitNoFaultPIPIncluded',
            'You must reject PIP coverage in order to opt out of PIP',
            function (value) {
              if (canRejectPIPStates.includes(this.options.context?.state)) {
                const { policyLimitNoFaultPIP } = this.parent;
                if (value === 'NONE' && policyLimitNoFaultPIP && policyLimitNoFaultPIP === 'INCLUDED') {
                  return false;
                }
                return true;
              }
              return true;
            }
          )
          .test(
            'policyLimitNoFaultPIPNone',
            'PIP coverage must be set to "No Coverage" if PIP is rejected',
            function (value) {
              if (canRejectPIPStates.includes(this.options.context?.state)) {
                const { policyLimitNoFaultPIP } = this.parent;
                if (value !== 'NONE' && policyLimitNoFaultPIP && policyLimitNoFaultPIP === 'NONE') {
                  return false;
                }
                return true;
              }
              return true;
            }
          )
          .nullable(),
        policyLimitUMBI: Yup.string().test(
          'umbiCSL',
          'UM/UIM BI cannot be CSL unless policy BI is also CSL.',
          function () {
            const { policyLimitUMBI, policyLimitBIPD } = this.options.context?.autoCoverage || {};
            const hasAutoPolicy = this.options.context?.auto;
            if (!hasAutoPolicy) {
              return true;
            }
            const umbiCSL = policyLimitUMBI.includes('CSL');
            const bipdCSL = policyLimitBIPD.includes('CSL');

            return policyLimitUMBI === '0/0' || umbiCSL === bipdCSL;
          }
        ),
        policyLimitUIMBI: Yup.string().test(
          'uimbiCSL',
          'UIM BI cannot be CSL unless policy UM BI and BI/PD are also CSL.',
          function () {
            const { policyLimitBIPD, policyLimitUIMBI } = this.options.context?.autoCoverage || {};
            const hasAutoPolicy = this.options.context?.auto;
            const combinedUMUIMState = combinedUMUIM[this.options.context?.state];
            if (combinedUMUIMState || !hasAutoPolicy) {
              return true;
            }
            const bipdCSL = policyLimitBIPD.includes('CSL');
            const uimbiCSL = policyLimitUIMBI.includes('CSL');
            return policyLimitUIMBI === '0/0' || bipdCSL === uimbiCSL;
          }
        ),
        policyLimitUMPD: Yup.mixed()
          .nullable()
          .test(
            'limitUMPDMustBeLowerThanPLimitValue',
            "Error, this can't be higher than property damage limit",
            function (value) {
              const state = this.options.context?.state;
              if (state === 'NM' || !policyLevelUMPDStates[state]) {
                // in NM, we just have a UMPD = PD limit option and shouldn't run this check.
                // or if we don't show UMPD on policy level, then don't run this check.
                return true;
              }
              return getUMPDValue(value) <= getPLimitValue(autoCoverage.policyLimitBIPD);
            }
          )
          .test('noLimitUMPDWithoutUMBI', 'Error, UMPD cannot be chosen in this state without UMBI', function (value) {
            const state = this.options.context?.state;
            return !(
              value !== 'NONE' &&
              UMPDOnlyWithUMBIStates[state] &&
              policyLevelUMPDStates[state] &&
              (autoCoverage.policyLimitUMBI?.startsWith('0/0') || autoCoverage.policyLimitUMBI === 'NONE')
            );
          })
      }),
      cars: Yup.array(
        Yup.object().shape({
          limitUMPD: Yup.mixed()
            .nullable()
            .test(
              'limitUMPDMustBeLowerThanPLimitValue',
              "Error, this can't be higher than property damage limit",
              function (value) {
                const state = this.options.context?.state;
                if (state === 'NM' || doNotShowCarLevelUMPDStates[state]) {
                  // in NM, we just have a UMPD = PD limit option and shouldn't run this check.
                  // or we don't have UMPD in this state or car level UMPD in this state so don't run this check
                  return true;
                }
                return getUMPDValue(value) <= getPLimitValue(autoCoverage.policyLimitBIPD);
              }
            )
            .test(
              'noLimitUMPDWithoutUMBI',
              'Error, UMPD cannot be chosen in this state without UMBI',
              function (value) {
                const state = this.options.context?.state;
                if (doNotShowCarLevelUMPDStates[state]) {
                  // we don't have UMPD in this state or car level UMPD in this state so don't run this check
                  return true;
                }
                return !(
                  value !== 'NONE' &&
                  UMPDOnlyWithUMBIStates[state] &&
                  (autoCoverage.policyLimitUMBI?.startsWith('0/0') || autoCoverage.policyLimitUMBI === 'NONE')
                );
              }
            ),
          garageLocation: Yup.object()
            .nullable()
            .shape({
              address: Yup.string()
                .nullable()
                .test('is-po-box', 'PO Box Addresses are invalid', function (value) {
                  const pattern = new RegExp('\\b[p]*(ost)*\\.*\\s*[o|0]*(ffice)*\\.*\\s*b[o|0]x\\b', 'i');
                  return !(pattern.test(value) || pattern.test(value));
                }),
              state: Yup.string()
                .nullable()
                .validUSState('auto.garageLocation.state')
                .test(
                  'garage-state-policy-test',
                  'Garaging address must be in the same state as the policy.',
                  function (value) {
                    return value === this.options?.context.state;
                  }
                ),
              zip: Yup.string()
                .nullable()
                .test(
                  'garage-zip-policy-test',
                  'Garaging address must be in the valid zip code range of the policy state',
                  function (value) {
                    const policyState = this.options.context?.state;
                    return checkZipCodeRange(value, policyState);
                  }
                )
            }),
          assignedDriver: Yup.mixed().test(
            'assignedDriver',
            'A driver may only be assigned  to one car',
            function (value) {
              if (driverCarAssignmentStates[this.options.context.state] && this.options.context.state !== 'MA') {
                if (!value) {
                  return this.createError({
                    message: 'All cars must be assigned a driver'
                  });
                }
                const cars = this.options.context.cars;
                const drivers = this.options.context.drivers.filter((driver) => !driver.excludeDriver);
                const assignedDrivers = cars.map((car) => car.assignedDriver);
                const driversSet = new Set(assignedDrivers);

                if (drivers.length <= cars.length) {
                  return drivers.length === driversSet.size
                    ? true
                    : this.createError({
                        message: 'Every driver must be assigned to a car'
                      });
                }
                if (drivers.length > cars.length) {
                  return cars.length <= driversSet.size
                    ? true
                    : this.createError({
                        message: 'A driver may only be assigned to one car'
                      });
                }

                return true;
              }
              return true;
            }
          ),
          deductibleCollision: Yup.string()
            .requiredString('Collision deductible is required')
            .test('Invalid', 'To add Collision, Comprehensive coverage is required.', function (value) {
              if (this.options.context.state === 'DC') {
                if (value && value !== 'NONE') {
                  const { deductibleComprehensive } = this.parent;
                  return deductibleComprehensive !== 'NONE';
                }
              }
              return true;
            }),
          deductibleComprehensive: Yup.string().requiredString('Comprehensive deductible is required'),
          limitRental: Yup.string().requiredString('Rental car expense is required'),
          limitACPE: Yup.string()
            .requiredString('Additional custom parts fields is required')
            .test('Invalid', function (value) {
              if (!isLimitACPEValid(value, this.parent)) {
                return this.createError({
                  message: `To add ${
                    this.options.context?.state === 'VA' ? 'Add On Equipment' : 'Additional Custom Parts'
                  } coverage, both Collision and Comprehensive coverages are required.`
                });
              }
              return true;
            }),
          symbolMake: Yup.string()
            .test(
              'symbolMake-length-is-2',
              'Must be exactly 2 characters',
              (value, { options: { context, parent } }) => {
                if (!context.canAddCarsManually || context.state === 'FL') return true;
                if (parent.symbolAux && !hasMakeModelStyleSymbols(parent)) return !value || value?.length === 2;
                return haveAnyOfThisCarsSymbolsChanged(parent, context.initialValues.cars) ? value?.length === 2 : true;
              }
            )
            .nullable(),
          symbolAux: Yup.string()
            .test(
              'symbolAux-length-is-2',
              'Must be exactly 2 characters',
              (value, { options: { context, parent } }) => {
                if (!context.canAddCarsManually || context.state === 'FL') return true;
                if (hasMakeModelStyleSymbols(parent)) return !value || value?.length === 2;
                return haveAnyOfThisCarsSymbolsChanged(parent, context.initialValues.cars) ? value?.length === 2 : true;
              }
            )
            .nullable(),
          symbolModel: Yup.string()
            .test(
              'symbolModel-length-is-2',
              'Must be exactly 2 characters',
              (value, { options: { context, parent } }) => {
                if (!context.canAddCarsManually || context.state === 'FL') return true;
                if (parent.symbolAux && !hasMakeModelStyleSymbols(parent)) return !value || value?.length === 2;
                return haveAnyOfThisCarsSymbolsChanged(parent, context.initialValues.cars) ? value?.length === 2 : true;
              }
            )
            .nullable(),
          symbolStyle: Yup.string()
            .test(
              'symbolStyle-length-is-2',
              'Must be exactly 2 characters',
              (value, { options: { context, parent } }) => {
                if (!context.canAddCarsManually || context.state === 'FL') return true;
                if (parent.symbolAux && !hasMakeModelStyleSymbols(parent)) return !value || value?.length === 2;
                return haveAnyOfThisCarsSymbolsChanged(parent, context.initialValues.cars) ? value?.length === 2 : true;
              }
            )
            .nullable(),
          symbolPGS: Yup.string()
            .test(
              'symbolPGS-length-is-1',
              'Must be exactly 1 characters',
              (value, { options: { context, parent } }) => {
                if (!context.canAddCarsManually || context.state !== 'FL') return true;
                return haveAnyOfThisCarsSymbolsChanged(parent, context.initialValues.cars) ? value?.length >= 1 : true;
              }
            )
            .nullable(),
          symbolBI: Yup.number()
            .test('symbolBI-length-is-required', 'Required', (value, { options: { context, parent } }) => {
              if (!context.canAddCarsManually || context.state !== 'FL') return true;
              return haveAnyOfThisCarsSymbolsChanged(parent, context.initialValues.cars) ? value?.length >= 0 : true;
            })
            .nullable(),
          symbolPD: Yup.number()
            .test('symbolPD-length-is-required', 'Required', (value, { options: { context, parent } }) => {
              if (!context.canAddCarsManually || context.state !== 'FL') return true;
              return haveAnyOfThisCarsSymbolsChanged(parent, context.initialValues.cars) ? value?.length >= 0 : true;
            })
            .nullable(),
          symbolMed: Yup.number()
            .test('symbolMed-length-is-required', 'Required', (value, { options: { context, parent } }) => {
              if (!context.canAddCarsManually || context.state !== 'FL') return true;
              return haveAnyOfThisCarsSymbolsChanged(parent, context.initialValues.cars) ? value?.length >= 0 : true;
            })
            .nullable(),
          symbolPIP: Yup.number()
            .test('symbolStyle-length-is-required', 'Required', (value, { options: { context, parent } }) => {
              if (!context.canAddCarsManually || context.state !== 'FL') return true;
              return haveAnyOfThisCarsSymbolsChanged(parent, context.initialValues.cars) ? value?.length >= 0 : true;
            })
            .nullable(),
          symbolColl: Yup.number()
            .test('symbolCOLL-length-is-required', 'Required', (value, { options: { context, parent } }) => {
              if (!context.canAddCarsManually || context.state !== 'FL') return true;
              return haveAnyOfThisCarsSymbolsChanged(parent, context.initialValues.cars) ? value?.length >= 0 : true;
            })
            .nullable(),
          symbolComp: Yup.number()
            .test('symbolCOMP-length-is-required', 'Required', (value, { options: { context, parent } }) => {
              if (!context.canAddCarsManually || context.state !== 'FL') return true;
              return haveAnyOfThisCarsSymbolsChanged(parent, context.initialValues.cars) ? value?.length >= 0 : true;
            })
            .nullable(),
          symbolUM: Yup.number()
            .test('symbolUMUIM-length-is-required', 'Required', (value, { options: { context, parent } }) => {
              if (!context.canAddCarsManually || context.state !== 'FL') return true;
              return haveAnyOfThisCarsSymbolsChanged(parent, context.initialValues.cars) ? value?.length >= 0 : true;
            })
            .nullable()
        })
      )
        .min(
          1,
          'Please add a car before updating this policy. If there are no cars to insure, please follow your designated process to cancel the auto policy.'
        )
        .nullable(),
      trailers: Yup.array(
        Yup.object().shape({
          VIN: Yup.string().requiredString('VIN is required'),
          type: Yup.string().requiredString('Trailer type is required'),
          year: Yup.number().typeError('Trailer year should be a number').required('Trailer year is required'),
          value: Yup.number()
            .typeError('Trailer value should be a number between $0 - $50000')
            .positive('Trailer value should be between $0 - $50000')
            .integer('Trailer value should be between $0 - $50000')
            .min(1, 'Trailer value should be greater than $0')
            .max(50000, 'Trailer value should be less than or equal to $50000')
            .when(
              ['deductibleCollision', 'deductibleComprehensive'],
              (deductibleCollision, deductibleComprehensive, schema) => {
                const validValues = [deductibleCollision.split('/')[0], deductibleComprehensive.split('/')[0]].filter(
                  (value) => value !== 'NONE' && value
                );
                if (!validValues.length) return schema;
                const minValue = Math.min(...validValues);
                return schema.min(500 + minValue, `Trailer value should be greater than ${500 + minValue}`);
              }
            )
            .required('Trailer value is required'),
          contents: Yup.number()
            .typeError('Content value should be a number between $0 - $5000')
            .positive('Content value should be between $0 - $5000')
            .integer('Content value should be between $0 - $5000')
            .min(0, 'Content value should be greater than $0')
            .max(5000, 'Content value should be less than or equal to $5000')
            .required('Trailer content value is required'),

          deductibleCollision: Yup.string()
            .requiredString('Collision deductible is required')
            .test(
              'minimumCollDeductibleMet',
              'Trailer deductibles must match deductibles of at least one insured vehicle, or be NO COVERAGE for liability ONLY',
              function (currentTrailerDeductibleCollision, { options }) {
                const { context, parent } = options;
                if (currentTrailerDeductibleCollision === 'NONE' && parent.deductibleComprehensive === 'NONE')
                  return true;
                const { cars } = context;
                for (const car of cars) {
                  if (
                    car.deductibleCollision === currentTrailerDeductibleCollision &&
                    car.deductibleComprehensive.split('/')[0] === parent.deductibleComprehensive.split('/')[0]
                  ) {
                    return true;
                  }
                }
                return false;
              }
            ),

          deductibleComprehensive: Yup.string()
            .requiredString('Comprehensive deductible is required')
            .test(
              'minimumCompDeductibleMet',
              'Trailer deductibles must match deductibles of at least one insured vehicle, or be NO COVERAGE for liability ONLY',
              function (currentTrailerDeductibleComprehensive, { options }) {
                const { context, parent } = options;
                if (currentTrailerDeductibleComprehensive === 'NONE' && parent.deductibleCollision === 'NONE')
                  return true;
                const { cars } = context;
                for (const car of cars) {
                  if (
                    car.deductibleComprehensive.split('/')[0] === currentTrailerDeductibleComprehensive.split('/')[0] &&
                    car.deductibleCollision === parent.deductibleCollision
                  ) {
                    return true;
                  }
                }
                return false;
              }
            ),
          garageLocation: Yup.object().shape({
            address: Yup.string()
              .requiredString('Address is required')
              .test('is-po-box', 'PO Box Addresses are invalid', (value) => {
                const pattern = new RegExp('\\b[p]*(ost)*\\.*\\s*[o|0]*(ffice)*\\.*\\s*b[o|0]x\\b', 'i');
                return !(pattern.test(value) || pattern.test(value));
              }),
            city: Yup.string().requiredString('City is required'),
            state: Yup.string().requiredString('State is required').validUSState('policy.trailers'),
            zip: Yup.string().requiredString('Zip code is required')
          })
        })
      ).nullable(),
      drivers: Yup.array(
        Yup.object().shape({
          excludeDriver: Yup.boolean()
            .nullable()
            .test('excludeDriver', 'Error, umbrella is active on Home policy', (value) => {
              if (value) {
                const policy = policies ? policies.find(({ policyType }) => policyType === 'H') : undefined;
                if (policy) {
                  return !policy.policyDetails.includeUmbrella;
                }
              }
              return true;
            }),
          driversLicenseNumber: driversLicenseNumberSchema,
          driversLicenseState: Yup.string()
            .requiredString('State is required')
            .validUSState('auto.drivers.driversLicenseState'),
          assignedCar: Yup.mixed().test('assignedCar', 'Each driver must be assigned to one car', function (value) {
            if (driverCarAssignmentStates[this.options.context.state] && this.options.context.state !== 'MA') {
              if (!value) {
                return this.createError({
                  message: 'All drivers must be assigned a primary vehicle'
                });
              }
              const cars = this.options.context.cars;
              if (value && !cars.some((car) => car.VIN === value)) {
                return this.createError({
                  message: 'Driver assigned VIN that does not exist '
                });
              }
              const drivers = this.options.context.drivers.filter((driver) => !driver.excludeDriver);
              const assignedDrivers = cars.map((car) => car.assignedDriver);

              if (drivers.length === cars.length) {
                const driversSet = new Set(assignedDrivers);
                // assert that each driver assigned exactly once
                return driversSet.size === assignedDrivers.length && drivers.length === driversSet.size
                  ? true
                  : this.createError({
                      message: 'Each driver must be assigned to one car'
                    });
              }

              return true;
            }
            return true;
          }),
          userAssignedCar: Yup.mixed().test('userAssignedCar', 'This car already has a main driver', function (value) {
            if (driverCarAssignmentStates[this.options.context.state] && this.options.context.state === 'MA') {
              if (!value || value === 'NONE') {
                return true;
              }
              const cars = this.options.context.cars;
              if (value && !cars.some((car) => car.VIN === value)) {
                return this.createError({
                  message: 'Driver assigned VIN that does not exist '
                });
              }
              const inexperiencedDrivers = this.options.context.drivers.filter((driver) => {
                let age = driver.age;
                if (!age) {
                  const today = new Date();
                  const birthDate = new Date(driver.dateOfBirth);
                  age = differenceInYears(today, birthDate);
                }
                return driver.age - driver.ageFirstLicensed < inexperiencedYearsLimit[this.options.context.state];
              });

              if (inexperiencedDrivers.filter((d) => d.userAssignedCar === value).length > 1) {
                return this.createError({
                  message: 'This car already has a main driver'
                });
              }
            }
            return true;
          })
        })
      ).nullable()
    });
  });
