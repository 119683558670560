import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import { useLocation } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { getValue } from '@ourbranch/lookups';

import { Button } from 'core/components/button';
import { AuthContext } from 'core/components/auth';
import { Label } from 'core/components/label';
import NotificationType from 'core/helpers/notifications';
import { useStore } from 'core/store';
import { NotificationCard } from 'core/components/notification-card';
import useStyles from './notification.styles';

const notificationsToFilterOutAtCheckout = [
  NotificationType.Offer.OfferNeedsUWReview,
  NotificationType.Offer.SelfReportedUWFlags,
  NotificationType.Offer.OfferWithOlderRoof
];

const AbodeProPlan = ({ classes }) => (
  <Label className={classes.styledText}>
    {`This offer was generated using a special link that includes `}
    <strong>{`an additional $100 charge for the Abode Pro plan.`}</strong>
    {` This charge is separate from your premium and must be paid by credit card. If you do not want the Abode Pro Plan included, please turn off the
        Connected Home discount in the Settings tab and update the offer.`}
  </Label>
);

const NewConnectedHomeSignUpCustomer = ({ classes }) => (
  <Label className={classes.styledText}>
    {`A new sign up will prompt the home security company to contact the customer to buy the home security system necessary to retain the Connected Home Discount. Available with select home security partnerships only.`}
  </Label>
);

const ConnectedHome = ({ classes }) => (
  <Label className={classes.styledText}>
    {`This offer has an affinity discount from a Connected Home partner, but does not include a Connected Home discount,
        resulting in an incorrect price. To correct this, go to the Settings tab and `}
    <strong>{`make sure the Connected Home discount is turned on and has devices
        added, or remove the affinity discount.`}</strong>
  </Label>
);

const OfferNeedsUWReview = ({ option }) => (
  <Label>
    {`Branch needs additional confirmations before ${
      option?.includes('H') ? (option?.includes('A') ? 'home and auto' : 'home') : 'auto'
    } policy eligibility can be confirmed. Please continue to the Underwriting Review page to verify.`}
  </Label>
);
const OfferWithOlderRoof = () => (
  <Label>
    Our systems flagged this offer because roof age is older than we expected. Please verify the roof year to ensure an
    accurate premium calculation.
  </Label>
);
const SelfReportedUWFlags = ({ classes, canClearUWFormRejections, setShowUWResetModal, offerStore }) => {
  let ineligibleForHome;
  let ineligibleForAuto;
  if (
    offerStore?.offer?.quote?.preBindUWCheck?.autoVerification?.ineligibleForAutoDueToUWSelfReport ||
    offerStore?.priorQuoteWithPreBindUWRejections?.ineligibleForAuto
  ) {
    ineligibleForAuto = true;
  }

  if (
    offerStore?.offer?.quote?.preBindUWCheck?.homeVerification?.ineligibleForHomeDueToUWSelfReport ||
    offerStore?.priorQuoteWithPreBindUWRejections?.ineligibleForHome
  ) {
    ineligibleForHome = true;
  }

  return (
    <Grid container className={classes.UWContainer}>
      <Label>
        {`During the sales underwriting review this member reported characteristic(s) that do not meet Branch
        underwriting guidelines, making them ineligible for ${
          ineligibleForHome ? (ineligibleForAuto ? 'home and auto' : 'home') : 'auto'
        } insurance.`}
        {canClearUWFormRejections && (
          <Button
            className={classes.verifyBtn}
            variant="contained"
            color="primary"
            onClick={() => setShowUWResetModal(true)}
          >
            Reset
          </Button>
        )}
      </Label>
    </Grid>
  );
};

const LapsedDriversLicenses = ({ state }) => {
  const stateValue = getValue('usStates', state);

  return (
    <Label>
      This is a {stateValue} Offer. Please ensure the age first licensed and driver's license lapse status is accurate
      for all drivers before continuing.
    </Label>
  );
};

const OfferNotifications = observer(({ type, setShowUWResetModal }) => {
  const { offer: offerStore } = useStore();
  const { canClearUWFormRejections } = useContext(AuthContext);
  const classes = useStyles();
  const location = useLocation();

  const atCheckout = location.pathname.includes('checkout');

  let notifications = offerStore.notifications;

  if (atCheckout) {
    notifications = notifications.filter(
      (notificationType) => !notificationsToFilterOutAtCheckout.includes(notificationType)
    );
  }

  return (
    <Grid id="notificationsContainer">
      {notifications.map((notificationType) => (
        <NotificationCard key={notificationType} type={type}>
          {notificationType === NotificationType.Offer.AbodeProPlan && <AbodeProPlan classes={classes} />}
          {notificationType === NotificationType.Offer.ConnectedHome && <ConnectedHome classes={classes} />}
          {notificationType === NotificationType.Offer.NewConnectedHomeSignUpCustomer && (
            <NewConnectedHomeSignUpCustomer classes={classes} />
          )}
          {notificationType === NotificationType.Offer.OfferNeedsUWReview && (
            <OfferNeedsUWReview option={offerStore?.selectedOption} />
          )}
          {notificationType === NotificationType.Offer.OfferWithOlderRoof && <OfferWithOlderRoof />}
          {notificationType === NotificationType.Offer.SelfReportedUWFlags && (
            <SelfReportedUWFlags
              classes={classes}
              canClearUWFormRejections={canClearUWFormRejections}
              setShowUWResetModal={setShowUWResetModal}
              offerStore={offerStore}
            />
          )}
          {notificationType === NotificationType.Offer.LapsedDriversLicenses && (
            <LapsedDriversLicenses state={offerStore.state} />
          )}
        </NotificationCard>
      ))}
    </Grid>
  );
});

export default OfferNotifications;
