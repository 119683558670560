import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import CssBaseline from '@material-ui/core/CssBaseline';
import { withStyles } from '@material-ui/core/styles';
import * as Sentry from '@sentry/react';
import { Auth as AwsAuth, Hub } from 'aws-amplify';

import awsConfig from 'aws-exports';
import { createStore, StoreProvider } from '../store';
import { identify, track } from '../helpers/analytics';
import { apolloClient } from '../apollo-client';
import { StaffSpeedDial } from './speed-dial';
import Auth from './auth';
import ErrorBoundary from './error-boundary';
import Layout from './layout/layout';
import Routes from './routes';
import ThemeProvider from './theme';
import { colors, fonts } from './theme/variables';
import { Toast, ToastProvider } from './toast';

if (process.env.REACT_APP_SENTRY_ENABLED === 'true' && process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://92e31532c8f0419eb6c9d3ce2f620928@sentry.io/1501891',
    environment: process.env.REACT_APP_STACKERY_ENVIRONMENT,
    ignoreErrors: ['ResizeObserver loop limit exceeded', 'NotAuthorizedException', 'subscriptionFailedCallback'],
    // Documentation: https://docs.sentry.io/platforms/javascript/configuration/filtering/
    beforeSend(event) {
      try {
        // Level: error
        if (event.level === 'error' && event.exception?.values) {
          const failedToFetchException = event.exception.values.some(
            (v) => v.type === 'TypeError' && v.value === 'Failed to fetch'
          );
          // Exception: TypeError: Failed to fetch
          if (failedToFetchException && event.breadcrumbs) {
            const audiencepsynchBreadcrumb = event.breadcrumbs.find(
              (b) =>
                b.category === 'fetch' &&
                b.data &&
                b.data.method === 'POST' &&
                b.data.url?.includes('analytics2.audiencepsynch.com/api/v1/pixel-data/json')
            );
            // audiencepsynch endpoint
            if (audiencepsynchBreadcrumb) {
              // Ignore event
              return null;
            }
          }
        }
        return event;
      } catch (error) {
        // eslint-disable-next-line no-console
        console.warn('[sentry] Failed checking if event should be ignored', error);
        return event;
      }
    }
  });
}

const rootStyles = {
  '@global': {
    '*': {
      fontFamily: fonts.LibreFranklin
    },
    body: {
      backgroundColor: colors.page__background_primary
    },
    ':focus': {
      outline: 'none'
    },
    '::-moz-focus-inner': {
      border: 0
    },
    a: {
      textDecoration: 'none',
      display: 'contents' // https://drafts.csswg.org/css-display/#valdef-display-contents
    }
  }
};

const store = createStore(apolloClient);
const App = () => {
  useEffect(() => {
    if (awsConfig.trackingEnabled) {
      Hub.listen('auth', (capsule) => {
        // eslint-disable-next-line default-case
        switch (capsule.payload.event) {
          case 'signIn': {
            AwsAuth.currentAuthenticatedUser().then((user) => {
              window.analytics.alias(`staff-${user.username}`);
              identify({ username: user.username, email: user.attributes.email });
              track('Staff Sign In', { username: user.username, email: user.attributes.email });
            });
            break;
          }
          case 'signIn_failure': {
            track('Unsuccesful Staff Sign In', {
              reason: `${capsule.payload.message} ${capsule.payload.data.message}`
            });
            break;
          }
        }
      });
    }
  }, []);

  return (
    <ThemeProvider>
      <StoreProvider store={store}>
        <ToastProvider>
          <Auth client={apolloClient}>
            <ApolloProvider client={apolloClient}>
              <CssBaseline />
              <ErrorBoundary>
                <Router basename="/">
                  <Layout>
                    <Routes />
                  </Layout>
                  <StaffSpeedDial />
                </Router>
              </ErrorBoundary>
              <Toast />
            </ApolloProvider>
          </Auth>
        </ToastProvider>
      </StoreProvider>
    </ThemeProvider>
  );
};

export default withStyles(rootStyles)(App);
