/* eslint-disable no-console */
import gql from 'graphql-tag';
import { MobXProviderContext } from 'mobx-react';
import React from 'react';
import PropTypes from 'prop-types';
import { Auth as AwsAuth, Hub } from 'aws-amplify';
import * as Sentry from '@sentry/react';

import { withToast } from 'core/components/toast';
import { CognitoPermissionGroups } from 'core/helpers/cognito-permission-groups';
import { identifyUserInFront } from 'configure-services';
import { AuthContext } from './auth.context';

AwsAuth.configure({
  authenticationFlowType: 'CUSTOM_AUTH'
});

function getUserFromPayload(payload) {
  return {
    username: payload['cognito:username'],
    groups: payload['cognito:groups'],
    email: payload.email,
    phoneNumber: payload.phone_number,
    affinityCode: payload['custom:affinity_code'],
    licenseNumber: payload['custom:license_number']
  };
}

function parseCustomAttribute(attribute) {
  try {
    return JSON.parse(attribute);
  } catch (e) {
    return [];
  }
}

function permissions(payload) {
  if (!payload['cognito:groups'] || !payload['cognito:groups'].length) {
    return {
      isService: false,
      isSales: false,
      canEdit: false
    };
  }

  // licensed agent groups
  const isService = payload['cognito:groups']?.includes('Service');
  const isSales =
    payload['cognito:groups']?.includes('InternalSales') || payload['cognito:groups']?.includes('ExternalSales');
  const isInternalSales = payload['cognito:groups']?.includes('InternalSales');
  const isExternalSales = payload['cognito:groups']?.includes('ExternalSales');
  const isTeamLeader = payload['cognito:groups']?.includes('TeamLeaders');

  // logic surrounding licensed and unlicensed states for external users
  const isAgency =
    !!parseCustomAttribute(payload['custom:affinity_groups']).length ||
    !!parseCustomAttribute(payload['custom:affinity_group_regex']).length;
  const parsedAllowedStates = parseCustomAttribute(payload['custom:allowed_states']);
  const parsedUnlicensedStates = parseCustomAttribute(payload['custom:unlicensed_states']);

  // individual features access
  const canReinstate = payload['cognito:groups']?.includes('Reinstatement');
  const canRescindCancellation = payload['cognito:groups']?.includes('RescindCancellation');
  const canSeeFullOfferDetails = payload['cognito:groups']?.includes('CompleteOfferDetails');
  const canScrubIncidents = payload['cognito:groups']?.includes('ScrubIncidents');
  const canModifyAffinityAndLeadSource = payload['cognito:groups']?.includes('ModifyAffinityCodes');
  const canBackDate = payload['cognito:groups']?.includes('CanBackDate');
  const canModifyBillingId = payload['cognito:groups']?.includes('ModifyBillingID');
  const canViewClarionDoorData = payload['cognito:groups']?.includes('ViewClarionDoorData');
  const canAddCarsManually = payload['cognito:groups']?.includes('AddCarsManually');
  const canAutoRenew = payload['cognito:groups']?.includes('CanAutoRenew');
  const canAddHoldCards = payload['cognito:groups']?.includes('CanAddHoldCards');
  const canChangeExclusions = payload['cognito:groups']?.includes('CanChangeExclusions');
  const hasUnlicensedGroup = payload['cognito:groups']?.includes('Unlicensed');
  const canToggleEmployeeDiscount = payload['cognito:groups']?.includes('CanToggleEmployeeDiscount');
  const canClearUDRs = payload['cognito:groups']?.includes('CanClearUDRs');
  const canEditHomeAddress = payload['cognito:groups']?.includes('CanEditHomeAddress');
  const canEditEffectiveDate = payload['cognito:groups']?.includes('CanEditEffectiveDate');
  const canFlagAsSuspicious = payload['cognito:groups']?.includes('CanFlagSuspiciousAccounts');
  const canManuallyChargeFee = payload['cognito:groups']?.includes('CanManuallyChargeFee');
  const canQuoteQuinstreet = payload['cognito:groups']?.includes('CanQuoteQuinstreet');
  const canClearUWFormRejections = payload['cognito:groups']?.includes('CanClearUWFormRejections');
  const canManuallyChargePolicy = payload['cognito:groups']?.includes('CanManuallyChargePolicy');
  const canRewritePolicy = payload['cognito:groups']?.includes('CanRewritePolicy');
  const canRemoveExcludedDriver = payload['cognito:groups']?.includes('CanRemoveExcludedDriver');
  const canCancelReasonCNIP = payload['cognito:groups']?.includes('CanCancelReasonCNIP');
  const canCancelReasonCNMS = payload['cognito:groups']?.includes('CanCancelReasonCNMS');
  const canRejectPIP = payload['cognito:groups']?.includes('CanRejectPIP');
  const showAgencyPortal = payload['cognito:groups']?.includes('ShowAgencyPortal');
  const hasCanQuoteGroup = payload['cognito:groups']?.includes('CanQuote');
  const hasCanViewPolicyGroup = payload['cognito:groups']?.includes('CanViewPolicy');
  const canViewQlikReports = payload['cognito:groups']?.includes('CanViewQlikReports');

  // can view both offers and policies but will not allow editing
  const viewOnly = payload['cognito:groups']?.includes('ViewOnly');

  // Users that are not licensed in any states and therefore should only have a populated list of unlicensed states alongside the Unlicensed cognito group
  const isUnlicensed = hasUnlicensedGroup && Boolean(parsedUnlicensedStates?.length && !parsedAllowedStates.length);

  // Users that are not licensed in any states will have access to editing only unlicensed fields on offers/policies.
  // Alongside the Unlicensed group, they should also have populated list of unlicensed states.
  // The form field component logic takes care of disabling the licensed fields for these users.
  const canEditPolicies = (isTeamLeader || isSales || isService || isUnlicensed) && !viewOnly;
  const canEditOffers = (isTeamLeader || isSales || isService || isUnlicensed || hasCanQuoteGroup) && !viewOnly;
  const canViewOffers = viewOnly || canEditOffers;
  const canViewPolicies = viewOnly || hasCanViewPolicyGroup || canEditPolicies;

  return {
    // allows quoting, editing, and viewing offers
    [CognitoPermissionGroups.canQuote]: (isTeamLeader || isSales || isService || hasCanQuoteGroup) && !viewOnly,

    // allows purchasing offers/binding
    [CognitoPermissionGroups.canBind]: (isTeamLeader || isSales || isService) && !viewOnly,

    // allows viewing the checkout route but not necessarily binding
    [CognitoPermissionGroups.canViewCheckoutPage]:
      (isTeamLeader || isSales || isService || hasCanQuoteGroup) && !viewOnly,

    [CognitoPermissionGroups.canViewPolicies]: canViewPolicies,
    [CognitoPermissionGroups.canViewOffers]: canViewOffers,

    // if opening policy/offer for state not licensed for, the code should only allow unlicensed actions
    // which is determined via the permissions prop and useFormFieldPermissionHelpers used in FormField
    [CognitoPermissionGroups.canEditPolicies]: canEditPolicies,
    [CognitoPermissionGroups.canEditOffers]: canEditOffers,
    [CognitoPermissionGroups.canEdit]: canEditPolicies || canEditOffers,

    // allows viewing offers, policies, and customer, but does not allow editing, checkout, or modifying the data of any kind
    [CognitoPermissionGroups.viewOnly]: viewOnly,
    [CognitoPermissionGroups.isTeamLeader]: isTeamLeader && !viewOnly,
    [CognitoPermissionGroups.isSales]: isSales,
    [CognitoPermissionGroups.isService]: isService && !viewOnly,
    [CognitoPermissionGroups.isAgency]: isAgency,
    [CognitoPermissionGroups.isInternalAgent]: !isAgency,
    [CognitoPermissionGroups.isUnlicensed]: isUnlicensed,
    [CognitoPermissionGroups.canReinstate]: canReinstate,
    [CognitoPermissionGroups.canRescindCancellation]: canRescindCancellation,
    [CognitoPermissionGroups.canSeeFullOfferDetails]: canSeeFullOfferDetails,
    [CognitoPermissionGroups.isInternalSales]: isInternalSales && !viewOnly,
    [CognitoPermissionGroups.isExternalSales]: isExternalSales && !viewOnly,
    [CognitoPermissionGroups.canScrubIncidents]: canScrubIncidents,
    [CognitoPermissionGroups.canModifyAffinityAndLeadSource]: canModifyAffinityAndLeadSource,
    [CognitoPermissionGroups.canBackDate]: canBackDate && !isAgency,
    [CognitoPermissionGroups.canModifyBillingId]: canModifyBillingId && !isAgency,
    [CognitoPermissionGroups.canViewClarionDoorData]: canViewClarionDoorData,
    [CognitoPermissionGroups.canAddCarsManually]: canAddCarsManually && !isAgency,
    [CognitoPermissionGroups.canAutoRenew]: canAutoRenew && !isAgency,
    [CognitoPermissionGroups.canAddHoldCards]: canAddHoldCards,
    [CognitoPermissionGroups.canToggleEmployeeDiscount]: canToggleEmployeeDiscount && !isAgency,
    [CognitoPermissionGroups.canChangeExclusions]: canChangeExclusions,
    [CognitoPermissionGroups.canClearUDRs]: canClearUDRs && isTeamLeader,
    [CognitoPermissionGroups.canEditHomeAddress]: canEditHomeAddress && isTeamLeader,
    [CognitoPermissionGroups.canEditEffectiveDate]: canEditEffectiveDate,
    [CognitoPermissionGroups.canFlagAsSuspicious]: canFlagAsSuspicious,
    [CognitoPermissionGroups.canManuallyChargeFee]: canManuallyChargeFee,
    [CognitoPermissionGroups.canQuoteQuinstreet]: canQuoteQuinstreet,
    [CognitoPermissionGroups.canClearUWFormRejections]: canClearUWFormRejections,
    [CognitoPermissionGroups.canUploadDocuments]: (isInternalSales || isService || isTeamLeader) && !viewOnly,
    [CognitoPermissionGroups.canManuallyChargePolicy]: canManuallyChargePolicy,
    [CognitoPermissionGroups.canRewritePolicy]: canRewritePolicy,
    [CognitoPermissionGroups.canRemoveExcludedDriver]: canRemoveExcludedDriver,
    [CognitoPermissionGroups.canCancelReasonCNIP]: canCancelReasonCNIP,
    [CognitoPermissionGroups.canCancelReasonCNMS]: canCancelReasonCNMS,
    [CognitoPermissionGroups.canRejectPIP]: canRejectPIP,
    [CognitoPermissionGroups.showAgencyPortal]: showAgencyPortal || isAgency,
    [CognitoPermissionGroups.canViewQlikReports]: canViewQlikReports
  };
}

const defaultState = {
  user: null,
  isLoggedIn: false,
  logout: () => AwsAuth.signOut(),
  changePassword: (oldPassword, newPassword) => {
    return AwsAuth.currentAuthenticatedUser().then((user) => {
      return AwsAuth.changePassword(user, oldPassword, newPassword);
    });
  },
  loading: true,
  userPreferences: null
};

const GET_USER_PREFERENCES = gql`
  query {
    getUserPreferences {
      username
      algoliaKey
      affinityGroups
    }
  }
`;

function getAllowedStates({ parsedAllowedStates }) {
  if (parsedAllowedStates?.length) {
    return parsedAllowedStates;
  }
  return [];
}

class Auth extends React.Component {
  static contextType = MobXProviderContext; // This sets `this.context` to be the MobX store.
  static propTypes = {
    children: PropTypes.node.isRequired,
    client: PropTypes.object.isRequired,
    toast: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    Hub.listen('auth', async (capsule) => {
      const { channel, payload } = capsule;
      if (channel === 'auth') {
        const { event, data } = payload;
        if (event === 'signIn') {
          await this.setAuthStateFromSession(
            data.signInUserSession.idToken.payload,
            data.signInUserSession.idToken.jwtToken
          );

          // Take user to root after login if they weren't on a specific policy, offer or member
          if (
            window.location.pathname === '/search/offers' ||
            window.location.pathname === '/search/policies' ||
            window.location.pathname === '/search/customers' ||
            window.location.pathname === '/quote' ||
            window.location.pathname === '/account'
          ) {
            window.location = '/';
          }
        } else if (event === 'signOut') {
          window.location.reload();
        }
      }
    }).bind(this);
    this.state = defaultState;
  }

  async componentDidMount() {
    try {
      const session = await AwsAuth.currentSession();
      await this.setAuthStateFromSession(session.idToken.payload, session.getIdToken().getJwtToken());
    } catch (e) {
      // eslint-disable-next-line
      console.log('Error getting session from AwsAuth: ', e);
      this.setState(defaultState);
    }
  }

  async getUserPreferences(payload) {
    const { client } = this.props;
    try {
      const {
        data: { getUserPreferences }
      } = await client.query({ query: GET_USER_PREFERENCES });
      const algoliaKey = getUserPreferences?.algoliaKey || payload['custom:algolia_api_key'];
      return { algoliaKey, affinityGroups: getUserPreferences?.affinityGroups };
    } catch (e) {
      console.log('Try deploying dynamoDB to have a valid algolia key');
      return { algoliaKey: undefined, affinityGroups: undefined };
    }
  }

  async setAuthStateFromSession(payload, token) {
    const userPreferences = await this.getUserPreferences();
    if (this.context.store) {
      this.context.store.userPreferences.setUserPreferences(userPreferences);
    }

    const user = getUserFromPayload(payload);
    const permissionGroups = permissions(payload);
    const parsedAllowedStates = parseCustomAttribute(payload['custom:allowed_states']);
    const parsedUnlicensedStates = parseCustomAttribute(payload['custom:unlicensed_states']);

    try {
      identifyUserInFront(user);
    } catch (error) {
      console.error(error);
      console.log('Error identifing user in front');
      Sentry.captureException(error);
    }

    this.setState({
      user,
      isLoggedIn: true,
      ...permissionGroups,
      token,
      loading: false,
      allowedStates: getAllowedStates({ parsedAllowedStates, permissionGroups }),
      unlicensedStates: parsedUnlicensedStates?.length ? parsedUnlicensedStates : [],
      userPreferences
    });
  }

  render() {
    const { children } = this.props;

    return <AuthContext.Provider value={this.state}>{children}</AuthContext.Provider>;
  }
}

export default withToast(Auth);
