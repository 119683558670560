import { useEffect } from 'react';
import { Hub } from 'aws-amplify';

const MAPPED_ERRORS = {
  'Member must satisfy regular expression pattern: [\\p{L}\\p{M}\\p{S}\\p{N}\\p{P}]+':
    'The username you entered is not valid. Please remove any whitespace characters before or after your username to continue.',
  'User does not exist':
    'This username is not recognized. Please check the spelling and try again, or use your email on file to sign in. If you forget your username, please follow your designated support process to resolve the issue.',
  'User is disabled.':
    'Your account has been disabled. If you should have access to this system, please follow your designated support process to resolve the issue.',
  'Username/client id combination not found':
    'This username is not recognized. Please check the spelling and try again, or use your email on file to sign in. Please follow your designated support process to recover your username.',
  'User password cannot be reset in the current state.':
    'You have not reset the temporary password. Please use the temporary login credentials provided in your Branch welcome email, or follow your designated support process to resolve this issue.'
};

const CatchAuthErrors = (props) => {
  useEffect(() => {
    Hub.listen('auth', function cb(capsule) {
      const { channel, payload } = capsule;
      if (channel === 'auth') {
        const { event } = payload;
        if (event === 'signIn_failure' || event === 'forgotPassword_failure') {
          for (const [badErrorMessage, betterErrorMessage] of Object.entries(MAPPED_ERRORS)) {
            if (capsule.payload.data.message.includes(badErrorMessage)) {
              setTimeout(() => {
                props.onAuthEvent(null, {
                  type: 'error',
                  data: betterErrorMessage
                });
              }, 0); // makes sure our error message is set after aws-amplify-react's internal error message
            }
          }
        }
      }
    });
  }, []);

  return null;
};

export default CatchAuthErrors;
