import crypto from 'crypto';
import qs from 'querystring';
import { key, iv } from './aes-256-cbc-key-iv.json';

export function createBixConversionApplicationLink(policyId: string) {
  const cipher = crypto.createCipheriv('aes-256-cbc', Buffer.from(key, 'hex'), Buffer.from(iv, 'hex'));
  const encryptedPolicyId = cipher.update(policyId, 'utf8', 'hex') + cipher.final('hex');
  const querystring = qs.stringify({ policyId: encryptedPolicyId });
  return `https://ourbranch.com/convert?${querystring}`;
}

export function createPolicyUnsignedFormLink({
  policyId,
  docType,
  docPrefix,
  origin = 'https://ourbranch.com'
}: {
  policyId: string;
  docType: number;
  docPrefix: string;
  origin?: string;
}) {
  const cipher = crypto.createCipheriv('aes-256-cbc', Buffer.from(key, 'hex'), Buffer.from(iv, 'hex'));
  const encryptedPolicyId = cipher.update(policyId, 'utf8', 'hex') + cipher.final('hex');
  const querystring = qs.stringify({ policyId: encryptedPolicyId, docType, docPrefix });

  return `${origin}/convert?${querystring}`;
}
