import React, { useMemo } from 'react';
import { getOptions, policyType, standaloneRentersStates } from '@ourbranch/lookups';
import { flowRight } from 'lodash-es';

import withDatePicker from 'core/components/with-date-picker';
import { useStore } from 'core/store/store.mobx';
import useBillingDayOfMonthOptions from 'core/hooks/use-billing-day-of-month-options';
import HomeSettings from './home-settings';
import CondoSettings from './condo-settings';
import AutoSettings from './auto-settings';
import RentersSettings from './renters-settings';
import LeadSourceSettings from './lead-source-settings';

const Settings = () => {
  const { offer: store, affinityLookups } = useStore();

  const hasMonoLineHomeOption = store.offer.options.find((option) => option.type === policyType.Home);
  const hasMonoLineAutoOption = store.offer.options.find((option) => option.type === policyType.Auto);
  const hasMonoLineRentersOption = store.offer.options.find((option) => option.type === policyType.Renters);
  const hasMonoLineCondoOption = store.offer.options.find((option) => option.type === policyType.Condo);

  const homeBillingDayOptions = useBillingDayOfMonthOptions(
    store.offer,
    hasMonoLineHomeOption ? policyType.Home : policyType.HABundle
  );
  const autoBillingDayOptions = useBillingDayOfMonthOptions(
    store.offer,
    hasMonoLineAutoOption ? policyType.Auto : policyType.HABundle
  );
  const rentersBillingDayOptions = useBillingDayOfMonthOptions(
    store.offer,
    hasMonoLineRentersOption ? policyType.Renters : policyType.ARBundle
  );
  const condoBillingDayOptions = useBillingDayOfMonthOptions(
    store.offer,
    hasMonoLineCondoOption ? policyType.Condo : policyType.CABundle
  );

  const showRenters =
    standaloneRentersStates[store.offer?.quote?.correctedAddress?.state] &&
    store.offer.options.some((o) => o.type === policyType.ARBundle || o.type === policyType.Renters);

  const {
    paymentTypeOptions,
    autoPaymentTypeOptions,
    rentersPaymentTypeOptions,
    hPaymentMethodOptions,
    aPaymentMethodOptions,
    rPaymentMethodOptions,
    cPaymentMethodOptions,
    leadSourceOptions
  } = useMemo(() => {
    return {
      paymentTypeOptions: getOptions('paymentType', store.state),
      autoPaymentTypeOptions: getOptions('autoPaymentType', store.state),
      rentersPaymentTypeOptions: getOptions('rentersPaymentType', store.state),
      hPaymentMethodOptions: getOptions('homeownersPaymentMethod', store.state),
      cPaymentMethodOptions: getOptions('condoPaymentMethod', store.state),
      aPaymentMethodOptions: getOptions('autoPaymentMethod'),
      rPaymentMethodOptions: getOptions('rentersPaymentMethod', store.state),
      leadSourceOptions: getOptions('leadSources')
    };
  }, [store, affinityLookups]);

  return (
    <>
      {store.shouldShowHomeTab && (
        <HomeSettings
          paymentMethodOptions={hPaymentMethodOptions}
          billingDaysOptions={homeBillingDayOptions}
          paymentTypeOptions={paymentTypeOptions}
        />
      )}
      {store.shouldShowCondoTab && (
        <CondoSettings
          paymentMethodOptions={cPaymentMethodOptions}
          billingDaysOptions={condoBillingDayOptions}
          paymentTypeOptions={paymentTypeOptions}
        />
      )}
      {store.shouldShowAutoTab && (
        <AutoSettings
          paymentMethodOptions={aPaymentMethodOptions}
          billingDaysOptions={autoBillingDayOptions}
          paymentTypeOptions={autoPaymentTypeOptions}
        />
      )}
      {showRenters && (
        <RentersSettings
          paymentMethodOptions={rPaymentMethodOptions}
          billingDaysOptions={rentersBillingDayOptions}
          paymentTypeOptions={rentersPaymentTypeOptions}
        />
      )}
      <LeadSourceSettings leadSourceOptions={leadSourceOptions} />
    </>
  );
};

Settings.propTypes = {};

export default flowRight(withDatePicker)(Settings);
