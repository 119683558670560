import React, { useEffect, useContext, memo } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import classNames from 'classnames';
import NavigationPrompt from 'react-router-navigation-prompt';
import Button from '@material-ui/core/Button';
import { acceptableCancelCodesForRewrite } from '@ourbranch/lookups';
import Grid from '@material-ui/core/Grid';

import { AuthContext } from 'core/components/auth';
import { useStore } from 'core/store';
import { Label } from 'core';
import LeavePageDialog from 'core/components/leave-page-dialog';
import Field from 'core/components/form/form.v2';
import useStyles from './footer.styles';

const isPolicyEligibleForRewrite = (policy) => {
  const flatCancel = policy.effectiveDate === policy.endDate;
  const firstTerm = policy.term === 1;
  return firstTerm && flatCancel && acceptableCancelCodesForRewrite.includes(policy.cancelReason);
};

function Footer({ title, form, leftForm, buttonLabel, onClick, setShowRewriteModal }) {
  const classes = useStyles();

  useEffect(() => {
    window.onbeforeunload = () => true;
  }, []);

  const {
    handleSubmit,
    values: { cancel }
  } = useFormikContext();
  const { canRewritePolicy, isAgency, isInternalSales, canEdit } = useContext(AuthContext);
  const {
    account: {
      policies: { policy: policyStore }
    }
  } = useStore();

  let delayPaymentTooltip = null;
  if (!cancel && policyStore.mustDelayPayments) {
    if (isInternalSales) {
      delayPaymentTooltip =
        'This checkbox has been pre-populated to avoid errant member charges prior to the first billing date of the policy. If you would like to process a payment, please manually bill the member.';
    } else if (isAgency) {
      delayPaymentTooltip =
        'This checkbox has been pre-populated to avoid errant member charges prior to the first billing date of the policy. If you would like to process a payment immediately, please reach out to Agency Support.';
    }
  }

  const disableDelayPaymentCheckbox =
    !cancel && policyStore.mustDelayPayments && (canEdit || isAgency || isInternalSales);

  return (
    <div className={classes.container}>
      <NavigationPrompt renderIfNotActive={false} when>
        {({ isActive, onCancel, onConfirm }) => {
          return <LeavePageDialog open={isActive} onClose={onCancel} cb={onConfirm} />;
        }}
      </NavigationPrompt>
      <Grid container className={classes.footerInner}>
        <div className={classNames(classes.footerItem, { [classes.withLeftForm]: !!leftForm })}>
          <Label className={classNames(classes.label, { [classes.leftFormLabel]: !!leftForm })} type="subtitle">
            {title}
          </Label>
          {leftForm}
        </div>
        {form && <div className={classNames(classes.footerItem, { [classes.right]: !!form })}>{form}</div>}
        <Grid className={classNames(classes.footerItem, classes.last)}>
          <Field
            type="checkbox"
            id="skipImmediateBillOrRefund"
            name="skipImmediateBillOrRefund"
            disabled={disableDelayPaymentCheckbox}
            tooltipText={delayPaymentTooltip}
            tooltipLabel={!cancel && policyStore.mustDelayPayments && (isAgency || isInternalSales) ? 'More Info' : ''}
            mode="light"
            label="Delay Payment/Refund"
            className={classNames({ [classes.checkbox]: !!leftForm })}
            checkboxTooltipClassName={classes.checkboxTooltip}
            ignoreGlobalDisabledState // cancel policy sets all form fields to disabled, so need to manually exclude this field
            permissions={{
              isLicensedAction: false
            }}
          />

          {isPolicyEligibleForRewrite(policyStore.policy) && canRewritePolicy && (
            <Button variant="text" className={classes.rewriteBtn} onClick={() => setShowRewriteModal(true)}>
              Rewrite Policy
            </Button>
          )}
          <Button
            variant="contained"
            color="primary"
            data-cy="preview-policy-changes"
            onClick={onClick || handleSubmit}
            className={classes.btn}
          >
            <Label className={classes.btnLabel}>{buttonLabel || 'Preview policy modifications'}</Label>
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}

Footer.propTypes = {
  onClick: PropTypes.func,
  title: PropTypes.string,
  form: PropTypes.node,
  leftForm: PropTypes.node,
  buttonLabel: PropTypes.string
};

Footer.defaultProps = {
  onClick: null,
  form: undefined,
  leftForm: undefined,
  title: 'Create new version',
  buttonLabel: 'Preview policy modifications'
};

export default memo(Footer);
