import React from 'react';

import { Label } from 'core/components/label';
import { Button } from 'core/components/button';
import useStyles from './hardware-mfa.styles';

function VerifyMFA({ useMfa }) {
  const styles = useStyles();

  return (
    <>
      <Label type="titleSecondary" className={styles.title}>
        Verify with your device
      </Label>
      <Label type="infoInnerTitle" className={styles.content}>
        The browser should be prompting you to verify your identity with a registered device. Follow the on-screen
        prompts to continue.
      </Label>
      <Label type="infoInnerTitle" className={styles.content}>
        If you registered your phone as an authentication device, please scan the QR code to continue.
      </Label>
      <Label type="infoInnerTitle" className={styles.content}>
        If you registered your computer as an authentication device, please use your device’s secure authentication
        method to continue (TouchID or Windows Hello).
      </Label>
      <Button variant="contained" color="secondary" className={styles.button} onClick={useMfa}>
        Let's go
      </Button>
    </>
  );
}

export default VerifyMFA;
