/* eslint-disable no-console */
import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Form, useFormikContext } from 'formik';
import { compareAsc, subYears, parseISO, differenceInYears } from 'date-fns';
import { Grid, Button } from '@material-ui/core';
import classNames from 'classnames';
import {
  lookupsJson,
  courseDiscountTypeByStates,
  courseDiscountsAges,
  additionalDriverDiscountsTypesByStates,
  stateSpecificToolTips,
  seniorDiscountByAgeOnlyStates,
  hideHighestEducationStates,
  hideGenderStates,
  sR22States,
  communityDriveAvailableStates,
  noCreditStates,
  courseDiscountTypes,
  lapsedLicenseMonthsStates,
  seniorAge,
  inexperiencedYearsLimit
} from '@ourbranch/lookups';

import { AuthContext } from 'core/components/auth';
import { Label } from 'core/components/label';
import { FormField } from 'core/components/form';
import withDatePicker from 'core/components/with-date-picker';
import CollapsibleCard from 'core/components/collapsible-card';
import excluded from 'core/assets/svg/ic-excluded.svg';
import { Tooltip } from 'core/components/tooltip';
import { useToast } from 'core/components/toast';
import { useSwapAppCoApp } from 'common/components/people/hooks';
import { tooltipHoverTexts } from 'core/helpers/constants';
import ClearUDRsModal from './clear-udrs-modal/clear-udrs-modal';
import useStyles from './person.styles';

const fullTimeStudentAgeLimit = 23;

const drivingCourseLabel = {
  AVOIDANCE: 'Has this driver taken an Accident Avoidance Course?',
  SENIOR: 'Has this driver taken an Accident Prevention Course?',
  DEFENSIVE: 'Has this driver taken a Defensive Driving Course?',
  PREVENTION: 'Has this driver taken an Accident Prevention Course?',
  MILITARY_DEFENSIVE:
    'Has this driver taken a voluntary accident prevention course provided by the Armed Forces in the last 5 years?',
  NATIONAL_GUARD: 'Has this driver taken a defensive driving course?',
  IS_ACTIVE_DUTY: 'Is this driver an active duty member of the US Armed Forces?',
  IS_ACTIVE_DUTY_NG: 'Is this driver an active duty member of the National Guard?',
  INEXPERIENCED: `Has this driver taken a Driver Training Course in the last 3 years?`
};

const Driver = ({
  index,
  fieldId: id,
  fieldName,
  item = {},
  initialFold,
  onFold,
  disabled,
  hasAuto,
  onRecheckDrivingRecord,
  maritalStatusOptions,
  state,
  showCommunityDriveLoginReset,
  resetCommunityDriveLogin,
  actions,
  allowLicensedActions
}) => {
  const classes = useStyles();
  const session = useContext(AuthContext);
  const { canClearUDRs, viewOnly } = session;
  const toast = useToast();

  const { setFieldValue: formikSetFieldValue, values } = useFormikContext();

  const { drivers, isBix } = values;
  const { isSwapping } = useSwapAppCoApp({ formikPath: 'drivers', allowLicensedActions });

  const [openClearUDRViolationModal, setOpenClearUDRViolationModal] = useState(false);
  const clearUDRViolationsIsChecked = values.drivers[index].clearUDRViolation;

  const isPrimaryMarried = drivers.some((d) => {
    return d.isPrimary && d.maritalStatus === 'M';
  });

  // if co-app and married, and primary is married, then they are most likely married to each other
  const isSpouseOfPrimary = item.isCoApplicant && item.maritalStatus === 'M' && isPrimaryMarried;

  const today = new Date();
  const birthDate = new Date(item.dateOfBirth);
  const age = differenceInYears(today, birthDate);
  const drivingCourseDiscountType = courseDiscountTypeByStates[state];
  const canBeFullTimeStudent =
    compareAsc(subYears(new Date(), fullTimeStudentAgeLimit), parseISO(item.dateOfBirth)) === -1 &&
    !item.isPrimary &&
    !isSpouseOfPrimary; // if 23 and under, and not primary, and not married to primary, then eligible for student discount
  const driverExcluded = item.excludeDriver;
  const hasUDR = item?.autoViolations?.UDR >= 1;

  useEffect(() => {
    if (!canBeFullTimeStudent || !item?.fullTimeStudent) {
      formikSetFieldValue(`${fieldName}.fullTimeStudent`, false);
      formikSetFieldValue(`${fieldName}.goodStudent`, false);
      formikSetFieldValue(`${fieldName}.schoolName`, null);
      formikSetFieldValue(`${fieldName}.schoolLocation`, null);
    }
  }, [fieldName, item.fullTimeStudent, canBeFullTimeStudent, formikSetFieldValue]);
  // check if there is a state specific age limit for the discount (different from Inexperienced)
  const canReceiveDrivingCourseDiscount =
    drivingCourseDiscountType &&
    drivingCourseDiscountType !== courseDiscountTypes.INEXPERIENCED &&
    (seniorDiscountByAgeOnlyStates[state]
      ? age >= seniorDiscountByAgeOnlyStates[state]
      : age >= courseDiscountsAges[state]);

  // check if there is a state specific age to calculate Inexperienced discount
  const canReceiveInexperiencedDrivingCourseDiscount =
    drivingCourseDiscountType &&
    drivingCourseDiscountType === courseDiscountTypes.INEXPERIENCED &&
    age - item.ageFirstLicensed < courseDiscountsAges[state];

  useEffect(() => {
    if (!item?.courseDiscountConditionCheck && !canReceiveDrivingCourseDiscount) {
      formikSetFieldValue(`${fieldName}.takenCourse`, item?.courseDiscountConditionCheck);
    }
  }, [fieldName, formikSetFieldValue, item.courseDiscountConditionCheck, canReceiveDrivingCourseDiscount]);

  const showCommunityDrive =
    communityDriveAvailableStates[state] &&
    ((isBix && communityDriveAvailableStates[state].showForBix) ||
      (!isBix && communityDriveAvailableStates[state].showForNonBix));

  if (item.isCoApplicant && item.excludeDriver) {
    // this would happen if the person is the Co-App on the Home Only offer but excluded on the Auto offer.
    // If they switch to home and auto, we want honor the excluded variable over co-app
    formikSetFieldValue(`${fieldName}.isCoApplicant`, false);
    toast.notify({
      type: 'success',
      message: `${item.firstName} ${item.lastName} has been updated to not be a co-applicant on this offer type since they are an excluded driver. Please update the offer to continue with this offer type.`
    });
  }

  useEffect(() => {
    if (state === 'MA' && !values.drivers[index].userAssignedCar) {
      const { ageFirstLicensed, assignedCar, id } = values.drivers[index];
      // we assign a default car in case some of the users are removed without updating the policy
      let carIx = -1;
      if (assignedCar) {
        carIx = values.cars.findIndex((car) => car.VIN === assignedCar);
      }
      if (age >= seniorAge[state]) {
        formikSetFieldValue(`${fieldName}.userAssignedCar`, assignedCar || values.cars[0]?.VIN);
        formikSetFieldValue(`cars.${carIx < 0 ? 0 : carIx}.userAssignedDriver`, id);
      }
      if (age - ageFirstLicensed < inexperiencedYearsLimit[state]) {
        formikSetFieldValue(`${fieldName}.userAssignedCar`, assignedCar || 'NONE');
        if (carIx >= 0) {
          formikSetFieldValue(`cars.${carIx}.userAssignedDriver`, id);
        }
      }
    }
  }, [fieldName, item.ageFirstLicensed, age]);

  useEffect(() => {
    if (
      !item?.courseDiscountConditionCheck &&
      !canReceiveDrivingCourseDiscount &&
      !canReceiveInexperiencedDrivingCourseDiscount
    ) {
      formikSetFieldValue(`${fieldName}.takenCourse`, item?.courseDiscountConditionCheck);
    }
  }, [
    fieldName,
    formikSetFieldValue,
    item.courseDiscountConditionCheck,
    canReceiveDrivingCourseDiscount,
    canReceiveInexperiencedDrivingCourseDiscount
  ]);

  return (
    <>
      {openClearUDRViolationModal && (
        <ClearUDRsModal
          setOpenClearUDRViolationModal={setOpenClearUDRViolationModal}
          fieldName={fieldName}
          item={item}
        />
      )}
      <CollapsibleCard
        content={
          <>
            {driverExcluded && <img src={excluded} alt="excluded" />}
            <Label
              type="infoSubtitle"
              className={classNames({ [classes.driverExcludedLabel]: driverExcluded })}
            >{`${item.firstName} ${item.lastName}`}</Label>
            {driverExcluded && (
              <Label type="infoCardTitle" className={classes.driverExcludedReason}>
                Excluded{' '}
                <Tooltip
                  text={
                    item.exclusionReason ||
                    'This exclusion was either automatically added due to the policy’s violations or manually added by an agent'
                  }
                  placement="bottom"
                />
              </Label>
            )}
            {item.isPrimary && <Label type="infoCardTitle">Applicant</Label>}
          </>
        }
        collapsibleCardNotification={hasUDR ? 'This driver has an unverifiable driving record.' : null}
        contentRight={actions}
        initialOpen={initialFold || item.initialOpen}
        onChange={(val) => onFold(index, val)}
        className={classNames({ [classes.driverExcluded]: driverExcluded })}
      >
        <div className={classNames(classes.container, classes.driverContainer)}>
          <Form key={`driver-form-${disabled || item.excludeDriver}`} disabled={disabled || item.excludeDriver}>
            <Grid container alignItems="flex-start" spacing={4}>
              <FormField
                id={`${fieldName}.firstName`}
                name={`${fieldName}.firstName`}
                type="string"
                label="First Name"
                mode="light"
                xs={3}
                disabled={isSwapping || driverExcluded} // can't change name in same update as swapping app / coapp
                fast={false}
              />
              <FormField
                id={`${fieldName}.middleName`}
                name={`${fieldName}.middleName`}
                type="string"
                label="Middle Name"
                mode="light"
                xs={3}
                disabled={isSwapping || driverExcluded} // can't change name in same update as swapping app / coapp
                fast={false}
              />
              <FormField
                id={`${fieldName}.lastName`}
                name={`${fieldName}.lastName`}
                type="string"
                label="Last Name"
                mode="light"
                xs={3}
                disabled={isSwapping || driverExcluded} // can't change name in same update as swapping app / coapp
                fast={false}
              />
              <FormField
                id={`${fieldName}.dateOfBirth`}
                name={`${fieldName}.dateOfBirth`}
                type="date"
                label="Date of Birth"
                disabled={driverExcluded}
                mode="light"
                xs={3}
              />
              <FormField
                id={`${fieldName}.suffix`}
                name={`${fieldName}.suffix`}
                type="string"
                disabled={driverExcluded}
                label="Suffix"
                mode="light"
                xs={2}
              />
              {!hideGenderStates.includes(state) && (
                <FormField
                  id={`${fieldName}.gender`}
                  name={`${fieldName}.gender`}
                  type="select"
                  label="Gender"
                  mode="light"
                  options={lookupsJson.gender}
                  xs={2}
                  disabled={driverExcluded}
                  permissions={{ isLicensedAction: false }}
                />
              )}
              <FormField
                id={`${fieldName}.maritalStatus`}
                name={`${fieldName}.maritalStatus`}
                type="select"
                label="Marital Status"
                mode="light"
                options={maritalStatusOptions || lookupsJson.maritalStatus}
                xs={2}
                disabled={driverExcluded}
                permissions={{ isLicensedAction: false }}
              />
              {!hideHighestEducationStates.includes(state) && (
                <FormField
                  id={`${fieldName}.highestEducation`}
                  name={`${fieldName}.highestEducation`}
                  type="select"
                  label="Highest Education Achieved"
                  mode="light"
                  options={lookupsJson.highestEducation}
                  xs={6}
                  disabled={driverExcluded}
                  permissions={{ isLicensedAction: false }}
                />
              )}
              <FormField
                id={`${fieldName}.driversLicenseState`}
                name={`${fieldName}.driversLicenseState`}
                type="state"
                label="License State"
                mode="light"
                disabled={driverExcluded}
                xs={3}
              />
              <FormField
                id={`${fieldName}.driversLicenseNumber`}
                name={`${fieldName}.driversLicenseNumber`}
                type="string"
                label="License Number"
                disabled={driverExcluded}
                mode="light"
                xs={3}
              />
              <FormField
                id={`${fieldName}.ageFirstLicensed`}
                name={`${fieldName}.ageFirstLicensed`}
                type="string"
                label="First Licensed at"
                mode="light"
                xs={3}
                disabled={driverExcluded}
              />
              <FormField
                id={`${fieldName}.addDate`}
                name={`${fieldName}.addDate`}
                type="date"
                label="Covered Since"
                mode="light"
                xs={3}
                disabled={driverExcluded}
              />
              {canBeFullTimeStudent && (
                <FormField
                  id={`${fieldName}.fullTimeStudent`}
                  name={`${fieldName}.fullTimeStudent`}
                  type="switch"
                  label="Full Time Student"
                  disabled={driverExcluded}
                  mode="light"
                  xs={12}
                />
              )}
              {canBeFullTimeStudent && item.fullTimeStudent && (
                <>
                  <Grid container item alignItems="flex-end" spacing={4}>
                    <FormField
                      id={`${fieldName}.schoolName`}
                      name={`${fieldName}.schoolName`}
                      type="string"
                      label="School Name"
                      disabled={driverExcluded}
                      mode="light"
                      xs={8}
                    />
                    <FormField
                      id={`${fieldName}.goodStudent`}
                      name={`${fieldName}.goodStudent`}
                      type="checkbox"
                      label="Good Student"
                      mode="light"
                      disabled={driverExcluded}
                      xs={4}
                    />
                  </Grid>
                  <FormField
                    id={`${fieldName}.schoolLocation.address`}
                    name={`${fieldName}.schoolLocation.address`}
                    type="string"
                    label="School Address"
                    disabled={driverExcluded}
                    mode="light"
                    xs={8}
                  />
                  <FormField
                    id={`${fieldName}.schoolLocation.address2`}
                    name={`${fieldName}.schoolLocation.address2`}
                    type="string"
                    label="Address 2"
                    disabled={driverExcluded}
                    mode="light"
                    xs={4}
                  />
                  <FormField
                    id={`${fieldName}.schoolLocation.city`}
                    name={`${fieldName}.schoolLocation.city`}
                    type="string"
                    label="City"
                    disabled={driverExcluded}
                    mode="light"
                    xs={4}
                  />
                  <FormField
                    id={`${fieldName}.schoolLocation.state`}
                    name={`${fieldName}.schoolLocation.state`}
                    type="state"
                    label="State"
                    mode="light"
                    disabled={driverExcluded}
                    xs={4}
                  />
                  <FormField
                    id={`${fieldName}.schoolLocation.zip`}
                    name={`${fieldName}.schoolLocation.zip`}
                    type="string"
                    label="Zip Code"
                    disabled={driverExcluded}
                    mode="light"
                    xs={4}
                  />
                </>
              )}
              {hasUDR && (
                <Grid item container spacing={5}>
                  {onRecheckDrivingRecord && (
                    <Grid item xs={12}>
                      <FormField
                        name={`${fieldName}.recheckDrivingRecord`}
                        id={`${fieldName}.recheckDrivingRecord`}
                        type="checkbox"
                        label="Re-check for driving record"
                        mode="light"
                        onChange={onRecheckDrivingRecord}
                        disabled={clearUDRViolationsIsChecked || viewOnly}
                      />
                    </Grid>
                  )}
                  {canClearUDRs && (
                    <Grid item xs={12}>
                      <FormField
                        id={`${fieldName}.clearUDRViolation`}
                        name={`${fieldName}.clearUDRViolation`}
                        type="checkbox"
                        label="Clear UDR Violation"
                        mode="light"
                        xs={6}
                        onClick={(e) => {
                          setOpenClearUDRViolationModal(e.target.checked);
                        }}
                      />
                    </Grid>
                  )}
                </Grid>
              )}
              {!canReceiveDrivingCourseDiscount &&
                !canReceiveInexperiencedDrivingCourseDiscount &&
                additionalDriverDiscountsTypesByStates[state]?.condition && (
                  // if not elligible for the standard discount, show field for any extra conditions to meet before qualifying for additional discount
                  <FormField
                    id={`${fieldName}.courseDiscountConditionCheck`}
                    name={`${fieldName}.courseDiscountConditionCheck`}
                    type="checkbox"
                    label={drivingCourseLabel[additionalDriverDiscountsTypesByStates[state]?.condition]}
                    mode="light"
                    xs={12}
                    disabled={driverExcluded}
                  />
                )}
              {canReceiveDrivingCourseDiscount || canReceiveInexperiencedDrivingCourseDiscount ? (
                <Grid item container display="flex-flow">
                  <Grid item xs={8}>
                    <FormField
                      id={`${fieldName}.takenCourse`}
                      name={`${fieldName}.takenCourse`}
                      type="checkbox"
                      className={classes.takenCourseContainer}
                      disabled={driverExcluded}
                      label={drivingCourseLabel[drivingCourseDiscountType]}
                      mode="light"
                      tooltipText={
                        stateSpecificToolTips[state] ? tooltipHoverTexts[stateSpecificToolTips[state]] : null
                      }
                      tooltipLabel={stateSpecificToolTips[state] ? 'More Info' : null}
                    />
                  </Grid>
                </Grid>
              ) : (
                item?.courseDiscountConditionCheck &&
                drivingCourseLabel[additionalDriverDiscountsTypesByStates[state]?.discountType] && (
                  <FormField
                    id={`${fieldName}.takenCourse`}
                    name={`${fieldName}.takenCourse`}
                    disabled={driverExcluded}
                    type="checkbox"
                    label={drivingCourseLabel[additionalDriverDiscountsTypesByStates[state]?.discountType]}
                    mode="light"
                    xs={12}
                  />
                )
              )}
              {lapsedLicenseMonthsStates[state] && hasAuto && (
                <FormField
                  id={`${fieldName}.lapsedLicense`}
                  name={`${fieldName}.lapsedLicense`}
                  disabled={driverExcluded}
                  type="checkbox"
                  label={`Has this driver had a lapsed driver's license in the last ${lapsedLicenseMonthsStates[state]} months?`}
                  mode="light"
                  xs={12}
                />
              )}
              {showCommunityDrive && (
                <Grid container item spacing={4} alignItems={'center'}>
                  <FormField
                    id={`${fieldName}.communityDriveParticipation`}
                    name={`${fieldName}.communityDriveParticipation`}
                    type="checkbox"
                    label="Community Drive Participation Discount"
                    mode="light"
                    xs={6}
                    disabled={driverExcluded}
                  />
                  {showCommunityDriveLoginReset && (
                    <Button
                      disabled={viewOnly || driverExcluded}
                      xs={4}
                      color="secondary"
                      variant="text"
                      onClick={(e) => {
                        resetCommunityDriveLogin(item.id);
                      }}
                    >
                      Reset Login
                    </Button>
                  )}
                </Grid>
              )}
              {hasAuto && sR22States[state] && (
                <Grid item xs={4}>
                  <FormField
                    id={`${fieldName}.needSR22`}
                    name={`${fieldName}.needSR22`}
                    type="checkbox"
                    label="Needs SR-22"
                    mode="light"
                    tooltipText="For any driver with a Financial Responsibility filing made against them, this box must be checked and an SR-22 form must be completed to be held on file with the state DMV. A template can be found in Notion"
                    tooltipLabel="More Info"
                    disabled={driverExcluded}
                  />
                </Grid>
              )}
            </Grid>
          </Form>
        </div>
        {(item.isPrimary || item.isCoApplicant) && item.insuranceScore && (
          <Grid container alignItems="center" justify="flex-end" className={classes.cardFooter}>
            <Grid container item xs={3} direction="column" alignItems="flex-end">
              <Label type="infoLabel">Insurance Score</Label>
              <Label type="infoValue">
                {noCreditStates[state]
                  ? 'N/A'
                  : Number(item.insuranceScore?.autoTotal?.toFixed(1) || item.insuranceScore?.total?.toFixed(1))}
              </Label>
            </Grid>
          </Grid>
        )}
      </CollapsibleCard>
    </>
  );
};

Driver.propTypes = {
  index: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
  item: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    middleName: PropTypes.string,
    lastName: PropTypes.string.isRequired,
    dateOfBirth: PropTypes.string.isRequired,
    addDate: PropTypes.string,
    ageFirstLicensed: PropTypes.number,
    driversLicenseNumber: PropTypes.string,
    driversLicenseState: PropTypes.string,
    fullTimeStudent: PropTypes.bool,
    gender: PropTypes.string,
    goodStudent: PropTypes.bool,
    highestEducation: PropTypes.string,
    isCoApplicant: PropTypes.bool,
    isPrimary: PropTypes.bool,
    maritalStatus: PropTypes.string,
    schoolLocation: PropTypes.object,
    schoolName: PropTypes.string,
    suffix: PropTypes.string
  }).isRequired,
  fieldId: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  onRemove: PropTypes.func.isRequired,
  removeFromList: PropTypes.func.isRequired,
  initialFold: PropTypes.bool.isRequired,
  onFold: PropTypes.func.isRequired,
  allowLicensedActions: PropTypes.bool.isRequired,
  fromPolicy: PropTypes.bool,
  hasAuto: PropTypes.bool,
  showResetCommunityDrive: PropTypes.bool
};

Driver.defaultProps = {
  disabled: false,
  fromPolicy: false,
  hasAuto: false,
  showResetCommunityDrive: false
};

export default withDatePicker(Driver);
