import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';

import { Table } from 'core';
import { Label } from 'core/components/label';
import NoResults from '../no-results';
import Row from './row';
import Header from './header';
import useStyles from './policies-results.styles';

const PoliciesResults = ({
  loading,
  hits,
  totalHits,
  page,
  onChangePage,
  rowClickHandler,
  columnsToUse,
  showTitle,
  mode,
  error
}) => {
  const classes = useStyles({ mode });
  const handleChangePage = useCallback((_, newPage) => onChangePage(newPage), [onChangePage]);

  return (
    <>
      {showTitle && (
        <Label type="subtitle" style={{ marginBottom: 16 }}>
          Policies
        </Label>
      )}
      <Paper
        square={mode === 'dropdown'}
        elevation={mode === 'dropdown' ? 0 : null}
        className={classes.resultsContainer}
      >
        {Boolean(loading || hits.length) && (
          <Table
            selfContained={false}
            loading={loading}
            paginationEnabled={mode !== 'dropdown'}
            count={totalHits}
            rowsPerPage={hits.length}
            currentPage={page}
            onChangePage={handleChangePage}
            header={
              mode !== 'dropdown' ? (
                <>
                  <Header columnsToUse={columnsToUse} />
                </>
              ) : null
            }
            body={
              Boolean(hits && hits.length) &&
              hits.map((hit) => <Row key={hit.id} columnsToUse={columnsToUse} hit={hit} onClick={rowClickHandler} />)
            }
          />
        )}
        {Boolean(!loading && hits.length === 0) && <NoResults error={error} />}
      </Paper>
    </>
  );
};

PoliciesResults.propTypes = {
  loading: PropTypes.bool.isRequired,
  hits: PropTypes.array,
  totalHits: PropTypes.number,
  page: PropTypes.number,
  onChangePage: PropTypes.func,
  rowClickHandler: PropTypes.func,
  columnsToUse: PropTypes.arrayOf(
    PropTypes.oneOf(['id', 'firstName', 'lastName', 'effectiveDate', 'endDate', 'state', 'matchedOn'])
  ),
  showTitle: PropTypes.bool,
  mode: PropTypes.string
};

PoliciesResults.defaultProps = {
  hits: [],
  page: 1,
  onChangePage: null,
  totalHits: 0,
  rowClickHandler: null,
  columnsToUse: ['id', 'firstName', 'lastName', 'effectiveDate', 'endDate', 'state', 'matchedOn'],
  showTitle: true,
  mode: ''
};

export default PoliciesResults;
