import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { observer } from 'mobx-react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Tabs } from '@material-ui/core';

import { useStore } from 'core/store';
import CustomersResults from 'core/components/search-results/customers-results';
import useSession from 'core/hooks/use-session';
import AccountMenu from '../account-menu';
import plus from 'core/assets/svg/add-2.svg';
import portalOrange from 'core/assets/svg/portal-orange.svg';
import search from 'core/assets/svg/search.svg';
import { HeaderTab } from '../header-tab';
import { SearchBar } from '../search-bar';
import logo from './logo.svg';
import styles from './header.styles';

const Header = observer(({ classes }) => {
  const session = useSession();
  const history = useHistory();
  const [dropdownVisible, setDropdownVisible] = useState(true);

  const onSearchPage = !!useRouteMatch({ path: '/search' });
  const onQuotePage = !!useRouteMatch({ path: '/quote' });
  const onPortalPage = !!useRouteMatch({ path: '/portal' });

  const makeActiveTabs = () => {
    if (session.showAgencyPortal && session.viewOnly) {
      return {
        PORTAL: 0,
        SEARCH: 1,
        NONE: false
      };
    }
    if (session.showAgencyPortal) {
      return {
        PORTAL: 0,
        NEW_QUOTE: 1,
        SEARCH: 2,
        //  when no tab is selected, materialui wont throw a warning if we send in a value of false
        // https://stackoverflow.com/questions/50496166/material-ui-tabs-no-selected-tab
        NONE: false
      };
    }
    return {
      NEW_QUOTE: 0,
      SEARCH: 1,
      NONE: false
    };
  };

  const TABS = makeActiveTabs();

  const activeTab =
    onPortalPage && session.showAgencyPortal
      ? TABS.PORTAL
      : onQuotePage && session.canQuote
      ? TABS.NEW_QUOTE
      : onSearchPage
      ? TABS.SEARCH
      : TABS.NONE;

  const {
    search: { searchString, loading, customersResults, clearInput, searchCustomersAction, setSearchString },
    affinityLookups
  } = useStore();

  const routes = useMemo(() => {
    if (!session.canQuote && !session.showAgencyPortal) {
      return ['/search/offers'];
    }
    if (!session.showAgencyPortal) {
      return ['/quote', '/search/offers'];
    }
    if (!session.canQuote) {
      return ['/portal/updates', '/search/offers'];
    }
    return ['/portal/updates', '/quote', '/search/offers'];
  }, [session]);

  const handleTabChange = useCallback(
    (e, newTab) => {
      e.preventDefault();
      if (activeTab === newTab) {
        // force reload the page because user is trying to navigate to the same tab they are already viewing
        // https://stackoverflow.com/questions/46820682/how-do-i-reload-a-page-with-react-router
        history.go(0);
      }
      history.push(routes[newTab]);
    },
    [history, routes, activeTab]
  );

  const hideOrShowDropdown = useCallback(
    (value) => {
      setDropdownVisible(value);
    },
    [setDropdownVisible]
  );

  const handleClickAway = useCallback(() => {
    hideOrShowDropdown(false);
  }, [hideOrShowDropdown]);

  const handleRowClick = useCallback(
    (Username) => {
      hideOrShowDropdown(false);
      history.push(`/customer/${Username}`);
    },
    [history, hideOrShowDropdown]
  );

  const clearSearchInput = useCallback(() => {
    clearInput();
  }, [clearInput]);

  const onSearchInputFocus = useCallback(() => {
    hideOrShowDropdown(true);
  }, [hideOrShowDropdown]);

  const onSearchStringChange = useCallback(
    (event) => {
      setSearchString(event.target.value);
      searchCustomersAction();
    },
    [setSearchString, searchCustomersAction]
  );

  if (!session) {
    return (
      <ClickAwayListener onClickAway={handleClickAway}>
        <AppBar position="absolute" color="inherit" className={classes.header} elevation={1}>
          <Toolbar className={classes.toolbar} />
        </AppBar>
      </ClickAwayListener>
    );
  }

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <AppBar position="relative" color="inherit" className={classes.header} elevation={1}>
        <Toolbar className={classes.toolbar}>
          <Grid container spacing={3} alignItems="center" justify="space-between" wrap="nowrap" direction="row">
            <Link to="/">
              <img src={logo} alt="gobranch" className={classes.logo} data-cy="logo" />
            </Link>
            {session.isLoggedIn && !affinityLookups.loading && (
              <>
                <Grid item xs={5} className={classes.searchContainer}>
                  {!onSearchPage && (
                    <SearchBar
                      hideOrShowDropdown={hideOrShowDropdown}
                      onCloseIconClick={clearSearchInput}
                      onInputFocus={onSearchInputFocus}
                      onInputChange={onSearchStringChange}
                      value={searchString}
                    />
                  )}
                </Grid>
                <Tabs value={activeTab} onChange={handleTabChange} indicatorColor="secondary" variant="fullWidth">
                  {session.showAgencyPortal && <HeaderTab label="Portal" iconSrc={portalOrange} />}
                  {session.canQuote && <HeaderTab label="New Offer" iconSrc={plus} iconSize="large" />}
                  <HeaderTab label="Search" iconSrc={search} />
                </Tabs>
                <AccountMenu />
              </>
            )}
          </Grid>
        </Toolbar>
        {!onSearchPage && searchString && dropdownVisible && (
          <CustomersResults
            loading={loading}
            hits={customersResults.hits}
            history={history}
            hideOrShowDropdown={hideOrShowDropdown}
            rowClickHandler={handleRowClick}
            showTitle={false}
            mode="dropdown"
            useLink
          />
        )}
      </AppBar>
    </ClickAwayListener>
  );
});

Header.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Header);
