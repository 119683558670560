import React from 'react';

import { Label } from 'core/components/label';
import useStyles from './hardware-mfa.styles';

function VerifyingMFA() {
  const styles = useStyles();

  return (
    <Label type="infoInnerTitle" className={styles.content}>
      Please wait while we verify your device. This should just take a few moments.
    </Label>
  );
}

export default VerifyingMFA;
