import React, { useState, useEffect, useContext } from 'react';
import TableCell from '@material-ui/core/TableCell';
import { Button, Grid } from '@material-ui/core';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

import { AuthContext } from 'core/components/auth';
import { Loading, Paper, SelectableRow, Table, Label } from 'core';
import { useStore } from 'core/store/store.mobx';
import DatePicker from 'core/components/date-picker';
import { RegenerateDocument } from '../regenerate-document';
import { dateTimeFormatter } from 'core/helpers/formatters';
import withDatePicker from 'core/components/with-date-picker';
import Section from 'core/components/section';
import { Card } from 'core/components/card';
import useStyles from '../../documents.styles';

// @TODO these might could be moved to mobx computed properties
const filterPolicyDocuments = (policyId, documents) => {
  if (!policyId) return documents;
  return documents.filter((doc) => doc.path.split('/')[3] === policyId);
};

const getPolicyIdFromPath = (path) => /(\w*-\d*-\w*)/.exec(path);

const createRegularDocs = (documents, policyId, processedNames, sortFunc, getDocNameFunc) => {
  if (documents && Array.isArray(documents.regular)) {
    const regularDocsArr = sortFunc(filterPolicyDocuments(policyId, documents.regular.slice()))
      .map((currentDoc) => {
        const docPolicyId = getPolicyIdFromPath(currentDoc.path);
        return {
          ...currentDoc,
          friendlyName: getDocNameFunc(currentDoc.path),
          policyId: docPolicyId ? docPolicyId[0] : null
        };
      })
      .filter((doc) => doc.policyId)
      .map((currentDoc, currentIndex, docs) => {
        if (processedNames.has(currentDoc.friendlyName)) {
          return currentDoc;
        }

        processedNames.add(currentDoc.friendlyName);

        const duplicateNameExists = docs.some(
          (doc, index) => doc.friendlyName === currentDoc.friendlyName && index !== currentIndex
        );

        if (duplicateNameExists) {
          return { ...currentDoc, friendlyName: `Updated ${currentDoc.friendlyName}` };
        }

        return currentDoc;
      });
    return regularDocsArr;
  }
  return [];
};

const RegularDocuments = observer(
  ({ getDocName, sortDocuments, showPolicyIdColumn, downloadDocument, fetchDocuments, toast }) => {
    const {
      account: {
        policies: {
          regeneratePolicyDocuments,
          list: policiesList,
          documents,
          loadingDocuments: loading,
          policy: policyStore
        }
      }
    } = useStore();

    const classes = useStyles();
    const { canEdit } = useContext(AuthContext);
    const processedNames = new Set();
    const policyId = policyStore.policy?.id;

    const regularDocuments = createRegularDocs(documents, policyId, processedNames, sortDocuments, getDocName);

    const [filterState, setFilterState] = useState([]);
    const [dateState, setDateState] = useState(new Date());
    const [regeneratingDoc, setRegeneratingDoc] = useState(false);

    useEffect(() => {
      const policyPages = ['auto', 'home', 'renters', 'condo'];

      setFilterState(
        policyPages.some((page) => window.location.href.includes(page))
          ? createRegularDocs(documents, policyId, processedNames, sortDocuments, getDocName)
          : createRegularDocs(documents, null, processedNames, sortDocuments, getDocName)
      ); // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [documents]); // including processedNames would trigger too many rerenders

    const onRegenerateDocuments = (policyId, policyType, accountId) => {
      setRegeneratingDoc(true);
      regeneratePolicyDocuments(policyId, accountId)
        .then(({ data }) => {
          if (data) {
            toast.notify({
              type: 'success',
              message: `${policyType} policy documents regenerated successfully`
            });
            fetchDocuments();
          } else {
            toast.notify({
              type: 'error',
              message: `There was an error regenerating ${policyType} policy documents`
            });
          }
        })
        .catch(() => {
          toast.notify({
            type: 'error',
            message: `There was an error regenerating ${policyType} policy documents`
          });
        })
        .finally(() => {
          setRegeneratingDoc(false);
        });
    };

    const onDateFilterClick = () => {
      const dateFilter = new Date(dateState).setHours(23, 59, 59);
      // set time to 23:59:59 to make sure we get all the docs for the day
      const objForFilter = {
        'Auto Policy': 0,
        'Auto Declarations': 0,
        'Auto ID Cards': 0,
        'Homeowners Policy': 0,
        'Homeowners Declarations': 0,
        'Home Insurance Proof of Insurance': 0,
        'Renters Policy': 0,
        'Renters Declarations': 0,
        'Condo Policy': 0,
        'Condo Declarations': 0
      }; // used to keep track of the docs we have returned in filter
      const filteredDateArray = regularDocuments
        .filter((d) => {
          const lastModDate = new Date(d.lastModified);
          return dateFilter >= lastModDate;
        })
        .filter((d) => {
          if (d.friendlyName.includes('Updated') && objForFilter[d.friendlyName.slice(8)] === 0) {
            objForFilter[d.friendlyName.slice(8)] = +1;
            return d;
          }
          if (objForFilter[d.friendlyName] === 0) {
            objForFilter[d.friendlyName] = +1;
            return d;
          }
          return false;
        });
      setFilterState(filteredDateArray);
    };

    const onResetClick = () => {
      setFilterState(regularDocuments);
    };

    const onDateChange = (value) => {
      setDateState(value);
    };

    return (
      <>
        <Section
          className={classNames({ [classes.docSection]: !policyId })}
          title="Policies Documents"
          rightLabel="Total"
          rightValue={`${loading || !documents || !Array.isArray(filterState) ? '...' : filterState.length} documents`}
        >
          {loading || !documents || !Array.isArray(documents.regular) ? (
            <Loading />
          ) : documents.regular.length ? (
            <Card>
              <Grid container alignItems="center" className={classes.regularDocsTableContainer}>
                <Label type="greenSmall">Show documents active on </Label>
                <Grid item>
                  <DatePicker
                    onChange={(e) => onDateChange(e.value)}
                    mode="light"
                    disableFuture
                    label="DATE"
                    value={dateState}
                    className={classes.datePicker}
                  />
                </Grid>
                <Grid item>
                  <Button
                    onClick={onDateFilterClick}
                    mode="big"
                    type="submit"
                    variant="contained"
                    color="secondary"
                    className={classes.filterButton}
                  >
                    Filter
                  </Button>
                </Grid>
                <Grid item>
                  <Button variant="text" color="secondary" onClick={onResetClick}>
                    Reset Filter
                  </Button>
                </Grid>
              </Grid>
              {filterState && filterState.length > 0 ? (
                <Table
                  selfContained={false}
                  header={
                    <>
                      <TableCell className={classes.firstCell}>FILE NAME</TableCell>
                      <TableCell>LAST MODIFICATION</TableCell>
                      {showPolicyIdColumn && <TableCell>POLICY ID</TableCell>}
                    </>
                  }
                  body={
                    <>
                      {filterState.length &&
                        filterState.map((d) => (
                          <SelectableRow key={d.path} id={d.path} onClick={downloadDocument}>
                            <TableCell className={classes.docTitle}>{d.friendlyName}</TableCell>
                            <TableCell>{dateTimeFormatter(d.lastModified)}</TableCell>
                            {showPolicyIdColumn && <TableCell>{d.policyId}</TableCell>}
                          </SelectableRow>
                        ))}
                    </>
                  }
                />
              ) : (
                <p className={classes.noDocs}>There were no active documents on this date.</p>
              )}
              {canEdit && (
                <RegenerateDocument
                  documentPolicyId={policyId}
                  policies={policiesList}
                  onRegenerate={onRegenerateDocuments}
                  regenerating={regeneratingDoc}
                />
              )}
            </Card>
          ) : (
            <Paper>No documents generated for this customer</Paper>
          )}
        </Section>
      </>
    );
  }
);

RegularDocuments.propTypes = {
  getDocName: PropTypes.func.isRequired,
  sortDocuments: PropTypes.func.isRequired,
  showPolicyIdColumn: PropTypes.bool,
  downloadDocument: PropTypes.func.isRequired,
  fetchDocuments: PropTypes.func.isRequired,
  toast: PropTypes.object
};

RegularDocuments.defaultProps = {
  showPolicyIdColumn: false
};

export default withDatePicker(RegularDocuments);
