import React from 'react';

import { Label } from 'core/components/label';
import useStyles from './hardware-mfa.styles';

function ErrorWithBrowser() {
  const styles = useStyles();

  return (
    <>
      <Label type="titleSecondary" className={styles.title}>
        This browser does not support Hardware Authentication
      </Label>
      <Label type="infoInnerTitle" className={styles.content}>
        Your browser does not support this authentication method. Please update and continue with the newest version of
        Chrome or Edge. Otherwise, please reach out to Support to try a different method of authentication.
      </Label>
    </>
  );
}

export default ErrorWithBrowser;
