const styles = (theme) => ({
  layout: {
    position: 'relative',
    width: '100%',
    backgroundColor: theme.colors.header__background_primary,
    left: 0,
    transition: '225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
    height: `calc(100vh - ${theme.sizes.headerHeight}px)`,
    'overflow-y': 'auto'
  },
  loading: {
    display: 'flex',
    paddingTop: 0,
    alignItems: 'center',
    justifyContent: 'center'
  },
  container: {
    width: 1132,
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingTop: theme.spacing(10)
  }
});

export default styles;
