import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  title: {
    fontWeight: 600
  },
  content: {
    marginTop: 20
  },
  button: {
    display: 'block',
    marginTop: 20
  }
});

export default makeStyles(styles);
