import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useFormikContext } from 'formik';
import { useHistory } from 'react-router-dom';
import { Grid } from '@material-ui/core';

import { AuthContext } from 'core/components/auth';
import { useStore } from 'core/store';
import { Tooltip } from 'core/components/tooltip';
import { Label } from 'core/components/label';
import editIcon from './edit-icon.svg';
import useStyles from './edit-referral.styles';

const EditReferral = ({ fromCheckout, inviter }) => {
  const { viewOnly, isAgency } = useContext(AuthContext);
  const history = useHistory();
  const formikCtx = useFormikContext();
  const classes = useStyles();
  const {
    offer: store,
    referralSearch: { setSearchString, setShowReferralMatches, searchCustomersAction }
  } = useStore();

  const hasInviter = inviter && inviter.firstName && inviter.lastName;
  const dirty = formikCtx && formikCtx.dirty;

  const canAddReferral = !viewOnly && !isAgency;

  const onEditClick = () => {
    if (!canAddReferral) return;
    if (fromCheckout) {
      history.push(`/offer/${store.offer.id}`);
    }
    if (hasInviter) {
      setSearchString(`${inviter.firstName} ${inviter.lastName}`);
      searchCustomersAction();
    }
    setShowReferralMatches(true);
  };

  if (!hasInviter && !canAddReferral) {
    return null;
  }

  return (
    <Grid container alignItems="center" className={classes.container}>
      <Label type="noteSmall" className={classes.label}>
        REFERRED BY{' '}
      </Label>
      <Label
        className={canAddReferral ? classNames(classes.link, dirty && classes.disabled) : classes.inviter}
        type="infoSubtitleOrange"
        onClick={dirty ? null : onEditClick}
      >
        {hasInviter && (
          <>
            {inviter.firstName} {inviter.lastName}
            {canAddReferral && <img src={editIcon} alt="square-and-pencil" />}
          </>
        )}
        {!hasInviter && canAddReferral && 'Add a referral'}
      </Label>
      {canAddReferral && dirty && (
        <Tooltip
          className={classes.tooltip}
          text="Please update offer price to save changes before adding a referral"
          placement="right"
        />
      )}
    </Grid>
  );
};

EditReferral.propTypes = {
  inviter: PropTypes.shape({
    id: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string
  })
};

EditReferral.defaultProps = {
  inviter: null
};

export default EditReferral;
