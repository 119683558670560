import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  footerWrapper: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(5)
  },
  openTabLink: {
    color: theme.colorPalette.orange_10,
    fontWeight: 'bold'
  },
  openTabText: {
    paddingLeft: theme.spacing(2)
  },
  hint: {
    fontWeight: 'bold',
    paddingLeft: theme.spacing(6)
  },
  fKeyIcon: {
    marginLeft: theme.spacing(1)
  }
}));

export default useStyles;
