import React from 'react';
import classNames from 'classnames';
import { rentersPolicyAvailable, standaloneRentersStates } from '@ourbranch/lookups';
import { observer } from 'mobx-react';
import { connect, useFormikContext } from 'formik';
import flowRight from 'lodash-es/flowRight';

import { Card } from 'core/components/card';
import { FormField } from 'core/components/form';
import { SchedulePP } from 'common/components/schedule-pp';
import { LabelTooltip } from 'core/components/label-tooltip';
import Section from 'core/components/section';
import { RentersAddressForm } from 'common/components/renters/renters-address-form';
import { RentersCoverage } from 'common/components/renters/renters-coverage';

import useStyles from './renters.styles';

const Renters = () => {
  const classes = useStyles();
  const { values, setFieldValue, setFieldTouched } = useFormikContext();

  const { state } = values.rentersCoverage.rentersLocation;

  return (
    !standaloneRentersStates[state] && (
      <Section title="Renters Coverage" type="SubSection">
        <Card className={classNames(classes.container, { [classes.collapsed]: !values.includeRenters })}>
          <div className={classes.containerInner}>
            {!rentersPolicyAvailable[state] && (
              <LabelTooltip
                label="Renter's Insurance"
                mode="light"
                tooltip={{
                  onHoverText: "Renter's coverage is not available in this state"
                }}
                xs={12}
              />
            )}
            <FormField
              id="includeRenters"
              name="includeRenters"
              mode="light"
              label="Renters"
              type="switch"
              disabled={!rentersPolicyAvailable[state]}
              xs={12}
              onChange={(_, checked) => {
                if (checked !== values.attachedRenters) {
                  setFieldValue('attachedRenters', checked);
                  setFieldTouched('attachedRenters');
                }
              }}
            />
          </div>
          {values.includeRenters && rentersPolicyAvailable[state] && <RentersAddressForm />}
          {values.includeRenters && rentersPolicyAvailable[state] && <RentersCoverage />}
        </Card>
        {standaloneRentersStates[state] && <SchedulePP />}
      </Section>
    )
  );
};

export default flowRight(connect, observer)(Renters);
