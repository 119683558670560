import { useMutation } from '@apollo/client';
import * as Sentry from '@sentry/react';
import classNames from 'classnames';
import { FormField, FormSubmit } from 'core/components/form';
import { withToast } from 'core/components/toast';
import { SET_USER_PWD } from 'customer/components/account-details/account-details.queries';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import validationSchema from './user-password.validation.schema';

const SetPasswordForm = ({ classes, username, toast, onSuccess }) => {
  const [updateAccount] = useMutation(SET_USER_PWD);

  const updateUserPassword = async (password) => {
    try {
      await updateAccount({
        variables: { username, password }
      });

      toast.notify({
        type: 'success',
        message: 'Password changed successfully'
      });

      onSuccess();
    } catch (res) {
      Sentry.captureException(
        JSON.stringify({
          res,
          body: { username }
        })
      );
      toast.notify({
        type: 'error',
        message: 'An error occured, password could not be updated'
      });
    }
  };

  return (
    <Formik
      initialValues={{ password: '' }}
      validationSchema={validationSchema}
      onSubmit={async ({ password }, { resetForm }) => {
        await updateUserPassword(password);

        resetForm();
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <FormField mode="light" name="password" id="password" label="Password" type="string" />
          <div className={classes.passwordInfo}>
            <p className={classes.format}>Password must</p>
            <ul className={classes.list}>
              <li>Be at least 8 characters</li>
              <li>Have at least 1 number</li>
            </ul>
          </div>
          <div className={classes.actionBar}>
            <FormSubmit
              size="medium"
              className={classNames(classes.button)}
              variant="contained"
              color="secondary"
              loading={isSubmitting}
            >
              Set Password
            </FormSubmit>
          </div>
        </Form>
      )}
    </Formik>
  );
};

SetPasswordForm.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  username: PropTypes.string.isRequired,
  toast: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired
};

export default withToast(SetPasswordForm);
