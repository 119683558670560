import React, { useEffect, useContext } from 'react';
import { useFormikContext } from 'formik';
import classNames from 'classnames';
import { Divider } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import { useStore } from 'core/store';
import { Loading } from 'core/components/loading';
import { Button } from 'core/components/button';
import { AuthContext } from 'core/components/auth';
import PropTypes from 'prop-types';

import AlertIconWhiteFilled from 'core/assets/svg/alert-white-filled.svg';
import AlertIconWhite from 'core/assets/svg/alert-white.svg';
import LaunchIconWhite from 'core/assets/svg/launch-white.svg';
import ProgressiveLogo from 'core/assets/svg/progressive-logo.svg';
import Label from 'core/components/label/label';
import { FormField } from 'core/components/form';
import Section from 'core/components/section';

import styles from './quinstreet.styles';

const Quinstreet = observer(({ classes }) => {
  const session = useContext(AuthContext);
  const { setFieldValue } = useFormikContext();
  const store = useStore();
  const {
    quote: { requestQuinstreetQuoteAction, loading, quinstreetOffer, setQuinstreetOffer, hasCarrierKnockout },
    offer: { offer }
  } = store;

  useEffect(() => {
    if (quinstreetOffer && !loading) {
      setQuinstreetOffer(null);
      setFieldValue('quoteQuinstreet', false);
    }
  }, [offer.id]);

  return (
    <Section title="External Offers" type="SubSection">
      <div className={classes.container}>
        <FormField
          id="quoteQuinstreet"
          name="quoteQuinstreet"
          mode="dark"
          label="Generate External Quotes"
          className={classes.switch}
          type="switch"
          onChange={(_, checked) => {
            if (checked) {
              requestQuinstreetQuoteAction(offer.quote, offer.webUserId, session.user);
            }
          }}
        />
        <div className={classes.containerInner}>
          <img src={ProgressiveLogo} className={classes.icon} alt="Banner Icon" />
          {((quinstreetOffer && !loading) || (hasCarrierKnockout && !loading && !quinstreetOffer)) && (
            <Button
              variant="contained"
              color="secondary"
              className={classes.button}
              onClick={() => {
                window.open(
                  hasCarrierKnockout && !quinstreetOffer
                    ? 'https://www.foragentsonly.com/login/'
                    : quinstreetOffer?.bridgeUrl,
                  '_blank'
                );
              }}
            >
              <img src={LaunchIconWhite} className={classes.buttonIcon} alt="Launch Icon" />
              Go to Progressive
            </Button>
          )}
        </div>
        {quinstreetOffer && !loading && (
          <div className={classes.dividerContainer}>
            <Divider className={classes.divider} />
          </div>
        )}
        {hasCarrierKnockout && !loading && !quinstreetOffer && (
          <div className={classes.containerInner}>
            <div className={classes.errorContainer}>
              <img src={AlertIconWhite} className={classes.icon} alt="Banner Icon" />
              <Label>
                We could not generate a quote for this carrier. Please try directly from the carriers website via the
                link.
              </Label>
            </div>
          </div>
        )}
        {loading && (
          <div className={classes.containerInner}>
            <Loading type="secondary" noBackground />
          </div>
        )}

        {quinstreetOffer && !loading && (
          <>
            <div className={classNames([classes.containerInner, classes.flexStart])}>
              {quinstreetOffer?.monthly && (
                <>
                  <div className={classes.flex}>
                    <span className={classNames([classes.dollarIcon, classes.beige])}>$</span>
                    <h1 className={classes.header}>{quinstreetOffer?.monthly}</h1>
                    <div>
                      <span className={classes.smallText}>PAID MONTHLY</span>
                      <span className={classes.thinText}>/6 MOS</span>
                    </div>
                  </div>
                  <span className={classes.bigSlash}>/</span>
                </>
              )}
              <div className={classes.flex}>
                <span className={classes.dollarIcon}>$</span>
                <h1 className={classNames(classes.header, { [classes.beige]: quinstreetOffer?.monthly })}>
                  {quinstreetOffer?.premium}
                </h1>
                <div>
                  <span className={classes.smallText}>PAID IN FULL</span>
                  <span className={classes.thinText}>/6 MOS</span>
                </div>
              </div>
            </div>
          </>
        )}
        <div className={classes.outlineContainer}>
          <img src={AlertIconWhiteFilled} className={classes.icon} alt="Banner Icon" />
          <Label>
            These coverages are purchased through external carriers, and are handled separately. The quotes generated
            may not reflect the final price and are subject to change prior to purchase.
          </Label>
        </div>
      </div>
    </Section>
  );
});

Quinstreet.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Quinstreet);
