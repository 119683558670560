export const applicantCode = [
  4000, 4001, 4002, 4003, 5002, 5007, 5008, 5011, 5012, 5013, 5014, 5015, 5016, 5017, 5018, 5019, 5020, 5021, 5022,
  5023, 5024, 5027, 0
];
export const personalDetailsCode = [5002, 5012];
export const priorAddressCode = [5001];
export const autoDetailsCode = [5003];
export const propDetailsCode = [50018, 5004, 5023];
export const addAutoCode = [5005, 5006, 5009, 5010];
export const sections = [
  ...personalDetailsCode,
  ...priorAddressCode,
  ...autoDetailsCode,
  ...propDetailsCode,
  ...addAutoCode
];
