/* eslint-disable func-names */
import * as Yup from 'yup';
import { paymentMethod } from '@ourbranch/lookups';

import { umbrellaValidationSchema } from './umbrella.validation-schema';
import { connectedHomeValidationSchema } from './connected-home.validation-schema';

const min = (minVal) => (val) => val >= minVal;

export const condoValidationSchema = ({
  policy,
  policies,
  savedSegment,
  autoPolicyDetails,
  isAdvancedConnectedHome
}) => {
  const baseSchema = Yup.object().shape({
    condo: Yup.object().shape({
      yearBuilt: Yup.number().test('yearBuiltMin', 'Year built must be higher than 1899', min(1900)),
      condoLocation: Yup.object().shape({
        address2: Yup.string()
          .nullable()
          .test('required-in-condo', `Condo's apartment or unit number is required`, (value, { options }) => {
            if (!options.context.condo.noUnitNumber) {
              return !!value;
            }
            return true;
          })
      }),
      mortgageDetails: Yup.array(
        Yup.object().shape({
          mortgageHolderName: Yup.string()
            .trim()
            .required('Required. If trying to remove this mortgage, use the Trash button.'),
          mortgageHolderAddress: Yup.object().shape({
            address: Yup.string().trim().required('Required'),
            address2: Yup.string().nullable(),
            city: Yup.string().trim().required('Required'),
            state: Yup.string().trim().required('Required'),
            zip: Yup.string().trim().required('Required')
          }),
          loanNumber: Yup.string().trim().required('Required')
        })
      )
        .nullable()
        .test(
          'atLeastOneRequired',
          'At least one mortgage details required because payment method is mortgage',
          function (mortgageList) {
            if (policy.paymentMethod === paymentMethod.Escrow) {
              return mortgageList?.length > 0;
            }
            return true;
          }
        )
    }),
    scheduledPersonalProperty: Yup.object()
      .shape({
        items: Yup.array(Yup.object()),
        deductible: Yup.mixed().test('deductible', 'SPP Deductible is required', function (value) {
          return !(this.parent.items && this.parent.items.length && !value);
        })
      })
      .nullable(),
    connectedHome: connectedHomeValidationSchema(isAdvancedConnectedHome),
    condoCoverage: Yup.object().shape({
      coverageC: Yup.number()
        .test('coverageC min limit', 'Personal Property Limit cannot be lower than $10K.', function (currentCoverageC) {
          return currentCoverageC >= 10000;
        })
        .test(
          'coverageC max limit',
          'Personal Property Limit value cannot be higher than $500K',
          function (currentCoverageC) {
            return currentCoverageC <= 500000;
          }
        ),
      coverageA: Yup.number()
        .test(
          'coverageA min limit',
          'Building Property Protection cannot be lower than $50K.',
          function (currentCoverageA) {
            return currentCoverageA >= 50000;
          }
        )
        .test(
          'coverageA max limit',
          'Dwelling limit value cannot be higher than 150% RCE',
          function (
            currentCoverageA,
            {
              options: {
                context: { condo }
              }
            }
          ) {
            const max = condo.replacementCostEstimate.total * 1.5;
            if (currentCoverageA > max) {
              return this.createError({
                message: `Building Property Protection cannot exceed $${max}`
              });
            }
            return true;
          }
        )
    })
  });

  return baseSchema.concat(umbrellaValidationSchema({ policy, policies, savedSegment, autoPolicyDetails }));
};
