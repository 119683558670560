import { makeStyles } from '@material-ui/core/styles';

const styles = (theme, smallPadding) => ({
  root: {
    fontWeight: 300,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    padding: (smallPadding) => (smallPadding ? '' : '33px 0')
  },
  selected: {
    fontWeight: 600,
    color: theme.colors.header__label_primary
  },
  wrapper: {
    flexDirection: 'row',
    padding: (smallPadding) => (smallPadding ? '0 16px' : '')
  },
  smallIcon: {
    width: 20,
    height: 20,
    marginRight: theme.spacing(2)
  },
  largeIcon: {
    width: 30,
    height: 30,
    marginRight: theme.spacing(2)
  },
  label: {
    display: 'inline-block',
    whiteSpace: 'nowrap'
  }
});

export default makeStyles(styles);
