import { useMemo } from 'react';
import { lookupsJson } from '@ourbranch/lookups';

import useSession from 'core/hooks/use-session';
import { useNewMonitoringServiceHelpers } from 'common/components/discounts/hooks/connected-home.hooks';

const securityProviders = lookupsJson.securityProviders;

// security providers allowing new monitoring sign up
export const providersAllowingSignUp = (isAgency) =>
  securityProviders.filter((provider) =>
    isAgency
      ? provider.signUpThroughBranch && provider.allowAgencySignUp && provider.signUpInPolicy
      : provider.signUpThroughBranch && provider.signUpInPolicy
  );

// all of our security provider options but with a key of id and value that is needed for an autocomplete field type
const advancedConnectedHomeOptions = lookupsJson.securityProviders.map((option) => ({
  id: option.id,
  text: option.value
}));

export const usePolicyConnectedHomeSecurityProviders = ({ isAdvancedConnectedHome }, affinityLookupsStore) => {
  const { isAgency } = useSession();
  const { isSigningUpForHomeSecurity } = useNewMonitoringServiceHelpers(affinityLookupsStore);

  const securityProviderOptions = useMemo(() => {
    // need to filter some providers on new sign-up in Policy
    if (isSigningUpForHomeSecurity) {
      return providersAllowingSignUp(isAgency);
    }

    if (isAdvancedConnectedHome) {
      return advancedConnectedHomeOptions;
    }

    return affinityLookupsStore.securityProviders;
  }, [isSigningUpForHomeSecurity, isAdvancedConnectedHome, affinityLookupsStore]);
  return securityProviderOptions;
};
