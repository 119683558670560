import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: theme.spacing(2),
    gap: theme.spacing(1)
  },
  title: {
    height: 34,
    alignItems: 'flex-end',
    fontWeight: 300
  },
  subtitle: {
    alignItems: 'flex-end',
    fontWeight: 400,
    fontSize: 12
  },
  invalidOptionTitle: {
    height: 34,
    fontWeight: 300,
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(1),
    color: theme.colorPalette.white_10
  },
  footerContainer: {
    height: 98,
    background: theme.colorPalette.beige_10,
    boxShadow: '0px -1px 3px 0px rgba(0,0,0,0.2)'
  },
  footerAlert: {
    background: theme.colorPalette.orange_10
  },
  floatingContainer: {
    position: 'fixed',
    left: 0,
    width: '100%',
    bottom: 0,
    zIndex: 150
  },
  footerContent: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    width: '100%',
    maxWidth: 1280,
    height: 98,
    margin: theme.spacing(0, 'auto')
  },
  btn: {
    padding: theme.spacing(0, 12),
    minWidth: 164
  },
  btnLabel: {
    fontWeight: 600,
    fontSize: 16
  },
  form: {
    display: 'flex',
    marginRight: theme.spacing(6)
  },
  formBtn: {
    backgroundColor: theme.colorPalette.orange_10,
    minWidth: 164,
    '&.MuiButton-contained.Mui-disabled': {
      color: theme.colorPalette.white_10,
      backgroundColor: theme.colorPalette.orange_10_op_70
    }
  },
  copyLink: {
    width: 'fit-content',
    padding: 0,
    height: 24
  },
  emailBtnLabel: {
    fontWeight: 500,
    fontSize: 14,
    paddingLeft: theme.spacing(0.5)
  },
  emailField: {
    paddingRight: theme.spacing(3),
    minHeight: 67
  },
  checkbox: {
    paddingRight: theme.spacing(3)
  }
}));

export default useStyles;
