import React from 'react';

const Claims = () => {
  return (
    <iframe
      src="https://v2-embednotion.com/Claims-9a767baeb2c0464c80a847642629e720"
      title="Claims"
      style={{
        width: '100%',
        height: '600px',
        border: '1px solid #ccc',
        borderRadius: '10px',
        padding: 'none',
        boxShadow: '0 2px 2px #ccc '
      }}
    />
  );
};

export default Claims;
