import React from 'react';
import { Grid } from '@material-ui/core';

import useStyles from './footer.styles';
import { ReactComponent as WindowsCtrlIcon } from '../../../core/assets/svg/ctrl.svg';
import { ReactComponent as FKeyIcon } from '../../../core/assets/svg/f-key-icon.svg';
import { ReactComponent as MacCommandIcon } from '../../../core/assets/svg/command.svg';

const userOS = navigator.userAgent.indexOf('Mac OS X');

const Footer = ({ activeTab }) => {
  const classes = useStyles();

  return (
    <Grid className={classes.footerWrapper}>
      <span className={classes.hint}>
        Hint: Try {userOS !== -1 ? <MacCommandIcon /> : <WindowsCtrlIcon />}
        <FKeyIcon className={classes.fKeyIcon} /> to search this page
      </span>
    </Grid>
  );
};

export default Footer;
