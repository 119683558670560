import React from 'react';
import { useFormikContext } from 'formik';
import { observer } from 'mobx-react';

import { Label } from 'core/components/label';
import { Button } from 'core/components/button';
import { withToast } from 'core/components/toast';
import { useStore } from 'core/store/store.mobx';

import useStyles from '../footer.styles';

const UpdateMVRs = observer(({ onUpdate, copyLink }) => {
  const { offer: store } = useStore();
  const classes = useStyles();
  const { dirty } = useFormikContext();

  return (
    <>
      <div className={classes.titleContainer}>
        <Label className={classes.title} type="subtitle">
          This price does not reflect rating on auto incidents or violations
        </Label>
        {!dirty && (
          <Button className={classes.copyLink} color="secondary" variant="text" onClick={copyLink}>
            Copy Offer Link
          </Button>
        )}
      </div>
      <Button
        onClick={onUpdate}
        disabled={store.loading}
        loading={store.loading}
        variant="contained"
        color="primary"
        className={classes.btn}
      >
        <Label className={classes.btnLabel}>Check for Auto Incidents</Label>
      </Button>
    </>
  );
});
export default withToast(UpdateMVRs);
