export const templates = {
  // application
  APPL_FRONT: { id: 'APPL_FRONT', name: '' },
  H_SFSW: { id: 'H_SFSW', name: 'Home Stress-Free Switch Letter' },
  A_SFSW: { id: 'A_SFSW', name: 'Auto Stress-Free Switch Letter' },
  R_SFSW: { id: 'R_SFSW', name: 'Renters Stress-Free Switch Letter' },
  H_SHAR: { id: 'H_SHAR', name: 'Third-Party Data-Sharing Agreement' },
  B_AUTH: { id: 'B_AUTH', name: 'Recurring Payment Authorization Form' },
  A_APPL: { id: 'A_APPL', name: 'Auto Application' },
  H_APPL: { id: 'H_APPL', name: 'Home Application' },
  R_APPL: { id: 'R_APPL', name: 'Renter Application' },
  C_APPL: { id: 'C_APPL', name: 'Condo Application' },
  H_CMSL: { id: 'H_CMSL', name: 'Coal Mine Subsidence Waiver' },
  H_MINA: {
    id: 'H_MINA',
    name: 'Mine Subsidence Insurance Underwriting Association Application'
  },
  C_MINA: {
    id: 'C_MINA',
    name: 'Mine Subsidence Insurance Underwriting Association Application'
  },
  H_EUSF: {
    id: 'H_EUSF',
    name: 'Excess Uninsured/Underinsured Motorists Selection/Rejection Form',
    docId: {
      NH: 'NH 022022',
      VT: 'VT 022022'
    }
  },
  A_UMSF: { id: 'A_UMSF', name: 'UM/UIM Selection Rejection Form' },
  A_UMRJ: { id: 'A_UMRJ', name: 'UM/UIM Rejection Form' },
  A_LOIW: { id: 'A_LOIW', name: 'Loss of Income Waiver' },
  A_UMNF: { id: 'A_UMNF', name: 'Notice Regarding UMPD' },
  A_MEDP: { id: 'A_MEDP', name: 'MedPay Selection Rejection Form' },
  // declaration
  A_DECK: { id: 'A_DECK', name: 'Auto Declarations' },
  H_DECK: { id: 'H_DECK', name: 'Homeowners Declarations' },
  R_DECK: { id: 'R_DECK', name: 'Renters Declarations' },
  C_DECK: { id: 'C_DECK', name: 'Condo Declarations' },
  A_GBCK: {
    id: 'A_GBCK',
    name: 'Community Giveback Endorsement',
    bixDocId: {
      AK: 'CW 042020',
      AR: 'CW 042020',
      AZ: 'CW 042020',
      DC: 'CW 042020',
      FL: 'CW 042020',
      GA: 'CW 042020',
      IA: 'CW 042020',
      IL: 'CW 122020',
      IN: 'CW 042020',
      KY: 'CW 042020',
      LA: 'CW 122020',
      MD: 'CW 122020',
      MI: 'MI 052021',
      MO: 'CW 042020',
      MS: 'CW 042020',
      MT: 'MT 042022',
      ND: 'CW 122020',
      NE: 'CW 122020',
      NM: 'CW 122020',
      OH: 'CW 042020',
      OK: 'OK 022021',
      OR: 'CW 122020',
      TX: 'CW 042020',
      UT: 'CW 042020',
      WI: 'CW 122020',
      WV: 'CW 122020'
    },
    docId: {
      AL: 'CW 102020',
      CO: 'CW 102020',
      ID: 'CW 102021',
      KS: 'CW 102020',
      MA: 'MA 082021',
      ME: 'CW 102021',
      OH: 'CW 042020',
      SD: 'CW 102021',
      TN: 'CW 102021',
      VT: 'CW 102021'
    }
  },
  A_ADWR: {
    id: 'A_ADWR',
    name: 'Auto Deductible Waiver Endorsement',
    bixDocId: {
      AK: 'CW 072021',
      AR: 'CW 042020',
      AZ: 'CW 042020',
      DC: 'CW 072021',
      GA: 'CW 072021',
      IA: 'CW 042020',
      IL: 'CW 072021',
      IN: 'CW 042020',
      KY: 'CW 042020',
      LA: 'CW 072021',
      MD: 'CW 042020',
      MI: 'CW 042020',
      MO: 'CW 042020',
      MS: 'CW 072021',
      MT: 'CW 072021',
      ND: 'CW 072021',
      NE: 'CW 072021',
      OH: 'CW 042020',
      OK: 'OK 022021',
      OR: 'CW 072021',
      TX: 'CW 072021',
      UT: 'CW 042020',
      WI: 'CW 042020',
      WV: 'CW 042020'
    },
    docId: {
      ID: 'CW 102021',
      ME: 'CW 102021',
      NH: 'CW 102021',
      OH: 'CW 042020',
      TN: 'CW 102021'
    }
  },
  A_EOUM: {
    id: 'A_EOUM',
    name: 'Economic Only Uninsured Motorist Coverage Endorsement',
    bixDocId: {
      LA: 'LA 072022'
    }
  },
  // id cards
  A_IDCD: { id: 'A_IDCD', name: 'Auto ID Cards' },
  A_MRMV: { id: 'A_MRMV', name: 'MA RMV Insurance Certification' },
  H_BIND: { id: 'H_BIND', name: 'Homeowners Binder Proof of Insurance' },
  C_BIND: { id: 'C_BIND', name: 'Condo Binder Proof of Insurance' },
  // Auto Policy Jacket
  A_PJAK: {
    id: 'A_PJAK',
    name: 'Auto Policy',
    bixDocId: {
      AK: 'AK 032022',
      AR: 'AR 072022',
      AZ: 'AZ 012021',
      DC: 'DC 012022',
      FL: 'FL 112021',
      GA: 'GA 052021',
      IA: 'IA 022022',
      IL: 'IL 062022',
      IN: 'IN 122020',
      KY: 'KY 112021',
      LA: 'LA 112021',
      MD: 'MD 102020',
      MI: 'MI 052021',
      MO: {
        default: 'MO 112021',
        '2022-05-17': 'MO 042022'
      },
      MS: 'MS 092021',
      MT: 'MT 112021',
      ND: 'ND 122021',
      NE: 'NE 072021',
      NM: 'NM 062021',
      OH: 'OH 042020',
      OK: 'OK 032021',
      OR: 'OR 012022',
      PA: 'PA 052021',
      TX: 'TX 072021',
      UT: {
        default: 'UT 102021',
        '2021-12-06': 'UT 112021'
      },
      WI: 'WI 052021',
      WV: 'WV 122021'
    },
    docId: {
      AL: 'AL 032021',
      AZ: {
        default: 'CW 122018',
        '2020-09-09': 'AZ 072020'
      },
      CO: 'CO 102020',
      ID: 'ID 092021',
      IL: 'CW 102020',
      KS: 'KS 042021',
      MA: 'MA 082021',
      ME: 'ME 072022',
      MO: 'CW 102020',
      NH: 'NH 012022',
      OH: 'CW 102020',
      SC: 'SC 062021',
      SD: 'SD 022022',
      TN: 'TN 082021',
      TX: 'TX 022020',
      VA: 'VA 092021',
      VT: 'VT 012022',
      WY: 'WY 052022'
    }
  },
  A_PLCY: {
    id: 'A_PLCY',
    name: 'Auto Policy',
    bixDocId: {
      AK: 'AK 062022',
      AR: 'AR 072022',
      AZ: 'AZ 032021',
      DC: 'DC 012022',
      FL: 'FL 122021',
      GA: 'GA 042022',
      IA: 'IA 022022',
      IL: {
        default: 'IL 062022',
        '2022-11-15': 'IL 112022'
      },
      IN: 'IN 012021',
      KY: 'KY 092022',
      LA: 'LA 082022',
      MD: 'MD 022021',
      MI: 'MI 062021',
      MO: {
        default: 'MO 112021',
        '2022-05-17': 'MO 042022'
      },
      MS: 'MS 062022',
      MT: 'MT 042022',
      ND: 'ND 122021',
      NE: 'NE 072021',
      NM: 'NM 062021',
      OH: 'OH 042020',
      OK: 'OK 022021',
      OR: 'OR 022022',
      PA: 'PA 072021',
      TX: 'TX 092021',
      UT: {
        default: 'UT 102021',
        '2021-12-06': 'UT 112021'
      },
      WI: 'WI 052021',
      WV: 'WV 122021'
    },
    docId: {
      AL: 'AL 032021',
      AZ: {
        default: 'AZ 062019',
        '2020-09-09': 'AZ 072020'
      },
      CO: 'CO 102020',
      ID: 'ID 112021',
      IL: 'IL 122018',
      KS: 'KS 042021',
      MA: 'MA 092022',
      ME: 'ME 082022',
      MO: 'MO 122018',
      NH: 'NH 012022',
      OH: 'CW 122018',
      SC: 'SC 062021',
      SD: 'SD 022022',
      TN: 'TN 112021',
      TX: 'TX 032020',
      VA: 'VA 012022',
      VT: 'VT 122021',
      WY: 'WY 052022'
    }
  },
  A_AMND: {
    id: 'A_AMND',
    name: 'Amendatory Endorsement',
    bixDocId: {
      AR: 'AR 102022',
      MI: 'MI 092022',
      NM: 'NM 122022',
      OK: 'OK 022022',
      OR: 'OR 102022',
      UT: {
        default: 'UT 042022',
        '2022-09-11': 'UT 082022'
      },
      WV: 'WV 092022'
    },
    docId: {
      AR: 'AR 102022',
      AZ: 'CW 122018',
      ID: 'ID 092022',
      IL: 'IL 122018',
      MO: 'CW 122018',
      NH: 'NH 082022',
      OH: 'CW 122018',
      SD: 'SD 082022',
      TN: 'TN 082022',
      WY: 'WY 902022'
    }
  },
  A_CIVU: {
    id: 'A_CIVU',
    name: 'Civil Union Endorsement',
    docId: {
      AZ: 'CW 122018',
      IL: 'IL 122018',
      MO: 'CW 122018',
      OH: 'CW 122018'
    }
  },
  A_COMM: {
    id: 'A_COMM',
    name: 'Branch Community Giveback Endorsement',
    docId: {
      AZ: 'CW 042020',
      CO: 'CW 102020',
      IL: 'CW 042020',
      MO: 'CW 042020',
      OH: 'CW 042020',
      TX: 'CW 042020'
    }
  },
  A_NDEX: {
    id: 'A_NDEX',
    name: 'Named Driver Exclusion',
    bixDocId: {
      AK: 'AK 022022',
      AR: 'CW 042020',
      AZ: 'AZ 012021',
      DC: 'CW 042020',
      FL: 'FL 112021',
      GA: 'GA 052021',
      IA: 'CW 042020',
      IL: 'CW 042020',
      IN: 'IN 122020',
      KY: 'KY 102021',
      LA: 'LA 072022',
      MD: 'MD 102020',
      MI: 'MI 042021',
      MO: 'CW 072020',
      MS: 'MS 092021',
      MT: 'CW 042021',
      ND: 'CW 042020',
      NE: 'NE 072021',
      NM: 'CW 042020',
      OH: 'CW 042020',
      OK: 'OK 022021',
      PA: 'PA 042021',
      TX: 'TX 072021',
      UT: 'UT 102021',
      WI: 'CW 042020',
      WV: 'CW 052021'
    },
    docId: {
      AZ: 'AZ 122018',
      CO: 'CO 112020',
      ID: 'ID 112021',
      IL: 'IL 122018',
      MA: 'MA 082021',
      ME: 'ME 012022',
      MO: 'CW 122018',
      OH: 'CW 122018',
      SC: 'SC 052021',
      SD: 'CW 102021',
      TN: 'CW 102021',
      TX: 'TX 012020',
      WY: 'WY 032022'
    }
  },
  A_NNOP: {
    id: 'A_NNOP',
    name: 'Named Non-Owner Coverage Endorsement',
    bixDocId: {
      AK: 'AK 022022',
      AR: 'AR 072022',
      AZ: 'AZ 012021',
      DC: 'DC 012022',
      GA: 'CW 042020',
      IA: 'IA 022022',
      IL: 'IL 062022',
      IN: 'IN 112020',
      KY: 'KY 102021',
      LA: 'LA 072022',
      MD: 'MD 102020',
      MI: 'MI 042021',
      MO: 'CW 042020',
      MS: 'MS 092021',
      MT: 'MT 112021',
      ND: 'ND 122021',
      NE: 'NE 072021',
      NM: 'CW 042020',
      OH: 'CW 042020',
      OK: 'OK 022021',
      OR: 'OR 012022',
      PA: 'PA 042021',
      TX: 'TX 082021',
      UT: 'UT 102021',
      WI: 'WI 102020',
      WV: 'WV 082021'
    },
    docId: {
      AL: 'AL 032021',
      AZ: 'AZ 122018',
      CO: 'CO 102020',
      ID: 'ID 112021',
      IL: 'IL 122018',
      KS: 'KS 042021',
      ME: 'ME 042022',
      MO: 'CW 122018',
      NH: 'NH 102021',
      OH: 'CW 122018',
      SC: 'SC 052021',
      SD: {
        default: 'SD 012022',
        '2022-12-05': 'SD 092022'
      },
      TN: 'TN 082021',
      TX: 'TX 012020',
      VA: 'VA 092021',
      VT: 'VT 112021',
      WY: 'WY 122021'
    }
  },
  A_RENT: {
    id: 'A_RENT',
    name: 'Renters Endorsement',
    bixDocId: {
      AR: 'AR 072022',
      GA: 'GA 052021',
      IA: 'IA 022022',
      ID: 'ID 092022',
      IL: {
        default: 'IL 062022',
        '2022-11-15': 'IL 102022'
      },
      IN: 'IN 122020',
      MD: 'MD 102020',
      MO: 'CW 042020',
      MS: 'MS 092021',
      NE: 'NE 072021',
      OH: 'CW 042020',
      OK: 'OK 022021',
      OR: {
        default: 'OR 022022',
        '2022-12-05': 'OR 102022'
      },
      UT: 'UT 102021',
      WI: 'WI 102020',
      WV: {
        default: 'WV 082021',
        '2022-12-05': 'WV 092022'
      }
    },
    docId: {
      AL: 'AL 032021',
      AZ: 'CW 122018',
      ID: {
        default: 'ID 112021',
        '2022-12-05': 'ID 092022'
      },
      IL: 'IL 122018',
      KS: 'KS 052021',
      MO: 'CW 122018',
      OH: 'CW 122018',
      VA: 'VA 102021'
    }
  },
  A_RNTC: {
    id: 'A_RNTC',
    name: 'Rental Car Endorsement',
    bixDocId: {
      MD: 'MD 102020'
    }
  },
  A_PIPR: {
    id: 'A_PIPR',
    bixDocId: {
      OH: 'CW 042020'
    },
    name: 'Pet Injury Endorsement'
  },
  A_ACPE: {
    id: 'A_ACPE',
    docId: {
      MA: 'MA 082021'
    },
    name: 'Additional Custom Parts and Equipment Coverage Endorsement'
  },
  A_SPSN: {
    id: 'A_SPSN',
    bixDocId: {
      WV: 'WV 092021'
    },
    name: 'Spousal Notice'
  },
  A_RIDE: {
    id: 'A_RIDE',
    name: 'Rideshare Endorsement',
    bixDocId: {
      AK: 'AK 022022',
      AR: 'AR 072022',
      AZ: 'AZ 022021',
      DC: 'DC 012022',
      FL: 'FL 062021',
      GA: 'CW 042020',
      IA: 'IA 022022',
      IL: 'IL 062022',
      IN: 'IN 112020',
      KY: 'KY 102021',
      LA: 'LA 112021',
      MD: 'MD 102020',
      MI: 'MI 042021',
      MO: 'CW 042020',
      MS: 'MS 092021',
      MT: 'MT 112021',
      ND: 'ND 122021',
      NE: 'NE 072021',
      NM: 'CW 042020',
      OH: 'CW 042020',
      OK: 'OK 022021',
      OR: 'OR 012022',
      PA: 'PA 042021',
      TX: 'TX 092020',
      UT: 'UT 102021',
      WI: 'WI 102020',
      WV: 'WV 082021'
    },
    docId: {
      AL: 'AL 032021',
      AZ: 'AZ 122018',
      CO: 'CO 102020',
      ID: 'ID 112021',
      IL: 'IL 122018',
      KS: 'KS 042021',
      MA: 'MA 082021',
      ME: 'ME 012022',
      MO: 'CW 122018',
      NH: 'NH 102021',
      OH: 'CW 122018',
      SC: 'SC 062021',
      SD: 'SD 012022',
      TN: 'TN 082021',
      TX: 'TX 022020',
      VA: 'VA 102021',
      VT: 'VT 122021',
      WY: 'WY 012022'
    }
  },
  A_RTNC: {
    id: 'A_RTNC',
    name: 'Renewal Terms and Conditions',
    bixDocId: {
      AZ: 'AZ 022021',
      IN: 'IN 012021',
      KY: 'KY 112021',
      MD: 'MD 122020',
      MI: 'MI 062021',
      MO: 'MO 072020',
      NE: 'NE 072021',
      NM: 'NM 112021',
      OH: 'OH 042020',
      OK: 'OK 022021',
      PA: 'PA 042021',
      TX: 'TX 072021',
      UT: 'UT 102021',
      WI: 'WI 052021',
      WV: 'WV 092021'
    },
    docId: {
      AL: 'AL 102021',
      AZ: 'AZ 062019',
      CO: 'CO 102020',
      ID: 'ID 112021',
      IL: 'IL 122019',
      KS: 'KS 032021',
      MO: 'MO 102019',
      OH: 'OH 112019',
      TN: 'TN 102021',
      TX: 'TX 012020',
      VA: 'VA 092021'
    }
  },
  A_WGPT: {
    id: 'A_WGPT',
    name: 'Window Glass Coverage Endorsement',
    bixDocId: {
      AZ: 'CW 042020',
      IN: 'CW 042020',
      MD: 'CW 042020',
      OH: 'CW 042020',
      OK: 'CW 042020',
      WI: 'CW 042020'
    },
    docId: {
      MA: 'MA 082021'
    }
  },
  A_AANR: {
    id: 'A_AANR',
    name: 'Important information about your credit based score notice'
  },
  A_SACN: {
    id: 'A_SACN',
    name: 'Notice of Changes to Your Auto Policy'
  },
  A_EXPI: {
    id: 'A_EXPI',
    name: 'Notice of Changes to Your Auto Policy'
  },
  A_NUMD: {
    id: 'A_NUMD',
    name: 'Required Disclosure'
  },
  A_EXPIR: {
    id: 'A_EXPIR',
    name: 'Notice of Changes to Your Auto Policy about Renters'
  },
  A_AATU: { id: 'A_AATU', name: 'Adverse Underwriting' },
  A_AATU2: { id: 'A_AATU2', name: 'Adverse Underwriting' },
  A_AAVK: { id: 'A_AAVK', name: 'Adverse Underwriting' },
  A_TRCC: {
    id: 'A_TRCC',
    name: 'Trailer Contents Coverage Endorsement',
    bixDocId: {
      AK: 'AK 102022',
      AZ: 'CW 082022',
      AR: 'AR 102022',
      GA: 'CW 082022',
      IL: 'CW 082022',
      IN: 'CW 082022',
      IA: 'CW 082022',
      KY: 'CW 082022',
      LA: 'CW 082022',
      MD: 'CW 082022',
      MI: 'CW 082022',
      MO: 'MO 092022',
      MS: 'CW 082022',
      MT: 'CW 082022',
      NE: 'CW 082022',
      NM: 'CW 082022',
      ND: 'CW 082022',
      OH: 'CW 082022',
      OK: 'CW 082022',
      OR: 'OR 102022',
      PA: 'CW 082022',
      TX: 'TX 102022',
      UT: 'UT 102021',
      WV: 'CW 082022',
      WI: 'CW 082022'
    },
    docId: {
      AL: 'AL 082022',
      ID: 'CW 082022',
      KS: 'CW 082022',
      ME: 'CW 082022',
      NH: 'CW 082022',
      SC: 'CW 082022',
      SD: 'SD 102022',
      TN: 'CW 082022',
      VT: 'CW 082022',
      WY: 'CW 082022'
    }
  },
  A_TPDC: {
    id: 'A_TPDC',
    name: 'Trailer Comp-Coll Coverage Endorsement',
    bixDocId: {
      AK: 'AK 102022',
      AZ: 'CW 082022',
      AR: 'AR 102022',
      GA: 'CW 082022',
      IL: 'CW 082022',
      IN: 'CW 082022',
      IA: 'CW 082022',
      KY: 'CW 082022',
      LA: 'CW 082022',
      MD: 'CW 082022',
      MI: 'CW 082022',
      MO: 'CW 082022',
      MS: 'CW 082022',
      MT: 'CW 082022',
      NE: 'CW 082022',
      NM: 'CW 082022',
      ND: 'CW 082022',
      OH: 'CW 082022',
      OK: 'CW 082022',
      OR: 'CW 082022',
      PA: 'CW 082022',
      TX: 'TX 102022',
      UT: 'UT 092021',
      WV: 'CW 082022',
      WI: 'CW 082022'
    },
    docId: {
      AL: 'CW 082022',
      ID: 'CW 082022',
      KS: 'CW 082022',
      ME: 'CW 082022',
      NH: 'CW 082022',
      SC: 'CW 082022',
      SD: 'CW 082022',
      TN: 'CW 082022',
      VT: 'CW 082022',
      WY: 'CW 082022'
    }
  },
  A_NEXL: {
    id: 'A_NEXL',
    name: 'Information',
    docId: {
      TX: 'TX 122018'
    }
  },
  A_CBRN: {
    id: 'A_CBRN',
    name: 'Information',
    docId: {
      TX: 'TX 062021'
    }
  },
  A_CYIR: { id: 'A_CYIR', name: '' },
  A_SPIN: {
    id: 'A_SPIN',
    name: 'Premium Increase Notice'
  },
  A_GPIN: {
    id: 'A_GPIN',
    name: 'Premium Increase Notice'
  },
  A_DISC: {
    id: 'A_DISC',
    docId: {
      CO: 'CO 102020'
    },
    name: 'Important Notice'
  },
  A_TRNO: {
    id: 'A_TRNO',
    name: 'Tier Notice'
  },
  B_PRMM: {
    id: 'B_PRMM',
    bixDocId: {
      MD: 'MD 032021'
    },
    name: 'Important Notice'
  },
  A_RECA: {
    id: 'A_RECA',
    bixDocId: {
      MD: 'MD 022021'
    },
    name: 'Notice of Recalculation of Premium'
  },
  A_REDU: {
    id: 'A_REDU',
    bixDocId: {
      NH: 'CW 082022'
    },
    name: 'Reduction in Coverage Notice'
  },
  A_PIPS: {
    id: 'A_PIPS',
    bixDocId: {
      MD: 'MD 012021'
    },
    name: 'Notice of Waive of Personal Injury Protection Coverage'
  },
  A_EUMC: {
    id: 'A_EUMC',
    bixDocId: {
      MD: 'MD 012021'
    },
    name: 'Enhanced Underinsured Motorist Coverage Endorsement'
  },
  A_MPIG: {
    id: 'A_MPIG',
    bixDocId: {
      MD: 'MD 012021'
    },
    name: 'Notice of Premium Increase'
  },
  A_MPIL: {
    id: 'A_MPIL',
    bixDocId: {
      MD: 'MD 012021'
    },
    name: 'Notice of Premium Increase'
  },
  A_DRPP: {
    id: 'A_DRPP',
    bixDocId: {
      MD: 'MD 012021'
    },
    name: 'Summary of Driver Record Point Plan'
  },
  A_DCDR: {
    id: 'A_DCDR',
    bixDocId: {
      MD: 'MD 012021'
    },
    name: 'Summary of Rate Classification'
  },
  A_KSRI: {
    id: 'A_KSRI',
    docId: {
      KS: 'KS 052021'
    },
    name: 'Kansas Rating Information'
  },
  A_DITR: {
    id: 'A_DITR',
    bixDocId: {
      MI: 'MI 042021'
    },
    name: 'Declaration of Intent to Reside'
  },
  A_NDEL: {
    id: 'A_NDEL',
    bixDocId: {
      AR: 'CW 042022',
      IA: 'IA 022022',
      MI: 'MI 042021',
      TX: 'TX 072021',
      UT: 'UT 102021'
    },
    name: 'Named Driver Exclusion Election Form'
  },
  A_MPSF: {
    id: 'A_MPSF',
    bixDocId: {
      MI: 'MI 052021'
    },
    name: 'Selection of Personal Injury Protection'
  },
  A_BISF: {
    id: 'A_BISF',
    bixDocId: {
      MI: 'MI 042021'
    },
    name: 'Choice of Bodily Injury Liability Coverage Limits'
  },
  A_BIOF: {
    id: 'A_BIOF',
    bixDocId: {
      MI: 'MI 052021'
    },
    name: 'Bodily Injury Option and Premiums'
  },
  A_BIOFRN: {
    id: 'A_BIOFRN',
    bixDocId: {
      MI: 'MI 052021'
    },
    name: 'Bodily Injury Option and Premiums'
  },
  A_WLBW: {
    id: 'A_WLBW',
    bixDocId: {
      MI: 'MI 062021'
    },
    name: 'Work Loss Benefits Waiver'
  },
  A_RTNO: {
    id: 'A_RTNO',
    bixDocId: {
      MI: 'MI 062021'
    },
    name: 'Rating Information Notice'
  },
  A_UIMS: {
    id: 'A_UIMS',
    bixDocId: {
      PA: 'PA 042021',
      UT: 'UT 102021'
    },
    name: 'UIM Selection Rejection Form'
  },
  A_APTA: {
    id: 'A_APTA',
    name: 'Auto Policy Term Acknowledgement'
  },
  A_TOSF: {
    id: 'A_TOSF',
    bixDocId: {
      PA: 'PA 042021'
    },
    name: 'Tort Selection Rejection Form'
  },
  A_KNFR: {
    id: 'A_KNFR',
    name: 'No-Fault Rejection Form',
    bixDocId: {
      KY: 'KY 062022'
    }
  },
  A_CODW: {
    id: 'A_CODW',
    bixDocId: {
      PA: 'PA 042021'
    },
    name: 'Collision Deductible Waiver'
  },
  A_ICSE: {
    id: 'A_ICSE',
    bixDocId: {
      PA: 'PA 042021'
    },
    name: 'Insurance Consultation Services Exemption'
  },
  A_NOBE: {
    id: 'A_NOBE',
    bixDocId: {
      PA: 'PA 042021'
    },
    name: 'Notice of Benefits'
  },
  A_NOTO: {
    id: 'A_NOTO',
    bixDocId: {
      PA: 'PA 042021'
    },
    name: 'Notice of Tort Options'
  },
  A_PSDS: {
    id: 'A_PSDS',
    bixDocId: {
      PA: 'PA 042021'
    },
    name: 'Surcharge Disclosure'
  },
  A_SMCQ: {
    id: 'A_SMCQ',
    bixDocId: {
      PA: 'PA 042021'
    },
    name: 'State Minimum Coverage Quote'
  },
  A_VEUA: {
    id: 'A_VEUA',
    docId: {
      VA: 'VA 042020'
    },
    name: 'Virginia Employees Using Auto in Government Business'
  },
  A_DEUA: {
    id: 'A_DEUA',
    docId: {
      VA: 'VA 042020'
    },
    name: 'District of Columbia Employees Using Auto in Government Business'
  },
  A_FEUA: {
    id: 'A_FEUA',
    docId: {
      VA: 'VA 072021'
    },
    name: 'Federal Employees Using Auto in Government Business'
  },
  A_LELO: {
    id: 'A_LELO',
    docId: {
      VA: 'VA 102021'
    },
    name: 'Auto Loan Lease Coverage Endorsement'
  },
  A_LRAO: {
    id: 'A_LRAO',
    docId: {
      VA: 'VA 082020'
    },
    name: 'Loss Reduction and Other Items Endorsement'
  },
  A_SILL: {
    id: 'A_SILL',
    docId: {
      VA: 'VA 082021'
    },
    name: 'Single Liability Limit Coverage Endorsement'
  },
  A_SUML: {
    id: 'A_SUML',
    docId: {
      VA: 'VA 082021'
    },
    name: 'Single Uninsured Motorist Limit Endorsement'
  },
  A_TOLC: {
    id: 'A_TOLC',
    docId: {
      VA: 'VA 102021'
    },
    name: 'Towing and Labor Costs Coverage'
  },
  A_TREX: {
    id: 'A_TREX',
    docId: {
      VA: 'VA 092021'
    },
    name: 'Transportation Expense Coverage'
  },
  A_ADDC: {
    id: 'A_ADDC',
    docId: {
      VA: 'VA 112021'
    },
    name: 'Add-on Equipment Coverage Endorsement'
  },
  A_MEIL: {
    id: 'A_MEIL',
    docId: {
      VA: 'VA 082021'
    },
    name: 'Medical Expense and Income Loss Benefits Coverage'
  },
  A_PVSE: {
    id: 'A_PVSE',
    docId: {
      VA: 'VA 092021'
    },
    name: 'Peer-to-Peer Vehicle Sharing Exclusion'
  },
  A_VARI: {
    id: 'A_VARI',
    docId: {
      VA: 'VA 072021'
    },
    name: 'Virginia Auto Rating Information Notice'
  },
  A_ELCN: {
    id: 'A_ELCN',
    docId: {
      VA: 'VA 012022'
    },
    name: 'Extraordinary Life Event Notice'
  },
  A_NRVC: {
    id: 'A_NRVC',
    docId: {
      ME: 'ME 042022'
    },
    name: 'Maine Notice'
  },
  A_UUIN: {
    id: 'A_UUIN',
    docId: {
      VA: 'VA 112021'
    },
    name: 'Important Notice'
  },
  A_MILN: {
    id: 'A_MILN',
    docId: {
      VA: 'VA 112021'
    },
    name: 'Important Notice'
  },
  A_CLBR: {
    id: 'A_CLBR',
    bixDocId: {
      OR: 'OR 012022'
    },
    name: 'Notice of Claim Rights'
  },
  A_PIPC: {
    id: 'A_PIPC',
    name: 'Florida Personal Injury Protection',
    bixDocId: {
      FL: 'FL 122021'
    }
  },
  A_UMFL: {
    id: 'A_UMFL',
    name: 'Florida Uninsured Motorists Insurance',
    bixDocId: {
      FL: 'FL 062021'
    }
  },
  A_ADII: {
    id: 'A_ADII',
    name: 'Automobile Death Indemnity Insurance',
    bixDocId: {
      FL: 'FL 062021'
    }
  },
  A_FVEN: {
    id: 'A_FVEN',
    name: 'Farm Vehicle Endorsement',
    bixDocId: {
      FL: 'FL 062021'
    }
  },
  A_GLSC: {
    id: 'A_GLSC',
    name: 'Florida Glass Schedule Endorsement',
    bixDocId: {
      FL: 'FL 062021'
    }
  },
  A_ITEX: {
    id: 'A_ITEX',
    name: 'Identity Theft Expense Endorsement',
    bixDocId: {
      FL: 'FL 062021'
    }
  },
  A_TFES: {
    id: 'A_TFES',
    name: 'Transportation of Fellow Employees, Students, or Others',
    docId: {
      MA: 'MA 092022'
    }
  },
  A_LGAP: {
    id: 'A_LGAP',
    name: 'Loan or Lease Gap Coverage Endorsement',
    bixDocId: {
      FL: 'FL 062021'
    },
    docId: {
      MA: 'MA 082021'
    }
  },
  B_TPAN: {
    id: 'B_TPAN',
    bixDocId: {
      NH: 'NH 022022'
    },
    name: 'Third Party Administrator Notice'
  },
  B_ELCN: {
    id: 'B_ELCN',
    bixDocId: {
      IA: 'CW 042020',
      MI: 'MI 052021',
      NM: 'NM 012022'
    },
    name: 'Extraordinary Life Event Notice'
  },
  B_WVCD: {
    id: 'B_WVCD',
    name: 'Credit Disclosure'
  },
  // Home Policy Jacket

  H_FLHO00030491: {
    id: 'H_FLHO00030491',
    name: 'Homeowners3 Special Form',
    bixDocId: {
      FL: 'HO 00 03 04 91'
    }
  },
  H_FLHO04960491: {
    id: 'H_FLHO04960491',
    name: 'Home Day Care Explanation',
    bixDocId: {
      FL: 'HO 04 96 04 91'
    }
  },
  H_FLHO04890701: {
    id: 'H_FLHO04890701',
    name: 'Windstorm or Hail Exclusion - Florida',
    bixDocId: {
      FL: 'HO 04 89 07 01'
    }
  },
  H_FLHO04210494: {
    id: 'H_FLHO04210494',
    name: 'Windstorm Protective Device',
    bixDocId: {
      FL: 'HO 04 21 04 94'
    }
  },
  H_FLHO00150491: {
    id: 'H_FLHO00150491',
    name: 'Special Personal Property',
    bixDocId: {
      FL: 'HO 00 15 04 91'
    }
  },
  H_FLHO04350491: {
    id: 'H_FLHO04350491',
    name: 'Loss Assessment',
    bixDocId: {
      FL: 'HO 04 35 04 91'
    }
  },
  H_FLHO04650491: {
    id: 'H_FLHO04650491',
    name: 'Coverage C Increased Special Limits of Liability',
    bixDocId: {
      FL: 'HO 04 65 04 91'
    }
  },
  H_FLHO04100491: {
    id: 'H_FLHO04100491',
    name: 'Additional Interests, Residences Premises Endorsement',
    bixDocId: {
      FL: 'HO 04 10 04 91'
    }
  },
  H_FLHO04410491: {
    id: 'H_FLHO04410491',
    name: 'Additional Insured,  Residences Premises Endorsement',
    bixDocId: {
      FL: 'HO 04 41 04 91'
    }
  },
  H_AOBE: {
    id: 'H_AOBE',
    name: 'Prohibition of Assignment of Benefits Endorsement',
    bixDocId: {
      FL: 'FL 062022'
    }
  },
  H_AMND: {
    id: 'H_AMND',
    name: 'Amendatory Endorsement',
    bixDocId: {
      AK: 'AK 102022',
      AZ: 'AZ 012021',
      IL: 'IL 022023',
      IN: 'CW 042020',
      LA: 'LA 012023',
      MI: 'MI 052022',
      MO: 'MO 072020',
      ND: 'ND 012022',
      NM: {
        default: 'NM 072021',
        '2020-08-27': 'NM 082022'
      },
      OH: 'OH 042020',
      OR: 'OR 112022',
      PA: 'PA 112022'
    },
    docId: {
      AZ: 'AZ 122018',
      ID: 'ID 092022',
      IL: 'IL 102019',
      MO: 'MO 122018',
      NH: 'NH 082022',
      OH: 'OH 122018',
      SD: 'SD 082022',
      TN: 'TN 082022',
      WY: 'WY 082022'
    }
  },
  H_ADDI: {
    id: 'H_ADDI',
    bixDocId: {
      AK: 'CW 042020',
      AR: 'CW 042020',
      DC: 'CW 042020',
      GA: 'CW 042020',
      IA: 'CW 042020',
      IL: 'CW 042020',
      IN: 'CW 042020',
      KY: 'CW 042020',
      LA: 'CW 042020',
      MD: 'CW 042020',
      MI: 'CW 042020',
      MO: 'CW 042020',
      MS: 'CW 042020',
      MT: 'CW 042020',
      NE: 'CW 042020',
      NM: 'CW 042020',
      OH: 'CW 042020',
      OK: 'OK 022021',
      OR: 'CW 042020',
      PA: 'PA 042021',
      TX: 'CW 042020',
      UT: 'CW 042020',
      WI: 'CW 042020',
      WV: 'CW 042020'
    },
    docId: {
      AL: 'CW 122018',
      AZ: 'CW 122018',
      CO: 'CW 122018',
      ID: 'CW 072021',
      IL: 'CW 122018',
      KS: 'CW 122018',
      MA: 'CW 072021',
      ME: 'CW 072021',
      NH: 'CW 072021',
      SD: 'CW 072021',
      TN: 'CW 072021',
      TX: 'CW 122018',
      VT: 'CW 072021',
      WY: 'CW 072021'
    },
    name: 'Additional Insureds Endorsement'
  },
  H_ADNO: {
    id: 'H_ADNO',
    bixDocId: {
      AK: 'CW 082021',
      AR: 'CW 082021',
      AZ: 'CW 042020',
      DC: 'CW 082021',
      GA: 'CW 042020',
      IA: 'CW 082021',
      IL: 'CW 082021',
      IN: 'CW 042020',
      KY: 'CW 082021',
      LA: 'CW 082021',
      MD: 'CW 042020',
      MI: 'CW 042020',
      MO: 'CW 042020',
      MS: 'CW 042020',
      MT: 'CW 082021',
      NE: 'CW 042020',
      NM: 'CW 042020',
      OH: 'CW 042020',
      OK: 'OK 022021',
      OR: 'CW 082021',
      PA: 'PA 042021',
      TX: 'CW 042020',
      UT: 'CW 082021',
      WI: 'CW 042020',
      WV: 'CW 042020'
    },
    docId: {
      AL: 'CW 122018',
      ID: 'CW 072021',
      MA: 'CW 072021',
      ME: 'CW 072021',
      NH: 'CW 072021',
      SD: 'CW 072021',
      TN: 'CW 072021',
      VT: 'CW 072021',
      WY: 'CW 072021'
    },
    name: 'Additional Insured Non Occupant (Designated Premises Only)'
  },
  H_AANR: {
    id: 'H_AANR',
    bixDocId: {
      AZ: 'CW 042020',
      CO: 'CW 122018',
      IN: 'CW 042020',
      KY: 'CW 042020',
      MD: 'CW 042020',
      MI: 'CW 042020',
      MO: 'CW 042020',
      NE: 'CW 042020',
      NM: 'CW 042020',
      OH: 'CW 042020',
      OK: 'CW 042020',
      PA: 'CW 042020',
      TX: 'CW 042020',
      UT: 'CW 042020',
      WV: 'CW 042020'
    },
    docId: {
      TN: 'CW 072021'
    },
    name: 'Important information about your credit based score notice'
  },
  H_AATU: { id: 'H_AATU', name: 'Adverse Underwriting' },
  H_ADNR: {
    id: 'H_ADNR',
    name: 'Additional Insured Non-Relative',
    bixDocId: {
      AK: 'CW 082021',
      AR: 'CW 082021',
      AZ: 'CW 042020',
      DC: 'CW 082021',
      GA: 'CW 042020',
      IA: 'CW 082021',
      IL: 'CW 082021',
      IN: 'CW 042020',
      KY: 'CW 082021',
      LA: 'CW 082021',
      MD: 'CW 042020',
      MI: 'CW 042020',
      MO: 'CW 042020',
      MS: 'CW 042020',
      MT: 'CW 082021',
      NE: 'CW 042020',
      NM: 'CW 042020',
      OH: 'CW 042020',
      OK: 'CW 042020',
      OR: 'CW 082021',
      PA: 'PA 042021',
      TX: 'CW 042020',
      UT: 'CW 082021',
      WI: 'CW 042020',
      WV: 'CW 052021'
    },
    docId: {
      AL: 'CW 122018',
      AZ: 'CW 122018',
      CO: 'CW 122018',
      ID: 'CW 072021',
      IL: 'CW 122018',
      KS: 'CW 122018',
      MA: 'CW 072021',
      ME: 'CW 072021',
      MO: 'CW 122018',
      NH: 'CW 072021',
      OH: 'CW 122018',
      SD: 'CW 072021',
      TN: 'CW 072021',
      TX: 'CW 122018',
      VT: 'CW 072021',
      WY: 'CW 072021'
    }
  },
  H_ADTR: {
    id: 'H_ADTR',
    name: 'Additional Insured Trust',
    bixDocId: {
      AK: 'AK 032022',
      AR: 'CW 082021',
      AZ: 'CW 042020',
      DC: 'CW 082021',
      GA: 'CW 042020',
      IA: 'CW 092020',
      IL: 'CW 082021',
      IN: 'CW 042020',
      KY: 'KY 102021',
      LA: 'CW 082021',
      MD: 'CW 042020',
      MI: 'MI 042021',
      MO: 'CW 092020',
      MS: 'CW 042020',
      MT: 'CW 082021',
      NE: 'CW 042020',
      NM: 'CW 092020',
      OH: 'CW 042020',
      OK: 'OK 022021',
      OR: 'CW 082021',
      PA: 'PA 042021',
      TX: 'TX 072021',
      UT: 'UT 092021',
      WI: 'WI 102020',
      WV: 'WV 082021'
    },
    docId: {
      AL: 'CW 122018',
      AZ: 'CW 122018',
      CO: 'CW 122018',
      ID: 'CW 072021',
      IL: 'CW 122018',
      KS: 'CW 122018',
      MA: 'MA 082021',
      ME: 'CW 072021',
      MO: 'CW 122018',
      NH: 'CW 072021',
      SD: 'CW 072021',
      OH: 'CW 122018',
      TN: 'CW 072021',
      TX: 'CW 122018',
      VT: 'VT 012022',
      WY: 'CW 072021'
    }
  },
  H_ANLI: {
    id: 'H_ANLI',
    name: 'Animal Liability Endorsement - Florida',
    bixDocId: {
      FL: 'FL 012022'
    }
  },
  H_ANME: {
    id: 'H_ANME',
    name: 'Animal Liability Exclusion',
    bixDocId: {
      AK: 'AK 022022',
      AR: 'CW 042020',
      AZ: 'CW 042020',
      DC: 'CW 042020',
      IA: 'CW 042020',
      IL: 'CW 042020',
      IN: 'CW 042020',
      KY: 'CW 042020',
      LA: 'LA 072022',
      MD: 'CW 042020',
      MI: 'MI 052021',
      MO: 'CW 042020',
      MS: 'CW 042020',
      MT: 'CW 042020',
      ND: 'CW 042020',
      NE: 'CW 042020',
      NM: 'CW 042020',
      OH: 'CW 042020',
      OK: 'OK 022021',
      OR: 'CW 042020',
      PA: 'PA 042021',
      TX: 'CW 042020',
      UT: 'CW 042020',
      WI: 'CW 042020',
      WV: 'CW 042020'
    },
    docId: {
      AZ: 'CW 122018',
      CO: 'CW 122018',
      ID: 'CW 072021',
      IL: 'IL 122018',
      KS: 'CW 122018',
      MO: 'CW 122018',
      OH: 'CW 122018',
      SD: 'CW 072021',
      TN: 'CW 072021',
      TX: 'CW 122018',
      WY: 'CW 072021'
    }
  },
  H_ANML: {
    id: 'H_ANML',
    bixDocId: {
      AK: 'AK 022022',
      AR: 'CW 042020',
      AZ: 'CW 042020',
      DC: 'CW 042020',
      GA: 'CW 042020',
      IL: 'CW 042020',
      IN: 'CW 042020',
      KY: 'CW 042020',
      LA: 'CW 042020',
      MD: 'CW 042020',
      MI: 'MI 052021',
      MO: 'CW 042020',
      MS: 'CW 042020',
      MT: 'CW 042020',
      NE: 'CW 042020',
      NM: 'CW 042020',
      OH: 'CW 042020',
      OK: 'OK 022021',
      OR: 'CW 042020',
      PA: 'PA 042021',
      TX: 'CW 042020',
      UT: 'CW 042020',
      WI: 'CW 042020',
      WV: 'CW 042020'
    },
    docId: {
      AZ: 'CW 122018',
      CO: 'CW 122018',
      ID: 'CW 072021',
      IL: 'CW 122018',
      KS: 'CW 122018',
      MA: 'CW 072021',
      MO: 'CW 122018',
      OH: 'CW 122018',
      SD: 'CW 072021',
      TN: 'CW 072021',
      TX: 'CW 122018',
      WY: 'CW 072021'
    },
    name: 'Animal Liability Limitation Endorsement'
  },
  H_BMTF: {
    id: 'H_BMTF',
    bixDocId: {
      AK: 'CW 082021',
      AR: 'CW 082021',
      AZ: 'CW 042020',
      DC: 'DC 032022',
      GA: 'CW 042020',
      IA: 'CW 082021',
      IL: 'CW 082021',
      IN: 'CW 042020',
      KY: 'CW 082021',
      LA: 'LA 052022',
      MD: 'CW 042020',
      MI: 'CW 042020',
      MO: 'CW 042020',
      MS: 'MS 052022',
      MT: 'CW 082021',
      ND: 'CW 082021',
      NE: 'CW 042020',
      NM: 'CW 042020',
      OH: 'CW 042020',
      OK: 'OK 022021',
      OR: 'CW 082021',
      PA: 'CW 042020',
      TX: 'CW 042020',
      UT: 'CW 082021',
      WI: 'CW 042020',
      WV: 'CW 082021'
    },
    docId: {
      AZ: 'CW 122018',
      CO: 'CW 122018',
      ID: 'CW 082021',
      IL: 'CW 122018',
      KS: 'CW 122018',
      MA: 'CW 082021',
      ME: 'CW 082021',
      MO: 'CW 122018',
      NH: 'CW 082021',
      OH: 'CW 122018',
      SD: 'CW 082021',
      TN: 'CW 082021',
      TX: 'CW 122018',
      VT: 'CW 082021',
      WY: 'CW 082021'
    },
    name: 'Building Materials Theft Endorsement'
  },
  H_CAMS: {
    id: 'H_CAMS',
    bixDocId: {
      AK: 'CW 042020',
      AR: 'CW 042020',
      AZ: 'CW 042020',
      DC: 'CW 042020',
      GA: 'CW 042020',
      IA: 'CW 042020',
      IL: 'CW 042020',
      IN: 'CW 042020',
      KY: 'CW 042020',
      LA: 'CW 042020',
      MD: 'CW 042020',
      MI: 'CW 042020',
      MO: 'CW 042020',
      MS: 'CW 042020',
      MT: 'CW 042020',
      ND: 'CW 042020',
      NE: 'CW 042020',
      NM: 'CW 042020',
      OH: 'CW 042020',
      OK: 'OK 022021',
      OR: 'CW 042020',
      PA: 'PA 042021',
      TX: 'CW 042020',
      UT: 'CW 042020',
      WI: 'CW 042020',
      WV: 'CW 042020'
    },
    docId: {
      AZ: 'CW 122018',
      CO: 'CW 122018',
      ID: 'CW 072021',
      IL: 'CW 122018',
      KS: 'CW 122018',
      MA: 'CW 072021',
      ME: 'CW 072021',
      MO: 'CW 122018',
      NH: 'CW 072021',
      OH: 'CW 122018',
      SD: 'CW 072021',
      TN: 'CW 072021',
      TX: 'CW 122018',
      VT: 'CW 072021',
      WY: 'CW 072021'
    },
    name: 'Additional Coverage On Cameras'
  },
  H_CONS: {
    id: 'H_CONS',
    bixDocId: {
      AK: 'CW 042020',
      AR: 'CW 042020',
      AZ: 'CW 042020',
      DC: 'CW 042020',
      GA: 'CW 042020',
      IA: 'CW 042020',
      IL: 'CW 042020',
      IN: 'CW 042020',
      KY: 'CW 042020',
      LA: 'CW 042020',
      MD: 'CW 042020',
      MI: 'CW 042020',
      MO: 'CW 042020',
      MS: 'CW 042020',
      MT: 'CW 042020',
      NE: 'CW 042020',
      NM: 'CW 042020',
      OH: 'CW 042020',
      OK: 'OK 022021',
      OR: 'CW 042020',
      PA: 'PA 042021',
      TX: 'CW 042020',
      UT: 'CW 042020',
      WI: 'CW 042020',
      WV: 'CW 042020'
    },
    docId: {
      AZ: 'CW 122018',
      CO: 'CW 122018',
      ID: 'CW 072021',
      IL: 'CW 122018',
      KS: 'CW 122018',
      MA: 'CW 072021',
      ME: 'CW 072021',
      MO: 'CW 122018',
      NH: 'CW 072021',
      OH: 'CW 122018',
      SD: 'CW 072021',
      TN: 'CW 072021',
      TX: 'CW 122018',
      VT: 'CW 072021',
      WY: 'CW 072021'
    },
    name: 'Contract of Sale Clause'
  },
  H_DCAR: {
    id: 'H_DCAR',
    name: 'Home Day Care Endorsement',
    bixDocId: {
      AK: 'CW 082021',
      AR: 'CW 082021',
      AZ: 'CW 042020',
      DC: 'CW 082021',
      GA: 'CW 042020',
      IA: 'CW 082021',
      IL: 'CW 082021',
      IN: 'CW 042020',
      KY: 'CW 082021',
      LA: 'CW 082021',
      MD: 'MD 092020',
      MI: 'CW 042020',
      MO: 'CW 042020',
      MS: 'CW 042020',
      MT: 'CW 082021',
      ND: 'CW 082021',
      NE: 'CW 042020',
      NM: 'NM 072021',
      OH: 'CW 042020',
      OK: 'OK 022021',
      OR: 'OR 122021',
      PA: 'PA 042021',
      TX: 'TX 092020',
      UT: 'CW 082021',
      WI: 'CW 042020',
      WV: 'WV 082021'
    },
    docId: {
      AL: 'AL 032021',
      AZ: 'CW 122018',
      CO: 'CW 122018',
      ID: 'CW 102021',
      IL: 'CW 122018',
      MA: 'CW 102021',
      ME: 'ME 032022',
      MO: 'CW 122018',
      NH: 'CW 102021',
      OH: 'CW 122018',
      SD: 'CW 102021',
      TN: 'CW 102021',
      TX: 'TX 122019',
      VA: 'VA 082020',
      VT: 'VT 012022',
      WY: 'CW 102021'
    }
  },
  H_DCOC: {
    id: 'H_DCOC',
    bixDocId: {
      AK: 'CW 082021',
      AR: 'CW 082021',
      AZ: 'CW 042020',
      DC: 'CW 082021',
      GA: 'CW 042020',
      IA: 'CW 082021',
      IL: 'CW 082021',
      IN: 'CW 042020',
      KY: 'CW 082021',
      LA: 'CW 082021',
      MD: 'CW 042020',
      MO: 'CW 042020',
      MS: 'CW 042020',
      MT: 'MT 102021',
      ND: 'CW 082021',
      NE: 'CW 042020',
      NM: 'CW 042020',
      OH: 'CW 042020',
      OK: 'OK 022021',
      OR: 'CW 082021',
      PA: 'PA 042021',
      TX: 'CW 042020',
      UT: 'CW 082021',
      WI: 'CW 042020',
      WV: 'CW 042020'
    },
    docId: {
      AZ: 'CW 122018',
      CO: 'CO 102020',
      ID: 'CW 102021',
      IL: 'CW 122018',
      KS: 'CW 122018',
      MA: 'CW 102021',
      ME: 'CW 102021',
      MO: 'CW 122018',
      OH: 'CW 122018',
      NH: 'CW 102021',
      SD: 'CW 102021',
      TN: 'CW 102021',
      TX: 'CW 122018',
      VT: 'CW 102021',
      WY: 'CW 102021'
    },
    name: 'Dwelling in the Course of Construction Endorsement'
  },
  H_DPRO: {
    id: 'H_DPRO',
    bixDocId: {
      AK: 'CW 042020',
      AR: 'CW 042020',
      AZ: 'CW 042020',
      GA: 'CW 042020',
      IL: 'CW 042020',
      IN: 'CW 042020',
      KY: 'CW 042020',
      LA: 'CW 042020',
      MD: 'CW 042020',
      MI: 'CW 042020',
      MO: 'CW 042020',
      MS: 'CW 042020',
      MT: 'CW 042020',
      ND: 'CW 042020',
      NE: 'CW 042020',
      NM: 'CW 042020',
      OH: 'CW 042020',
      OK: 'OK 022021',
      OR: 'CW 042020',
      PA: 'PA 042021',
      TX: 'CW 042020',
      UT: 'CW 042020',
      WI: 'CW 042020',
      WV: 'CW 042020'
    },
    docId: {
      AZ: 'CW 122018',
      CO: 'CW 122018',
      ID: 'CW 072021',
      IL: 'CW 122018',
      KS: 'CW 122018',
      MA: 'CW 072021',
      ME: 'CW 072021',
      MO: 'CW 122018',
      NH: 'CW 072021',
      OH: 'CW 122018',
      SD: 'CW 072021',
      TN: 'CW 072021',
      TX: 'CW 122018',
      VT: 'CW 072021',
      WY: 'CW 072021'
    },
    name: 'Damage To Property Of Others'
  },
  H_ELDR: {
    id: 'H_ELDR',
    name: 'Electronic Data Recovery Expense Endorsement',
    bixDocId: {
      AK: 'CW 082021',
      AR: 'CW 082021',
      AZ: 'CW 042020',
      DC: 'CW 082021',
      GA: 'CW 042020',
      IA: 'CW 082021',
      IL: 'CW 082021',
      IN: 'CW 042020',
      KY: 'CW 082021',
      LA: 'CW 082021',
      MD: 'CW 042020',
      MI: 'MI 052022',
      MO: 'MO 072020',
      MS: 'CW 042020',
      MT: 'CW 082021',
      ND: 'CW 082021',
      NE: 'CW 042020',
      NM: 'CW 042020',
      OH: 'CW 042020',
      OK: 'OK 022021',
      OR: 'CW 082021',
      PA: 'PA 042021',
      TX: 'CW 042020',
      UT: 'CW 082021',
      WI: 'CW 042020',
      WV: 'CW 042020'
    },
    docId: {
      AZ: 'CW 122018',
      CO: 'CW 122018',
      IL: 'CW 122018',
      KS: 'CW 122018',
      MA: 'CW 072021',
      MO: 'MO 122018',
      OH: 'CW 122018',
      TN: 'CW 072021',
      VA: 'VA 042020'
    }
  },
  H_EPHP: {
    id: 'H_EPHP',
    name: 'Home Sharing Protection Extended Premesis',
    bixDocId: {
      AK: 'CW 082021',
      AR: 'CW 082021',
      AZ: 'CW 042020',
      DC: 'CW 082021',
      GA: 'CW 042020',
      IA: 'CW 082021',
      IL: 'CW 082021',
      IN: 'CW 042020',
      KY: 'CW 082021',
      LA: 'CW 082021',
      MD: 'CW 042020',
      MI: 'CW 042020',
      MO: 'CW 042020',
      MS: 'CW 042020',
      MT: 'CW 082021',
      NE: 'CW 042020',
      NM: 'CW 082021',
      OH: 'CW 042020',
      OK: 'OK 022021',
      OR: 'CW 082021',
      PA: 'PA 042021',
      TX: 'TX 092020',
      UT: 'CW 082021',
      WI: 'CW 042020',
      WV: 'CW 042020'
    },
    docId: {
      AZ: 'CW 122018',
      CO: 'CW 122018',
      ID: 'CW 102021',
      IL: 'CW 122018',
      KS: 'CW 122018',
      MA: 'CW 102021',
      ME: 'CW 102021',
      MO: 'CW 122018',
      NH: 'CW 102021',
      OH: 'CW 122018',
      SD: 'CW 102021',
      TN: 'CW 102021',
      TX: 'TX 122019',
      VT: 'VT 022022',
      WY: 'CW 102021'
    }
  },
  H_EBSL: {
    id: 'H_EBSL',
    name: 'Equipment Breakdown and Service Line Coverage',
    bixDocId: {
      AK: 'AK 022022',
      AR: 'CW 022022',
      AZ: 'CW 012022',
      DC: 'CW 022022',
      IA: 'CW 022022',
      IL: 'CW 022022',
      IN: 'CW 022022',
      KY: 'CW 022022',
      LA: 'CW 022022',
      MD: 'CW 022022',
      MI: 'CW 022022',
      MO: 'CW 022022',
      MS: 'CW 012022',
      MT: 'CW 022022',
      ND: 'CW 022022',
      NE: 'CW 022022',
      NM: 'CW 022022',
      OH: 'CW 012022',
      OK: 'CW 022022',
      OR: 'CW 022022',
      PA: 'CW 022022',
      TX: {
        default: 'CW 022022',
        '2022-09-06': 'TX 062022'
      },
      UT: 'CW 022022',
      WI: 'CW 022022',
      WV: 'CW 022022'
    }
  },
  H_EBDC: {
    id: 'H_EBDC',
    name: 'Equipment Breakdown Coverage Endorsement',
    bixDocId: {
      AK: 'AK 022022',
      AR: 'CW 022022',
      DC: 'CW 022022',
      IA: 'CW 022022',
      IL: 'CW 022022',
      LA: 'CW 022022',
      MS: 'CW 012022',
      MT: 'CW 022022',
      OR: 'CW 022022'
    }
  },
  H_EXPI: {
    id: 'H_EXPI',
    name: 'Notice of Changes to Your Policy',
    bixDocId: {
      OR: 'OR 102022'
    }
  },
  H_OCPC: {
    id: 'H_OCPC',
    name: 'Outline of Coverage'
  },
  H_PBOR: {
    id: 'H_PBOR',
    name: 'Policyholder Bill of Rights'
  },
  H_EXTP: {
    id: 'H_EXTP',
    name: 'Extended Premises Endorsement',
    bixDocId: {
      AK: 'AK 032022',
      AR: 'CW 082021',
      AZ: 'CW 042020',
      DC: 'CW 082021',
      GA: 'CW 042020',
      IA: 'CW 082021',
      IL: 'CW 082021',
      IN: 'CW 042020',
      KY: 'CW 082021',
      LA: 'CW 082021',
      MD: 'CW 042020',
      MI: 'CW 042020',
      MO: 'CW 042020',
      MS: 'CW 042020',
      MT: 'CW 082021',
      ND: 'CW 082021',
      NE: 'CW 042020',
      NM: 'CW 042020',
      OH: 'CW 042020',
      OK: 'OK 022021',
      OR: 'CW 082021',
      PA: 'PA 042021',
      TX: 'TX 092020',
      UT: 'CW 082021',
      WI: 'CW 042020',
      WV: 'WV 082021'
    },
    docId: {
      AZ: 'CW 122018',
      CO: 'CO 102020',
      ID: 'CW 102021',
      IL: 'CW 122018',
      KS: 'CW 122018',
      MA: 'MA 082021',
      ME: 'CW 102021',
      MO: 'CW 122018',
      NH: 'CW 102021',
      OH: 'CW 122018',
      SD: 'CW 102021',
      TN: 'CW 102021',
      TX: 'TX 122019',
      VA: 'VA 042020',
      VT: 'CW 102021',
      WY: 'CW 102021'
    }
  },
  H_FLOD: {
    id: 'H_FLOD',
    name: 'Flood Notice',
    bixDocId: {
      AZ: 'CW 042020',
      IN: 'CW 042020',
      KY: 'CW 042020',
      MD: 'CW 042020',
      MI: 'CW 042020',
      MO: 'CW 042020',
      NE: 'CW 042020',
      NM: 'CW 042020',
      OH: 'CW 042020',
      OK: 'OK 022021',
      PA: 'PA 042021',
      TX: 'TX 072021',
      UT: 'CW 042020',
      WI: 'CW 042020',
      WV: 'CW 042020'
    },
    docId: {
      AZ: 'CW 122018',
      CO: 'CW 122018',
      IL: 'CW 122018',
      KS: 'CW 122018',
      MO: 'CW 122018',
      OH: 'CW 122018',
      TN: 'CW 102021',
      TX: 'TX 012020'
    }
  },
  H_HPAE: {
    id: 'H_HPAE',
    name: 'Host Protection Amendatory Endorsement',
    bixDocId: {
      AK: 'AK 032022',
      AR: 'CW 082021',
      AZ: 'CW 042020',
      DC: 'CW 082021',
      GA: 'CW 042020',
      IA: 'CW 082021',
      IL: 'CW 082021',
      IN: 'CW 042020',
      KY: 'CW 082021',
      LA: 'CW 082021',
      MD: 'CW 042020',
      MI: 'CW 042020',
      MO: 'CW 042020',
      MS: 'CW 042020',
      MT: 'CW 082021',
      ND: 'CW 082021',
      NE: 'CW 042020',
      NM: 'CW 082021',
      OH: 'CW 042020',
      OK: 'OK 022021',
      OR: 'CW 082021',
      PA: 'PA 042021',
      TX: 'TX 092020',
      UT: 'CW 082021',
      WI: 'CW 042020',
      WV: 'CW 042020'
    },
    docId: {
      AZ: 'CW 122018',
      CO: 'CW 122018',
      ID: 'CW 102021',
      IL: 'CW 122018',
      KS: 'CW 122018',
      MA: 'CW 102021',
      ME: 'CW 102021',
      MO: 'CW 122018',
      NH: 'CW 102021',
      OH: 'CW 122018',
      SD: 'CW 102021',
      TN: 'CW 102021',
      TX: 'TX 122019',
      VT: 'VT 022022',
      WY: 'CW 102021'
    }
  },
  H_IB10: {
    id: 'H_IB10',
    name: 'Increased Building Structure Protection 10% Endorsement',
    bixDocId: {
      AK: 'CW 042020',
      AZ: 'CW 042020',
      DC: 'CW 042020',
      GA: 'CW 042020',
      IL: 'CW 042020',
      IN: 'CW 042020',
      KY: 'CW 042020',
      LA: 'CW 042020',
      MD: 'CW 042020',
      MI: 'CW 042020',
      MO: 'CW 042020',
      MS: 'CW 042020',
      MT: 'CW 042020',
      ND: 'CW 042020',
      NE: 'CW 042020',
      NM: 'CW 042020',
      OH: 'CW 042020',
      OK: 'OK 022021',
      OR: 'CW 042020',
      PA: 'PA 042021',
      TX: 'TX 092020',
      UT: 'CW 042020',
      WI: 'CW 042020',
      WV: 'WV 082021'
    },
    docId: {
      AZ: 'CW 122018',
      CO: 'CO 102020',
      ID: 'CW 072021',
      IL: 'CW 122018',
      KS: 'CW 122018',
      MA: 'CW 072021',
      ME: 'CW 072021',
      MO: 'CW 122018',
      NH: 'CW 072021',
      OH: 'CW 122018',
      SD: 'CW 072021',
      TN: 'CW 072021',
      TX: 'TX 122019',
      VA: 'VA 042020',
      VT: 'CW 072021',
      WY: 'CW 072021'
    }
  },
  H_IB20: {
    id: 'H_IB20',
    name: 'Increased Building Structure Protection 20% Endorsement',
    bixDocId: {
      AK: 'CW 042020',
      AZ: 'CW 042020',
      GA: 'CW 042020',
      IA: 'CW 042020',
      IL: 'CW 042020',
      IN: 'CW 042020',
      KY: 'CW 042020',
      LA: 'CW 042020',
      MD: 'CW 042020',
      MI: 'CW 042020',
      MO: 'CW 042020',
      MS: 'CW 042020',
      MT: 'CW 042020',
      ND: 'CW 042020',
      NE: 'CW 042020',
      NM: 'CW 042020',
      OH: 'CW 042020',
      OK: 'OK 022021',
      OR: 'CW 042020',
      PA: 'PA 042021',
      TX: 'TX 092020',
      UT: 'CW 042020',
      WI: 'CW 042020',
      WV: 'WV 082021'
    },
    docId: {
      AZ: 'CW 122018',
      CO: 'CO 102020',
      ID: 'CW 072021',
      IL: 'CW 122018',
      KS: 'CW 122018',
      MA: 'CW 072021',
      ME: 'CW 072021',
      MO: 'CW 122018',
      NH: 'CW 072021',
      OH: 'CW 122018',
      SD: 'CW 072021',
      TN: 'CW 072021',
      TX: 'TX 122019',
      VA: 'VA 042020',
      VT: 'CW 072021',
      WY: 'CW 072021'
    }
  },
  H_IB40: {
    id: 'H_IB40',
    name: 'Increased Building Structure Protection 40% Endorsement',
    bixDocId: {
      AK: 'CW 042020',
      AR: 'CW 042020',
      AZ: 'CW 042020',
      GA: 'CW 042020',
      IA: 'CW 042020',
      IL: 'CW 042020',
      IN: 'CW 042020',
      KY: 'CW 042020',
      LA: 'CW 042020',
      MD: 'CW 042020',
      MI: 'CW 042020',
      MO: 'CW 042020',
      MS: 'CW 042020',
      MT: 'CW 042020',
      ND: 'CW 042020',
      NE: 'CW 042020',
      NM: 'CW 042020',
      OH: 'CW 042020',
      OK: 'OK 022021',
      OR: 'CW 042020',
      PA: 'PA 042021',
      TX: 'TX 092020',
      UT: 'CW 042020',
      WI: 'CW 042020',
      WV: 'WV 082021'
    },
    docId: {
      AZ: 'CW 122018',
      CO: 'CO 102020',
      ID: 'CW 072021',
      IL: 'CW 122018',
      KS: 'CW 122018',
      MA: 'CW 072021',
      ME: 'CW 072021',
      MO: 'CW 122018',
      NH: 'CW 072021',
      OH: 'CW 122018',
      SD: 'CW 072021',
      TN: 'CW 072021',
      TX: 'TX 122019',
      VA: 'VA 042020',
      WY: 'CW 072021'
    }
  },
  H_LOSP: {
    id: 'H_LOSP',
    bixDocId: {
      AK: 'CW 042020',
      AR: 'CW 042020',
      AZ: 'CW 042020',
      DC: 'CW 042020',
      GA: 'CW 042020',
      IA: 'CW 042020',
      IL: 'CW 042020',
      IN: 'CW 042020',
      KY: 'CW 042020',
      LA: 'CW 042020',
      MD: 'CW 042020',
      MI: 'CW 042020',
      MO: 'CW 042020',
      MS: 'CW 042020',
      MT: 'CW 042020',
      ND: 'CW 042020',
      NE: 'CW 042020',
      NM: 'CW 042020',
      OH: 'CW 042020',
      OK: 'OK 022021',
      OR: 'CW 042020',
      PA: 'PA 042021',
      TX: 'CW 042020',
      UT: 'CW 042020',
      WI: 'CW 042020',
      WV: 'CW 042020'
    },
    docId: {
      AL: 'CW 122018',
      AZ: 'CW 122018',
      CO: 'CW 122018',
      ID: 'CW 072021',
      IL: 'CW 122018',
      KS: 'CW 122018',
      MA: 'CW 072021',
      ME: 'CW 072021',
      MO: 'CW 122018',
      NH: 'CW 072021',
      OH: 'CW 122018',
      SD: 'CW 072021',
      TN: 'CW 072021',
      TX: 'TX 122019',
      VT: 'CW 072021',
      WY: 'CW 072021'
    },
    name: 'Loss Payable Clause'
  },
  H_MINE: {
    id: 'H_MINE',
    name: 'Mine Subsidence Coverage Endorsement',
    bixDocId: {
      IL: 'IL 072022',
      IN: 'IN 112020',
      KY: 'KY 092021',
      OH: 'OH 042020',
      WV: 'WV 082021'
    },
    docId: {
      IL: 'IL 122018',
      OH: 'CW 122018'
    }
  },
  H_MNSF: {
    id: 'H_MNSF',
    name: 'Mine Subsidence Selection Rejection Form',
    bixDocId: {
      IN: 'IN 122020',
      KY: 'KY 092021'
    }
  },
  H_MUSI: {
    id: 'H_MUSI',
    bixDocId: {
      AK: 'CW 042020',
      AR: 'CW 042020',
      AZ: 'CW 042020',
      DC: 'CW 042020',
      GA: 'CW 042020',
      IA: 'CW 042020',
      IL: 'CW 042020',
      IN: 'CW 042020',
      KY: 'CW 042020',
      LA: 'CW 042020',
      MD: 'CW 042020',
      MI: 'CW 042020',
      MO: 'CW 042020',
      MS: 'CW 042020',
      MT: 'CW 042020',
      ND: 'CW 042020',
      NE: 'CW 042020',
      NM: 'CW 042020',
      OH: 'CW 042020',
      OK: 'OK 022021',
      OR: 'CW 042020',
      PA: 'PA 042021',
      TX: 'CW 042020',
      UT: 'CW 042020',
      WI: 'CW 042020',
      WV: 'CW 042020'
    },
    docId: {
      AZ: 'CW 122018',
      CO: 'CW 122018',
      ID: 'CW 072021',
      IL: 'CW 122018',
      KS: 'CW 122018',
      MA: 'CW 072021',
      ME: 'CW 072021',
      MO: 'CW 122018',
      NH: 'CW 072021',
      OH: 'CW 122018',
      SD: 'CW 072021',
      TN: 'CW 072021',
      TX: 'TX 122019',
      VT: 'CW 072021',
      WY: 'CW 072021'
    },
    name: 'Additional Coverage On Musical Instruments'
  },
  H_NRCE: {
    id: 'H_NRCE',
    bixDocId: {
      AZ: 'CW 042020',
      IN: 'CW 042020',
      KY: 'CW 042020',
      MD: 'CW 042020',
      MI: 'CW 042020',
      MO: 'CW 042020',
      NE: 'CW 042020',
      NM: 'CW 042020',
      OH: 'CW 042020',
      OK: 'CW 042020',
      PA: 'CW 042020',
      TX: 'CW 042020',
      UT: 'CW 042020',
      WI: 'CW 042020',
      WV: 'CW 042020'
    },
    docId: {
      AZ: 'CW 122018',
      CO: 'CO 102020',
      IL: 'CW 122018',
      KS: 'CW 122018',
      MO: 'CW 122018',
      OH: 'CW 122018',
      TN: 'CW 102021',
      TX: 'TX 122019'
    },
    name: 'Important Notice'
  },
  H_SRCE: {
    id: 'H_SRCE',
    bixDocId: {
      AZ: 'CW 042020',
      IN: 'CW 042020',
      KY: 'CW 042020',
      MD: 'CW 042020',
      MI: 'CW 042020',
      MO: 'CW 042020',
      NE: 'CW 042020',
      NM: 'CW 042020',
      OH: 'CW 042020',
      OK: 'CW 042020',
      PA: 'CW 042020',
      TX: 'CW 042020',
      UT: 'CW 042020',
      WI: 'CW 042020',
      WV: 'CW 042020'
    },
    docId: {
      AZ: 'CW 122018',
      CO: 'CO 102020',
      IL: 'CW 122018',
      KS: 'CW 122018',
      MO: 'CW 122018',
      OH: 'CW 122018',
      TN: 'CW 102021',
      TX: 'TX 122019'
    },
    name: 'Important Notice'
  },
  H_IRCE: {
    id: 'H_IRCE',
    bixDocId: {
      AZ: 'CW 042020',
      IN: 'CW 042020',
      KY: 'CW 042020',
      MD: 'CW 042020',
      MI: 'CW 042020',
      MO: 'CW 042020',
      NE: 'CW 042020',
      NM: 'CW 042020',
      OH: 'CW 042020',
      OK: 'CW 042020',
      PA: 'CW 042020',
      TX: 'CW 042020',
      UT: 'CW 042020',
      WI: 'CW 042020',
      WV: 'CW 042020'
    },
    docId: {
      TN: 'CW 102021'
    },
    name: 'Important Notice'
  },
  H_RCEL: {
    id: 'H_RCEL',
    name: 'Replacement Cost Estimate Letter'
  },
  H_OFAC: {
    id: 'H_OFAC',
    bixDocId: {
      AZ: 'CW 042020',
      IN: 'CW 042020',
      KY: 'CW 042020',
      MI: 'CW 042020',
      MO: 'CW 042020',
      NE: 'CW 042020',
      NM: 'CW 042020',
      OH: 'CW 042020',
      OK: 'CW 042020',
      PA: 'CW 042020',
      TX: 'CW 042020',
      UT: 'CW 042020',
      WI: 'CW 042020',
      WV: 'CW 042020'
    },
    name: 'Assets Control Advisory Notice'
  },
  H_OMSI: {
    id: 'H_OMSI',
    bixDocId: {
      IN: 'IN 112020',
      OH: 'OH 042020',
      WV: 'WV 092021'
    },
    name: 'Mine Subsidence Renewal Offer'
  },
  H_PJAK: {
    id: 'H_PJAK',
    name: 'Home Policy Jacket',
    bixDocId: {
      AK: 'AK 062022',
      AR: 'AR 072022',
      AZ: 'AZ 012021',
      DC: 'DC 122022',
      GA: 'GA 052021',
      FL: 'FL 042021',
      IA: 'IA 022022',
      IL: 'IL 062022',
      IN: 'IN 122020',
      KY: 'KY 112021',
      LA: 'LA 112021',
      MD: 'MD 032021',
      MI: 'MI 052021',
      MO: 'MO 112021',
      MS: 'MS 102021',
      MT: 'MT 042022',
      ND: 'ND 122021',
      NE: 'NE 072021',
      NM: 'NM 072021',
      OH: 'OH 042020',
      OK: 'OK 032021',
      OR: 'OR 122021',
      PA: 'PA 062021',
      TX: 'TX 112020',
      UT: {
        default: 'UT 102021',
        '2021-12-06': 'UT 112021'
      },
      WI: 'WI 052021',
      WV: 'WV 052021'
    },
    docId: {
      AL: 'AL 032021',
      AZ: 'AZ 102020',
      CO: 'CO 102020',
      ID: 'ID 122021',
      IL: 'IL 102020',
      KS: 'KS 042021',
      MA: 'MA 092022',
      ME: 'ME 062022',
      MO: 'CW 082019',
      NH: 'NH 012022',
      OH: 'CW 102020',
      SC: 'SC 062021',
      SD: 'SD 012022',
      TN: 'TN 112021',
      TX: 'TX 102020',
      VA: 'VA 072021',
      VT: 'VT 052022',
      WY: 'WY 032022'
    }
  },
  H_PLCY: {
    id: 'H_PLCY',
    name: 'Homeowners Policy',
    bixDocId: {
      AK: 'AK 052022',
      AR: 'AR 072022',
      AZ: 'CW 042020',
      DC: 'DC 122022',
      GA: 'GA 092021',
      IA: 'IA 022022',
      IL: {
        default: 'IL 062022',
        '2023-03-31': 'IL 102022'
      },
      IN: 'IN 122020',
      KY: 'KY 092021',
      LA: 'LA 092022',
      MD: 'MD 032021',
      MI: 'MI 052021',
      MO: 'MO 122021',
      MS: 'MS 062022',
      MT: 'MT 042022',
      ND: 'ND 122021',
      NE: 'NE 072021',
      NM: 'NM 072021',
      OH: 'CW 042020',
      OK: 'OK 022021',
      OR: 'OR 022022',
      PA: 'PA 062021',
      TX: 'TX 072021',
      UT: {
        default: 'UT 102021',
        '2021-12-06': 'UT 112021'
      },
      WI: 'WI 052021',
      WV: 'WV 102021'
    },
    docId: {
      AL: 'AL 032021',
      AZ: 'CW 122018',
      CO: {
        default: 'CO 072020',
        '2022-08-08': 'CO 072022'
      },
      ID: 'ID 112021',
      IL: 'CW 122018',
      KS: 'KS 052021',
      MA: 'MA 082022',
      ME: 'ME 062022',
      MO: 'CW 122018',
      NH: 'NH 022022',
      OH: 'CW 122018',
      SC: 'SC 062021',
      SD: 'SD 012022',
      TN: 'TN 112021',
      TX: 'TX 012020',
      VA: 'VA 092021',
      VT: 'VT 052022',
      WY: 'WY 032022'
    }
  },
  H_INVC: {
    id: 'H_INVC',
    name: 'Escrow Invoice'
  },

  H_PUMB: {
    id: 'H_PUMB',
    name: 'Personal Umbrella Liability Endorsement',
    bixDocId: {
      AK: 'CW 082021',
      AR: 'AR 072022',
      AZ: 'CW 042020',
      DC: 'DC 022022',
      GA: 'GA 092021',
      IA: 'IA 022022',
      IL: 'IL 072022',
      IN: 'IN 121820',
      KY: 'KY 092021',
      LA: 'LA 062022',
      MD: 'MD 102021',
      MI: 'MI 042021',
      MO: {
        default: 'MO 072020',
        '2022-05-17': 'MO 122021'
      },
      MS: 'MS 102021',
      MT: 'MT 102022',
      ND: 'ND 112021',
      NE: 'NE 072021',
      NM: 'NM 122021',
      OH: {
        default: 'CW 042020',
        '2022-09-09': 'OH 082022'
      },
      OK: 'OK 022021',
      OR: {
        default: 'OR 012022',
        '2022-11-04': 'OR 112022'
      },
      PA: 'PA 102021',
      SC: 'SC 062021',
      TX: 'TX 072021',
      UT: 'UT 092021',
      WI: 'WI 102020'
    },
    docId: {
      AL: 'AL 032021',
      AZ: 'CW 122018',
      ID: {
        default: 'ID 112021',
        '2022-12-05': 'ID 092022'
      },
      IL: 'IL 122018',
      KS: 'KS 032021',
      ME: 'ME 062022',
      MO: 'MO 122018',
      NH: {
        default: 'NH 012022',
        '2022-12-05': 'NH 082022'
      },
      OH: 'CW 122018',
      SC: 'SC 062021',
      SD: {
        default: 'SD 012022',
        '2022-12-05': 'SD 082022'
      },
      TN: {
        default: 'TN 072021',
        '2022-12-05': 'TN 082022'
      },
      TX: 'TX 012020',
      VA: 'VA 082021',
      VT: 'VT 042022',
      WY: {
        default: 'WY 032022',
        '2022-12-05': 'WY 082022'
      }
    }
  },
  H_RENI: {
    id: 'H_RENI',
    bixDocId: {
      AK: 'AK 052022',
      AR: 'AR 072022',
      AZ: 'CW 042020',
      DC: 'CW 042020',
      GA: 'CW 042020',
      IA: 'CW 042020',
      IL: 'CW 042020',
      IN: 'CW 042020',
      KY: 'CW 042020',
      LA: 'LA 112021',
      MD: 'CW 042020',
      MI: 'CW 042020',
      MO: 'CW 042020',
      MS: 'CW 042020',
      MT: 'CW 042020',
      ND: 'CW 042020',
      NE: 'CW 042020',
      NM: 'CW 042020',
      OH: 'CW 042020',
      OK: 'OK 022021',
      OR: 'CW 042020',
      PA: 'PA 042021',
      TX: 'CW 042020',
      UT: 'CW 042020',
      WI: 'CW 042020',
      WV: 'WV 082021'
    },
    docId: {
      AZ: 'CW 122018',
      CO: 'CO 102020',
      ID: 'CW 072021',
      IL: 'CW 122018',
      KS: 'CW 122018',
      MA: 'MA 082021',
      ME: 'CW 072021',
      MO: 'CW 122018',
      NH: 'NH 012022',
      OH: 'CW 122018',
      SD: 'CW 072021',
      TN: 'CW 072021',
      TX: 'CW 122018',
      VT: 'CW 072021',
      WY: 'CW 072021'
    },
    name: 'Fair Rental Income Endorsement'
  },
  H_RCOM: {
    id: 'H_RCOM',
    name: 'Roof Surfaces Notice',
    bixDocId: {
      AZ: 'CW 042020',
      IN: 'CW 112020',
      KY: 'CW 112020',
      MD: 'CW 122018',
      MI: 'CW 122018',
      MO: 'CW 122018',
      NE: 'CW 122018',
      NM: 'CW 122018',
      OH: 'CW 082020',
      OK: 'CW 122018',
      PA: 'CW 122018',
      TX: 'CW 120218',
      UT: 'CW 122018',
      WI: 'CW 122018',
      WV: 'CW 122018'
    },
    docId: {
      AZ: 'CW 122018',
      ID: 'CW 102021',
      IL: 'CW 122018',
      KS: 'CW 122018',
      MO: 'CW 122018',
      OH: 'CW 122018',
      TN: 'CW 102021',
      TX: 'CW 122018'
    }
  },
  H_ROFE: {
    id: 'H_ROFE',
    name: 'Roof Surfaces Extended Coverage Endorsement',
    bixDocId: {
      AK: 'CW 042020',
      AR: 'CW 042020',
      AZ: 'CW 042020',
      GA: 'CW 042020',
      IA: 'CW 042020',
      IL: 'CW 042020',
      IN: 'CW 042020',
      KY: 'CW 042020',
      LA: 'CW 042020',
      MD: 'MD 092020',
      MI: 'CW 042020',
      MO: 'CW 042020',
      MS: 'CW 042020',
      MT: 'CW 042020',
      NE: 'CW 042020',
      NM: 'CW 042020',
      OH: 'CW 042020',
      OK: 'OK 022021',
      OR: 'CW 042020',
      PA: 'PA 042021',
      TX: 'TX 092020',
      UT: 'CW 042020',
      WI: 'CW 042020',
      WV: 'WV 082021'
    },
    docId: {
      AZ: 'CW 122018',
      CO: 'CO 102020',
      ID: 'CW 072021',
      IL: 'CW 122018',
      KS: 'CW 122018',
      MA: 'CW 072021',
      ME: 'CW 072021',
      MO: 'CW 122018',
      OH: 'CW 122018',
      SD: 'CW 072021',
      TN: 'CW 072021',
      TX: 'TX 122019',
      WY: 'CW 072021'
    }
  },
  H_ROF1: {
    id: 'H_ROF1',
    bixDocId: {
      AZ: 'CW 042020',
      IN: 'CW 042020',
      KY: 'CW 042020',
      MI: 'CW 042020',
      MO: 'CW 042020',
      NE: 'CW 042020',
      NM: 'CW 042020',
      OH: 'CW 042020',
      OK: 'OK 022021',
      PA: 'PA 042021',
      TX: 'CW 042020',
      UT: 'CW 042020',
      WI: 'CW 042020',
      WV: 'CW 042020'
    },
    docId: {
      ID: 'CW 102021',
      TN: 'CW 102021'
    },
    name: ''
  },
  H_ROFM: {
    id: 'H_ROFM',
    bixDocId: {
      AK: 'CW 042020',
      AR: 'CW 042020',
      AZ: 'CW 042020',
      GA: 'CW 042020',
      IA: 'CW 042020',
      IL: 'CW 042020',
      IN: 'CW 042020',
      KY: 'CW 042020',
      LA: 'CW 042020',
      MD: 'CW 042020',
      MI: 'CW 042020',
      MO: 'CW 042020',
      MS: 'CW 042020',
      MT: 'CW 042020',
      NE: 'CW 042020',
      NM: 'CW 042020',
      OH: 'CW 042020',
      OK: 'OK 022021',
      OR: 'CW 042020',
      PA: 'PA 042021',
      TX: 'CW 042020',
      UT: 'CW 042020',
      WI: 'CW 042020',
      WV: 'CW 042020'
    },
    docId: {
      AZ: 'CW 122018',
      CO: 'CW 122018',
      ID: 'CW 072021',
      IL: 'CW 122018',
      KS: 'CW 122018',
      ME: 'CW 072021',
      MO: 'CW 122018',
      NH: 'CW 072021',
      OH: 'CW 122018',
      SD: 'CW 072021',
      TN: 'CW 072021',
      TX: 'CW 122018'
    },
    name: 'Metal Roof Surface Cosmetic Damage Exclusion'
  },
  H_ROFR: {
    id: 'H_ROFR',
    bixDocId: {
      AZ: 'CW 042020',
      IN: 'CW 042020',
      KY: 'CW 042020',
      MI: 'CW 042020',
      MO: 'CW 042020',
      NE: 'CW 042020',
      NM: 'CW 042020',
      OH: 'CW 042020',
      OK: 'OK 022021',
      PA: 'PA 042021',
      TX: 'CW 042020',
      UT: 'CW 042020',
      WI: 'CW 042020',
      WV: 'CW 042020'
    },
    docId: {
      AZ: 'CW 122018',
      CO: 'CW 122018',
      ID: 'CW 102021',
      IL: 'CW 122018',
      KS: 'CW 122018',
      MO: 'CW 122018',
      OH: 'CW 122018',
      TN: 'CW 102021',
      TX: 'CW 122018'
    },
    name: 'Metal Roof Surface Cosmetic Damage Exclusion'
  },
  H_ROOF: {
    id: 'H_ROOF',
    name: 'Roof Surfaces Endorsement',
    bixDocId: {
      AK: 'CW 082021',
      AR: 'CW 082021',
      AZ: 'CW 042020',
      GA: 'CW 042020',
      IA: 'CW 082021',
      IL: 'CW 082021',
      IN: 'CW 042020',
      KY: 'CW 082021',
      LA: 'CW 082021',
      MD: 'CW 042020',
      MI: 'CW 042020',
      MO: 'CW 042020',
      MS: 'CW 042020',
      MT: 'CW 082021',
      NE: 'CW 042020',
      NM: 'CW 042020',
      OH: 'CW 042020',
      OK: 'OK 022021',
      OR: 'CW 082021',
      PA: 'PA 042021',
      TX: 'TX 092020',
      UT: 'CW 082021',
      WI: 'CW 042020',
      WV: 'CW 042020'
    },
    docId: {
      AZ: 'CW 122018',
      ID: 'CW 072021',
      IL: 'CW 122018',
      KS: 'CW 122018',
      MA: 'CW 072021',
      ME: 'CW 072021',
      MO: 'CW 122018',
      OH: 'CW 122018',
      SD: 'CW 072021',
      TN: 'CW 072021',
      TX: 'TX 122019',
      WY: 'CW 072021'
    }
  },
  H_ROOS: {
    id: 'H_ROOS',
    bixDocId: {
      AZ: 'CW 042020',
      IN: 'CW 042020',
      KY: 'CW 042020',
      MD: 'CW 042020',
      MI: 'CW 042020',
      MO: 'CW 042020',
      NE: 'CW 042020',
      NM: 'CW 042020',
      OH: 'CW 042020',
      OK: 'CW 042020',
      PA: 'CW 042020',
      TX: 'CW 042020',
      UT: 'CW 042020',
      WI: 'CW 042020',
      WV: 'CW 042020'
    },
    docId: {
      AZ: 'CW 122018',
      ID: 'CW 102021',
      IL: 'CW 122018',
      KS: 'CW 122018',
      MO: 'CW 122018',
      OH: 'CW 122018',
      TN: 'CW 102021',
      TX: 'CW 122018'
    },
    name: 'Important Notice'
  },
  H_ROSE: {
    id: 'H_ROSE',
    bixDocId: {
      AZ: 'CW 112020',
      IN: 'CW 112020',
      KY: 'CW 112020',
      MD: 'CW 112020',
      MI: 'CW 112020',
      MO: 'CW 112020',
      NE: 'CW 112020',
      NM: 'CW 112020',
      OH: 'CW 112020',
      OK: 'CW 112020',
      PA: 'CW 112020',
      TX: 'CW 112020',
      UT: 'CW 112020',
      WI: 'CW 112020',
      WV: 'CW 112020'
    },
    docId: {
      ID: 'CW 102021',
      TN: 'CW 102021'
    },
    name: 'Important Notice'
  },
  H_SCOR: {
    id: 'H_SCOR',
    bixDocId: {
      AK: 'CW 042020',
      AR: 'CW 042020',
      AZ: 'CW 042020',
      DC: 'CW 042020',
      GA: 'CW 042020',
      IA: 'CW 042020',
      IL: 'CW 042020',
      IN: 'CW 042020',
      KY: 'CW 042020',
      LA: 'CW 042020',
      MD: 'CW 042020',
      MI: 'CW 042020',
      MO: 'CW 042020',
      MS: 'CW 042020',
      MT: 'CW 042020',
      ND: 'CW 042020',
      NE: 'CW 042020',
      NM: 'CW 042020',
      OH: 'CW 042020',
      OK: 'OK 022021',
      OR: 'CW 042020',
      PA: 'PA 042021',
      TX: 'CW 042020',
      UT: 'CW 042020',
      WI: 'CW 042020',
      WV: 'CW 042020'
    },
    docId: {
      AZ: 'CW 122018',
      ID: 'CW 072021',
      IL: 'CW 122018',
      KS: 'CW 122018',
      MA: 'MA 082021',
      ME: 'CW 072021',
      MO: 'CW 122018',
      NH: 'CW 072021',
      OH: 'CW 122018',
      SD: 'CW 072021',
      TN: 'CW 072021',
      TX: 'CW 122018',
      VT: 'CW 072021',
      WY: 'CW 072021'
    },
    name: 'Secondary Residence'
  },
  H_SFPP: {
    id: 'H_SFPP',
    bixDocId: {
      GA: 'GA 092021',
      NE: 'NE 072021',
      OK: 'OK 022021',
      OR: 'OR 012022',
      WV: 'WV 082021'
    },
    docId: {
      ID: 'ID 112021',
      ME: 'ME 012022'
    },
    name: 'Standard Fire Policy Provision'
  },

  H_SHCG: {
    id: 'H_SHCG',
    name: 'Sinkhole Loss Coverage - Florida',
    bixDocId: {
      FL: 'FL 012022'
    }
  },
  H_SPRO: {
    id: 'H_SPRO',
    name: 'Special Provisions - Florida',
    bixDocId: {
      FL: 'FL 022022'
    }
  },
  H_AAEN: {
    id: 'H_AAEN',
    name: 'Assigment Agreement Endorsement - Florida',
    bixDocId: {
      FL: 'FL 022022'
    }
  },
  H_LEAD: {
    id: 'H_LEAD',
    name: 'Lead Liability Coverage Endorsement',
    docId: {
      MA: 'MA 082021'
    }
  },
  H_LWDC: {
    id: 'H_LWDC',
    name: 'Limited Water Damage Coverage Endorsement',
    bixDocId: {
      FL: 'FL 022022'
    }
  },
  H_ACVR: {
    id: 'H_ACVR',
    name: 'Actual Cash Value Roof Surfaces Endorsement',
    bixDocId: {
      FL: 'FL 022022'
    }
  },
  H_PINJ: {
    id: 'H_PINJ',
    name: 'Personal Injury Coverage - Florida',
    bixDocId: {
      FL: 'FL 022022'
    }
  },
  H_LFMC: {
    id: 'H_LFMC',
    name: 'Limited Fungi, Mold, Wet, or Dry Rot, Or Bacteria Coverage Endorsement',
    bixDocId: {
      FL: 'FL 042021'
    }
  },
  H_PPEX: {
    id: 'H_PPEX',
    name: 'Personal Property Exclusion - Florida',
    bixDocId: {
      FL: 'FL 042021'
    }
  },
  H_OLAW: {
    id: 'H_OLAW',
    name: 'Ordinance of Law Endorsement',
    bixDocId: {
      FL: 'FL 042021'
    }
  },
  H_WBUP: {
    id: 'H_WBUP',
    name: 'Water Backup or Sump Overflow - Florida Endorsement',
    bixDocId: {
      FL: 'FL 042021'
    }
  },
  H_SDWE: {
    id: 'H_SDWE',
    name: 'Seasonal Dwelling Endorsement',
    bixDocId: {
      FL: 'FL 042021'
    }
  },
  H_CYHD: {
    id: 'H_CYHD',
    name: 'Calendar Year Hurricane Deductible Form Endorsement',
    bixDocId: {
      FL: 'FL 122021'
    }
  },
  H_IRCC: {
    id: 'H_IRCC',
    name: 'Increased Replacement Cost Coverage',
    bixDocId: {
      FL: 'FL 122021'
    }
  },
  H_LMSE: {
    id: 'H_LMSE',
    name: 'Limited Screened Enclosure and Carport Coverage',
    bixDocId: {
      FL: 'FL 122021'
    }
  },
  H_HCOM: {
    id: 'H_HCOM',
    name: 'Home Computer Coverage',
    bixDocId: {
      FL: 'FL 122021'
    }
  },
  H_LORE: {
    id: 'H_LORE',
    name: 'Loss Reduction and Other Items',
    bixDocId: {
      FL: 'FL 122021'
    }
  },
  H_SPPE: {
    id: 'H_SPPE',
    name: 'Scheduled Personal Property',
    bixDocId: {
      AK: 'AK 022022',
      AZ: 'CW 042020',
      DC: 'CW 102021',
      GA: 'CW 042020',
      FL: 'FL 042021',
      IA: 'CW 102021',
      IL: 'CW 082021',
      IN: 'CW 042020',
      KY: 'CW 082021',
      LA: 'LA 072022',
      MD: 'CW 042020',
      MI: 'CW 042020',
      MO: 'CW 042020',
      MS: 'CW 042020',
      MT: 'CW 102021',
      ND: 'CW 042020',
      NE: 'CW 042020',
      NM: 'CW 042020',
      OH: 'CW 042020',
      OK: 'OK 022021',
      OR: 'CW 102021',
      PA: 'PA 042021',
      TX: 'TX 092020',
      UT: 'CW 102021',
      WI: 'CW 042020',
      WV: 'CW 042020'
    },
    docId: {
      AL: 'CW 062021',
      AR: 'CW 102020',
      AZ: 'CW 122018',
      CO: 'CO 102020',
      ID: {
        default: 'CW 102021',
        '2022-12-05': 'ID 092022'
      },
      IL: 'CW 122018',
      MA: 'CW 102021',
      ME: 'CW 102021',
      MO: 'CW 122018',
      NH: {
        default: 'CW 102021',
        '2022-12-05': 'NH 082022'
      },
      OH: 'CW 122018',
      SC: 'CW 062021',
      SD: {
        default: 'CW 102021',
        '2022-12-05': 'SD 082022'
      },
      TN: {
        default: 'CW 102021',
        '2022-12-05': 'TN 082022'
      },
      TX: 'TX 122019',
      VA: 'VA 042020',
      VT: 'CW 102021',
      WY: {
        default: 'CW 102021',
        '2022-12-05': 'WY 082022'
      }
    }
  },
  H_SPRT: {
    id: 'H_SPRT',
    bixDocId: {
      AK: 'CW 042020',
      AR: 'CW 042020',
      AZ: 'CW 042020',
      DC: 'CW 042020',
      GA: 'CW 042020',
      IA: 'CW 042020',
      IL: 'CW 042020',
      IN: 'CW 042020',
      KY: 'CW 042020',
      LA: 'CW 042020',
      MD: 'CW 042020',
      MI: 'CW 042020',
      MO: 'CW 042020',
      MS: 'CW 042020',
      MT: 'CW 042020',
      ND: 'CW 042020',
      NE: 'CW 042020',
      NM: 'CW 042020',
      OH: 'CW 042020',
      OK: 'OK 022021',
      OR: 'CW 042020',
      PA: 'PA 042021',
      TX: 'CW 042020',
      UT: 'CW 042020',
      WI: 'CW 042020',
      WV: 'CW 042020'
    },
    docId: {
      ID: 'CW 072021',
      MA: 'CW 072021',
      ME: 'CW 072021',
      NH: 'CW 072021',
      SD: 'CW 072021',
      TN: 'CW 072021',
      VT: 'CW 072021',
      WY: 'CW 072021'
    },
    name: 'Additional Coverage On Sports Equpment '
  },
  H_WDEX: {
    id: 'H_WDEX',
    name: 'Water Damage Exclusion Endorsement',
    bixDocId: {
      FL: 'FL 012022'
    }
  },
  H_WNHD: {
    id: 'H_WNHD',
    bixDocId: {
      AK: 'CW 042020',
      AR: 'CW 042020',
      AZ: 'CW 042020',
      DC: 'CW 042020',
      GA: 'CW 042020',
      IA: 'CW 042020',
      IL: 'CW 042020',
      IN: 'CW 042020',
      KY: 'CW 042020',
      LA: 'LA 062022',
      MD: 'CW 092020',
      MI: 'CW 042020',
      MO: 'CW 042020',
      MS: 'CW 042020',
      MT: 'CW 042020',
      NE: 'CW 042020',
      NM: 'NM 072021',
      OH: 'CW 042020',
      OK: 'OK 022021',
      OR: 'CW 042020',
      PA: 'PA 042021',
      TX: 'CW 042020',
      UT: 'CW 042020',
      WI: 'CW 042020',
      WV: 'CW 042020'
    },
    docId: {
      ID: 'CW 072021',
      MA: 'CW 072021',
      ME: 'CW 072021',
      NH: 'CW 072021',
      SC: 'SC 072021',
      SD: 'CW 072021',
      TN: 'CW 072021',
      VA: 'VA 042020',
      VT: 'CW 072021',
      WY: 'CW 072021'
    },
    name: 'Windstorm and Hail Deductible Endorsement'
  },
  H_WNEX: {
    id: 'H_WNEX',
    name: 'Windstorm and Hail Exclusion',
    bixDocId: {
      TX: 'TX 092020'
    },
    docId: {
      TX: 'TX 122018'
    }
  },
  H_WTBU: {
    id: 'H_WTBU',
    name: 'Water Backup Endorsement',
    bixDocId: {
      AK: 'AK 052022',
      AR: 'AR 072022',
      AZ: 'CW 042020',
      DC: 'CW 082021',
      GA: 'CW 042020',
      IA: 'CW 082021',
      IL: 'CW 082021',
      IN: 'CW 042020',
      KY: 'CW 082021',
      LA: 'LA 062022',
      MI: 'CW 042020',
      MO: 'CW 082021',
      MS: 'CW 042020',
      MT: 'CW 082021',
      ND: 'CW 082021',
      NE: 'CW 042020',
      NM: 'NM 072021',
      OH: 'CW 042020',
      OK: 'OK 022021',
      OR: 'CW 082021',
      PA: 'PA 042021',
      TX: 'TX 092020',
      UT: 'CW 082021',
      WI: 'CW 042020',
      WV: 'CW 042020'
    },
    docId: {
      AZ: 'CW 122018',
      CO: 'CO 102020',
      ID: 'CW 072021',
      IL: 'CW 122018',
      KS: 'CW 122018',
      MA: 'CW 072021',
      ME: 'CW 072021',
      MO: 'CW 122018',
      NH: 'NH 012022',
      OH: 'CW 122018',
      SD: 'CW 072021',
      TN: 'CW 072021',
      TX: 'TX 112019',
      VA: 'VA 042020',
      VT: 'CW 072021',
      WY: 'CW 072021'
    }
  },
  H_WTBI: {
    id: 'H_WTBI',
    name: 'Increased Water Backup Endorsement',
    docId: {
      VA: 'VA 042020'
    }
  },
  H_YARD: {
    id: 'H_YARD',
    bixDocId: {
      AK: 'CW 082021',
      AR: 'CW 092021',
      AZ: 'CW 042020',
      DC: 'CW 092021',
      GA: 'CW 042020',
      IA: 'CW 092021',
      IL: 'CW 092021',
      IN: 'CW 042020',
      KY: 'CW 092021',
      LA: 'CW 092021',
      MD: 'CW 042020',
      MI: 'CW 042020',
      MO: 'CW 042020',
      MS: 'CW 042020',
      MT: 'CW 092021',
      ND: 'CW 092021',
      NE: 'CW 042020',
      NM: 'NM 072021',
      OH: 'CW 042020',
      OK: 'OK 022021',
      OR: 'CW 092021',
      PA: 'PA 042021',
      TX: 'CW 042020',
      UT: 'CW 092021',
      WI: 'CW 042020',
      WV: 'CW 092021'
    },
    docId: {
      AL: 'AL 032021',
      ID: 'CW 102021',
      MA: 'CW 102021',
      ME: 'CW 102021',
      NH: 'CW 102021',
      SD: 'CW 102021',
      TN: 'CW 102021',
      VT: 'CW 102021',
      WY: 'CW 102021'
    },
    name: 'Additional Coverage for Yard and Garden'
  },
  H_DISC: {
    id: 'H_DISC',
    docId: {
      CO: 'CO 032021'
    },
    name: 'Colorado Specific DISC'
  },
  H_LOSS: {
    id: 'H_LOSS',
    docId: {
      CO: 'CO 032021'
    },
    name: 'Colorado Specific LOSS'
  },
  H_PRMM: {
    id: 'H_PRMM',
    bixDocId: {
      MD: 'MD 032021'
    },
    name: 'Important Notice'
  },
  H_SAFE: {
    id: 'H_SAFE',
    bixDocId: {
      MD: 'CW 072022'
    },
    name: 'Home Safety Program'
  },
  H_EQIN: {
    id: 'H_EQIN',
    bixDocId: {
      AR: 'AR 072022'
    },
    name: 'Important Notice'
  },
  H_CLAM: {
    id: 'H_CLAM',
    bixDocId: {
      MD: 'MD 012021'
    },
    name: 'Information Regarding Claims'
  },
  H_RECA: {
    id: 'H_RECA',
    bixDocId: {
      MD: 'MD 022021'
    },
    name: 'Notice of Recalculation of Premium'
  },
  H_REDU: {
    id: 'H_REDU',
    bixDocId: {
      MD: 'MD 022021'
    },
    name: 'Reduction in Coverage Notice'
  },
  H_CSDF: {
    id: 'H_CSDF',
    bixDocId: {
      MD: 'MD 092020'
    },
    name: 'Coverage Summary Disclosure'
  },
  H_WINN: {
    id: 'H_WINN',
    bixDocId: {
      MD: 'MD 012021'
    },
    name: 'Windstorm Protective Device Notice'
  },
  H_SOAC: {
    id: 'H_SOAC',
    name: 'Customer Notice'
  },
  H_SOACA: {
    id: 'H_SOACA',
    name: 'Customer Notice'
  },
  H_ACCN: {
    id: 'H_ACCN',
    name: 'Anti-Concurrent-Causation Language Notice'
  },
  H_SBCF: {
    id: 'H_SBCF',
    bixDocId: {
      MD: 'MD 092020'
    },
    name: 'Sewer Backup'
  },
  H_SBIL: {
    id: 'H_SBIL',
    bixDocId: {
      MD: 'MD 092020'
    },
    name: 'Sewer Backup Increased Limits'
  },
  H_SDFR: {
    id: 'H_SDFR',
    docId: {
      NH: 'NH 012022'
    },
    name: 'Standard Fire Policy Provisions'
  },
  H_SINK: {
    id: 'H_SINK',
    bixDocId: {
      PA: 'PA 042021'
    },
    docId: {
      TN: 'TN 072021'
    },
    name: 'Sinkhole Collapse Endorsement'
  },
  H_OSTA: {
    id: 'H_OSTA',
    bixDocId: {
      PA: 'PA 042021'
    },
    docId: {
      MA: 'MA 082021'
    },
    name: 'Oil Storage Tank(s) Endorsement'
  },
  H_ALEN: {
    id: 'H_ALEN',
    name: 'Important Notice'
  },
  H_SPIN: {
    id: 'H_SPIN',
    name: 'Premium Increase Notice'
  },
  H_LPDS: {
    id: 'H_LPDS',
    name: 'Important Notice'
  },

  // Renter Policy Jacket ( alphbetical order)
  R_AANR: {
    id: 'R_AANR',
    name: 'Important information about your credit based score notice',
    bixDocId: {
      CW: 'CW 042020'
    }
  },
  R_AATU: { id: 'R_AATU', name: 'Adverse Underwriting' },
  R_AAVK: { id: 'R_AAVK', name: 'Adverse Underwriting' },
  R_AMND: {
    id: 'R_AMND',
    name: 'Amendatory Endorsment',
    bixDocId: {
      AZ: 'AZ 072022'
    }
  },
  R_CYIR: {
    id: 'R_CYIR',
    name: ''
  },
  R_FLOD: {
    id: 'R_FLOD',
    name: 'Flood Notice',
    bixDocId: {
      CW: 'CW 012021'
    }
  },
  R_PJAK: {
    id: 'R_PJAK',
    name: 'Renter Policy Jacket',
    docId: {
      CO: 'CO 072020'
    },
    bixDocId: {
      AZ: 'AZ 012021',
      ND: 'ND 112022',
      NM: 'NM 082022',
      PA: 'PA 092022'
    }
  },
  R_PLCY: {
    id: 'R_PLCY',
    name: 'Renters Policy',
    docId: {
      CO: 'CO 072020'
    },
    bixDocId: {
      AZ: 'AZ 022021',
      ND: 'ND 112022',
      NM: 'NM 082022',
      PA: 'PA 092022'
    }
  },
  R_RTNC: {
    id: 'R_RTNC',
    name: 'Renewal Terms and Conditions',
    bixDocId: {
      AZ: 'AZ 012021',
      ND: 'ND 112022',
      NM: 'NM 082022',
      PA: 'PA 092022'
    }
  },
  R_SPPE: {
    id: 'R_SPPE',
    name: 'Scheduled Personal Property',
    bixDocId: {
      AZ: 'AZ 012021',
      CW: 'CW 032022',
      NM: 'NM 082022',
      PA: 'PA 092022',
      ND: 'CW 032022'
    }
  },

  // Condo Policy Jacket ( alphbetical order)
  C_INVC: {
    id: 'C_INVC',
    name: 'Escrow Invoice'
  },
  C_AATU: { id: 'C_AATU', name: 'Adverse Underwriting' },
  C_AAVK: { id: 'C_AAVK', name: 'Adverse Underwriting' },
  C_EBDC: {
    id: 'C_EBDC',
    name: 'Equipment Breakdown Coverage Endorsement',
    bixDocId: {
      AZ: 'CW 052022',
      MI: 'CW 052022',
      MO: 'CW 052022',
      OH: 'CW 052022',
      PA: 'CW 052022',
      WI: 'CW 052022'
    }
  },
  C_ELDR: {
    id: 'C_ELDR',
    name: 'Electronic Data Recovery Expense Endorsement',
    bixDocId: {
      AZ: 'CW 102021',
      MI: 'MI 072022',
      MO: 'MO 072020',
      OH: 'CW 102021',
      PA: 'CW 102021',
      WI: 'CW 102021'
    }
  },
  C_FLOD: {
    id: 'C_FLOD',
    name: 'Flood Notice',
    bixDocId: {
      CW: 'CW 102021'
    }
  },
  C_MUSI: {
    id: 'C_MUSI',
    name: 'Additional Coverage on Musical Instruments',
    bixDocId: {
      AZ: 'CW 102021',
      MI: 'CW 102021',
      MO: 'CW 102021',
      OH: 'CW 102021',
      PA: 'CW 102021',
      WI: 'CW 102021'
    }
  },
  C_OMSI: {
    id: 'C_OMSI',
    bixDocId: {
      OH: 'OH 102021'
    },
    name: 'Mine Subsidence Renewal Offer'
  },
  C_PJAK: {
    id: 'C_PJAK',
    name: 'Condo Policy Jacket',
    bixDocId: {
      AZ: 'AZ 082022',
      MI: 'MI 112022',
      MO: 'MO 082022',
      OH: 'OH 102021',
      PA: 'PA 092022',
      WI: 'WI 062022'
    }
  },
  C_PLCY: {
    id: 'C_PLCY',
    name: 'Condo Policy',
    bixDocId: {
      AZ: 'AZ 092022',
      MI: ' MI 112022',
      MO: 'MO 092022',
      OH: 'OH 092022',
      PA: 'PA 102022',
      WI: 'WI 062022'
    }
  },
  C_PUMB: {
    id: 'C_PUMB',
    name: 'Personal Umbrella Liability Endorsement',
    bixDocId: {
      AZ: 'AZ 082022',
      MI: 'MI 092022',
      MO: 'MO 082022',
      OH: 'OH 092022',
      PA: 'PA 062022',
      WI: 'WI 062022'
    }
  },
  C_RTNC: {
    id: 'C_RTNC',
    name: 'Renewal Terms and Conditions',
    bixDocId: {
      OH: 'OH 102021',
      az: 'AZ 092022'
    }
  },
  C_RTNO: {
    id: 'C_RTNO',
    name: 'Rating Information Notice',
    docId: {
      MI: 'MI 072022'
    }
  },
  C_SPIN: {
    id: 'C_SPIN',
    name: 'Premium Increase Notice'
  },
  C_SPPE: {
    id: 'C_SPPE',
    name: 'Scheduled Personal Property',
    bixDocId: {
      AZ: 'AZ 082022',
      MI: 'MI 082022',
      MO: 'CW 082022',
      OH: 'CW 102021',
      PA: 'CW 102021',
      WI: 'CW 102021'
    }
  },
  C_SPRT: {
    id: 'C_SPRT',
    name: 'Additional Coverage on Sports Equipment Endorsement',
    bixDocId: {
      AZ: 'CW 102021',
      MI: 'CW 102021',
      MO: 'CW 102021',
      OH: 'CW 102021',
      PA: 'CW 102021',
      WI: 'CW 102021'
    }
  },
  C_WTBU: {
    id: 'C_WTBU',
    name: 'Water Backup Endorsement',
    bixDocId: {
      AZ: 'CW 102021',
      MI: 'CW 102021',
      MO: 'CW 102021',
      OH: 'CW 102021',
      PA: 'CW 102021',
      WI: 'CW 102021'
    }
  },
  // Generic Policy Jacket
  B_CMPL: {
    id: 'B_CMPL',
    bixDocId: {
      IN: 'IN 112020'
    },
    name: 'Complaint Notice'
  },
  B_NOCI: {
    id: 'B_NOCI',
    name: 'Complaint Notice'
  },
  B_PRIV: {
    id: 'B_PRIV',
    bixDocId: {
      AZ: 'CW 042020',
      IN: 'CW 042020',
      KY: 'CW 042020',
      MI: 'MI 072021',
      MO: 'CW 042020',
      NM: 'CW 042020',
      OH: 'CW 042020',
      OK: 'CW 042020',
      PA: 'CW 042020',
      TX: 'CW 042020',
      WI: 'CW 042020'
    },
    docId: {
      CO: 'CW 102020'
    },
    name: 'Privacy Statement'
  },
  B_SASP: { id: 'B_SASP', name: 'Subscriber Agreement Signature Page' },
  B_NIMP: {
    id: 'B_NIMP',
    name: 'Information',
    bixDocId: {
      TX: 'TX 072021'
    },
    docId: {
      TX: 'TX 012020'
    }
  },
  H_WHEE: {
    id: 'H_WHEE',
    bixDocId: {
      GA: 'GA 052021'
    },
    name: 'Windstorm and Hail Exclusion Endorsement'
  },
  H_WHXX: {
    id: 'H_WHXX',
    bixDocId: {
      TX: 'TX 072021'
    },
    name: 'Wind/Hail Exclusion Form'
  },
  H_NEXL: {
    id: 'H_NEXL',
    name: 'Information',
    bixDocId: {
      TX: 'TX 072021'
    },
    docId: {
      TX: 'TX 122019'
    }
  },
  H_CBRN: {
    id: 'H_CBRN',
    name: 'Information',
    bixDocId: {
      TX: 'TX 062021'
    },
    docId: {
      TX: 'TX 062021'
    }
  },
  H_WHEX: {
    id: 'H_WHEX',
    name: 'Windstorm and Hail Exclusion',
    bixDocId: {
      TX: 'TX 092020'
    },
    docId: {
      AL: 'AL 032021',
      TX: 'TX 122018'
    }
  },
  H_FROF: {
    id: 'H_FROF',
    name: 'Fortified Roof Endorsement',
    docId: {
      AL: 'AL 032021'
    }
  },
  H_OLCL: {
    id: 'H_OLCL',
    name: 'Residential Property Insurance Outline',
    docId: {
      AL: 'AL 032021'
    }
  },
  H_RTNO: {
    id: 'H_RTNO',
    name: 'Rating Information Notice',
    docId: {
      TX: 'MI 042021'
    }
  },
  H_NOTC: {
    id: 'H_NOTC',
    name: 'Rating Information Notice'
  },
  H_NOTCR: {
    id: 'H_NOTCR',
    name: 'Rating Information Notice (Renewal)'
  },
  H_WMDA: {
    id: 'H_WMDA',
    name: 'Wind and Hail Mitigation Form'
  },
  H_WMDI: {
    id: 'H_WMDI',
    name: 'Wind and Hail Mitigation Discount'
  },
  H_WNHE: {
    id: 'H_WNHE',
    name: 'Wind and Hail Exclusion Endorsement',
    bixDocId: {
      MS: 'MS 102021'
    },
    docId: {
      SC: 'SC 052021'
    }
  },
  B_OFAC: { id: 'B_OFAC', name: 'OFAC Statement' },
  // FCRA Notice
  H_CYIR: { id: 'H_CYIR', name: '' },
  H_AAVK: { id: 'H_AAVK', name: 'Adverse Underwriting' },
  H_AUVK: { id: 'H_AUVK', name: 'Adverse Underwriting' },
  A_AUVK: { id: 'A_AUVK', name: 'Adverse Underwriting' },
  A_AUTU2: { id: 'A_AUTU2', name: 'Adverse Underwriting' },
  // Cancellation
  H_CNRN: { id: 'H_CNRN', name: 'Cancellation notice' },
  // Home cancels
  H_CNRS: {
    id: 'H_CNRS',
    name: 'Cancellation notice',
    bixDocId: {
      AZ: 'AZ 012021',
      OH: 'OH 042020',
      TX: 'TX 072021',
      MD: 'MD 012021',
      MI: 'MI 072021'
    },
    docId: {
      AZ: 'AZ 122018',
      IL: 'IL 122019',
      MO: 'MO 092019',
      OH: 'OH 122018',
      TN: 'CW 102021',
      TX: 'TX 012020',
      CO: 'CO 102020'
    }
  },
  H_CNPY: {
    id: 'H_CNPY',
    name: 'Cancellation notice',
    bixDocId: {
      OH: 'OH 042020',
      MD: 'MD 012021',
      PA: 'PA 052021'
    },
    docId: {
      OH: 'OH 122018',
      AZ: 'AZ 122018',
      IL: 'IL 122019',
      MO: 'MO 092019',
      TN: 'CW 102021',
      TX: 'TX 012020'
    }
  },
  H_NRRS: {
    id: 'H_NRRS',
    name: 'Cancellation notice',
    bixDocId: {
      CW: 'CW 022021',
      MI: 'MI 052021'
    },
    docId: {
      CW: 'CW 122018'
    }
  },
  H_NULL: {
    id: 'H_NULL',
    name: 'Cancellation notice',
    bixDocId: {
      CW: 'CW 042020',
      OK: 'OK 022021',
      PA: 'PA 052021'
    },
    docId: {
      CW: 'CW 122018'
    }
  },
  // Auto cancels
  A_CNRS: {
    id: 'A_CNRS',
    name: 'Cancellation notice',
    bixDocId: {
      TX: 'TX 072021',
      AZ: 'AZ 012021',
      MD: 'MD 012021',
      MI: 'MI 042021'
    },
    docId: {
      IL: 'IL 122019',
      MO: 'MO 102019',
      TN: 'CW 102021',
      TX: 'TX 012020',
      CO: 'CO 102020'
    }
  },
  A_CNPY: {
    id: 'A_CNPY',
    name: 'Cancellation notice',
    bixDocId: {
      OH: 'OH 042020',
      AZ: 'AZ 012021',
      MD: 'MD 012021',
      MI: 'MI 042021'
    },
    docId: {
      OH: 'OH 042020',
      AZ: 'AZ 122018',
      MO: 'MO 102019',
      TN: 'CW 102021',
      TX: 'TX 012020'
    }
  },
  A_NRRS: {
    id: 'A_NRRS',
    name: 'Cancellation notice',
    bixDocId: {
      CW: 'CW 022021',
      OH: 'OH 122018',
      TX: 'CW 022021',
      AZ: 'AZ 012021',
      OK: 'CW 022021',
      MD: 'MD 012021',
      MI: 'MI 052021',
      IN: 'IN 122020'
    },
    docId: {
      CW: 'CW 122018',
      OH: 'OH 042020',
      IL: 'IL 012020',
      MO: 'MO 102019',
      TN: 'CW 102021',
      TX: 'TX 012020',
      CO: 'CW 022021',
      KS: 'KS 032021'
    }
  },
  A_NULL: {
    id: 'A_NULL',
    name: 'Cancellation notice'
  },
  // Renters cancels
  R_CNPY: {
    id: 'R_CNPY',
    name: 'Cancellation notice'
  },
  R_CNRS: {
    id: 'R_CNRS',
    name: 'Cancellation notice'
  },
  R_NRRS: {
    id: 'R_NRRS',
    name: 'Cancellation notice'
  },
  H_LADN: {
    id: 'H_LADN',
    name: 'LA Deductible Notice',
    docId: {
      LA: 'LA 012023'
    }
  },
  H_NPDS: {
    id: 'H_NPDS',
    name: 'Notice of Policy Disclosure Summary',
    docId: {
      LA: 'LA 072022'
    }
  },
  H_PCDS: {
    id: 'H_PCDS',
    name: 'Louisiana Policy Coverage Disclosure Summary',
    docId: {
      LA: 'LA 072022'
    }
  },
  H_RTNC: {
    id: 'H_RTNC',
    name: 'Renewal Terms and Conditions',
    bixDocId: {
      AZ: 'CW 022021',
      IN: 'IN 121820',
      KY: 'KY 102021',
      MI: 'MI 072021',
      MO: 'MO 072020',
      NE: 'NE 072021',
      NM: 'NM 122021',
      OH: 'CW 042020',
      OK: 'OK 022021',
      PA: 'PA 042021',
      TX: 'TX 072021',
      WI: 'WI 052021',
      WV: 'WV 092021'
    },
    docId: {
      AL: 'AL 102021',
      AZ: 'CW 062419',
      CO: 'CO 102020',
      ID: 'ID 102021',
      IL: 'IL 032020',
      KS: 'KS 102021',
      MO: 'CW 032020',
      OH: 'OH 112019',
      TN: 'TN 102021',
      TX: 'TX 032020',
      VA: 'VA 092021'
    }
  },
  H_TCDE: {
    id: 'H_TCDE',
    name: 'Tropical Cyclone Deductible Endorsement',
    bixDocId: {
      LA: 'LA 062022'
    }
  },
  H_TROP: {
    id: 'H_TROP',
    name: 'Tropical Cyclone Deductible',
    docId: {
      MA: 'MA 082021'
    }
  },
  H_SEWX: {
    id: 'H_SEWX',
    name: 'DC Sewer Notice'
  },
  H_SEWR: {
    id: 'H_SEWR',
    name: 'DC Sewer Notice'
  },
  B_CRDN: {
    id: 'B_CRDN',
    name: 'Important Notice'
  },
  A_INNO: {
    id: 'A_INNO',
    name: 'Important Insurance Notice'
  },
  H_FLOX: {
    id: 'H_FLOX',
    name: 'Flood Notice'
  },
  H_NRBIX: {
    id: 'H_NRBIX',
    name: 'Insurance Cancellation Notice'
  },
  A_NRBIX: {
    id: 'A_NRBIX',
    name: 'Insurance Cancellation Notice'
  }
};
