import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { Grid } from '@material-ui/core';
import {
  coverageCANotOffered,
  coverageDRNotOffered,
  coverageFOffered,
  coverageMINotOffered,
  coverageSPNotOffered,
  coverageSTNotOffered,
  EBSLstateDate,
  equipmentBreakdownServiceLineCoveragesStates,
  mineSubsidenceOptional,
  mineSubsidenceRequired,
  waterBackupNameByState
} from '@ourbranch/lookups';

import { Label } from 'core/components/label';
import { FormField } from 'core/components/form';
import useOptions from 'core/hooks/use-options';
import useStyles from '../condo-coverage.styles';
import { LabelTooltip } from 'core/components/label-tooltip';
import { CondoType, tooltipHoverTexts } from 'core/helpers/constants';
import { awsDateToDateObject, numberFormatter } from 'core/helpers/formatters';
import { getShowMSALE } from 'offer/helpers/get-coverage';
import { useStore } from 'core/store';
import { isAfter, isSameDay } from 'date-fns';
import { localToUtcTime } from 'customer/components/policy/dates';

const OtherCoverages = ({ state, coverage, condo, fromPolicy }) => {
  const classes = useStyles();
  const { account, offer: offerStore } = useStore();
  const { setFieldValue } = useFormikContext();

  const policyStore = account?.policies?.policy;

  const coverageFOptions = useOptions('coverageF', state);
  const coverageJOptions = useOptions('coverageJ', state);
  const coverageCAOptions = useOptions('coverageCA', state);
  const coverageMIOptions = useOptions('coverageMI', state);
  const coverageSTOptions = useOptions('coverageST', state);
  const coverageSPOptions = useOptions('coverageSP', state);
  const coverageBPOptions = useOptions('coverageBP', state);
  const waterBackupOptions = useOptions('waterBackupCoverageOptions', state);
  const mineSubsidenceALECoverageOptions = useOptions('mineSubsidenceALECoverageOptions', state);
  const enforceMS = !!(mineSubsidenceRequired[state] && mineSubsidenceRequired[state][condo.county.toUpperCase()]);
  const optionalMS =
    mineSubsidenceOptional[state] &&
    (mineSubsidenceOptional[state][condo.county.toUpperCase()] || mineSubsidenceOptional[state].all) &&
    !condo.buildingHasMoreThanFiveUnits &&
    ![CondoType.HIGH_RISE, CondoType.MID_RISE].includes(condo.typeOfCondo);

  const offerF = coverageFOffered[state];
  const showCoverageCA = !coverageCANotOffered[state];
  const showCoverageMI = !coverageMINotOffered[state];
  const showCoverageST = !coverageSTNotOffered[state];
  const showCoverageSP = !coverageSPNotOffered[state];
  const showCoverageDR = !coverageDRNotOffered[state];

  const waterBackupLabel = (waterBackupNameByState && waterBackupNameByState[state]) || 'Water Backup';

  let offerWaterBackup = false;

  const showMSALE = getShowMSALE(state);

  const buildingCodeCoverageOptions = useOptions('buildingCodeCoverageOptions', state).map((option) => ({
    id: option.id,
    value: `${option.value} ($${numberFormatter((option.id / 100) * coverage.coverageC)})`
  }));

  useEffect(() => {
    if (enforceMS) {
      setFieldValue(
        'condoCoverage.coverageMS',
        ![CondoType.HIGH_RISE, CondoType.MID_RISE].includes(condo.typeOfCondo) && !condo.buildingHasMoreThanFiveUnits
      );
    }
  }, [enforceMS, coverage, setFieldValue]);

  const rateControlDate = awsDateToDateObject(offerStore?.offer?.quote?.global.rateControlDate);
  const stateDate = new Date(EBSLstateDate[state]);
  let canAddEquipmentBreakdown = equipmentBreakdownServiceLineCoveragesStates.includes(state);

  if (fromPolicy) {
    const { updatedDateTime } = policyStore?.policy.versionHistory.find(({ version }) => version === 1);
    let createdDate = localToUtcTime(updatedDateTime, state);
    offerWaterBackup = policyStore.policy.offer.quote.offerings.offerWaterBackup;
    if (state === 'TX') {
      // exception in TX to look at effectiveDate because of early publishing
      createdDate = localToUtcTime(policyStore?.policy.effectiveDate, state);
    }
    canAddEquipmentBreakdown =
      canAddEquipmentBreakdown &&
      policyStore?.policy?.isBix &&
      (isAfter(createdDate, stateDate) || isSameDay(stateDate, createdDate));
  } else {
    // new offer
    canAddEquipmentBreakdown =
      canAddEquipmentBreakdown &&
      offerStore?.offer?.quote?.isBix &&
      (isAfter(rateControlDate, stateDate) || isSameDay(stateDate, rateControlDate));

    offerWaterBackup = offerStore.offer?.quote?.offerings?.offerWaterBackup;
  }

  return (
    <Grid container item spacing={5} className={classes.coverageContainer} alignItems="flex-start">
      <Grid item xs={12}>
        <Label type="formSubTitle">Other Coverages</Label>
      </Grid>
      <FormField
        name="condoCoverage.coverageBP"
        type="select"
        label="Business Property"
        mode="dark"
        options={coverageBPOptions}
        xs={4}
      />
      {offerF && (
        <FormField
          name="condoCoverage.coverageF"
          type="select"
          label="Fire Department Charges"
          mode="dark"
          options={coverageFOptions}
          xs={4}
        />
      )}
      <Grid item xs={4}>
        <LabelTooltip
          label="Building Code Coverage"
          tooltip={{ label: 'More Info', onHoverText: tooltipHoverTexts.buildingCodeCoverage }}
        >
          <FormField
            name="condoCoverage.coverageBCPctOfC"
            type="select"
            mode="dark"
            options={buildingCodeCoverageOptions}
          />
        </LabelTooltip>
      </Grid>
      <Grid item xs={6}>
        <LabelTooltip
          label={waterBackupLabel}
          tooltip={{ label: 'More Info', onHoverText: tooltipHoverTexts.waterBackup }}
        >
          <FormField
            name="condoCoverage.coverageWaterBackup"
            type="select"
            mode="dark"
            className="has-custom-error"
            disabled={!offerWaterBackup}
            options={waterBackupOptions}
          />
          {!offerWaterBackup && (
            <Label type="formLabel" className={classes.errorMessage}>
              Cannot offer water backup due to history of water backup claims
            </Label>
          )}
        </LabelTooltip>
      </Grid>
      <FormField
        name="condoCoverage.coverageJ"
        type="select"
        label="Jewelry, Watches, and Furs"
        mode="dark"
        options={coverageJOptions}
        xs={6}
      />
      {showCoverageCA && (
        <FormField
          name="condoCoverage.coverageCA"
          type="select"
          label="Cameras"
          mode="dark"
          options={coverageCAOptions}
          xs={6}
        />
      )}
      {showCoverageMI && (
        <FormField
          name="condoCoverage.coverageMI"
          type="select"
          label="Musical Instruments"
          mode="dark"
          xs={6}
          options={coverageMIOptions}
        />
      )}
      {showCoverageST && (
        <FormField
          name="condoCoverage.coverageST"
          type="select"
          label="Silverware Theft"
          mode="dark"
          xs={6}
          options={coverageSTOptions}
        />
      )}
      {showCoverageSP && (
        <FormField
          name="condoCoverage.coverageSP"
          type="select"
          label="Sports Equipment"
          mode="dark"
          xs={6}
          options={coverageSPOptions}
        />
      )}
      {(optionalMS || enforceMS) && (
        <Grid item xs={12}>
          <FormField
            name="condoCoverage.coverageMS"
            type="checkbox"
            label="Mine Subsidence"
            mode="dark"
            xs={6}
            disabled={!!enforceMS}
          />
          {showMSALE && !coverage.coverageMS && (
            <FormField
              name="condoCoverage.coverageMSALE"
              type="select"
              label="Mine Subsidence Additional Expense Coverage"
              mode="dark"
              xs={6}
              disabled
              options={mineSubsidenceALECoverageOptions}
            />
          )}
        </Grid>
      )}
      {showCoverageDR && (
        <Grid item xs={6}>
          <FormField
            name="condoCoverage.coverageDR"
            type="checkbox"
            label="Electronic Data Recovery"
            mode="dark"
            tooltipText={tooltipHoverTexts.coverageDR}
            tooltipLabel="More Info"
          />
        </Grid>
      )}
      {canAddEquipmentBreakdown && (
        <Grid item xs={6}>
          <FormField
            name="condoCoverage.coverageEBSL"
            type="checkbox"
            label="Equipment Breakdown"
            mode="dark"
            tooltipText={tooltipHoverTexts.coverageEB}
            tooltipLabel="More Info"
          />
        </Grid>
      )}
    </Grid>
  );
};

OtherCoverages.propTypes = {
  state: PropTypes.string.isRequired,
  coverage: PropTypes.object.isRequired,
  condo: PropTypes.object.isRequired,
  fromPolicy: PropTypes.bool.isRequired
};

export default OtherCoverages;
