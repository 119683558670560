const styles = (theme) => ({
  container: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    minHeight: 240,
    justifyContent: 'center'
  },
  title: {
    paddingBottom: 8,
    color: theme.colorPalette.green_20,
    fontSize: 24,
    lineHeight: '32px',
    fontWeight: 300
  },
  content: {
    padding: '0 50px'
  },
  buttons: {
    paddingTop: 20
  },
  buttonIcon: {
    marginRight: 10
  }
});

export default styles;
