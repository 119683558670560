import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  collapsible: {
    minHeight: 70,
    backgroundColor: theme.colorPalette.white_30
  },
  greenLabel: {
    color: theme.colorPalette.green_15,
    fontWeight: 'normal'
  },
  container: {
    alignItems: 'center',
    display: 'flex',
    gap: theme.spacing(4),
    padding: theme.spacing(8)
  },
  empty: {
    extend: 'container',
    justifyContent: 'center',
    fontSize: 16
  },
  icon: {
    height: 32
  }
});

export default makeStyles(styles);
