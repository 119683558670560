import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    borderBottom: `${theme.colorPalette.orange_10} 1px solid`,
    marginBottom: theme.spacing(3)
  },
  tab: {
    textTransform: 'uppercase',
    fontSize: 12,
    color: theme.colorPalette.green_90_op_80,
    fontWeight: 'bold!important',
    display: 'flex',
    flexDirection: 'row',
    padding: 0,
    minWidth: 25
  }
}));

export default useStyles;
