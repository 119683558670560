import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  greenLight: {
    backgroundColor: theme.colorPalette.green_10_op_75
  },
  green: {
    backgroundColor: theme.colorPalette.green_30
  },
  orange: {
    backgroundColor: theme.colorPalette.orange_60
  },
  red: {
    backgroundColor: theme.colorPalette.red_20
  },
  bannerHidden: {
    height: 0,
    animation: '$fadeOut 1s'
  },
  bannerShow: {
    width: '100%',
    padding: '0px 15px',
    borderRadius: 0,
    color: theme.colors.light_text,
    animation: '$fadeIn 1s',
    minHeight: 58
  },
  '@keyframes fadeIn': {
    '0%': {
      visibility: 'hidden',
      transform: 'translateY(-200%)'
    },
    '100%': {
      visibility: 'visible',
      transform: 'translateY(0)'
    }
  },
  '@keyframes fadeOut': {
    '0%': {
      visibility: 'visible',
      transform: 'translateY(0)'
    },
    '100%': {
      visibility: 'hidden',
      transform: 'translateY(-200%)'
    }
  },
  icon: {
    marginRight: 16,
    width: 50,
    height: 30
  },
  bannerContent: {
    width: '94%',
    minHeight: 58
  }
}));

export default useStyles;
