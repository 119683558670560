import React from 'react';

const ContactUs = () => {
  return (
    <iframe
      src="https://v2-embednotion.com/Contact-Us-29421786cff74f0e806dff853085cf19"
      title="Contact Us"
      style={{
        width: '100%',
        height: '600px',
        border: '1px solid #ccc',
        borderRadius: '10px',
        padding: 'none',
        boxShadow: '0 2px 2px #ccc '
      }}
    />
  );
};

export default ContactUs;
