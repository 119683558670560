import { types, flow, getEnv, getParent } from 'mobx-state-tree';
import _find from 'lodash-es/find';
import _filter from 'lodash-es/filter';
import _uniqBy from 'lodash-es/uniqBy';
import _orderBy from 'lodash-es/orderBy';
import { paymentMethod } from '@ourbranch/lookups';
import { clean } from '@ourbranch/policy-utils';
import { policyTypes } from '@ourbranch/policy-types';

import Notification, { isPolicyCrossSellEligible } from 'core/helpers/notifications';
import { checkAdditionalCoverages, checkIfUnauthorizedAndPushToSearchPage } from 'core/helpers/quoter.service';
import { getMerged } from 'customer/components/policy/merge-segments';

import {
  GET_DOCUMENTS_AND_APPLICATIONS,
  GET_UPLOAD_URL,
  REGENERATE_POLICY_DOCUMENTS
} from 'customer/components/documents/documents.queries';
import { SEND_APPLICATION_LINK } from 'customer/components/onboarding-task-center/components/application-url-form/application-links.queries';
import {
  GET_ACCOUNT_POLICIES,
  GET_PAYMENT_METHOD_DETAILS,
  GET_VERIFIED_STATUS,
  VERIFY_BANK_ACCOUNT
} from './policies.queries';
import { PolicyStore } from './policy-store';
import { getPolicyStatus, PolicyStatus } from 'core/helpers/policy-status';
import PolicyList from './models/policy-list';

export const PoliciesStore = types
  .model({
    loading: types.optional(types.boolean, false),
    list: types.array(PolicyList),
    loadingDocuments: types.optional(types.boolean, false),
    documents: types.maybeNull(types.frozen()),
    unsignedApplications: types.maybeNull(types.frozen()),
    unsignedBixConversions: types.maybeNull(types.frozen()),
    policy: types.maybe(PolicyStore),
    notifications: types.maybeNull(types.array(types.string))
  })
  .actions((self) => ({
    getAccountPolicies: flow(function* getAccountPolicies(id, history) {
      self.loading = true;
      self.loadingDocuments = true;
      const { client } = getEnv(self);
      try {
        const { data } = yield client.query({
          query: GET_ACCOUNT_POLICIES,
          fetchPolicy: 'network-only',
          variables: {
            id
          }
        });
        self.account = data.account;
        self.list = data.account.policies || [];

        self.documents = data?.documents;
        self.setNotifications();
        self.loading = false;
        self.loadingDocuments = false;
      } catch (error) {
        self.loading = false;
        self.loadingDocuments = false;
        if (history) {
          checkIfUnauthorizedAndPushToSearchPage(error, history);
        }
      }
    }),
    getBankAccountToken: flow(function* getBankAccountToken(accountId, policyId) {
      const { client } = getEnv(self);
      const { data } = yield client.query({
        query: GET_PAYMENT_METHOD_DETAILS,
        fetchPolicy: 'no-cache',
        variables: {
          accountId,
          policyId
        }
      });
      // _find will grab the first element returned, which is the default payment method.
      return _find(data.paymentMethods.allPaymentMethods, (paymentMethod) => {
        return paymentMethod?.bankName?.length > 0;
      });
    }),

    generateStripeBankInfo: flow(function* generateStripeBankInfo(accountId, policyId, stripeCustomerId) {
      const bankAccount = yield self.getBankAccountToken(accountId, policyId);
      if (bankAccount) {
        const res = yield self.getVerifiedStatus(policyId, stripeCustomerId, bankAccount.id);
        if (res) {
          self.addStripeBankInfo(policyId, res.status, bankAccount.id, bankAccount.last4);
        }
      }
    }),

    getVerifiedStatus: flow(function* getVerifiedStatus(policyId, stripeCustomerId, stripeBankAccountId) {
      const { client } = getEnv(self);
      const { data } = yield client.query({
        query: GET_VERIFIED_STATUS,
        fetchPolicy: 'no-cache',
        variables: {
          policyId,
          stripeCustomerId,
          stripeBankAccountId
        }
      });
      return { status: data.getVerifiedStatus.status };
    }),
    verifyBankAccount: flow(function* verifyBankAccount(verificationDetails) {
      const { client } = getEnv(self);

      try {
        const { data } = yield client.query({
          query: VERIFY_BANK_ACCOUNT,
          fetchPolicy: 'no-cache',
          variables: {
            verificationDetails
          }
        });
        return { status: data.verifyMicrodeposits.status };
      } catch (error) {
        if (error?.graphQLErrors[0]?.message) {
          throw new Error(error.graphQLErrors[0].message);
        }
        throw error;
      }
    }),
    setVerifiedStatus(status, policyId) {
      const foundPolicy = self.list?.find(({ id }) => id === policyId);
      if (foundPolicy) {
        foundPolicy.stripeBankInfo.achStatus = status;
      }
    },
    addStripeBankInfo(policyId, achStatus, stripeBankAccountId, last4) {
      const policy = self.list?.find((e) => e.id === policyId);
      const newStripeAccountInfo = {
        achStatus,
        stripeBankAccountId,
        last4
      };
      policy.stripeBankInfo = newStripeAccountInfo;
    },
    getDocuments: flow(function* getDocuments(accountId) {
      const { client } = getEnv(self);
      self.loadingDocuments = true;
      try {
        const { data } = yield client.query({
          query: GET_DOCUMENTS_AND_APPLICATIONS,
          variables: { accountId }
        });
        self.documents = data.documents;
        self.unsignedApplications = data.unsignedApplications;
        self.setNotifications();
        self.loadingDocuments = false;
      } catch (e) {
        self.loadingDocuments = false;
        return [];
      }
    }),
    regeneratePolicyDocuments: flow(function* regeneratePolicyDocuments(policyId) {
      const { client } = getEnv(self);
      return yield client.mutate({
        mutation: REGENERATE_POLICY_DOCUMENTS,
        variables: {
          policyId
        }
      });
    }),
    getUploadUrl: flow(function* getUploadUrl(accountId, fileName, mimeType) {
      const { client } = getEnv(self);
      return yield client.query({
        query: GET_UPLOAD_URL,
        variables: {
          accountId,
          fileName,
          mimeType
        }
      });
    }),
    sendApplicationLink: flow(function* sendApplicationUrl(accountId, policyId) {
      const { client } = getEnv(self);
      return yield client.mutate({
        mutation: SEND_APPLICATION_LINK,
        variables: {
          accountId,
          policyId
        }
      });
    }),
    setNotifications: function setNotifications() {
      const notifications = [];
      const policy = self?.policy?.policy;
      const bixConversionDocsInfo = self.getUnsignedBixConversion(policy?.id);
      const isCrossSellEligible = self.list.length === 1 && policy && isPolicyCrossSellEligible(policy);

      let isPendingThirdPartyPurchases = false;
      if (policy) {
        const { policyType, policyDetails, offer } = policy;
        const hasHome = policyType === policyTypes.Home || policyType === policyTypes.HABundle;

        if (hasHome && policyDetails) {
          const { homeCoverage, includeEarthquake, earthquakeCoverage, includeFlood, floodCoverage } = policyDetails;
          const { canAddEarthquake, canAddFlood } = checkAdditionalCoverages(offer, policyType);
          if (
            (homeCoverage?.interestedInSeparateWindHail && !homeCoverage?.externalPolicyId) ||
            (canAddEarthquake && includeEarthquake && !earthquakeCoverage?.policyId) ||
            (canAddFlood && includeFlood && !floodCoverage?.policyId)
          ) {
            isPendingThirdPartyPurchases = true;
          }
        }
      }

      if (!bixConversionDocsInfo?.signedDocUploadedInS3) {
        notifications.push(Notification.Policy.SignedConversionDocuments);
      }
      if (isCrossSellEligible) {
        notifications.push(Notification.Policy.CrossSellEligibility);
      }
      if (isPendingThirdPartyPurchases) {
        notifications.push(Notification.Policy.PendingThirdPartyPurchases);
      }
      const accountStore = getParent(self);
      if (accountStore.customerMetadata?.suspiciousActivity?.flagged) {
        notifications.push(Notification.Policy.SuspiciousAccount);
      }

      if (accountStore.incompleteTasks.length) {
        notifications.push(Notification.Policy.IncompleteTasks);
      }

      if (notifications.length) {
        self.notifications = notifications;
      } else {
        self.notifications = null;
      }
    }
  }))
  .views((self) => ({
    getAutoPolicyCoverage() {
      const autoPolicy = self.list
        ? self.list.find(
            (policy) =>
              policy.policyType === 'A' &&
              (getPolicyStatus(policy) === PolicyStatus.Active || getPolicyStatus(policy) === PolicyStatus.Future) &&
              getPolicyStatus(policy) === getPolicyStatus(self.policy.policy)
          )
        : undefined;

      if (autoPolicy) {
        const { segments } = autoPolicy;
        const mappedSegments = segments.slice().map(clean);

        if (mappedSegments.length > 1) {
          const mergedPolicyDetails = getMerged(mappedSegments, mappedSegments[segments.length - 1].segmentId);
          return mergedPolicyDetails?.autoCoverage || autoPolicy?.policyDetails?.autoCoverage;
        }
        return mappedSegments[0]?.autoCoverage || autoPolicy?.policyDetails?.autoCoverage;
      }

      return undefined;
    },

    getHomePolicyDetails() {
      const homePolicy = self.list
        ? self.list.find(
            (policy) =>
              policy.policyType === policyTypes.Home &&
              (getPolicyStatus(policy) === PolicyStatus.Active || getPolicyStatus(policy) === PolicyStatus.Future)
          )
        : undefined;
      let mergedPolicyDetails;
      if (homePolicy) {
        const { segments } = homePolicy;
        const mappedSegments = segments.slice().map(clean);
        if (mappedSegments.length >= 1) {
          mergedPolicyDetails = getMerged(mappedSegments, mappedSegments[segments.length - 1].segmentId);
        }
      }
      return mergedPolicyDetails;
    },

    getAutoPolicyDetails() {
      const autoPolicy = self.list
        ? self.list.find(
            (policy) =>
              policy.policyType === policyTypes.Auto &&
              (getPolicyStatus(policy) === PolicyStatus.Active || getPolicyStatus(policy) === PolicyStatus.Future)
          )
        : undefined;
      let mergedPolicyDetails;
      if (autoPolicy) {
        const { segments } = autoPolicy;
        const mappedSegments = segments.slice().map(clean);
        if (mappedSegments.length >= 1) {
          mergedPolicyDetails = getMerged(mappedSegments, mappedSegments[segments.length - 1].segmentId);
        }
      }
      return mergedPolicyDetails;
    },

    hasActiveOrFutureAutoPolicy() {
      return self.list
        ? self.list.find((policy) => {
            return (
              policy.policyType === policyTypes.Auto &&
              (getPolicyStatus(policy) === PolicyStatus.Active || getPolicyStatus(policy) === PolicyStatus.Future)
            );
          })
        : undefined;
    },

    hasActiveOrFutureHomePolicy() {
      return self.list
        ? self.list.find((policy) => {
            return (
              policy.policyType === policyTypes.Home &&
              (getPolicyStatus(policy) === PolicyStatus.Active || getPolicyStatus(policy) === PolicyStatus.Future)
            );
          })
        : undefined;
    },

    hasSecondHome() {
      const activeAndFutureHomePolicies = self.list
        ? self.list.filter(
            (policy) =>
              policy.policyType === policyTypes.Home &&
              (getPolicyStatus(policy) === PolicyStatus.Active || getPolicyStatus(policy) === PolicyStatus.Future)
          )
        : [];

      // Need to filter by the stem of the policy to get unique ones becaue it can be possible that the policy
      // is Active and the renewal policy for the same home is already created with a status of Future
      const uniqueActiveAndFutureHomePolicies = _uniqBy(activeAndFutureHomePolicies, (policy) => {
        const policyIdArray = policy.id.split('-');
        const stem = `${policyIdArray[0]}-${policyIdArray[1]}`;
        return stem;
      });

      return uniqueActiveAndFutureHomePolicies.length > 1;
    },

    get achPolicies() {
      return self.list
        ? _uniqBy(
            _filter(self.list, (policy) => {
              return policy.paymentMethod === paymentMethod.ACH;
            }),
            'stripeBankInfo.stripeBankAccountId'
          )
        : [];
    },
    getStripeBankInfo(policyId) {
      return self.list?.find((e) => e.id === policyId)?.stripeBankInfo;
    },
    getUnsignedBixConversion(policyId) {
      return {
        signedDocUploadedInS3: !self.unsignedBixConversions?.find((policy) => policy.policyId === policyId),
        signedTimestamp: self.policy.bixConversionSignedTimestamp
      };
    },
    getRecentPolicy() {
      return _orderBy(self.list, (policy) => policy.versionHistory[0].updatedDateTime, ['desc'])[0];
    },
    get unsigned() {
      if (self.unsignedApplications && self.unsignedApplications.length && self.list.length) {
        const [application] = self.unsignedApplications;
        return self.list.some((p) => p.id === application.policyId) ? application : null;
      }
      return null;
    }
  }));
