import { paymentType as PaymentType } from '@ourbranch/lookups';
import { differenceInCalendarDays, getDate, addMonths, setDate } from 'date-fns';
import { awsDateToDateObject } from 'core/helpers/formatters';

const firstInstallmentBillingDate = (effectiveDate, billingDayOfMonth) => {
  return setDate(addMonths(effectiveDate, getDate(effectiveDate) > billingDayOfMonth ? 1 : 0), billingDayOfMonth);
};

export const canOfferMegaDownPay = (effectiveDateIn, billingDayOfMonth, paymentType) => {
  if (effectiveDateIn && billingDayOfMonth && paymentType) {
    const effectiveDate = awsDateToDateObject(effectiveDateIn);
    return (
      paymentType === PaymentType.Monthly &&
      differenceInCalendarDays(firstInstallmentBillingDate(effectiveDate, billingDayOfMonth), effectiveDate) < 20 &&
      getDate(effectiveDate) !== billingDayOfMonth
    );
  }
  return false;
};

// returns a new global object with correctly configured megadownpay
export const setMegaDownPayOnOffer = (details) => {
  const { global } = details;
  let updatedAutoMegaDownPay = global.autoMegaDownPay;
  let updatedHomeMegaDownPay = global.homeMegaDownPay;
  let updatedRentersMegaDownPay = global.rentersMegaDownPay;
  let updatedCondoMegaDownPay = global.condoMegaDownPay;

  if (details.selectedOption?.includes('A') && global.autoMegaDownPay) {
    updatedAutoMegaDownPay =
      canOfferMegaDownPay(global.autoEffectiveDate, global.autoBillingDayOfMonth, global.autoPaymentType) || null;
  }
  if (details.selectedOption?.includes('H') && global.homeMegaDownPay) {
    updatedHomeMegaDownPay =
      canOfferMegaDownPay(global.homeEffectiveDate, global.homeBillingDayOfMonth, global.homeownersPaymentType) || null;
  }
  if (details.selectedOption?.includes('R') && global.rentersMegaDownPay) {
    updatedRentersMegaDownPay =
      canOfferMegaDownPay(global.rentersEffectiveDate, global.rentersBillingDayOfMonth, global.rentersPaymentType) ||
      null;
  }

  if (details.selectedOption?.includes('C') && global.condoMegaDownPay) {
    updatedCondoMegaDownPay =
      canOfferMegaDownPay(global.condoEffectiveDate, global.condoBillingDayOfMonth, global.condoPaymentType) || null;
  }

  return {
    ...global,
    homeMegaDownPay: updatedHomeMegaDownPay,
    autoMegaDownPay: updatedAutoMegaDownPay,
    rentersMegaDownPay: updatedRentersMegaDownPay,
    condoMegaDownPay: updatedCondoMegaDownPay
  };
};
