/* eslint-disable no-underscore-dangle */
import { types, flow } from 'mobx-state-tree';
import _uniqBy from 'lodash-es/uniqBy';

import { affinityLookupsRequest, agencyInformationRequest } from './core-resources.service';

const GET_ALL = 'ALL';
/**  This store will be use for general metadata
 * we need to ask in parallel of the policy data
 */
export const AffinityLookupsStore = types
  .model({
    // we use loading for page blocking operations
    loading: types.boolean,
    // we use partial loading, for component blocking operations
    partialLoading: types.boolean,
    data: types.optional(types.frozen(), []),
    agencyInformation: types.optional(types.frozen(), [])
  })
  .views((self) => ({
    get securityProviders() {
      return _uniqBy(
        self.data.filter((aff) => aff.data.homeSecurity),
        'name'
      ).map((aff) => ({ id: aff.name, value: aff.name }));
    }
  }))
  .actions((self) => ({
    getSuggestedAffinities: flow(function* getSuggestedAffinities({ queryParams, limit = 10, requestOptions = {} }) {
      const { affinity, name } = queryParams;
      if (affinity || name) {
        self.partialLoading = true;
        try {
          const response = yield affinityLookupsRequest({
            limit,
            requestOptions,
            query: queryParams,
            isFuzzySearch: true
          });
          self.data = _uniqBy(self.data.concat(response), 'affinity') || [];
          self.partialLoading = false;
        } catch (e) {
          self.partialLoading = false;
        }
      }
    }),
    getByAffinity: flow(function* getByAffinity(affinity) {
      if (!self.data?.find((aff) => aff.affinity === affinity)) {
        self.partialLoading = true;
        try {
          const response = yield affinityLookupsRequest({ query: { affinity } });
          self.data = _uniqBy(self.data.concat(response), 'affinity') || [];
          self.partialLoading = false;
        } catch (e) {
          self.partialLoading = false;
        }
      }
    }),
    getByAffinityList: flow(function* getByAffinityList(list, limit) {
      if (list.length) {
        self.partialLoading = true;
        try {
          const response = yield affinityLookupsRequest({ query: { affinities: list }, limit });
          self.data = _uniqBy(self.data.concat(response), 'affinity');
          self.partialLoading = false;
        } catch (e) {
          self.partialLoading = false;
        }
      }
    }),
    getSecurityProviders: flow(function* getSecurityProviders() {
      self.partialLoading = true;
      try {
        const response = yield affinityLookupsRequest({ query: { data: { homeSecurity: true } }, limit: GET_ALL });
        self.data = _uniqBy(self.data.concat(response), 'affinity');
        self.partialLoading = false;
      } catch (e) {
        self.partialLoading = false;
      }
    }),
    getAgencyInformation: flow(function* getAgencyInformation(code) {
      self.partialLoading = true;
      try {
        const response = yield agencyInformationRequest(code);
        // if is not found, just store an empty object to fix the de-sync between cognito users and our data
        self.agencyInformation = { ...self.agencyInformation, [code]: response.length > 0 ? response[0] : {} };
        self.partialLoading = false;
      } catch (e) {
        self.partialLoading = false;
      }
    })
  }));
