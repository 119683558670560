import { useRouteMatch } from 'react-router-dom';
import { useFormikContext } from 'formik';
import merge from 'lodash-es/merge';

import useSession from 'core/hooks/use-session';
import { PermissionRequireTypes, CognitoPermissionGroups } from 'core/helpers/cognito-permission-groups';
import { useCurrentState } from 'common/hooks/useCurrentState';

export const defaultPermissions = {
  edit: { groups: [CognitoPermissionGroups.canEdit], type: PermissionRequireTypes.AtLeastOne },
  isLicensedAction: true
};

export function useFormFieldPermissionHelpers({ permissions: permissionsIn }) {
  const permissions = merge({}, defaultPermissions, permissionsIn); // use deep merge since prop-types lib does only shallow merge
  const session = useSession();
  const { values } = useFormikContext();
  const geographicState = useCurrentState(values);
  const inPolicyRoute = !!useRouteMatch({ path: '/customer/:id/policy/:policyId' })?.isExact;
  const inOfferEditRoute = !!useRouteMatch({ path: '/offer/:offerId' })?.isExact;
  const inUWFormRoute = !!useRouteMatch({ path: '/offer/:offerId/verifyUW' })?.isExact;
  const inOfferCheckoutRoute = !!useRouteMatch({ path: '/offer/:offerId/:option/checkout' })?.isExact;
  const inCustomerRoute = !!useRouteMatch({ path: '/customer/:id' })?.isExact;
  const inNewQuoteRoute = !!useRouteMatch({ path: '/quote' })?.isExact;

  const getLicenseAllowsModifyingField = () => {
    if (inPolicyRoute && permissions.isLicensedAction) {
      return session.allowedStates.includes(geographicState);
    }
    if (inPolicyRoute && !permissions.isLicensedAction) {
      return session.unlicensedStates.includes(geographicState) || session.allowedStates.includes(geographicState);
    }
    if (inOfferEditRoute || inOfferCheckoutRoute) {
      return session.allowedStates.includes(geographicState);
    }
    return (
      (inOfferEditRoute && session.canEditOffers) ||
      (inOfferCheckoutRoute && (session.canBind || session.canViewCheckoutPage)) ||
      (inCustomerRoute && session.canEditPolicies) ||
      (inPolicyRoute && (session.isService || session.isSales || session.isTeamLeader)) ||
      (inNewQuoteRoute && session.canQuote) ||
      (inUWFormRoute && (session.canBind || session.canViewCheckoutPage))
    );
  };

  const getCognitoGroupAllowsModifyingField = () => {
    if (session.viewOnly) {
      return false;
    }
    return permissions.edit.type === PermissionRequireTypes.All
      ? permissions.edit.groups.every((permissionGroup) => session[permissionGroup])
      : permissions.edit.groups.find((permissionGroup) => session[permissionGroup]);
  };

  return {
    allowedToModify: getLicenseAllowsModifyingField() && getCognitoGroupAllowsModifyingField()
  };
}
