import React from 'react';

import { Label } from 'core/components/label';
import { Button } from 'core/components/button';

import useStyles from './hardware-mfa.styles';

function ErrorWithMFA() {
  const styles = useStyles();

  return (
    <>
      <Label type="titleSecondary" className={styles.title}>
        Authentication or registration failed. Please try again.
      </Label>
      <Label type="infoInnerTitle" className={styles.content}>
        If you are registering or verifying a device, please follow the browser instructions.
      </Label>
      <Button variant="contained" color="secondary" className={styles.button} onClick={() => window.location.reload()}>
        Back to Sign In
      </Button>
    </>
  );
}

export default ErrorWithMFA;
