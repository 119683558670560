import React, { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { Grid } from '@material-ui/core';
import { observer } from 'mobx-react';
import {
  lookupsJson,
  connectedHomePromoDetails,
  newCustomersDefaultDevices,
  noConnectedHomeProviderSpecialOfferByState
} from '@ourbranch/lookups';

import { useStore } from 'core/store';
import { Label } from 'core/components/label';
import { FormField } from 'core/components/form';
import { LabelTooltip } from 'core/components/label-tooltip';
import {
  connectedHomeTooltipHoverTexts as TooltipHoverTexts,
  NEW_CUSTOMER_HOME_SECURITY
} from 'core/helpers/constants';
import Notification from 'core/helpers/notifications';
import { Disclaimers } from 'core/helpers/disclaimers';
import { NotificationCard } from 'core/components/notification-card';
import { ProvidersName } from 'common/helpers/connected-home';
import { OfferNotifications } from 'offer/components/notifications';
import {
  useNewMonitoringServiceHelpers,
  useNewMonitoringServiceEffects,
  useConnectedHomeSecurityProviderHelpers,
  useHomeSecurityPartnerEffects
} from '../hooks/connected-home.hooks';
import disclaimerIcon from './disclaimer-icon.svg';
import useStyles from './connected-home-form-fields.styles';

const signupOptions = [
  { value: 'existing', label: 'Currently owned' },
  { value: 'new', label: 'New sign up' }
];

const ConnectedHomeFormFields = observer(
  ({ mode, securityProviderOptions, isThereProvidersOfferingNewSignUp, newSignUpProviderFromStoreForOlderOffers }) => {
    const classes = useStyles();
    const { values, setFieldValue, setFieldTouched } = useFormikContext();
    const [showWaterAndSmokeDisclaimer, setShowWaterAndSmokeDisclaimer] = useState(false);
    const lightMode = mode === 'light';
    const Disclaimer = Disclaimers[values?.connectedHome?.providerName];

    const {
      offer: offerStore,
      account: {
        policies: { policy: policyStore }
      },
      affinityLookups: affinityLookupsStore
    } = useStore();

    useNewMonitoringServiceEffects(affinityLookupsStore);

    const isAdvancedConnectedHome = policyStore?.isAdvancedConnectedHome || offerStore.isAdvancedConnectedHome;

    const newSignUpNotification = offerStore?.notifications?.find(
      (notification) => notification === Notification.Offer.NewConnectedHomeSignUpCustomer
    );
    /*
  if signing up for new monitoring service, then
  get the default count of devices given for the new service
  and fill those device counts into the form fields
  */
    const { isSigningUpForHomeSecurity, canAffinityAllowNewCustomerSignUp, homeSecurityCustomerType } =
      useNewMonitoringServiceHelpers(affinityLookupsStore);

    /*
  if there's a home security affinity partner,
  then the following effects should happen:
  1. connectedHome.monitored must be VP or C depending on if it's
  advanced connected home
  2. providerName must be the same as the affinity
  3. these cannot be changed, so fields should be disabled
   */
    useHomeSecurityPartnerEffects({ isAdvancedConnectedHome }, affinityLookupsStore);

    const showDeviceFields =
      !isSigningUpForHomeSecurity || (isSigningUpForHomeSecurity && values?.connectedHome?.providerName !== undefined);

    const geographicState = offerStore.state || policyStore.geographicState;

    const { disableSecurityProvider } = useConnectedHomeSecurityProviderHelpers(
      { isAdvancedConnectedHome },
      affinityLookupsStore
    );

    if (noConnectedHomeProviderSpecialOfferByState[geographicState] && homeSecurityCustomerType === 'new') {
      securityProviderOptions = securityProviderOptions.filter(
        (provider) => !noConnectedHomeProviderSpecialOfferByState[geographicState].includes(provider.value)
      );
    }

    // disable device fields if new sign up AND auto filling, or new sign up from old offer (honor the old offer sign up)
    const disableDeviceFields =
      (Object.keys(newCustomersDefaultDevices).includes(values.connectedHome.providerName) ||
        !isThereProvidersOfferingNewSignUp) &&
      isSigningUpForHomeSecurity;

    const setHomeSecurityPartnerCustomerType = (e) => {
      setFieldValue(
        'global.homeSecurityPartnerCustomerType',
        e.target.value === 'new' ? NEW_CUSTOMER_HOME_SECURITY.SIGN_UP_TYPE : NEW_CUSTOMER_HOME_SECURITY.NO_SIGN_UP_TYPE
      );
      setFieldTouched('global.homeSecurityPartnerCustomerType');
      setFieldTouched('connectedHome.homeSecurityPartnerCustomerTypeOption');
      setFieldValue('connectedHome.homeSecurityPartnerCustomerTypeOption', e.target.value);
    };

    useEffect(() => {
      if (
        showDeviceFields &&
        values?.connectedHome?.providerName === ProvidersName.SimpliSafe &&
        (values?.connectedHome?.smokeDetectingDevices === 0 || values?.connectedHome?.moistureDevices === 0)
      ) {
        setShowWaterAndSmokeDisclaimer(true);
      } else {
        setShowWaterAndSmokeDisclaimer(false);
      }
    }, [showDeviceFields, values.connectedHome]);

    return (
      <>
        <Grid container justify="flex-start" alignItems="center">
          <Grid item container alignItems="center">
            <Grid item>
              <Label type={lightMode ? null : 'smallSubtitleLight'}>Monitoring system:</Label>
            </Grid>

            {((canAffinityAllowNewCustomerSignUp && isThereProvidersOfferingNewSignUp) ||
              (canAffinityAllowNewCustomerSignUp && newSignUpProviderFromStoreForOlderOffers)) && (
              <FormField
                type="radio"
                name="connectedHome.homeSecurityPartnerCustomerTypeOption"
                mode={mode}
                value={homeSecurityCustomerType}
                onChange={setHomeSecurityPartnerCustomerType}
                disabled={!securityProviderOptions.length}
                fast={false}
                options={signupOptions}
              />
            )}
          </Grid>

          {isSigningUpForHomeSecurity && newSignUpNotification && <OfferNotifications type="tertiary" />}

          {!!securityProviderOptions.length && isSigningUpForHomeSecurity && (
            <Grid container item xs={12}>
              <FormField
                type="select"
                name="connectedHome.providerName"
                mode={mode}
                options={securityProviderOptions}
                fast={false}
                disabled={disableSecurityProvider}
                label="Featured Security Provider"
              />
            </Grid>
          )}

          {!isSigningUpForHomeSecurity && (
            <>
              <Grid item xs={8}>
                <FormField
                  type={isAdvancedConnectedHome ? 'autocomplete' : 'select'}
                  name="connectedHome.providerName"
                  mode={mode}
                  options={securityProviderOptions}
                  allowUserInput={isAdvancedConnectedHome}
                  fast={false}
                  disabled={disableSecurityProvider}
                  label="Security Provider"
                  xs={12}
                />
              </Grid>

              <Grid item xs={4}>
                <LabelTooltip
                  label="Monitoring Type"
                  mode={mode}
                  tooltip={{ label: 'More Info', onHoverText: TooltipHoverTexts.monitoringTypes.bix }}
                >
                  <FormField
                    type="value"
                    name="connectedHome.monitored"
                    mode={mode}
                    fast={false}
                    formatter={(value) => {
                      const found = isAdvancedConnectedHome
                        ? lookupsJson.monitoringTypesAdvancedConnectedHome.find(({ id }) => id === value)
                        : lookupsJson.monitoringTypesRegularConnectedHome.find(({ id }) => id === value);
                      return found ? found.value : '';
                    }}
                  />
                </LabelTooltip>
              </Grid>
            </>
          )}

          {isSigningUpForHomeSecurity && values.connectedHome.providerName && (
            <>
              <Grid space={8}>
                {connectedHomePromoDetails[values.connectedHome.providerName]?.promoItems && (
                  <>
                    <Label className={classes.spacing} type={lightMode ? 'label' : 'discountPromo'}>
                      Promo Details
                    </Label>
                    <ul>
                      {connectedHomePromoDetails[values.connectedHome.providerName]?.promoItems.map((details) => (
                        <li className={classes.listBullets} key={details}>
                          <Label type={lightMode ? 'infoValue' : 'discount'}>{details}</Label>
                        </li>
                      ))}
                    </ul>
                  </>
                )}
                {Object.keys(Disclaimers).includes(values.connectedHome.providerName) && (
                  <FormField
                    type="checkbox"
                    name="connectedHome.agreeToTerms"
                    mode={mode}
                    label={<Disclaimer type={lightMode ? 'infoValue' : 'discount'} className={classes.privacyLink} />}
                    disabled={!isThereProvidersOfferingNewSignUp}
                    xs={12}
                    fast={false}
                    className={classes.agreementCheckbox}
                  />
                )}
              </Grid>
            </>
          )}

          {showDeviceFields && (
            <>
              <Grid container justify="space-between">
                <Grid item xs={4}>
                  <LabelTooltip
                    label="Moisture Devices"
                    mode={mode}
                    tooltip={{ label: 'More Info', onHoverText: TooltipHoverTexts.moisture.bix }}
                  >
                    <FormField
                      type="numeric"
                      name="connectedHome.moistureDevices"
                      mode={mode}
                      fast={false}
                      disabled={disableDeviceFields}
                    />
                  </LabelTooltip>
                </Grid>
                <Grid item xs={4}>
                  <LabelTooltip
                    label="Smoke Detecting Devices"
                    mode={mode}
                    tooltip={{ label: 'More Info', onHoverText: TooltipHoverTexts.smoke.bix }}
                  >
                    <FormField
                      type="numeric"
                      name="connectedHome.smokeDetectingDevices"
                      mode={mode}
                      fast={false}
                      disabled={disableDeviceFields}
                    />
                  </LabelTooltip>
                </Grid>

                <Grid item xs={4}>
                  <LabelTooltip
                    label="Theft Prevention Devices"
                    mode={mode}
                    tooltip={{ label: 'More Info', onHoverText: TooltipHoverTexts.theft.bix }}
                  >
                    <FormField
                      type="numeric"
                      name="connectedHome.theftPreventionDevices"
                      mode={mode}
                      fast={false}
                      disabled={disableDeviceFields}
                    />
                  </LabelTooltip>
                </Grid>

                {!isAdvancedConnectedHome && (
                  <Grid item xs={4}>
                    <LabelTooltip
                      label="Motion Detecting Devices"
                      mode={mode}
                      tooltip={{ label: 'More Info', onHoverText: TooltipHoverTexts.motion.nonbix }}
                    >
                      <FormField
                        type="numeric"
                        name="connectedHome.motionDetectingDevices"
                        mode={mode}
                        fast={false}
                        disabled={disableDeviceFields}
                      />
                    </LabelTooltip>
                  </Grid>
                )}
              </Grid>
              <FormField
                className={classes.autoWaterShutoff}
                type="checkbox"
                name="connectedHome.autoWaterShutoffDevices"
                mode={mode}
                label="Auto Water Shutoff Device"
                tooltipText={TooltipHoverTexts.water.bix}
                tooltipLabel="More Info"
                xs={6}
                fast={false}
                disabled={disableDeviceFields}
              />
              {showWaterAndSmokeDisclaimer && (
                <NotificationCard type="light" icon={disclaimerIcon} className={classes.disclaimer}>
                  <Grid container alignItems="center">
                    As an existing SimpliSafe customer, the member will receive a free moisture and smoke bundle to help
                    maximize their discount.
                  </Grid>
                </NotificationCard>
              )}
            </>
          )}
        </Grid>
      </>
    );
  }
);

export default ConnectedHomeFormFields;
