import React from 'react';
import PropTypes from 'prop-types';
import { Autocomplete as MaterialAutocomplete } from '@material-ui/lab';
import { TextField as MaterialTextField } from '@material-ui/core';
import { omit } from 'lodash-es';

const Autocomplete = (props) => {
  const {
    id = '',
    name = '',
    inputProps: fieldProps = {},
    InputProps: MuiInputProps = {},
    onBlur = () => {},
    onSelection = () => {},
    options = [],
    value = '',
    className = '',
    InputComponent = MaterialTextField,
    ...restProps
  } = props;

  return (
    <MaterialAutocomplete
      fullWidth
      freeSolo
      openOnFocus
      id={id}
      options={options}
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.text || '')}
      closeIcon={null}
      popupIcon={null}
      onChange={onSelection}
      value={value}
      renderInput={(params) => {
        const { InputProps, InputLabelProps, ...restParams } = params;

        // We omit default material's className so it doesn't mess up our custom TextField styles
        const InputPropsWithoutClass = omit(InputProps, ['className']);
        return (
          <InputComponent
            {...restParams}
            {...fieldProps}
            className={className}
            InputProps={{ ...InputPropsWithoutClass, ...MuiInputProps }}
            key={id}
            name={name || id}
            onBlur={onBlur}
            labelProps={InputLabelProps}
          />
        );
      }}
      {...restProps}
    />
  );
};

Autocomplete.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  inputProps: PropTypes.object,
  InputProps: PropTypes.object,
  InputComponent: PropTypes.oneOfType([PropTypes.object, PropTypes.node, PropTypes.element]),
  onBlur: PropTypes.func,
  options: PropTypes.array,
  onSelection: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  className: PropTypes.string
};

export default Autocomplete;
