import React, { useState, useCallback } from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';

import { useStore } from 'core/store';
import useSession from 'core/hooks/use-session';
import ValueField from 'core/components/value-field';
import { addressFormatter } from 'core/helpers/formatters';
import { useToast } from 'core/components/toast';
import emailIcon from 'core/assets/svg/email.svg';
import SetPassword from '../set-user-password/set-user-password';
import AdditonalPhoneNumbers from '../additional-phone-numbers';
import Suspicious from '../suspicious';
import useStyles from './account-details-display.styles';

const AccountDetailsDisplay = () => {
  const { account } = useStore();
  const classes = useStyles();
  const { canEditPolicies, viewOnly, canFlagAsSuspicious } = useSession();
  const [resetLoading, setResetStatus] = useState(false);
  const toast = useToast();

  const resetPassword = useCallback(async () => {
    setResetStatus(true);
    try {
      const { data, errors } = await account.resetPwd(account.id);
      if (data && data.resetPwd.success) {
        toast.notify({
          type: 'success',
          message: `We sent a temporary password to  ${account.email ? account.email : ''}`
        });
      } else if (errors) {
        toast.notify({
          type: 'error',
          message: 'There was an error resetting the password'
        });
      }
    } catch (error) {
      toast.notify({
        type: 'error',
        message: 'There was an error resetting the password'
      });
    }
    setResetStatus(false);
  }, [setResetStatus, account, toast]);

  const inviterLabel = ({ firstName, lastName, id }) =>
    id || firstName ? (
      <>
        <Link to={`/customer/${id}`} className={classes.inviterLink}>
          {`${firstName} ${lastName}` || id}
        </Link>
      </>
    ) : (
      'No Referrer'
    );

  return (
    <>
      <ValueField value={`${account.fname} ${account.lname}`} />
      <ValueField
        label="Mailing Address"
        value={(account.mailingAddress && addressFormatter(account.mailingAddress)) || ''}
      />
      <ValueField label="Email" value={account.email} />
      <ValueField label="Primary Phone" value={account.phoneNumber} />
      <AdditonalPhoneNumbers />
      <ValueField label="Customer Since" value={new Date(account.created).toLocaleDateString()} />
      {(canEditPolicies || viewOnly) && <ValueField label="Referred By" value={inviterLabel(account.inviter)} />}
      {canEditPolicies && (
        <>
          <Button
            disabled={resetLoading}
            disableFocusRipple
            className={classes.actionContainer}
            onClick={resetPassword}
            variant="text"
            color="secondary"
          >
            <img
              alt="email icon"
              className={classNames(classes.icon, { [classes.disabledIcon]: resetLoading })}
              src={emailIcon}
            />
            Send temporary password
          </Button>
          <SetPassword />
          {canFlagAsSuspicious && <Suspicious />}
        </>
      )}
    </>
  );
};

export default observer(AccountDetailsDisplay);
