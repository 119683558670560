import React, { useCallback, useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { addDays } from 'date-fns';
import getDate from 'date-fns/getDate';
import parseISO from 'date-fns/parseISO';
import { observer } from 'mobx-react';
import { mortgageLookups, paymentMethod, paymentType, originalGSNICStates } from '@ourbranch/lookups';
import { useStore } from 'core/store';
import Section from 'core/components/section';
import { Card } from 'core/components/card';
import { Label } from 'core/components/label';
import { FormField } from 'core/components/form';
import { NotificationCard } from 'core/components/notification-card';
import withDatePicker from 'core/components/with-date-picker';
import { canOfferMegaDownPay } from '../../helpers/mega-down-pay-helpers';
import useStyles from './settings.styles';
import AddressAutocomplete from 'core/components/address-autocomplete';

const mortgageList = mortgageLookups?.map((mortgage, index) => ({
  id: index,
  text: mortgage.correctName
}));

const HomeSettings = observer(function HomeSettings({ paymentMethodOptions, billingDaysOptions, paymentTypeOptions }) {
  const { offer } = useStore();
  const classes = useStyles();
  const { values, setFieldValue, setFieldTouched } = useFormikContext();
  const { home: { mortgageDetails } = {} } = values;
  const { isBix } = offer;
  const [isPaymentMortgage, setIsPaymentMortgage] = useState(
    values.global.homeownersPaymentMethod === paymentMethod.Escrow
  );

  const offerMegaDownPay = useMemo(
    () =>
      canOfferMegaDownPay(
        values.global.homeEffectiveDate,
        values.global.homeBillingDayOfMonth,
        values.global.homeownersPaymentType
      ),
    [values.global.homeEffectiveDate, values.global.homeBillingDayOfMonth, values.global.homeownersPaymentType]
  );

  // pre-fill the mortgage lender address based on selection
  const handleMortgageSelection = useCallback(
    (_, { text, id }) => {
      const mortgage = mortgageLookups.find((mgItem) => mgItem.correctName === text);
      const primaryIndex = mortgageDetails.findIndex((detail) => detail.primary);
      const mortgageDetailIndex = primaryIndex > -1 ? primaryIndex : mortgageDetails.length;
      if (mortgage) {
        const { address, address2, city, state, zip, correctName, id: mortgageID } = mortgage;
        const mortgageHolderAddress = {
          address,
          address2,
          city,
          state,
          zip
        };
        setFieldValue(`primaryMortgageDetail.mortgageHolderAddress`, mortgageHolderAddress);
        setFieldValue(`primaryMortgageDetail.mortgageHolderName`, correctName);
        setFieldValue(`primaryMortgageDetail.mortgageHolderId`, mortgageID || id);
        setFieldValue(`home.mortgageDetails[${mortgageDetailIndex}].mortgageHolderAddress`, mortgageHolderAddress);
        setFieldValue(`home.mortgageDetails[${mortgageDetailIndex}].mortgageHolderName`, correctName);
        setFieldValue(`home.mortgageDetails[${mortgageDetailIndex}].id`, mortgageID || id);
        setFieldValue(`home.mortgageDetails[${mortgageDetailIndex}].primary`, true);
      }
    },
    [setFieldValue, mortgageDetails]
  );

  const handleLoanNumberChange = ({ currentTarget: { value } }) => {
    if (!value) return;

    const primaryIndex = mortgageDetails.findIndex((detail) => detail.primary);
    const mortgageDetailIndex = primaryIndex > -1 ? primaryIndex : mortgageDetails.length;
    setFieldValue(`home.mortgageDetails[${mortgageDetailIndex}].loanNumber`, value);
    setFieldValue(`home.mortgageDetails[${mortgageDetailIndex}].primary`, true);
  };

  useEffect(() => {
    setIsPaymentMortgage(values.global.homeownersPaymentMethod === paymentMethod.Escrow);
  }, [values]);

  useEffect(() => {
    // Effects to do on first render:
    // touch fields so that validation errors will show
    if (isPaymentMortgage) {
      [
        'primaryMortgageDetail.mortgageHolderName',
        'primaryMortgageDetail.loanNumber',
        'primaryMortgageDetail.mortgageHolderAddress.address',
        'primaryMortgageDetail.mortgageHolderAddress.city',
        'primaryMortgageDetail.mortgageHolderAddress.zip',
        'primaryMortgageDetail.mortgageHolderAddress.state'
      ].forEach((fieldName) => setFieldTouched(fieldName));
    }
  }, []);

  // this is used to default the billing day of the month to the day that the policy is effective
  const homeEffectiveDay = useMemo(() => {
    if (values.global.homeEffectiveDate) {
      return getDate(parseISO(values.global.homeEffectiveDate));
    }
  }, [values]);

  // if home billing day of month is null, default the selection to the effective day of the policy
  // or the 28th if the effectiveDay is past the allowed options of days 1 - 28
  useEffect(() => {
    if (!values.global.homeBillingDayOfMonth && values.global.homeBillingDayOfMonth !== homeEffectiveDay) {
      if (homeEffectiveDay > 28) {
        setFieldValue('global.homeBillingDayOfMonth', 28);
      } else {
        setFieldValue('global.homeBillingDayOfMonth', homeEffectiveDay);
      }
    }
  }, [values, homeEffectiveDay, setFieldValue]);

  const state = offer?.state;

  const showCreditCardNote =
    values.global.homeownersPaymentMethod === paymentMethod.ACH && !isBix && originalGSNICStates.includes(state);

  const onHomePaymentChange = useCallback(
    (homePaymentMethod) => {
      if (homePaymentMethod === paymentMethod.Escrow) {
        // set payment freq to Mortgage
        setFieldValue('global.homeownersPaymentType', paymentType.Escrow);
      } else {
        setFieldValue('global.homeownersPaymentType', paymentType.OneTime);
      }
    },
    [setFieldValue]
  );

  return (
    <Section title="Home Policy Settings" type="SubSection">
      <Card className={classes.overflowContainer} type="secondary">
        <Grid container xs={12}>
          <Label type="formSubTitle" className={classes.subtitle}>
            Policy Term
          </Label>
        </Grid>
        <Grid container>
          <FormField
            type="date"
            name="global.homeEffectiveDate"
            label="Home policy effective date"
            xs={4}
            mode="dark"
            disableFuture={false}
            maxDate={addDays(new Date(), 60)}
            fast={false}
          />
        </Grid>
        <Grid container alignItems="center" spacing={2} xs={12}>
          <Grid item xs={2}>
            <Label type="formSubTitle" className={classes.subtitle}>
              Billing Details
            </Label>
          </Grid>
          {showCreditCardNote && (
            <Grid item xs={6}>
              <Label type="noteSmall">Note: There is no additional fee for paying with credit card.</Label>
            </Grid>
          )}
        </Grid>
        <Grid container>
          <Grid container item xs={12} spacing={4}>
            <FormField
              type="select"
              name="global.homeBillingDayOfMonth"
              label="Home Billing Day"
              mode="dark"
              options={billingDaysOptions}
              xs={4}
              fast={false}
            />
            <FormField
              label="Home payment method"
              xs={4}
              type="select"
              name="global.homeownersPaymentMethod"
              options={paymentMethodOptions}
              onChange={onHomePaymentChange}
              mode="dark"
            />
            <FormField
              label="Home payment frequency"
              xs={4}
              type="select"
              name="global.homeownersPaymentType"
              options={paymentTypeOptions.filter((option) => {
                if (!isPaymentMortgage) {
                  return option.id !== paymentType.Escrow;
                }
                return option;
              })}
              mode="dark"
              disabled={isPaymentMortgage}
              fast={false}
            />
          </Grid>
        </Grid>
        {isPaymentMortgage && (
          <Grid container>
            <Grid container spacing={4}>
              <FormField
                mode="dark"
                name={`primaryMortgageDetail.mortgageHolderName`}
                options={mortgageList}
                onSelection={handleMortgageSelection}
                label="Lender Name *"
                type="autocomplete"
                xs={4}
                allowUserInput
                fast={false}
                optionsLimit={10}
              />
              <FormField
                name={`primaryMortgageDetail.loanNumber`}
                type="string"
                label="Loan Number *"
                mode="dark"
                xs={8}
                onBlur={handleLoanNumberChange}
              />
            </Grid>
            <Grid container spacing={4}>
              <AddressAutocomplete
                name="primaryMortgageDetail"
                id="primaryMortgageDetail.mortgageHolderAddress.address"
                type="string"
                label="Mortgage Holder Address *"
                xs={8}
                mode="dark"
              />
              <FormField
                name={`primaryMortgageDetail.mortgageHolderAddress.address2`}
                type="string"
                label="Address 2"
                xs={4}
                mode="dark"
              />
              <FormField
                name={`primaryMortgageDetail.mortgageHolderAddress.city`}
                type="string"
                label="City *"
                xs={4}
                mode="dark"
              />
              <FormField
                name={`primaryMortgageDetail.mortgageHolderAddress.state`}
                type="state"
                label="State *"
                xs={4}
                mode="dark"
              />
              <FormField
                name={`primaryMortgageDetail.mortgageHolderAddress.zip`}
                type="string"
                label="Zip code *"
                xs={4}
                mode="dark"
              />
            </Grid>
          </Grid>
        )}
        {offerMegaDownPay && (
          <>
            <Grid container>
              <FormField
                type="checkbox"
                name="global.homeMegaDownPay"
                id="global.homeMegaDownPay"
                label="Add second payment to down payment billing"
                fast={false}
              />
            </Grid>
            {!values.global.homeMegaDownPay && (
              <NotificationCard type="quaternary">
                <Label className={classes.warningText}>
                  The member will be charged a second payment within 20 days of making their down payment.
                </Label>
              </NotificationCard>
            )}
          </>
        )}
      </Card>
    </Section>
  );
});

HomeSettings.propTypes = {
  paymentMethodOptions: PropTypes.array.isRequired,
  billingDaysOptions: PropTypes.array.isRequired,
  paymentTypeOptions: PropTypes.array.isRequired
};

export default withDatePicker(HomeSettings);
