import React, { useState, useCallback } from 'react';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import classNames from 'classnames';
import { lookupsJson } from '@ourbranch/lookups';

import { Label } from 'core/components/label';
import { FormField } from 'core/components/form';
import caretOrange from 'core/assets/svg/caretOrange.svg';
import { yearOptions } from 'core/helpers/year-options';
import { ElemType, MapClassesToElem } from 'core/helpers/styles-helpers';

export const AddCarDetails = ({ fieldName, classes, state }) => {
  const [open, setOpen] = useState(false);

  const onToggle = useCallback(() => {
    setOpen(!open);
  }, [setOpen, open]);
  return (
    <Grid container className={classes.addCarDetailsForm}>
      <Grid container item className={classes.addCarDetailsButtonContainer} onClick={onToggle}>
        <Label className={classes.addCarDetailsLabel}>Additional Car Details</Label>
        <IconButton classes={MapClassesToElem(ElemType.IconButton, classes)}>
          <img
            className={classNames(classes.icon, { [classes.collapse]: open })}
            src={caretOrange}
            alt={open ? 'fold' : 'unfold'}
          />
        </IconButton>
      </Grid>
      {open && (
        <Grid className={classes.noMargin} spacing={4} container item justify="space-between">
          <FormField
            type="select"
            id={`${fieldName}.year`}
            name={`${fieldName}.year`}
            label="Year"
            options={yearOptions(1981)}
            mode="light"
            xs={4}
          />
          <FormField
            type="string"
            id={`${fieldName}.make`}
            name={`${fieldName}.make`}
            label="Make"
            mode="light"
            xs={4}
          />
          <FormField
            type="string"
            id={`${fieldName}.model`}
            name={`${fieldName}.model`}
            label="Model"
            mode="light"
            xs={4}
          />
          <FormField
            type="select"
            id={`${fieldName}.antiTheftStatus`}
            name={`${fieldName}.antiTheftStatus`}
            label="Anti Theft"
            options={lookupsJson.vehicleEquipmentStatusCodes}
            mode="light"
            xs={4}
          />
          <FormField
            type="select"
            id={`${fieldName}.blindSpotStatus`}
            name={`${fieldName}.blindSpotStatus`}
            label="Blind Spot"
            options={lookupsJson.vehicleEquipmentStatusCodes}
            mode="light"
            xs={4}
          />
          <FormField
            type="select"
            id={`${fieldName}.aebStatus`}
            name={`${fieldName}.aebStatus`}
            label="AEB"
            options={lookupsJson.vehicleEquipmentStatusCodes}
            mode="light"
            xs={4}
          />
          <FormField
            type="select"
            id={`${fieldName}.singleAirbagStatus`}
            name={`${fieldName}.singleAirbagStatus`}
            label="Single Airbag"
            options={lookupsJson.vehicleEquipmentStatusCodes}
            mode="light"
            xs={4}
          />
          <FormField
            type="select"
            id={`${fieldName}.doubleAirbagStatus`}
            name={`${fieldName}.doubleAirbagStatus`}
            label="Dual Airbags"
            options={lookupsJson.vehicleEquipmentStatusCodes}
            mode="light"
            xs={4}
          />
          <FormField
            className={classes.luxuryCheckbox}
            type="checkbox"
            id={`${fieldName}.luxury`}
            name={`${fieldName}.luxury`}
            label="Luxury"
            mode="light"
            xs={4}
          />
          <FormField
            type="string"
            id={`${fieldName}.symbolMake`}
            name={`${fieldName}.symbolMake`}
            label="Make Symbol"
            mode="light"
            xs={3}
          />
          <FormField
            type="string"
            id={`${fieldName}.symbolAux`}
            name={`${fieldName}.symbolAux`}
            label="Aux Symbol"
            mode="light"
            xs={3}
          />
          <FormField
            type="string"
            id={`${fieldName}.symbolModel`}
            name={`${fieldName}.symbolModel`}
            label="Model Symbol"
            mode="light"
            xs={3}
          />
          <FormField
            type="string"
            id={`${fieldName}.symbolStyle`}
            name={`${fieldName}.symbolStyle`}
            label="Style Symbol"
            mode="light"
            xs={3}
          />
          {state === 'FL' && (
            <>
              <FormField
                type="string"
                id={`${fieldName}.symbolPGS`}
                name={`${fieldName}.symbolPGS`}
                label="PGS"
                mode="light"
                xs={3}
              />
              <FormField
                type="numeric"
                id={`${fieldName}.symbolBI`}
                name={`${fieldName}.symbolBI`}
                label="BI"
                mode="light"
                xs={3}
              />
              <FormField
                type="numeric"
                id={`${fieldName}.symbolPD`}
                name={`${fieldName}.symbolPD`}
                label="PD"
                mode="light"
                xs={3}
              />
              <FormField
                type="numeric"
                id={`${fieldName}.symbolMed`}
                name={`${fieldName}.symbolMed`}
                label="MED"
                mode="light"
                xs={3}
              />
              <FormField
                type="numeric"
                id={`${fieldName}.symbolPIP`}
                name={`${fieldName}.symbolPIP`}
                label="PIP"
                mode="light"
                xs={3}
              />
              <FormField
                type="numeric"
                id={`${fieldName}.symbolColl`}
                name={`${fieldName}.symbolColl`}
                label="COLL"
                mode="light"
                xs={3}
              />
              <FormField
                type="numeric"
                id={`${fieldName}.symbolComp`}
                name={`${fieldName}.symbolComp`}
                label="COMP"
                mode="light"
                xs={3}
              />
              <FormField
                type="numeric"
                id={`${fieldName}.symbolUM`}
                name={`${fieldName}.symbolUM`}
                label="UM/UIM"
                mode="light"
                xs={3}
              />
            </>
          )}
        </Grid>
      )}
    </Grid>
  );
};
