import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Tab } from '@material-ui/core';

import useStyles from './header-tab.styles';

const MenuItem = ({ smallPadding, iconSize, iconSrc, label, ...props }) => {
  const classes = useStyles(smallPadding);
  const tabClasses = useMemo(() => {
    const { smallIcon, largeIcon, label: labelClass, ...otherClasses } = classes;
    return otherClasses;
  }, [classes]);
  return (
    <Tab
      classes={tabClasses}
      label={
        <>
          <img className={iconSize === 'large' ? classes.largeIcon : classes.smallIcon} src={iconSrc} alt={label} />
          <span className={classes.label}>{label}</span>
        </>
      }
      {...props}
    />
  );
};

MenuItem.propTypes = {
  iconSrc: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  iconSize: PropTypes.oneOf(['large', 'small']),
  smallPadding: PropTypes.bool
};

MenuItem.defaultProps = {
  iconSize: 'large',
  smallPadding: false
};

export default MenuItem;
