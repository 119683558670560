import React from 'react';

import { Label } from 'core/components/label';
import { Button } from 'core/components/button';
import useStyles from './hardware-mfa.styles';

function RegisterMFA({ registerMfa }) {
  const styles = useStyles();
  return (
    <>
      <Label type="titleSecondary" className={styles.title}>
        Register your device
      </Label>
      <Label type="infoInnerTitle" className={styles.content}>
        By enrolling your device as a "security key", you can take advantage of the enhanced security provided by
        WebAuthn for all future logins. If you have any questions or require assistance, our support team is ready to
        help.
      </Label>
      <Label type="infoInnerTitle" className={styles.content}>
        Your browser will prompt you to begin registering a new multi-factor authentication device. If you have a
        password manager, please select the option to use a non-password manager key. The browser will then prompt you
        to create a passkey. Simply follow the on-screen instructions to continue.
      </Label>
      <Label type="infoInnerTitle" className={styles.content}>
        If you plan to use this device exclusively for future logins, select "This device". On the other hand, if you
        plan to log in from multiple devices, choose "A different device". This will prompt you to scan a QR code using
        your mobile phone.
      </Label>
      <Label type="infoInnerTitle" className={styles.content}>
        If you only see the options "A different device" and "USB security key", please proceed with registration using
        your phone as your computer does not support this registration method.
      </Label>
      <Button variant="contained" color="secondary" className={styles.button} onClick={registerMfa}>
        Let's go
      </Button>
    </>
  );
}

export default RegisterMFA;
