import React, { memo, useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useFormikContext } from 'formik';
import Grid from '@material-ui/core/Grid';
import {
  getOptions,
  windstormMitigationDiscountStates,
  stormShuttersStates,
  enhancedRoofWindstormStates,
  enhancedRoofWindstormValues,
  windstormMitigationLevelValues,
  windstormMitigationDiscountYearBuilt,
  windstormMitigationDefinitionsLinkStates,
  stormShuttersVerificationFormLinkStates,
  stormShuttersInAdvancedWindstormSectionStates
} from '@ourbranch/lookups';

import { NotificationCard } from 'core/components/notification-card';
import Field from 'core/components/form/form.v2';
import { Label } from 'core/components/label';
import { LabelTooltip } from 'core/components/label-tooltip';
import { useCurrentState } from 'common/hooks/useCurrentState';
import { AdvancedWindstormInfoModal } from '../advanced-windstorm-info-modal';
import { StormShutters } from '../storm-shutters';
import useStyles from './advanced-windstorm-details.styles';

const id = 'home';

const AdvancedWindstormDetails = ({ fromPolicy }) => {
  const classes = useStyles();
  const [displayAdvancedWindstormDetailsInfoModal, setDisplayAdvancedWindstormDetailsInfoModal] = useState({
    show: false,
    name: '',
    options: null
  });

  const { values } = useFormikContext();
  const { home } = values;
  const state = useCurrentState(values);
  const windstormMitigationLevelOptions = getOptions('windstormMitigationLevel', state, windstormMitigationLevelValues);
  const showStormShutters =
    stormShuttersStates.includes(state) && stormShuttersInAdvancedWindstormSectionStates.includes(state);
  const showEnhancedRoofWindstorm = enhancedRoofWindstormStates[state];
  const showWindstormMitigationLevel =
    windstormMitigationDiscountStates[state] &&
    (!home.yearBuilt || home.yearBuilt < windstormMitigationDiscountYearBuilt);
  const stormShuttersLink =
    fromPolicy && stormShuttersVerificationFormLinkStates[state]
      ? stormShuttersVerificationFormLinkStates[state]
      : null;
  const hideRoofCover = useMemo(() => home.yearBuilt > 2006, [home.yearBuilt]);

  const onTooltipClick = useCallback(
    (name) => {
      const options =
        name === 'windstormMitigationLevel'
          ? windstormMitigationLevelOptions
          : getOptions(name, null, enhancedRoofWindstormValues);
      const definitionsLink =
        name === 'windstormMitigationLevel' && windstormMitigationDefinitionsLinkStates[state]
          ? windstormMitigationDefinitionsLinkStates[state]
          : null;
      setDisplayAdvancedWindstormDetailsInfoModal({ show: true, name, options, definitionsLink });
    },
    [windstormMitigationLevelOptions, getOptions, state]
  );

  return (
    <>
      <Grid item xs={12}>
        <Label type="formSubTitleLight">Advanced Windstorm Details</Label>
      </Grid>
      <NotificationCard type="light">
        <Grid container>
          <b>NOTE</b> The advanced windstorm section requires a detailed knowledge of the construction methods used on
          your home. Proof of these construction methods will be required post-purchase. Your premium could be impacted
          if your documentation does not match the home construction selections made here.
        </Grid>
      </NotificationCard>
      {showEnhancedRoofWindstorm && (
        <>
          <Grid className={classes.gridItem} item xs={6}>
            <LabelTooltip label="Roof Deck" mode="light" onClick={() => onTooltipClick('roofDeck')}>
              <Field
                name={`${id}.roofDeck`}
                type="select"
                mode="light"
                className={classes.field}
                options={enhancedRoofWindstormValues.roofDeck}
                showDefaultValue
              />
            </LabelTooltip>
          </Grid>
          {!hideRoofCover && (
            <Grid className={classes.gridItem} item xs={6}>
              <LabelTooltip label="Roof Cover" mode="light" onClick={() => onTooltipClick('roofCover')}>
                <Field
                  name={`${id}.roofCover`}
                  type="select"
                  mode="light"
                  className={classes.field}
                  options={enhancedRoofWindstormValues.roofCoverState[state]}
                  showDefaultValue
                />
              </LabelTooltip>
            </Grid>
          )}
          <Grid className={classes.gridItem} item xs={6}>
            <LabelTooltip
              label="Roof Deck Attachment"
              mode="light"
              onClick={() => onTooltipClick('roofDeckAttachment')}
              showDefaultValue
            >
              <Field
                name={`${id}.roofDeckAttachment`}
                type="select"
                mode="light"
                className={classes.field}
                options={enhancedRoofWindstormValues.roofDeckAttachment}
                showDefaultValue
              />
            </LabelTooltip>
          </Grid>
          <Grid className={classes.gridItem} item xs={6}>
            <LabelTooltip
              label="Roof To Wall Connection"
              mode="light"
              onClick={() => onTooltipClick('roofToWallConnection')}
            >
              <Field
                name={`${id}.roofToWallConnection`}
                type="select"
                mode="light"
                className={classes.field}
                options={enhancedRoofWindstormValues.roofToWallConnection}
                showDefaultValue
              />
            </LabelTooltip>
          </Grid>
          <Grid className={classes.gridItem} item xs={6}>
            <LabelTooltip
              label="Window Opening Protection"
              mode="light"
              onClick={() => onTooltipClick('openingProtection')}
            >
              <Field
                name={`${id}.openingProtection`}
                type="select"
                mode="light"
                className={classes.field}
                options={enhancedRoofWindstormValues.openingProtection}
                showDefaultValue
              />
            </LabelTooltip>
          </Grid>
          <Grid className={classes.gridItem} item xs={6}>
            <LabelTooltip label="Secondary Water Resistance" mode="light" onClick={() => onTooltipClick('roofSWR')}>
              <Field
                name={`${id}.roofSWR`}
                type="select"
                mode="light"
                className={classes.field}
                options={enhancedRoofWindstormValues.roofSWR}
                showDefaultValue
              />
            </LabelTooltip>
          </Grid>
        </>
      )}
      {showStormShutters && (
        <StormShutters
          className={classes.gridItem}
          checkboxTooltipClassName={classNames({ [classes.stormShuttersTooltip]: stormShuttersLink })}
          tooltipText="All openings of your home must have storm shutters, or be engineered to specifications that provide equivalent protection. After you purchase, you will be asked to submit a proof-of-protection form, signed by a qualified inspector and notarized."
          label="All openings of my home have storm shutters or are engineered to provide equivalent protection."
          link={stormShuttersLink}
        />
      )}
      {showWindstormMitigationLevel && (
        <>
          <Grid className={classes.gridItem} item xs={12}>
            <LabelTooltip
              label="Windstorm Loss Retrofit Level"
              mode="light"
              onClick={() => onTooltipClick('windstormMitigationLevel')}
            >
              <Field
                name={`${id}.windstormMitigationLevel`}
                className={classes.field}
                type="select"
                mode="light"
                options={windstormMitigationLevelOptions}
                showDefaultValue
              />
            </LabelTooltip>
          </Grid>
          <div className={classes.separator} />
        </>
      )}
      {displayAdvancedWindstormDetailsInfoModal.show && (
        <AdvancedWindstormInfoModal
          open={displayAdvancedWindstormDetailsInfoModal.show}
          name={displayAdvancedWindstormDetailsInfoModal.name}
          onClose={() => setDisplayAdvancedWindstormDetailsInfoModal({ show: false })}
          options={displayAdvancedWindstormDetailsInfoModal.options}
          definitionsLink={displayAdvancedWindstormDetailsInfoModal.definitionsLink}
        />
      )}
    </>
  );
};

AdvancedWindstormDetails.propTypes = {
  fromPolicy: PropTypes.bool
};

AdvancedWindstormDetails.defaultProps = {
  fromPolicy: false
};

export default memo(AdvancedWindstormDetails);
