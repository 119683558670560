import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  container: {
    height: theme.sizes.footerHeight,
    display: 'flex',
    justifyContent: 'center',
    position: 'fixed',
    bottom: 0,
    left: 0,
    boxShadow: '0 -2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12)',
    width: '100%',
    backgroundColor: theme.colors.page__background_primary,
    zIndex: 100
  },
  footerInner: {
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 8),
    gap: theme.spacing(0, 8)
  },
  subtitle: {
    fontWeight: 300
  },
  footerItem: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0, 3),
    flexWrap: 'wrap'
  },
  right: {
    flex: '1 0 auto'
  },
  btn: {
    maxWidth: 350
  },
  btnLabel: {
    fontWeight: 500,
    fontSize: 16,
    color: theme.colors.white
  },
  rewriteBtn: {
    color: theme.colorPalette.orange_50
  },
  label: {
    fontSize: 40,
    fontWeight: 100,
    color: theme.colorPalette.green_10
  },
  leftFormLabel: {
    fontSize: 24,
    fontWeight: 500
  },
  withLeftForm: {
    maxWidth: 400,
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    margin: 'auto',
    gap: 0
  },
  last: {
    justifyContent: 'flex-end'
  },
  checkbox: {
    alignItems: 'flex-end',
    justifyContent: 'center'
  },
  checkboxTooltip: {
    position: 'unset'
  }
});

export default makeStyles(styles);
