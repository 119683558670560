import React, { useContext } from 'react';
import { observer } from 'mobx-react';

import { AuthContext } from 'core/components/auth';
import { FormField } from 'core/components/form';
import { Label } from 'core/components/label';
import { Button } from 'core/components/button';
import { withToast } from 'core/components/toast';
import { useStore } from 'core/store/store.mobx';

import useStyles from '../footer.styles';

const UpdateOffer = observer(({ onUpdate }) => {
  const { offer: store } = useStore();
  const classes = useStyles();

  const session = useContext(AuthContext);
  const showOverrideCheckbox = !session.isAgency && session.username !== store.offer?.quote?.rep;

  return (
    <>
      <div className={classes.titleContainer}>
        <Label className={classes.title} type="subtitle">
          Update Offer
        </Label>
      </div>
      <div>
        {showOverrideCheckbox && (
          <FormField
            mode="light"
            name="dontOverwriteRep"
            label="Don't overwrite rep"
            type="checkbox"
            className={classes.checkbox}
          />
        )}
        <Button
          onClick={onUpdate}
          disabled={store.loading}
          loading={store.loading}
          variant="contained"
          color="primary"
          className={classes.btn}
        >
          <Label className={classes.btnLabel}>Update Offer Price</Label>
        </Button>
      </div>
    </>
  );
});
export default withToast(UpdateOffer);
