import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  searchDialog: {
    minWidth: 720,
    minHeight: 290,
    padding: 0,
    position: 'relative',
    flexDirection: 'column',
    display: 'flex',
    backgroundColor: theme.colors.form__background_primary
  },
  searchFooter: {
    height: 125
  },
  linkButton: {
    backgroundColor: theme.colorPalette.orange_10,
    color: theme.colors.white,
    width: 296
  },
  buttonContainer: {
    marginRight: 48
  },
  searchHeader: {
    padding: theme.spacing(7, 0, 0, 12)
  },
  searchBar: {
    padding: theme.spacing(6, 12)
  },
  searchSubtitle: {
    paddingTop: theme.spacing(3)
  },
  closeButton: {
    position: 'absolute',
    top: 5,
    right: 5
  },
  footer: {
    padding: theme.spacing(6, 12, 6),
    display: 'flex',
    flexDirection: 'column'
  },
  email: {
    width: 400
  },
  emailContainer: {
    paddingRight: 0,
    marginTop: theme.spacing(2)
  },
  addReferralButton: {
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10)
  },
  addReferralButtonContainer: {
    padding: 0
  },
  notificationCard: {
    boxShadow: 'none'
  },
  notification: {
    fontSize: 12,
    display: 'inline',
    color: theme.colorPalette.green_20,
    alignItems: 'center'
  },
  emailSection: {
    display: 'flex',
    justifyContent: 'space-between'
  }
}));

export default useStyles;
