import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Form, useFormikContext } from 'formik';
import { range } from 'lodash-es';
import { Grid } from '@material-ui/core';
import { coverageDNotOffered, lookupsJson } from '@ourbranch/lookups';

import { useCurrentState } from 'common/hooks/useCurrentState';
import { FormField } from 'core/components/form';
import { Label } from 'core/components/label';
import { LabelTooltip } from 'core/components/label-tooltip';
import Section from 'core/components/section';
import { tooltipHoverTexts } from 'core/helpers/constants';
import { numberFormatter, numberThousandsFormatter } from 'core/helpers/formatters';
import useOptions from 'core/hooks/use-options';
import { OtherCoverages } from './other-coverages';
import useStyles from './condo-coverage.styles';

const moneyFormat = {
  thousandSeparator: true,
  prefix: '$',
  allowNegative: false
};

const CondoCoverage = ({ disabled, fromPolicy }) => {
  const classes = useStyles();
  const { values } = useFormikContext();
  const state = useCurrentState(values);

  const { condo, condoCoverage } = values;
  const { coverageC, minimumDeductibleValue } = condoCoverage;
  const showCoverageD = !coverageDNotOffered[state];
  const lossAssessmentOptions = useMemo(() => lookupsJson.coverageG.filter((opt) => opt.id), [lookupsJson.coverageG]);
  const liabilityOptions = useOptions('condoLiability', state);
  const guestMedicalLimitOptions = useOptions('guestMedicalLimit', state);
  const allOtherOptions = useOptions('condoDeductibleAllOther', state);

  const allOtherOptionsFormatted = useMemo(() => {
    const formatPercentage = (option) => ({
      ...option,
      value: `${option.value} ($${numberThousandsFormatter(option.id * coverageC)})`
    });

    return allOtherOptions
      .filter((option) => {
        const value = option.id > 1 ? option.id : option.id * coverageC;
        return value >= minimumDeductibleValue;
      })
      .map((option) => (option.id < 1 ? formatPercentage(option) : option));
  }, [allOtherOptions, coverageC, minimumDeductibleValue]);

  const aleOptions = useMemo(
    () =>
      // 10%-50% in 5% increments
      // 50%-200% in 25% increments
      [...range(10, 50, 5), ...range(50, 225, 25)].map((percentage) => ({
        id: percentage,
        value: `${numberFormatter(percentage)}% ($${numberFormatter((percentage * coverageC) / 100)})`
      })),
    [coverageC]
  );

  return (
    <Section title="Condo Policy Coverage" type="SubSection">
      <div className={classes.container}>
        <Form disabled={disabled}>
          <Grid container justify="flex-start" alignItems="center" spacing={4} className={classes.containerDark}>
            <Grid item xs={6}>
              <LabelTooltip
                label="Personal Property Limit"
                tooltip={{
                  label: 'More Info',
                  onHoverText: tooltipHoverTexts.personalPropertyLimit
                }}
              >
                <FormField name="condoCoverage.coverageC" type="numeric" mode="dark" format={moneyFormat} />
              </LabelTooltip>
            </Grid>
            <Grid item className={classes.checkboxContainer} xs={6}>
              <FormField
                type="checkbox"
                name="condoCoverage.personalPropReimbursement"
                mode="dark"
                label="Replacement cost"
                fast={false}
                tooltipText="In a covered loss, this covers personal property up to the full cost of replacement, rather than the actual cash value of the item(s)."
                tooltipLabel="More Info"
              />
            </Grid>

            <Grid item xs={12}>
              <Label type="coverageParagraph" className={classes.coverageLabel}>
                Other Coverages Based on Your Personal Property Limit
              </Label>
            </Grid>
            <Grid item xs={6}>
              <LabelTooltip
                label="Building Property Protection"
                tooltip={{
                  label: 'More Info',
                  onHoverText: tooltipHoverTexts.buildingPropertyProtection
                }}
              >
                <FormField name="condoCoverage.coverageA" type="numeric" mode="dark" format={moneyFormat} />
              </LabelTooltip>
            </Grid>
            {showCoverageD && (
              <Grid item xs={6}>
                <LabelTooltip
                  label="Additional Living Expense"
                  tooltip={{ label: 'More Info', onHoverText: tooltipHoverTexts.additionalLivingExpense }}
                >
                  <FormField name="condoCoverage.coverageDPctOfC" type="select" mode="dark" options={aleOptions} />
                </LabelTooltip>
              </Grid>
            )}
            <Grid item xs={4}>
              <LabelTooltip
                label="Loss Assessments"
                tooltip={{ label: 'More Info', onHoverText: tooltipHoverTexts.lossAssessments }}
              >
                <FormField name="condoCoverage.coverageG" type="select" mode="dark" options={lossAssessmentOptions} />
              </LabelTooltip>
            </Grid>

            <Grid item xs={4}>
              <LabelTooltip
                label="Family Liability"
                tooltip={{ label: 'More Info', onHoverText: tooltipHoverTexts.familyLiability }}
              >
                <FormField name="condoCoverage.coverageX" type="select" mode="dark" options={liabilityOptions} />
              </LabelTooltip>
            </Grid>
            <Grid item xs={4}>
              <LabelTooltip
                label="Guest Medical Limit"
                tooltip={{ label: 'More Info', onHoverText: tooltipHoverTexts.guestMedicalLimit }}
              >
                <FormField
                  name="condoCoverage.coverageY"
                  type="select"
                  mode="dark"
                  options={guestMedicalLimitOptions}
                />
              </LabelTooltip>
            </Grid>
            <Grid container item spacing={2} className={classes.coverageContainer}>
              <Grid item xs={12}>
                <Label type="formSubTitle">Deductible</Label>
              </Grid>
              <Grid item xs={6}>
                <LabelTooltip
                  label="All Perils"
                  tooltip={{
                    label: 'More Info',
                    onHoverText: `${tooltipHoverTexts.deductibleAllOther} Does not include water backup.`
                  }}
                >
                  <FormField
                    name="condoCoverage.deductibleAllOther"
                    type="select"
                    mode="dark"
                    options={allOtherOptionsFormatted}
                  />
                </LabelTooltip>
              </Grid>
            </Grid>
            <OtherCoverages condo={condo} coverage={condoCoverage} state={state} fromPolicy={fromPolicy} />
          </Grid>
        </Form>
      </div>
    </Section>
  );
};

CondoCoverage.propTypes = {
  disabled: PropTypes.bool,
  fromPolicy: PropTypes.bool
};

CondoCoverage.defaultProps = {
  disabled: false,
  fromPolicy: false
};

export default CondoCoverage;
