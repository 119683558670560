import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import { AuthContext } from 'core/components/auth';
import { Table, TableHeaderCell } from 'core';
import { Label } from 'core/components/label';
import NoResults from '../no-results';
import OfferItem from './offer-item';

const OffersResults = ({ loading, hits, totalHits, page, onChangePage, showHeader, isDialogSearch, error }) => {
  const { canQuote, viewOnly } = useContext(AuthContext);
  return (
    <>
      {showHeader && (
        <Label type="subtitle" style={{ marginBottom: 16 }}>
          Offers
        </Label>
      )}

      <Paper>
        {Boolean(loading || hits.length) && (
          <Table
            selfContained={false}
            loading={loading}
            paginationEnabled
            count={totalHits}
            rowsPerPage={hits.length}
            currentPage={page}
            onChangePage={(_, newPage) => onChangePage(newPage)}
            header={
              <>
                {isDialogSearch && <TableHeaderCell columnId="toSelect">To select</TableHeaderCell>}
                <TableHeaderCell columnId="fname">First Name</TableHeaderCell>
                <TableHeaderCell columnId="lname">Last Name</TableHeaderCell>
                <TableHeaderCell columnId="address">Address</TableHeaderCell>
                <TableHeaderCell columnId="city">City</TableHeaderCell>
                <TableHeaderCell columnId="state">State</TableHeaderCell>
                <TableHeaderCell columnId="zip">Zip</TableHeaderCell>
                <TableHeaderCell columnId="updatedDateTime">Last Update</TableHeaderCell>
                {(canQuote || viewOnly) && <TableHeaderCell columnId="edit" />}
              </>
            }
            body={
              <>
                {Boolean(hits && hits.length) &&
                  hits.map((hit) => <OfferItem key={hit.id} offer={hit} isDialogSearch={isDialogSearch} />)}
              </>
            }
          />
        )}
        {!loading && hits.length === 0 && <NoResults error={error} />}
      </Paper>
    </>
  );
};

OffersResults.propTypes = {
  loading: PropTypes.bool.isRequired,
  hits: PropTypes.array,
  totalHits: PropTypes.number,
  page: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  showHeader: PropTypes.bool.isRequired,
  isDialogSearch: PropTypes.bool
};

OffersResults.defaultProps = {
  hits: [],
  totalHits: 0,
  isDialogSearch: false
};

export default OffersResults;
