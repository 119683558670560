import { types, flow, getEnv, getParent } from 'mobx-state-tree';
import omit from 'lodash-es/omit';

import {
  mapRequestQuoteToInputType,
  shouldReject,
  hasRejection,
  formattedError,
  mapRevisedQuinstreetQuoteDetails
} from 'core/helpers/quoter.service';
import { track } from 'core/helpers/analytics';
import { withRetry } from 'core/helpers/with-retry';
import { REQUEST_QUOTE, SEND_CLUSTER_LINK, REQUEST_QUINSTREET_QUOTE } from './quote.queries';

const Error = types.model({
  code: types.union(types.string, types.number),
  message: types.maybe(types.string)
});

export const QuoteStore = types
  .model({
    loading: types.boolean,
    errors: types.array(Error),
    prefillData: types.maybeNull(types.frozen()),
    offer: types.maybeNull(types.frozen()),
    quinstreetOffer: types.maybeNull(types.frozen())
  })
  .actions((self) => ({
    requestQuinstreetQuoteAction: flow(function* requestQuinstreetQuote(quoteDetails, webUserId, rep) {
      self.errors = [];
      try {
        const { client } = getEnv(self);
        self.loading = true;

        const parent = getParent(self);

        yield parent.account.sendEventAsMember({
          eventName: 'Staff Generated Quinstreet Quote - Customer',
          properties: JSON.stringify({ webUserId, staffRep: rep.username }),
          userId: webUserId
        });

        track('Staff Generated Quinstreet Quote', { webUserId, staffRep: rep.username });
        const {
          data: { quinstreetOffer },
          errors
        } = yield withRetry(
          client.query({
            query: REQUEST_QUINSTREET_QUOTE,
            variables: { quoteDetails: mapRevisedQuinstreetQuoteDetails(quoteDetails) },
            fetchPolicy: 'no-cache'
          }),
          'requestQuinstreetQuote'
        );
        self.quinstreetOffer = quinstreetOffer;
        if (quinstreetOffer?.premium) {
          track('Staff Generated Quinstreet Quote Response Success', {
            webUserId,
            premium: quinstreetOffer.premium,
            staffRep: rep.username
          });
        } else if (errors?.length) {
          self.handleError(errors[0]);
          track('Staff Generated Quinstreet Quote Response Error', {
            webUserId,
            staffRep: rep.username
          });
        }
        self.loading = false;
      } catch (error) {
        track('Staff Generated Quinstreet Quote Response Error', {
          webUserId,
          staffRep: rep.username
        });
        // eslint-disable-next-line no-console
        console.error(`Error in requestQuinstreetQuote: ${error}`);
        self.loading = false;
        self.handleError(error);
      }
    }),
    requestQuoteAction: flow(function* requestQuote(userInput, allowedStates, retry = true) {
      self.errors = [];
      if (allowedStates.includes(userInput.state)) {
        // check that the user has a license for this state
        try {
          const { client } = getEnv(self);
          self.loading = true;
          track('Staff Generated Quote', { userInput });

          const {
            data: { offer },
            errors
          } = yield withRetry(
            client.query({
              query: REQUEST_QUOTE,
              variables: mapRequestQuoteToInputType(userInput, self.errors),
              fetchPolicy: 'no-cache'
            }),
            'requestQuote'
          );
          if (!offer && retry && errors?.[0]?.errorType === 'ExecutionTimeout') {
            self.requestQuoteAction(userInput, allowedStates, false);
            return;
          }
          if (shouldReject(offer.quote) || hasRejection(offer.quote)) {
            const { offerings } = offer.quote;
            const {
              autoRejectCode,
              homeownersRejectCode,
              condoRejectCode,
              monolineAutoRejectCode,
              monolineHomeRejectCode,
              monolineCondoRejectCode
            } = offerings;
            const filtered = [
              autoRejectCode,
              homeownersRejectCode,
              condoRejectCode,
              monolineAutoRejectCode,
              monolineHomeRejectCode,
              monolineCondoRejectCode
            ].filter((err) => !!err);
            self.errors = filtered.map((code) => ({ code }));
            self.loading = false;

            if (offer?.options?.length > 0) {
              self.offer = offer;
              self.loading = false;
            }
          } else {
            self.offer = offer;
            self.loading = false;
          }
          self.fetchAffinityData();
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(`Error in requestQuote: ${error}`);
          self.loading = false;
          self.handleError(error);
        }
      } else {
        const notLicensedError = {
          message:
            'You are not licensed in this state, if you believe this is a mistake, please contact Branch at 833-4BRANCH (833-427-2624) support@ourbranch.com'
        };
        self.loading = false;
        self.handleError(notLicensedError);
      }
    }),
    sendClusterLink: flow(function* sendClusterLink(offerId, email, quote, agent) {
      const { client } = getEnv(self);
      return yield client.mutate({
        mutation: SEND_CLUSTER_LINK,
        variables: {
          offerId,
          revisedQuoteDetails: quote,
          email,
          agent
        }
      });
    }),
    fetchAffinityData: flow(function* fetchAffinityData(affinity) {
      if (affinity) {
        const { affinityLookups } = getParent(self);
        yield affinityLookups.getByAffinity(affinity);
      }
    }),
    handleError: (error) => {
      const parsed = formattedError(error);
      if (parsed) {
        self.errors = [...self.errors, parsed];
      }
    },
    setQuinstreetOffer(quinstreetOffer) {
      self.quinstreetOffer = quinstreetOffer;
    },
    setPrefillData(quote, skipRejectionCheck) {
      if (!skipRejectionCheck) {
        if (shouldReject(quote) || hasRejection(quote)) {
          const { offerings } = quote;
          const { autoRejectCode, homeownersRejectCode, condoRejectCode } = offerings;
          const filtered = [autoRejectCode, homeownersRejectCode, condoRejectCode].filter((err) => !!err);
          self.errors = filtered.map((code) => ({ code }));
        }
      }
      self.prefillData = omit(quote, '__typename');
    },
    clearPrefillData() {
      self.prefillData = null;
    }
  }))
  .views((self) => ({
    get hasRejections() {
      return self.errors.find((err) => err.code && err.code >= 10000);
    },
    get hasCarrierKnockout() {
      return self.errors.some((err) => err.message === 'Carrier Knockout from Quinstreet');
    }
  }));
